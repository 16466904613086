import {
    QUIZ_MODE,
    MODULE_ID,
    LESSON_CLICKED,
    QUIZ_ACTIVE,
    START_QUIZ,
    USER_ANSWER,
    SELECTED_ANSWER,
    QUIZ_COMPLETED,
    QUIZ_SCORE,
    CLEAR_ALL,
    COURSE_ID,
    QUIZ_TYPE
} from './types.js'

export const setQuizMode = (inp) => {
    return {
        type: QUIZ_MODE,
        payload: inp
    }
}

export const setQuizModuleId = (inp) => {
    return {
        type: MODULE_ID,
        payload: inp
    }
}

export const setQuizCourseId = (inp) => {
    return {
        type: COURSE_ID,
        payload: inp
    }
}

export const setQuizType = (inp) => {
    return {
        type: QUIZ_TYPE,
        payload: inp
    }
}

export const isLessonURL = (inp) => {
    return {
        type: LESSON_CLICKED,
        payload: inp
    }
}

export const setQuizActive = (inp) => {
    return {
        type: QUIZ_ACTIVE,
        payload: inp
    }
}

export const beginQuiz = (inp) => {
    return {
        type: START_QUIZ,
        payload: inp
    }
}

export const setUserAnswer = (inp) => {
    return {
        type: USER_ANSWER,
        payload: inp
    }
}

export const isQuizCompleted = (inp) => {
    return {
        type: QUIZ_COMPLETED,
        payload: inp
    }
}

export const setCurrentAns = (inp) => {
    return {
        type: SELECTED_ANSWER,
        payload: inp
    }
}

export const setQuizScore = (inp) => {
    return {
        type: QUIZ_SCORE,
        payload: inp
    }
}

export const clearAllState = () => {
    return {
        type: CLEAR_ALL
    }
}