import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Form, Dropdown, Menu, Avatar } from 'antd'
import {
    FindInstructorById,
    acceptInstructorApplication,
    denyInstructorApplication,
} from '../../../../services'
import { openNotification } from '../../../../utils'
import { Button, Input } from '../../../../components'
import { ReviewDropdown } from '../../Components/Review Dropdown'
import Enabled from '../../assets/enabled.svg'
import Disabled from '../../assets/disabled.svg'

import './style.scss'

export const ApplicationReview = () => {
    const [instructor, setinstructor] = useState([])
    const [questionaire, setQuestionaire] = useState([])
    const [applicationFeedback, setApplicationFeedback] = useState('')
    console.log(applicationFeedback)

    const history = useHistory()

    const instructorIdAddress = window.location.pathname
    const instructorId = instructorIdAddress.split('/').pop()

    useEffect(() => {
        const getInstructorById = async () => {
            try {
                const response = await FindInstructorById(instructorId)
                setinstructor(response.data)
                setQuestionaire(
                    JSON.parse(response.data.questionnaire).Questionier
                )
                // console.log(response.data)
            } catch (err) {
                console.log(err)
            }
            // setQuestionLoading(false)
        }
        getInstructorById()
    }, [instructorId])

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <Button>Instructor Action Required</Button>
            </Menu.Item>
            <Menu.Item key="1">
                <Button>Report Under Review</Button>
            </Menu.Item>
        </Menu>
    )

    const profilePic = () => {
        if (instructor?.profilePicture) {
            return (
                <Avatar
                    size="large"
                    id="profilePic"
                    src={instructor.profilePicture}
                />
            )
        }
    }

    const applicationStatus = (val) => {
        if (val === 1) {
            return (
                <div className="approved">
                    <p>Approved</p>
                </div>
            )
        } else if (val === 2) {
            return (
                <div className="processing">
                    <p>Processing</p>
                </div>
            )
        } else if (val === 3) {
            return (
                <div className="rejected">
                    <p>Rejected</p>
                </div>
            )
        } else if (val === 4) {
            return (
                <div className="Suspended">
                    <p>Suspended</p>
                </div>
            )
        }
    }

    const approveInstructor = async () => {
        try {
            const response = await acceptInstructorApplication({
                id: instructor.id,
            })
            // console.log('response', response)
            openNotification({
                type: 'success',
                title: 'Approved Instuctor',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Approved Instuctor',
                message: error.message,
            })
        }
    }

    const denyinstructor = async () => {
        try {
            const response = await denyInstructorApplication({
                id: instructor.id,
            })
            // console.log('response', response)
            openNotification({
                type: 'success',
                title: 'Denied Instuctor',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Denied Instuctor',
                message: error.message,
            })
        }
    }

    const handleApprove = async (e) => {
        e.preventDefault()
        await approveInstructor()
        history.push('/admin/applications')
    }
    const handleDeny = async (e) => {
        e.preventDefault()
        await denyinstructor()
        history.push('/admin/applications')
    }
    return (
        <div className="instructorApplications">
            <div className="header">Application Review</div>
            <div className="instructorOverview">
                <div className="instructor">
                    <div className="instructorName">
                        {profilePic()}
                        <p>
                            {instructor.firstName + ' ' + instructor.lastName}
                        </p>
                    </div>
                    <div>
                        <p className="heading">Application Status:</p>
                        <p>
                            {applicationStatus(
                                instructor.instructorApplicationStatus
                            )}
                        </p>
                        <p className="heading">Reports Recived:</p>
                        <p>placeholder</p>
                    </div>
                </div>
                <div className="permissions">
                    <h5>Permissions</h5>
                    <div className="permissionContainer">
                        <div className="permission">
                            <img src={Enabled} alt="Enabled" />
                            <p>Courses</p>
                        </div>
                        <div className="permission">
                            <img src={Enabled} alt="Enabled" />
                            <p>Comments</p>
                        </div>
                        <div className="permission">
                            <img src={Disabled} alt="Disabled" />
                            <p>Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="subheading">Questionaire Review</h2>
            <div className="questionaire">
                <div className="questions">
                    <div>
                        <p className="question">
                            1. What type of teaching have you done before?
                        </p>
                        <p className="awnser">{questionaire[0]?.Answers}</p>
                    </div>
                    <div>
                        <p className="question">
                            2. How much experince making videos do you have?
                        </p>
                        <p className="awnser">{questionaire[1]?.Answers}</p>
                    </div>
                    <div>
                        <p className="question">
                            3. Do you have an audience to share your course
                            with?
                        </p>
                        <p className="awnser">{questionaire[2]?.Answers}</p>
                    </div>
                </div>
                <div className="review-dropdown">
                    <ReviewDropdown initVal={3} />
                </div>
            </div>
            <h2 className="subheading">CV</h2>
            <div className="cv files">
                <a href={instructor.cv} target="_blank" rel="noreferrer">
                    <p>{instructor.cv?.split('?')[0].split('/').pop()}</p>
                </a>
                <div className="review-dropdown">
                    <ReviewDropdown initVal={3} />
                </div>
            </div>
            <h2 className="subheading">Personal Statement</h2>
            <div className="pStatement files">
                <a href={instructor.biography} target="_blank" rel="noreferrer">
                    <p>
                        {instructor.biography?.split('?')[0].split('/').pop()}
                    </p>
                </a>
                <div className="review-dropdown">
                    <ReviewDropdown initVal={3} />
                </div>
            </div>
            <h2 className="subheading">Qualifications</h2>
            <div className="qualifications files">
                {/* when we have the wualifications files from the endpoint map through them here to show the entire list */}
                <a href={instructor.biography} target="_blank" rel="noreferrer">
                    <p>
                        placholder
                        {/* {instructor.biography?.split('?')[0].split('/').pop()} */}
                    </p>
                </a>
                <div className="review-dropdown">
                    <ReviewDropdown initVal={3} />
                </div>
            </div>
            <br />
            <h3>Additional Comments</h3>
            <Form>
                <Input.TextArea
                    className="comment-textarea"
                    placeholder="Additional Comments"
                    type="text"
                    name="summary"
                    required="required"
                    onChange={(e) => {
                        setApplicationFeedback(e.target.value)
                    }}
                />
            </Form>
            <div className="submitButtons">
                <Button onClick={handleDeny} className="deny">
                    Deny Application
                </Button>
                <Dropdown overlay={menu}>
                    <Button
                        className="ant-dropdown-link dropDown"
                        onClick={(e) => e.preventDefault()}
                    >
                        Set Status
                    </Button>
                </Dropdown>
                <Button onClick={handleApprove} className="approve">
                    Approve Application
                </Button>
            </div>
        </div>
    )
}
