import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CheckoutForm from '../Subscription/Stripe/CheckoutForm'
import { createIntentCardPayment } from '../../../../services'

import './styles.scss'

// Stripe is initialized with the key from the .env file
const stripePromise = loadStripe(
    'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
)

export const Payment = (props) => {
    const [clientSecret, setClientSecret] = useState('')

    const location = useLocation()
    useEffect(() => {
        localStorage.setItem('plan', JSON.stringify(props.plan?.plan))
    })

    const plan = location?.state?.state

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads so it can be passed through
        const response = createIntentCardPayment({
            productId: plan?.plan,
        })

        // Receive the response, assign the clientsecret value to the value received
        response.then((data) => {
            const secret = data
            setClientSecret(secret.data.clientSecret)
        })

        // Console log for testing remove before deployment
    }, [plan])

    // Sets the stripe styling to the selected stripe theme
    const appearance = {
        theme: 'stripe',
    }

    // Pass the appearance object to the elements instance
    const options = {
        clientSecret,
        appearance,
    }

    return (
        <div id="stripeContainer">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    )
}
