import React from 'react'
import { Link } from 'react-router-dom'
import { DownloadCertButton } from './DownloadCertButton'

export const ButtonProgressModes = ({ id, progressStatus }) => {
    // Dynamically returns the appropriate progress mode text of the course in the button of the enrolled page

    const buttonText = () => {
        switch (progressStatus) {
            case 0:
                return 'Start Learning'
            case 100:
                return 'Download Certificate'
            default:
                if (progressStatus >= 1 && progressStatus <= 99) {
                    return 'Continue Learning'
                }
                return 'Start Learning'
        }
    }

    return (
        <div>
            {progressStatus === 100 ? (
                <DownloadCertButton courseId={id} />
            ) : (
                <Link to={progressStatus < 100 && `/me/courses/course/${id}`}>
                    <button
                        className="progress-mode-btn"
                        // style={{
                        //     background: '#3A86FF',
                        //     color: 'white',
                        // }}
                    >
                        {buttonText()}
                    </button>
                </Link>
            )}
        </div>
    )
}

// export default ButtonProgressModes
