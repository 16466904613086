import { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { routes } from './routes'
import { ProtectedRoute } from './ProtectedRoute'
import { useAuth } from '../hooks'
import {
    getAuthToken,
    getLocationHistory,
    removeLocationHistory,
} from '../utils'
import { Loading } from '../components'

export const AppRouter = (props) => {
    const {
        getProfile,
        state: { isAppLoading, isLoggedIn },
    } = useAuth()

    const history = useHistory()

    useEffect(() => {
        const getP = async () => {
            if (getAuthToken()) {
                await getProfile()
                if (getLocationHistory()) {
                    history.push(getLocationHistory())
                    removeLocationHistory()
                }
            }
        }
        getP()
    }, [getProfile, history, isLoggedIn])

    if (isAppLoading) {
        return <Loading />
    }

    return (
        <Switch>
            {routes.map((route) => {
                return route.protected ? (
                    <ProtectedRoute key={route.name} {...route} {...props} />
                ) : (
                    <Route key={route.name} {...route} {...props} />
                )
            })}
        </Switch>
    )
}
