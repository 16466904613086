import { Link } from 'react-router-dom'

const NavList = ({ setShowLinks, setOpen }) => {
    const links = [
        {
            id: 1,
            url: '/',
            text: 'Home',
        },
        {
            id: 2,
            url: '/courses',
            text: 'Courses',
        },
        {
            id: 3,
            url: '/pricing',
            text: 'Pricing',
        },
        {
            id: 4,
            url: '/teach',
            text: 'Become an Instuctor',
        },
        {
            id: 5,
            url: '/contact',
            text: 'Contact Us',
        },
    ]
    return (
        <ul className="links">
            {links.map((link) => {
                const { id, url, text, component } = link
                return (
                    <li key={id}>
                        <Link
                            to={url}
                            onClick={() => {
                                setShowLinks(false)
                                setOpen(false)
                            }}
                        >
                            {text || component}
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}

export default NavList
