import { Link, Redirect } from 'react-router-dom'
// import Form from ant design
import { Form } from 'antd'
import { Input, Button } from '../../../components'
import { useAuth } from '../../../hooks'
import { getDeviceId } from '../../../utils'
import ZusLogo from '../shared/zus-logo/ZusLogo'
import Exit from '../shared/assets/exit.svg'
import image from '../shared/assets/admin-login.svg'
// import styles
import '../shared/FormStyling.scss'

export const AdminLogin = () => {
    const {
        state: { isLoading, redirectTo },
        adminlogin: loginAdmin,
    } = useAuth()

    // Ant Form
    const [form] = Form.useForm()

    const handleLogin = (values) => {
        loginAdmin({ ...values, deviceId: getDeviceId() })
    }

    // function countDown() {
    //     const modal = Modal.error({
    //         title: 'User Not Found',
    //         content: `Please proceed to user default Log In`,
    //     })

    //     setTimeout(() => {
    //         modal.destroy()
    //     }, 5000)
    // }

    if (redirectTo) {
        return <Redirect to={redirectTo} />
    }
    return (
        <section id="signin">
            <div className="container">
                <div className="logo-exit">
                    <div className="logo-zustech-form">
                        <Link to="/">
                            <ZusLogo />
                        </Link>
                    </div>
                </div>
                <main>
                    <Form
                        name="form"
                        form={form}
                        initialValues={{
                            rememberMe: false,
                        }}
                        onFinish={handleLogin}
                        autoComplete="on"
                    >
                        <div className="form-container">
                            <div className="form-btn">
                                <Link to="/account/login">
                                    <button
                                        type="button"
                                        className="signIn-btn"
                                    >
                                        User Sign in
                                    </button>
                                </Link>
                            </div>
                            <div className="header">
                                <h1>Welcome Admin</h1>
                                <p className="cta-signin">
                                    Sign in to continue
                                </p>
                            </div>
                            <div className="py-1">
                                <Input
                                    name="userName"
                                    validationRules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please input a valid email!',
                                        },
                                    ]}
                                    placeholder="Email"
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="py-1">
                                <Input.Password
                                    name="password"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your password!',
                                        },
                                    ]}
                                    placeholder="Password"
                                    disabled={isLoading}
                                />
                            </div>

                            <Input.Checkbox
                                type="checkbox"
                                name="rememberMe"
                                label="Remember me"
                                disabled={isLoading}
                                id="rememberCheckbox"
                            />

                            <Button
                                loading={isLoading}
                                type="submit"
                                disabled={isLoading}
                            >
                                Sign in
                            </Button>

                            <div className="forgot-password">
                                <p className="forgot-pwd-link">
                                    <Link to="/account/forgot-password">
                                        Forgot password?
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </Form>

                    <div className="welcome-message">
                        <div className="exit-to-home-right">
                            <Link to="/">
                                <img
                                    src={Exit}
                                    alt="exit-cross"
                                    className="return-home-right"
                                />
                            </Link>
                        </div>
                        <div className="welcome-container">
                            <div className="content">
                                <h1>Welcome Admin</h1>
                                <p>Sign in to continue</p>
                            </div>
                            <img src={image} alt="" className="image" />
                        </div>
                    </div>
                </main>
            </div>
        </section>
    )
}
