import {
    SET_CATEGORY,
    SET_COURSE_PRICE,
    SET_COURSE_TYPE,
    SET_COVER_IMAGE,
    SET_PREREQUISITE,
    SET_DIFFICULTY_LEVEL,
    SET_MODULES,
    SET_OBJECTIVES,
    SET_COURSE_TITLE,
    SET_COURSE_LANGUAGE,
    SET_INTRODUCTORY_LESSONS,
    SET_DESCRIPTION,
    CLEAR_COURSE_FORM,
    REMOVE_FROM_PREREQUISITE,
    REMOVE_FROM_OBJECTIVES,
    REMOVE_COVERIMG
} from './types'

export const setCategory = (inp) => {
    return {
        type: SET_CATEGORY,
        payload: inp
    }
}
export const setCourseTitle = (inp) => {
    return {
        type: SET_COURSE_TITLE,
        payload: inp
    }
}
export const setCoursePrice = (inp) => {
    return {
        type: SET_COURSE_PRICE,
        payload: inp
    }
}
export const setCourseLanguage = (inp) => {
    return {
        type: SET_COURSE_LANGUAGE,
        payload: inp
    }
}
export const setCourseType = (inp) => {
    return {
        type: SET_COURSE_TYPE,
        payload: inp
    }
}
export const setCourseImg = (inp) => {
    return {
        type: SET_COVER_IMAGE,
        payload: inp
    }
}
export const setPrerequisite = (inp) => {
    return {
        type: SET_PREREQUISITE,
        payload: inp
    }
}
export const setDiffLvl = (inp) => {
    return {
        type: SET_DIFFICULTY_LEVEL,
        payload: inp
    }
}
export const setModules = (inp) => {
    return {
        type: SET_MODULES,
        payload: inp
    }
}
export const setIntroLessons = (inp) => {
    return {
        type: SET_INTRODUCTORY_LESSONS,
        payload: inp
    }
}
export const setObjective = (inp) => {
    return {
        type: SET_OBJECTIVES,
        payload: inp
    }
}
export const setDescription = (inp) => {
    return {
        type: SET_DESCRIPTION,
        payload: inp
    }
}
export const clearCourseForm = () => {
    return {
        type: CLEAR_COURSE_FORM
    }
}
export const removeFromObj = (inp) => {
    return {
        type: REMOVE_FROM_OBJECTIVES,
        payload: inp
    }
}
export const removeFromPrequi = (inp) => {
    return {
        type: REMOVE_FROM_PREREQUISITE,
        payload: inp
    }
}
export const removeCoverImg = () => {
    return {
        type: REMOVE_COVERIMG
    }
}