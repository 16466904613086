import React from 'react'
import './LoadingSpinner.scss'

const LoadingSpinner = () => {
    return (
        <div className="loading-container">
            <div className="loading-spinner" />
        </div>
    )
}

export default LoadingSpinner
