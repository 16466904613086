import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Select, Button, Table, Modal } from 'antd'
import {
    useForm,
    useInstructorData
} from '../../../hooks'
import {
    findModuleByInstructorId,
    createQuizQuestion,
    getCourseByModuleId,
    findCourseByInstructorId
} from '../../../services'
import { openNotification } from '../../../utils/helpers'
import arrowOut from '../../../assets/icons/VectorQuizArrowOut.svg'
import DelIcon from '../../../assets/icons/tableBin.svg'
import '../CreateEditCourses/styles.scss'

function OptionSelection({ options, goHome, goBack }) {
    const {
        quizForm,
        setOptionA,
        setOptionB,
        setOptionC,
        setOptionD,
        setQuestionAns
    } = useForm()

    return (
        <div className="subform">
            <ul className="CEQ_cont">
                <li>
                    <div
                        className="img_cont"
                        onClick={() => {
                            goBack('home')
                            setOptionA('')
                            setOptionB('')
                            setOptionC('')
                            setOptionD('')
                        }}
                    >
                        <img
                            src={arrowOut}
                            alt="arrowout"
                        />
                    </div>
                    <p>Options (Select Answer)</p>
                </li>
                {options.map((option) => (
                    <li key={option}>
                        <button
                            id={option}
                            className={quizForm.answer === option ? 'selected' : ''}
                            onClick={() => {
                                setQuestionAns(option)
                                goHome('')
                            }}
                        >{option}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function CloseEndedQuest({ goBack }) {
    const {
        quizForm,
        setQuizOptions,
        setOptionA,
        setOptionB,
        setQuestionType
    } = useForm()
    const [home, setHome] = useState('')

    return (
        <>
            {(quizForm.type === '' || home === 'home') ?
                (
                    <div className="subform" id="CEQ">
                        <ul className="CEQ_cont CEQ">
                            <li>
                                <div
                                    className="img_cont"
                                    onClick={() => {
                                        goBack('')
                                    }}
                                >
                                    <img
                                        src={arrowOut}
                                        alt="arrowout"
                                    />
                                </div>
                                <p>Close Ended Questions (Yes/No or True/False)</p>
                            </li>
                            <li>
                                <button
                                    className={quizForm.type === 'Yes/No' ? 'selected' : ''}
                                    onClick={() => {
                                        setQuestionType('TrueOrFalse')
                                        setQuizOptions(['Yes', 'No'])
                                        setOptionA('Yes')
                                        setOptionB('No')
                                        setHome('updated')
                                    }}
                                    id="yesOrNo"
                                >Yes / No
                                </button>
                            </li>
                            <li>
                                <button
                                    className={quizForm.type === 'True/False' ? 'selected' : ''}
                                    onClick={() => {
                                        setQuestionType('TrueOrFalse')
                                        setQuizOptions(['True', 'False'])
                                        setOptionA('True')
                                        setOptionB('False')
                                        setHome('updated')
                                    }}
                                    id="trueOrFalse"
                                >True / False
                                </button>
                            </li>
                        </ul>
                    </div>
                ) :
                (
                    (quizForm.type !== '') &&
                        <OptionSelection id="answerSelection" options={quizForm.options} goHome={goBack} goBack={setHome} />
                )}
        </>
    )
}

function Multiplechoice({ goBack }) {
    const [home, setHome] = useState('')
    const {
        quizForm,
        setOptionA,
        setOptionB,
        setOptionC,
        setOptionD,
        setQuestionType,
        setQuizOptions
    } = useForm()

    return (
        <>
            {(quizForm.type === '' || home === 'home') ?
                (
                    <div className="subform" id="multichoice">
                        <ul className="CEQ_cont multichoice">
                            <li>
                                <div
                                    className="img_cont"
                                    onClick={() => goBack('')}
                                >
                                    <img
                                        src={arrowOut}
                                        alt="arrowout"
                                    />
                                </div>
                                <p>Type in Option(s)</p>
                            </li>
                            <li className="option_cont">
                                <div className="option_label">a</div>
                                <div className="option_input_field">
                                    <Form.Item>
                                        <Input
                                            name="optionA"
                                            id="optionA"
                                            placeholder="Provide option"
                                            value={quizForm.optionA}
                                            onChange={(e) => { setOptionA(e.target.value) }}
                                        />
                                    </Form.Item>
                                </div>
                            </li>
                            <li className="option_cont">
                                <div className="option_label">b</div>
                                <div className="option_input_field">
                                    <Form.Item>
                                        <Input
                                            name="optionB"
                                            id="optionB"
                                            placeholder="Provide option"
                                            value={quizForm.optionB}
                                            onChange={(e) => { setOptionB(e.target.value) }}
                                        />
                                    </Form.Item>
                                </div>
                            </li>
                            <li className="option_cont">
                                <div className="option_label">c</div>
                                <div className="option_input_field">
                                    <Form.Item>
                                        <Input
                                            name="optionC"
                                            id="optionC"
                                            placeholder="Provide option"
                                            value={quizForm.optionC}
                                            onChange={(e) => { setOptionC(e.target.value) }}
                                        />
                                    </Form.Item>
                                </div>
                            </li>
                            <li className="option_cont">
                                <div className="option_label">d</div>
                                <div className="option_input_field">
                                    <Form.Item>
                                        <Input
                                            name="optionD"
                                            id="optionD"
                                            placeholder="Provide option"
                                            value={quizForm.optionD}
                                            onChange={(e) => { setOptionD(e.target.value) }}
                                        />
                                    </Form.Item>
                                </div>
                            </li>
                        </ul>
                        <div className="btn-cont">
                            <Button
                                id="setMultipleOption"
                                onClick={() => {
                                    const options = []
                                    if (quizForm.optionA !== '') {
                                        options.push(quizForm.optionA)
                                    }
                                    if (quizForm.optionB !== '') {
                                        options.push(quizForm.optionB)
                                    }
                                    if (quizForm.optionC !== '') {
                                        options.push(quizForm.optionC)
                                    }
                                    if (quizForm.optionD !== '') {
                                        options.push(quizForm.optionD)
                                    }
                                    setQuestionType('SingleAnswerFromMultipleChoice')
                                    setQuizOptions(options)
                                    setHome('updated')
                                }}
                                type="primary"
                            >Next
                            </Button>
                        </div>
                    </div>
                ) : (
                    (quizForm.type !== '') &&
                        <OptionSelection id="answerSelection" options={quizForm.options} goHome={goBack} goBack={setHome} />
                )}
        </>
    )
}

export function QuizCreationPage() {
    const {
        quizForm,
        setQuizTitle,
        setQuizQuestion,
        setQuisAppliedModule,
        clearQuizForm
    } = useForm()
    const {
        state: { instructorID }
    } = useInstructorData()
    const history = useHistory()
    const [type, setType] = useState('')
    const [update, setUpdate] = useState()
    const [isCreatingQuest, setIsCreatingQuest] = useState(false)
    const { Option } = Select;
    const [instructorModules, setInstructorModules] = useState([])
    const [instructorCourses, setInstructorCourses] = useState([])
    const [selectedModules, setSelectedModules] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedCourseId, setSelectedCourseId] = useState(null)
    // const filterOptions = instructorModules.filter((o) => !selectedModules.includes(o.name))
    const [questionDataSource, setQuestionDataSource] = useState([])
    const [clearFieldModal, setClearFieldModal] = useState(false)
    const [leaveModal, setLeaveModal] = useState(false)
    const [showQuestComp, setShowQuestComp] = useState(true)
    const [initialModal, setInitialModal] = useState(false)
    const [courseSelectionModal, setCourseSelectionModal] = useState(false)
    const [courseOptions, setCourseOptions] = useState([])
    const [storedQuiz, setStoredQuiz] = useState([])
    const [quizType, setQuizType] = useState('')
    const QuestionTableColumn = [
        {
            title: 'QUESTION',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'QUESTION TYPE',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: 200
        },
        {
            title: 'ANSWER',
            dataIndex: 'answer',
            key: 'answer',
            align: 'center',
            width: 200
        },
        {
            key: 'action',
            render: (_, record) => (
                <div
                    id={record.id}
                    className="img_cont"
                    style={{
                        width: '30px',
                        margin: '0 auto',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setQuestionDataSource((prev) => prev.filter((ele) => ele.id !== record.id))
                        setUpdate(true)
                    }}
                >
                    <img src={DelIcon} alt="delete-icon" />
                </div>
            ),
            width: 100,
            align: 'center'
        },
    ]

    // Select field filter from antd
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        // Checking if there are saved quiz
        const storedQuiz = JSON.parse(localStorage.getItem('SavedQuiz'))
        // Fetching created question and quiz details that hasn't been used for creating quiz
        if (storedQuiz !== null && storedQuiz.length >= 1) {
            setStoredQuiz(storedQuiz)
            setInitialModal(true)
        }
    }, [])

    useEffect(() => {
        // Get neccessary module for module type quiz
        const getModules = async () => {
            try {
                const res = await findModuleByInstructorId(instructorID, 100, 1)
                setInstructorModules(res.data)
            } catch (error) {
                console.error(error.message)
            }
        }
        // Get instructor course for course type quiz
        const getCourse = async () => {
            try {
                const res = await findCourseByInstructorId(instructorID, 0, 1, 100)
                setInstructorCourses(res.data)
            } catch (error) {
                console.error(error.message)
            }
        }
        // Get instructor by module, so as to select course for previewing module level quiz
        const getCourseOption = async (id) => {
            // Fetchs all course a module is been used in
            try {
                const res = await getCourseByModuleId(id)
                console.log(id)
                if (res.data.length >= 1) {
                    console.log(res.data)
                    setCourseOptions(res.data)
                } else {
                    setCourseOptions([])
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        // setting course for preview only if module has been selected
        if (quizForm.appliedModule !== null) {
            getCourseOption(quizForm.appliedModule)
        }
        getModules()
        getCourse()
        return () => {
            setInstructorModules([])
        }
    }, [instructorID, quizForm.appliedModule])

    useEffect(() => {
        // getting selected module's ID and setting it to store
        // Neccessary for the input field, field needs name, endpoint needs id
        const getSelectedModuleId = () => {
            // Setting the id of the selected module
            if (selectedModules !== null && instructorModules.length !== 0) {
                for (let j = 0; j < instructorModules.length; j++) {
                    if (selectedModules === instructorModules[j].name) {
                        const id = instructorModules[j].id
                        setQuisAppliedModule(id)
                    }
                }
            }
        }

        const getSelectedCourseId = () => {
            if (selectedCourse !== null && instructorCourses.length !== 0) {
                for (let j = 0; j < instructorCourses.length; j++) {
                    if (selectedCourse === instructorCourses[j].name) {
                        const id = instructorCourses[j].id
                        setSelectedCourseId(id)
                        setUpdate(true)
                    }
                }
            }
        }
        getSelectedModuleId()
        getSelectedCourseId()
        // eslint-disable-next-line
    }, [selectedModules, selectedCourse])

    useEffect(() => {
        const storedData = localStorage.getItem('QuizInfo')
        const getQuizInfoFromStore = () => {
            const dataObj = JSON.parse(storedData)
            if (dataObj !== null) {
                setQuizTitle(dataObj.quizTitle)
                setQuestionDataSource(dataObj.question)
                setQuizType(dataObj.quizType)
                if (dataObj.quizType === 'Module') {
                    setQuisAppliedModule(dataObj.Id)
                    setSelectedModules(dataObj.moduleName)
                } else if (dataObj.quizType === 'Course') {
                    setSelectedCourseId(dataObj.Id)
                    setSelectedCourse(dataObj.courseName)
                }
            }
        }
        const storeQuizInfo = () => {
            const data = {
                quizTitle: quizForm.title,
                question: questionDataSource,
                quizType: quizType,
            }
            if (quizType === 'Module') {
                data['Id'] = quizForm.appliedModule
                data['moduleName'] = selectedModules
            }
            if (quizType === 'Course') {
                data['Id'] = selectedCourseId
                data['courseName'] = selectedCourse
            }
            localStorage.setItem('QuizInfo', JSON.stringify(data))
            setUpdate(false)
        }
        if (update === true) {
            storeQuizInfo()
        } else {
            getQuizInfoFromStore()
        }
        // eslint-disable-next-line
    }, [update])

    // CREATE QUESTION LOGICS
    async function createQuestion() {
        setIsCreatingQuest(true)
        const formData = new FormData()
        // SETTING THE TYPE OF QUESTION
        switch (quizForm.type) {
            case 'MultipleAnswerFromMultipleChoice':
                formData.append('QuestionType', 0)
                break
            case 'ShortAnswer':
                formData.append('QuestionType', 1)
                break
            case 'SingleAnswerFromMultipleChoice':
                formData.append('QuestionType', 2)
                break
            case 'Paragraph':
                formData.append('QuestionType', 3)
                break
            case 'TrueOrFalse':
                formData.append('QuestionType', 4)
                break
            default:
                formData.append('QuestionType', null)
        }
        // SETTING QUESTION OPTIONS
        if ((quizForm.optionA !== '')) {
            formData.append('OptionA', quizForm.optionA)
        }
        if ((quizForm.optionB !== '')) {
            formData.append('OptionB', quizForm.optionB)
        }
        if ((quizForm.optionC !== '')) {
            formData.append('OptionC', quizForm.optionC)
        }
        if ((quizForm.optionD !== '')) {
            formData.append('OptionD', quizForm.optionD)
        }
        // SETTING QUESTIONLEVEL (no means of getting this from the instructor yet, so setting to one)
        formData.append('QuestionLevel', 1)
        formData.append('TestQuestion', quizForm.question)
        formData.append('CorrectAnswer', quizForm.answer)
        formData.append('TopicId', 2)
        try {
            const questStore = JSON.parse(localStorage.getItem('QuestStore'))
            const res = await createQuizQuestion(formData)
            if (res.data) {
                const questionObj = {
                    key: res.data.id,
                    id: res.data.id,
                    question: quizForm.question,
                    type: quizForm.type,
                    answer: quizForm.answer
                }
                if (questStore !== null) {
                    questStore.push(res.data)
                    localStorage.setItem('QuestStore', JSON.stringify(questStore))
                } else {
                    const questionCollection = [res.data]
                    localStorage.setItem('QuestStore', JSON.stringify(questionCollection))
                }
                setQuestionDataSource(prev => ([...prev, questionObj]))
                clearQuizForm()
                setUpdate(true)
                setIsCreatingQuest(false)
                openNotification({
                    title: 'Question Creation',
                    message: 'Question Created Successfully',
                    type: 'success'
                })
            }
        } catch (err) {
            setIsCreatingQuest(false)
            console.error(err.message)
            openNotification({
                title: 'Question Creation',
                message: err.message,
                type: 'error'
            })
        }
    }

    // VALIDATION AND VALUE CHECKING
    function validateForm() {
        if (quizForm.question === '') {
            openNotification({
                title: 'Quiz Question',
                type: 'error',
                message: 'Kindly provide quiz question'
            })
            return false
        } else if (quizForm.type === '') {
            openNotification({
                title: 'Question Type',
                type: 'error',
                message: 'Kindly select quiz type'
            })
            return false
        } else if (quizForm.type === 'multichoice' && quizForm.options.length > 1) {
            openNotification({
                title: 'Question Options',
                type: 'error',
                message: 'Multiplechoice isn\'t set yet'
            })
            return false
        } else if (quizForm.answer === 'Select quiz type...') {
            openNotification({
                title: 'Question Answer',
                type: 'error',
                message: 'Kindly provide quiz answer'
            })
            return false
        }
        return true
    }

    return (
        <div className="form__cont quiz_form">
            <h2>Create Quiz/Test</h2>
            {showQuestComp &&
            <div className="questionTableCont">
                {(questionDataSource.length !== 0) ? (<Table
                    dataSource={questionDataSource}
                    columns={QuestionTableColumn}
                    scroll={{
                        y: 180,
                    }}
                    id="questionTable"
                    pagination={{
                        position: ['none', 'none'],
                    }}
                />) : (<h4>Question has not been created yet</h4>)}
            </div>}
            <Form>
                {showQuestComp &&
                <>
                    <div className="form__item__cont">
                        <label>Question</label>
                        <Form.Item>
                            <Input
                                name="quizQuestion"
                                id="quizQuestion"
                                placeholder="Question to ask student..."
                                value={quizForm.question}
                                onChange={(e) => { setQuizQuestion(e.target.value) }}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>{quizForm.answer !== 'Select question type...' ? 'Answer' : 'Question Type'}</label>
                        {type === '' &&
                        <Form.Item>
                            <Select
                                id="questionTypeSelection"
                                placeholder="Select question type..."
                                onChange={(value) => { setType(value) }}
                                value={quizForm.answer}
                            >
                                <Option id="closeEnd" value="choice">
                                    Closed end questions (YES/NO or TRUE/FALSE)
                                </Option>
                                <Option id="multiChoice" value="MultipleChoice">
                                    Multi-choice questions
                                </Option>
                            </Select>
                        </Form.Item>}
                        {type === 'choice' && <CloseEndedQuest id="closeEndComponent" goBack={setType} />}
                        {type === 'MultipleChoice' && <Multiplechoice id="multiChoiceComponent" goBack={setType} />}
                    </div>
                    <div className="btn__cont">
                        <Button
                            onClick={() => {
                                // setIsloading(true)
                                // Open a modal to ask if all questions should be cleared as well
                                setClearFieldModal(true)
                            }}
                            type="primary"
                            id="clearallBtn"
                        >Clear all
                        </Button>
                        <Button
                            id="createQuestBtn"
                            type="primary"
                            onClick={() => {
                                // setShowPC(false)
                                // setIsloading(true)
                                const cont = validateForm()
                                if (cont) {
                                    createQuestion()
                                } else {
                                    // setIsloading(false)
                                }
                            }}
                        // Latest update, "save and exit button doesn't do the work of the submit button"
                        >{isCreatingQuest ? 'Creating question...' : 'Save Question'}
                        </Button>
                    </div>
                </>}
                {!showQuestComp &&
                <div className="product__summary">
                    <p> {questionDataSource.length} Question(s) </p>
                    <div
                        className="button__like"
                        onClick={() => {
                            setShowQuestComp(true)
                        }}
                    >View
                    </div>
                </div>}
                <div className="btn__cont creator">
                    <Button
                        id="collaspeQuestionForm"
                        style={{
                            marginLeft: '30%'
                        }}
                        type="primary"
                        onClick={() => {
                            // close this section
                            const inverse = !showQuestComp
                            setShowQuestComp(inverse)
                        }}
                    >{showQuestComp ? 'Save and Close' : 'Add More Question'}
                    </Button>
                </div>
                <>
                    <div className="form__item__cont">
                        <label> Quiz Title</label>
                        <Form.Item>
                            <Input
                                name="quizName"
                                id="quizName"
                                placeholder="Provide quiz title..."
                                value={quizForm.title}
                                onChange={(e) => {
                                    setQuizTitle(e.target.value)
                                    setUpdate(true)
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Quiz Type</label>
                        <Form.Item
                            id="quizType"
                            style={{ backgroundColor: '#fdfdfd', borderRadius: '8px', boxShadow: '0px 4px 4px 0px #0000001A' }}
                        >
                            <div className="btn__cont">
                                <button
                                    id="courseQuiz"
                                    className={quizType === 'Course' ? 'active' : 'btn'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setQuizType('Course')
                                    }}
                                > Course Quiz
                                </button>
                                <button
                                    id="moduleQuiz"
                                    className={quizType === 'Module' ? 'active' : 'btn'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setQuizType('Module')
                                    }}
                                > Module Quiz
                                </button>
                            </div>
                        </Form.Item>
                    </div>
                    {(quizType === 'Module') &&
                    <div className="form__item__cont">
                        <label>Select Modules to Apply Quiz</label>
                        <Form.Item>
                            <Select
                                id="moduleSelection"
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select modules for quiz..."
                                value={selectedModules}
                                onChange={(value) => {
                                    setSelectedModules(value)
                                }}
                                filterOption={filterOption}
                                options={instructorModules.map(item => ({
                                    value: item.name,
                                    label: item.name,
                                    key: item.id
                                }))}
                            />
                        </Form.Item>
                    </div>}
                    {(quizType === 'Course') &&
                    <div className="form__item__cont">
                        <label>Select Course to Apply Quiz</label>
                        <Form.Item>
                            <Select
                                id="courseSelection"
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select course for quiz..."
                                value={selectedCourse}
                                onChange={(value) => {
                                    setSelectedCourse(value)
                                }}
                                filterOption={filterOption}
                                options={instructorCourses.map(item => ({
                                    value: item.name,
                                    label: item.name,
                                    key: item.id
                                }))}
                            />
                        </Form.Item>
                    </div>}
                    {(questionDataSource.length < 2) &&
                    <div className="creation_info">
                        Minimum of 1 question is required for Quiz creation
                    </div>}
                    <div className="btn__cont creator">
                        <Button
                            id="saveQuizBtn"
                            type="primary"
                            onClick={() => {
                                if (quizForm.title !== '') {
                                    const savedQuiz = JSON.parse(localStorage.getItem('SavedQuiz'))
                                    const currentQuiz = {
                                        quizTitle: quizForm.title,
                                        question: questionDataSource,
                                        quizType: quizType
                                    }
                                    switch (quizType) {
                                        case 'Module':
                                            currentQuiz['Id'] = quizForm.appliedModule
                                            currentQuiz['moduleName'] = selectedModules
                                            break
                                        case 'Course':
                                            currentQuiz['Id'] = selectedCourseId
                                            currentQuiz['courseName'] = selectedCourse
                                            break
                                        default:
                                            break
                                    }
                                    if (savedQuiz !== null && savedQuiz.length >= 1) {
                                        for (let i = 0; i <= savedQuiz.length; i++) {
                                            if (savedQuiz[i].quizTitle === quizForm.title) {
                                                savedQuiz[i].question = questionDataSource
                                                savedQuiz[i].quizType = quizType
                                                switch (quizType) {
                                                    case 'Module':
                                                        savedQuiz[i].Id = quizForm.appliedModule
                                                        savedQuiz[i].moduleName = selectedModules
                                                        break
                                                    case 'Course':
                                                        savedQuiz[i].Id = selectedCourseId
                                                        savedQuiz[i].courseName = selectedCourse
                                                        break
                                                    default:
                                                        break
                                                }
                                                // savedQuiz[i].moduleId = quizForm.appliedModule
                                                // savedQuiz[i].moduleName = selectedModules
                                                localStorage.setItem('SavedQuiz', JSON.stringify(savedQuiz))
                                                break
                                            } else if (i === savedQuiz.length) {
                                                savedQuiz.push(currentQuiz)
                                                localStorage.setItem('SavedQuiz', JSON.stringify(savedQuiz))
                                            }
                                        }
                                    } else {
                                        localStorage.setItem('SavedQuiz', JSON.stringify([currentQuiz]))
                                    }
                                    clearQuizForm()
                                    setQuizTitle('')
                                    setQuestionDataSource([])
                                    setQuisAppliedModule('')
                                    setSelectedModules('')
                                    setSelectedCourse('')
                                    openNotification({
                                        title: 'Quiz Info Saved',
                                        message: 'You just saved some quiz data for Later use',
                                        type: 'success'
                                    })
                                    setLeaveModal(true)
                                } else {
                                    openNotification({
                                        title: 'Quiz Title',
                                        type: 'error',
                                        message: 'Kindly provide quiz title'
                                    })
                                    return false
                                }
                            }}
                        >Save Quiz
                        </Button>
                        <Button
                            id="createQuizBtn"
                            type="primary"
                            disabled={(questionDataSource.length < 1)}
                            onClick={() => {
                                if (quizForm.title === '') {
                                    openNotification({
                                        title: 'Quiz Title',
                                        type: 'error',
                                        message: 'Kindly provide quiz title'
                                    })
                                    return false
                                }
                                if (quizType === 'Module') {
                                    if (quizForm.appliedModule.length < 1) {
                                        openNotification({
                                            title: 'Quiz Applied Modules',
                                            type: 'error',
                                            message: 'Kindle select module to apply quiz'
                                        })
                                        return false
                                    } else {
                                        setCourseSelectionModal(true)
                                        setUpdate(true)
                                        // createQuiz()
                                    }
                                }
                                if (quizType === 'Course') {
                                    if (selectedCourse === null) {
                                        openNotification({
                                            title: 'Quiz Applied Course',
                                            type: 'error',
                                            message: 'Kindle select course to apply quiz'
                                        })
                                        return false
                                    } else {
                                        // setUpdate(true)
                                        history.push(`/tutor/preview-quiz/${selectedCourseId}`)
                                    }
                                }
                            }}
                        >Preview Quiz
                        </Button>
                    </div>
                </>
                {/* Clear Question Input field */}
                <Modal
                    centered
                    id="ModalCourseCreation"
                    footer={null}
                    open={clearFieldModal}
                    // open={true}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        clearQuizForm()
                        setClearFieldModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>Clear Available Question Data(s)</h4>
                        <div style={{ marginBlock: '2em' }}>
                            Do you want to delete all questions in the Question Table as well?
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                /** button to submit the form */
                                key={Math.random() * 100}
                                size="middle"
                                id="deleteAll"
                                type="primary"
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    // marginLeft: '200px',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    clearQuizForm()
                                    setQuestionDataSource([])
                                    setClearFieldModal(false)
                                }}
                            >Yes
                            </Button>
                            <Button
                                /** button to submit the form */
                                key={Math.random() * 100}
                                size="middle"
                                id="deleteAll"
                                type="primary"
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    marginLeft: '50px',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    clearQuizForm()
                                    setClearFieldModal(false)
                                }}
                            >No
                            </Button>
                        </div>
                    </div>
                </Modal>
                {/* Question success modal */}
                <Modal
                    centered
                    id="questionCreated"
                    footer={null}
                    // open={clearFieldModal}
                    open={false}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        setClearFieldModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>Question Created Successfully</h4>
                        <div style={{ marginBlock: '2em' }}>
                            Do you want to delete all questions in the Question Table as well?
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                /** button to submit the form */
                                key={Math.random() * 100}
                                size="middle"
                                id="deleteAll"
                                type="primary"
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    // marginLeft: '200px',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setClearFieldModal(false)
                                }}
                            >Yes
                            </Button>
                            <Button
                                /** button to submit the form */
                                key={Math.random() * 100}
                                size="middle"
                                id="deleteAll"
                                type="primary"
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    marginLeft: '50px',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    clearQuizForm()
                                    setClearFieldModal(false)
                                }}
                            >No
                            </Button>
                        </div>
                    </div>
                </Modal>
                {/* Course selection for preview */}
                <Modal
                    centered
                    id="questionCreated"
                    footer={null}
                    open={courseSelectionModal}
                    // open={false}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        setCourseSelectionModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>Course Selection For Quiz Preview</h4>
                        <div className="courseOptionCont" style={{ marginBlock: '2em' }}>
                            {(courseOptions.length >= 1) ?
                                <p>Select course you want to preview quiz on</p> :
                                <>
                                    <p>Selected Module is not used in any course</p>
                                    <p>Quiz can't be created for such module</p>
                                </>}
                            <ul className="course_options">
                                {courseOptions.length >= 1 &&
                                courseOptions.map(course => (
                                    <div
                                        className="courseOptionItem"
                                        key={course.id}
                                        onClick={() => {
                                            history.push(`/tutor/preview-quiz/${course.id}`)
                                        }}
                                    >{course.name}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal>
                {/* Start Afresh or Leave page */}
                <Modal
                    centered
                    id="leaveRequest"
                    footer={null}
                    open={leaveModal}
                    // open={true}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        setLeaveModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>After Effect of Saving Quiz</h4>
                        <div className="courseOptionCont" style={{ marginBlock: '2em' }}>
                            <p>Quiz Saved Successfully, Do you want to create another quiz?</p>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                                <Button
                                    /** button to submit the form */
                                    key={Math.random() * 100}
                                    size="middle"
                                    id="leaveForm"
                                    type="primary"
                                    style={{
                                        borderRadius: '10px',
                                        paddingInline: '2em',
                                        // marginLeft: '200px',
                                        height: '40px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        history.push('/tutor/courses')
                                    }}
                                >No, Exit Form
                                </Button>
                                <Button
                                    /** button to submit the form */
                                    key={Math.random() * 100}
                                    size="middle"
                                    id="stayToCreate"
                                    type="primary"
                                    style={{
                                        borderRadius: '10px',
                                        paddingInline: '2em',
                                        marginLeft: '50px',
                                        height: '40px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setLeaveModal(false)
                                    }}
                                >Yes, Create Another
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Initial Modal to either continue from saved quiz or start new form */}
                <Modal
                    centered
                    id="questionCreated"
                    footer={null}
                    open={initialModal}
                    // open={false}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        setInitialModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>Pending Quiz to Finish up</h4>
                        <div className="courseOptionCont" style={{ marginBlock: '2em' }}>
                            <p>Select quiz you want to complete creation or create new</p>
                            <ul className="course_options">
                                {storedQuiz.length >= 1 &&
                                storedQuiz.map(quiz => (
                                    <div
                                        className="courseOptionItem"
                                        key={quiz.quizTitle}
                                        onClick={() => {
                                            // history.push(`/tutor/preview-quiz/${course.id}`)
                                            setQuizTitle(quiz.quizTitle)
                                            setQuestionDataSource(quiz.question)
                                            setQuisAppliedModule(quiz.moduleId)
                                            setSelectedModules(quiz.moduleName)
                                            setInitialModal(false)
                                        }}
                                    >{quiz.quizTitle}
                                    </div>
                                ))}
                                <div
                                    onClick={() => {
                                        clearQuizForm()
                                        setInitialModal(false)
                                    }}
                                >Start Afresh
                                </div>
                            </ul>
                        </div>
                    </div>
                </Modal>
            </Form>
        </div>
    )
}