import React, { useState, useEffect } from 'react'
// import {
//     getAllSubscriptions,
//     createSubscription,
// } from '../../../../../services'
import { getAllPlans } from '../../../../../services'

import { openNotification } from '../../../../../utils/helpers'
import { Button } from '../../../../../components'
import { useAuth } from '../../../../../hooks'

import './checkout.scss'

export function CheckoutPage() {
    // This grabs the user data that was stored, used to display their name on the page
    const {
        state: { data: user },
    } = useAuth()

    const [subscriptionPlans, setSubscriptionPlans] = useState([])

    const getSubscriptions = async () => {
        try {
            const response = await getAllPlans()
            setSubscriptionPlans(response)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
    }

    useEffect(() => {
        getSubscriptions()
    }, [])

    // get the users curnedt plan details
    const planid = parseInt(localStorage.getItem('plan'), 10)
    const subscriptionPlan = subscriptionPlans.filter((v) => v.id === planid)
    const plan = subscriptionPlan[0]

    // Message declaration for local storage handling, used on second example, commented out to push for now
    // const [message, setMessage] = useState(null)

    // Date decleration; there's probably a much cleaner way of doing this, I imagine this can be replaced by grabbing the date from the API
    // The date is decladed in seperate consts because of formatting, declaring just the date as a whole looks ugly when displayed to the user, this way you can display nicer
    // declare a new date with the inbuilt date function
    const dateObj = new Date()
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()
    const date = day + '/' + month + '/' + year

    // dosn't take the 29th of february into account
    const getNewDate = (day, month, year, plan) => {
        let newday
        let newmonth
        let newyear

        const incrementDate = (day, month, year, plan) => {
            if (plan?.duration === 1) {
                const newday = day + 1
                const newmonth = month
                const newyear = year
                const newdate = [newday, newmonth, newyear]
                return newdate
            } else if (plan?.duration === 2) {
                const newday = day + 7
                const newmonth = month
                const newyear = year
                const newdate = [newday, newmonth, newyear]
                return newdate
            } else if (plan?.duration === 3) {
                const newday = day
                const newmonth = month + 1
                const newyear = year
                const newdate = [newday, newmonth, newyear]
                return newdate
            } else if (plan?.duration === 4) {
                const newday = day
                const newmonth = month
                const newyear = year + 1
                const newdate = [newday, newmonth, newyear]
                return newdate
            }
            const newdate = [newday, newmonth, newyear]
            return newdate
        }
        const newdate = incrementDate(day, month, year, plan)

        const dayCheck = (newdate) => {
            if (newdate[0] > 28 && newdate[1] === 2) {
                newdate[0] -= 28
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 1) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 3) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 5) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 7) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 9) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 30 && newdate[1] === 11) {
                newdate[0] -= 30
                newdate[1] += 1
                return newdate
            } else if (newdate[0] > 31) {
                newdate[0] -= 31
                newdate[1] += 1
                return newdate
            }
            return newdate
        }

        const checkedDay = dayCheck(newdate)

        const checkMonth = (newdate) => {
            if (newdate[1] > 12) {
                newdate[1] -= 12
                newdate[2] += 1
                return newdate
            }
            return newdate
        }

        const newDate = checkMonth(checkedDay)
        return newDate
    }
    const newdate = getNewDate(day, month, year, plan)
    const newDate = newdate[0] + '/' + newdate[1] + '/' + newdate[2]

    // Grabbing the payment intent from the URL
    // const payment_intent = new URLSearchParams(window.location.search).get(
    //     'payment_intent'
    // )

    // Grabbing the client secret from the URL
    // const payment_intent_client_secret = new URLSearchParams(
    //     window.location.search
    // ).get('payment_intent_client_secret')

    // Grabbing the success status from the URL
    // const success = new URLSearchParams(window.location.search).get(
    //     'redirect_status'
    // )

    return (
        <div id="checkout">
            <div id="content">
                <div id="left-col">
                    <p id="purchase-date">{date}</p>
                    <div id="plan-container">
                        <h3 id="m1">
                            Your <span>Plan</span>
                        </h3>
                        <p id="annual-tag">
                            <p id="plan-desc">
                                Thank you for your subscription to the{' '}
                                {plan?.name} : with this plan you’ll have full
                                access to all premium features the site
                            </p>
                            <p id="start-text">
                                Click the button below to get started!
                            </p>
                        </p>
                    </div>
                    <p id="renewal-text">
                        Renews on: <br />
                        <span id="renewal-date">{newDate}</span>
                        <br />
                    </p>
                    <p id="price-text">Price: £{plan?.amount}</p>
                </div>

                <div id="right-col">
                    <h2 id="thanks-message">Thank you for your purchase!</h2>
                    <p id="bodytext">
                        <span id="welcome">
                            {' '}
                            Welcome to Zustech, {user?.firstName}{' '}
                        </span>{' '}
                        <br />
                        You have bought, the {plan?.name} <br />
                        We hope you find your time here with us to be fun and
                        safe. If there’s anything we can do to make the
                        experience better. Please let us know!
                    </p>
                    <p id="subscription-message">
                        Your subscription will automatically renew on the last
                        renewal date. If this was a mistake you have 14 days to
                        cancel the subscription
                    </p>
                    <p id="support-message">
                        Please visit our support page if you are experiencing
                        any issues or have any questions!
                    </p>
                    <p id="bottom-thanks">
                        Thanks again, <br />
                        Zustech Team!
                    </p>
                </div>
                <div id="footer-column">
                    <Button id="continue-btn" to="/me/courses">
                        Get Started with Zustech!
                    </Button>
                </div>
            </div>
        </div>
    )
}
