import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
// Style sheet is in parent folder ../courseCard.scss

export function SicCourse({ thumbnail, CTA }) {
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className="course__card">
            <div className="course__thumbnail">
                <img src={thumbnail} alt="thumbnail" />
            </div>
            <div className="course__details">
                <h3>AI Technology Masterclass</h3>
                <div>
                    <p>Published 01/02/23</p>
                    <button>Still-in-creation</button>
                </div>
            </div>
            <div
                className="course__numericsI"
                style={{
                    justifyContent: 'center',
                    width: '30%',
                }}
            >
                <div className="top">
                    <p>Progess Status</p>
                    <p className="cc_progrs">22%</p>
                </div>
            </div>
            <div
                className="course__numericsII"
                style={{
                    width: '10%',
                }}
            >&nbsp;
            </div>
            <div className="course__status">
                <Link to="/">
                    <CTA
                        type="primary"
                        style={{
                            backgroundColor: '#C1CDF8',
                            color: '#3A86FF',
                            borderRadius: '6px',
                            border: 'none',
                            cursor: 'not-allowed'
                        }}
                    > Edit
                    </CTA>
                </Link>
                <CTA
                    type="primary"
                    danger
                    style={{
                        borderRadius: '6px',
                        cursor: 'not-allowed'
                    }}
                    onClick={() => { setOpenModal(true) }}
                > Delete
                </CTA>
            </div>
            <Modal
                centered
                keyboard="true"
                open={openModal}
                footer={null}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                style={{
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        marginBlock: '2em',
                    }}
                >
                    <h4
                        style={{
                            color: '#3a86ff'
                        }}
                    >Are you sure you want to delete AI technology masterclass
                    </h4>
                    <p>If you decide to delete AI Technology masterclass, you will never get a chance to bring it back</p>
                    <div
                        style={{
                            marginBlock: '1em 0.5em',
                            display: 'flex',
                            height: '80px',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                paddingInline: '2.3em',
                                borderRadius: '10px',
                            }}
                        >Cancel
                        </Button>
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                borderRadius: '10px',
                                paddingInline: '2em'
                            }}
                        >Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
