import React, { useState, useEffect } from 'react'
import { Progress } from 'antd'
import { GrStatusGood } from 'react-icons/gr'
import { useAuth } from '../../../../hooks'
// import ImageThumb from '../assets/thumbbcourse.png'
import './ActiveEnrolled.scss'
import { getUsersProgressOnCourse } from '../../../../services'
import { ButtonProgressModes } from './component/ButtonProgressModes'

export const ActiveEnrolled = ({ course, id }) => {
    const {
        state: { data: user },
    } = useAuth()
    const [progressStatus, setProgressStatus] = useState(0)
    const [coverImg, setCoverImg] = useState('https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg')
    // const [isLoading, setIsLoading] = useState(false)
    console.log(course)

    // Fetch user's course progress
    useEffect(() => {
        const getUsersProg = async () => {
            try {
                const res = await getUsersProgressOnCourse(course.id, user.id)
                setProgressStatus(res?.data?.progress)
            } catch (err) {
                console.error(err.message)
            }
        }
        if (course.coursePicture !== null && course.coursePicture !== 'mediaFD' && course.coursePicture !== '{}' && course.coursePicture !== 'string') {
            setCoverImg(course.coursePicture)
        }
        getUsersProg()
    // eslint-disable-next-line
    }, [])

    return (
        <div key={id} id={`enrolled-card-cont-${id}`} className="active-enrolled-cont">
            <div className="enrolled-card-cont">
                <img
                    src={coverImg}
                    className="enrolled-thumbnail"
                    alt=""
                />
                <div className="desc-content">
                    <h3
                        style={{
                            color: '#3A86FF', // courseType === 2 ? '#FFCC00' : '#3A86FF'
                        }}
                    >
                        {course.name}
                    </h3>
                    <p>{course.instructorName}</p>
                    <div className="prog-detail">
                        <Progress
                            percent={progressStatus}
                            status="active"
                            showInfo={true}
                        />
                    </div>
                    {progressStatus === 100 ?
                        (<div className="complete-course-text">You have successfully completed this course. <GrStatusGood /> </div>) :
                        null}
                </div>
                <div className="sect-progress-mode">
                    <ButtonProgressModes
                        courseType={course.courseType}
                        progressStatus={progressStatus}
                        id={course.id}
                    />
                    {progressStatus === 100 ?
                        (<div className="rate-review">Rate and Review</div>) :
                        null}
                </div>
            </div>
        </div>
    )
}