import DashboardIcon from '../assets/icons/dashboard_icon.svg'
import CourseMarketIcon from '../assets/icons/course_icon.svg'
import MyCoursesIcon from '../assets/icons/my_course_icon.svg'
import MyInstitutionalIcon from '../assets/icons/institutional icon.png'
import MyPremiumIcon from '../assets/icons/premium icon.png'
// import TaskIcon from '../assets/icons/task_icon.svg'
// import StatisticsIcon from '../assets/icons/stats_icon.svg'
// import ForumIcon from '../assets/icons/forum_icon.svg'
import SettingsIcon from '../assets/icons/settings_icon.svg'
import BillingIcon from '../assets/icons/billing_icon.svg'

export const userDashboardRoutes = [
    {
        id: 'dashboard',
        match: '/me',
        path: '/me',
        title: 'Dashboard',
        icon: DashboardIcon,
    },
    {
        id: 'courseMarketplace',
        match: '/me/courses',
        path: '/me/courses',
        title: 'Course Marketplace',
        icon: CourseMarketIcon,
    },
    {
        id: 'enrolledCourse',
        match: '/me/learning',
        path: '/me/learning',
        title: 'Enrolled Courses',
        icon: MyCoursesIcon,
    },
    {
        id: 'premiumCourse',
        match: '/me/premium',
        path: '/me/premium',
        title: 'One-time Purchase Courses',
        icon: MyPremiumIcon,
    },
    {
        id: 'SingleSubscriptionCourse',
        match: '/me/single-course',
        path: '/me/single-course',
        title: 'Single Subscription Courses',
        icon: MyInstitutionalIcon,
    },
    // {
    //     id: 'task',
    //     match: '/me/tasks',
    //     path: '/me/tasks',
    //     title: 'My Tasks',
    //     icon: TaskIcon,
    // },
    // {
    //     id: 'statistics',
    //     match: '/me/stats',
    //     path: '/me/stats',
    //     title: 'Grades & Statistics',
    //     icon: StatisticsIcon,
    // },
    // {
    //     id: 'forum',
    //     match: '/me/forum',
    //     path: '/me/forum',
    //     title: 'Forum',
    //     icon: ForumIcon,
    // },
    {
        id: 'subscription',
        match: '/me/subscription',
        path: '/me/subscription',
        title: 'Subscription Plans',
        icon: BillingIcon,
    },
    {
        id: 'setting',
        match: '/me/settings/:any',
        path: '/me/settings/profile',
        title: 'Settings',
        icon: SettingsIcon,
    },
]
