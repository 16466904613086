import {
    SET_MODULE_TITLE,
    SET_MODULE_SUMMARY,
    SET_LESSONS,
    CLEAR_MODULE_FORM
} from '../../actions/moduleForm/types'

const INIT_STATE = {
    title: '',
    summary: '',
    lessons: {}
}

export const moduleFormReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_MODULE_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case SET_MODULE_SUMMARY:
            return {
                ...state,
                summary: action.payload
            }
        case SET_LESSONS:
            return {
                ...state,
                lessons: action.payload
            }
        case CLEAR_MODULE_FORM:
            return {
                ...state,
                title: '',
                summary: '',
                lessons: {}
            }
        default:
            return state
    }
}