import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
// import axios from 'axios';
// import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { findQuizByInstructorId } from '../../../../services'
import { Quizcard } from '../../MyCourses/courseCard/index'
import SearchIcon from '../../../../assets/icons/search.svg'
import { useAuth } from '../../../../hooks';
import { RequestLoader } from '../../../../components/Loading'

export function QuizSecCont() {
    const {
        state: { data }
    } = useAuth()

    const [list, setList] = useState([])
    const [inputValue, setInputvalue] = useState('')
    const [isLoading, setIsLoading] = useState()
    // const onChange = (e) => {
    //     const val = e.target.checked
    //     // item has to be checked before been added to list
    //     if (e.target.checked) {
    //         // check of value exist in list
    //         list.includes(val) ? console.log('value exist already') : setList([...list, val])
    //     } else {
    //         // remove from the list, utilizing the return array of filter to update prevArry
    //         const newList = list.filter(lst => lst !== val)
    //         setList(newList)
    //     }
    // };

    useEffect(() => {
        const getAllQuiz = async () => {
            setIsLoading(true)
            try {
                const response = await findQuizByInstructorId(data.id)
                setList(response.data)
                // setList([])
                setIsLoading(false)
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
        }

        getAllQuiz()
        return () => {
            setList([])
        }
    }, [data?.id])

    const handleSearch = () => {
        console.log(`Search icon Clicked, content of input field is '${inputValue}'`)
    }

    // const menu =
    //     <Menu
    //         items={[
    //             { key: 'active', label: (<Checkbox value="active" onChange={onChange}>Active Quizs</Checkbox>) },
    //             { key: 'review', label: (<Checkbox value="review" onChange={onChange}>In-review Quizs</Checkbox>) },
    //             { key: 'SIC', label: (<Checkbox value="SIC" onChange={onChange}>Still-in-creation Quizs</Checkbox>) },
    //             { key: 'retired', label: (<Checkbox value="retired" onChange={onChange}>Retired Quizs</Checkbox>) },
    //         ]}
    //     >.
    //     </Menu>
    return (
        <section>
            {/* <div className="filter__section">
                <p>Filter by:</p>
                <Dropdown id="filter" trigger={['click']} overlay={menu}>
                    <p>
                        Status <DownOutlined />
                    </p>
                </Dropdown>
            </div> */}
            <div className="search__et__create">
                <div className="input__cont">
                    <Input
                        placeholder="Input search text"
                        value={inputValue}
                        onChange={(e) => setInputvalue(e.target.value)}
                    />
                    <div
                        onClick={() => handleSearch()}
                        className="search__cont"
                    >
                        <img src={SearchIcon} alt="search Icon" />
                    </div>
                </div>
                <Link to="/tutor/create-quiz">
                    <Button className="create_btn" type="primary">
                        {' '}
                        Create Quiz
                    </Button>
                </Link>
            </div>
            <div className="card__wrapper">
                {isLoading && (
                    <div className="loader_cont">
                        <RequestLoader />
                    </div>
                )}
                {!isLoading && list.length < 1 && (
                    <div className="empty_data">
                        <h4>You have not created your first quiz yet.</h4>
                        <h4>Click on create quiz to start now</h4>
                    </div>
                )}
                {!isLoading &&
                    list.length >= 1 &&
                    list.map((listCont, idx) => {
                        return <Quizcard key={idx} type={listCont} />
                    })}
            </div>
        </section>
    )
}