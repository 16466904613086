import { useState } from 'react'
import { Form } from 'antd'
import { Password } from './Password'
import { TextArea } from './TextArea'
import { Checkbox, CheckboxOption } from './Checkbox'
import './Input.scss'
// import { Select } from './Select'

export const Input = ({
    children,
    type = 'text',
    className,
    label,
    validationRules,
    disabled,
    name,
    placeholder,
    ...props
}) => {

    const [value, setValue] = useState('')
    return (
        <>
            <div>
                {label && (
                    <label className="font-weight-bold w-100">{label}</label>
                )}
                <Form.Item name={name} rules={validationRules}>
                    <input
                        className={`form-control ${className}`}
                        type={type}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        {...props}
                    />
                </Form.Item>
            </div>
        </>
    )
}

Input.Password = Password
Input.TextArea = TextArea
Input.Checkbox = Checkbox
Input.Checkbox.Option = CheckboxOption
// Input.Select = Select
