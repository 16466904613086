import {
    SET_QUIZ_TITLE,
    SET_QUIZ_QUESTION,
    SET_QUESTION_TYPE,
    SET_OPTIONA,
    SET_OPTIONB,
    SET_OPTIONC,
    SET_OPTIOND,
    SET_QUIZ_OPTIONS,
    SET_ANSWER,
    SET_QUIZ_MODULES,
    CLEAR_QUIZ_FORM
} from './types.js'

export const setQuizTitle = (input) => {
    return {
        type: SET_QUIZ_TITLE,
        payload: input
    }
}

export const setQuizQuestion = (input) => {
    return {
        type: SET_QUIZ_QUESTION,
        payload: input
    }
}

export const setQuestionType = (input) => {
    return {
        type: SET_QUESTION_TYPE,
        payload: input
    }
}

export const setOptionA = (input) => {
    return {
        type: SET_OPTIONA,
        payload: input
    }
}

export const setOptionB = (input) => {
    return {
        type: SET_OPTIONB,
        payload: input
    }
}

export const setOptionC = (input) => {
    return {
        type: SET_OPTIONC,
        payload: input
    }
}

export const setOptionD = (input) => {
    return {
        type: SET_OPTIOND,
        payload: input
    }
}

export const setQuizOptions = (input) => {
    return {
        type: SET_QUIZ_OPTIONS,
        payload: input
    }
}

export const setQuestionAns = (input) => {
    return {
        type: SET_ANSWER,
        payload: input
    }
}

export const setQuizModules = (input) => {
    return {
        type: SET_QUIZ_MODULES,
        payload: input
    }
}

export const clearQuizfield = () => {
    return {
        type: CLEAR_QUIZ_FORM
    }
}