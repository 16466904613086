import { request as RQ } from '../utils'

export const createPaymentIntent = async ({
    paymentMethodId,
    paymentIntentId,
    amount,
    currency,
}) => {
    try {
        const url = `/Payment/createIntent`
        const request = await RQ
        const response = await request.post(url, {
            paymentMethodId,
            paymentIntentId,
            amount,
            currency,
        })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createIntentCardPayment = async (value) => {
    try {
        const url = `/Payment/createIntentCardPayment`
        const request = await RQ
        const response = await request.post(url, value)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
// FOR STRIPE CHECKOUT IN CART PAGE
export const createPremiumPayment = async (values) => {
    try {
        const url = `/Payment/createPremiumCourseIntentCardPayment`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
// TO DISCOUNT ITEM PRICE IN CART
export const applyPromoCode = async (values) => {
    try {
        const url = `/PromoCode/applyPromoCode`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
// GET ALL CURRECNCY SUPPORTED BY ZUSTECH
export const getAllSupportedCurrency = async (page, pageSize) => {
    try {
        const url = `/Country/FindAllCountries?Page=${page}&PageSize=${pageSize}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findWalletByUserId = async (userId) => {
    try {
        const url = `/Wallet/FindWalletByUserId?UserId=${userId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}