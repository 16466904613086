const data = [
    {
        id: 1,
        username: 'Felix - UX Designer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
        image: './images/Grou',
    },

    {
        id: 2,
        username: 'Mitchelle - UX Designer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },

    {
        id: 3,
        username: 'Kaitlyn - Web Developer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },

    {
        id: 4,
        username: 'Jed Salmon - Web Developer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },

    {
        id: 5,
        username: 'Alexander - Web Developer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },
    {
        id: 6,
        username: 'Emmanuel - Web Developer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },
    {
        id: 7,
        username: 'Yousef - Web Developer',
        testimonial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames gravida quisque massa purus euismod integer tincidunt. ',
    },
];

export default data;