import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useAuth } from '../hooks'
import { setLocationHistory } from '../utils'
import { Loading } from '../components'

export const ProtectedRoute = ({ roles, ...props }) => {
    const history = useHistory()
    const {
        state: { isLoggedIn },
    } = useAuth()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        if (isLoggedIn === false) {
            setLocationHistory(history.location)
            history.push('/account/login')
        }
        setLoading(false)
    }, [history, roles, isLoggedIn])

    return loading ? <Loading /> : <Route {...props} />
}
