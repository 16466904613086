import React from 'react'
// import { Modal } from 'antd'

const Step2Form = (props) => {
    const {
        formValues,
        handlePrev,
        setOpenTermsModal,
        isTermsAgreed,
        Controller,
        control,
        formState,
    } = props
    return (
        <div className="step2--container">
            <div className="header--text">
                Have you quickly double checked your submission? Review it Now{' '}
                <br />
                For The Best Chance of Success
            </div>
            <div className="step2--form">
                <div className="contact--section space">
                    <h4>Contact Number</h4>
                    <p>{formValues.contactNumber}</p>
                </div>
                <div className="country--section space">
                    <h4>Country of Residence</h4>
                    <p>{formValues.country}</p>
                </div>
                <div className="instructor--bio--section space">
                    <h4>Instructor's Bio</h4>
                    <p>{formValues.instructorBio}</p>
                </div>
                <div className="job--title--section space">
                    <h4>Job Title</h4>
                    <p>{formValues.jobTitle}</p>
                </div>
                <div className="proficiency--section space">
                    <h4>Proficiency in creating courses</h4>
                    <p>{formValues.proficiency}</p>
                </div>

                <Controller
                    name="onboardingTerms" // Use a proper camelCase name
                    control={control}
                    rules={{
                        required:
                            'You have to agree to the terms and condition to submit!',
                    }}
                    render={({ field, fieldState }) => (
                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                            }}
                        >
                            <input
                                style={{
                                    width: '100%',
                                    margin: '0 auto',
                                    cursor: 'pointer',
                                }}
                                type="checkbox"
                                id="termsCheckbox"
                                name="termsCheckbox"
                                {...field}
                                checked={isTermsAgreed ? field.value : false}
                                onChange={(e) => {
                                    field.onChange(e)
                                    if (e.target.checked) {
                                        setOpenTermsModal(true)
                                    } else {
                                        setOpenTermsModal(false)
                                    }
                                }}
                            />
                            <label
                                htmlFor="termsCheckbox"
                                className="checkbox--text"
                            >
                                I have read and agreed to the terms and
                                conditions of service.
                            </label>
                            {formState.isSubmitted && fieldState.invalid && (
                                <p
                                    className="error-message"
                                    style={{ color: 'red', fontSize: '.8rem' }}
                                >
                                    {fieldState.error.message}
                                </p>
                            )}
                        </div>
                    )}
                />
                {/* SUMBIT BUTTON/BACK SECTION */}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                    }}
                >
                    <button
                        className="back--btn"
                        id="go-back"
                        onClick={handlePrev}
                    >
                        Back
                    </button>
                    <button
                        className="submit--btn"
                        id="submit-application"
                        type="submit"
                    >
                        Submit Application
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Step2Form