import { Droppable } from 'react-beautiful-dnd'
import ToDo from './ToDo'

const ToDoList = ({
    toDoList,
    handleToggle,
    handleFilter,
}) => {
    return (
        <div>
            <div id="list">
                <Droppable droppableId="tasks" type="TASK">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {toDoList.map((todo, index) => {
                                return (
                                    <ToDo
                                        index={index}
                                        key={todo.id.toString()}
                                        todo={todo}
                                        handleToggle={handleToggle}
                                        handleFilter={handleFilter}
                                    />
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {/* <div id="listFooter">
                    <p id="numLeft">
                        <span id="num" /> items left
                    </p>
                    <p id="clearCompleted">
                        Clear completed
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default ToDoList
