import graphic1 from '../assets/illustration-slide_1.png';
import graphic2 from '../assets/illustration-slide_2.png';
import graphic3 from '../assets/illustration-slide_3.png';
import graphic4 from '../assets/illustration-slide_4.png';

import wave1 from '../assets/wave1.png';
import wave2 from '../assets/wave2.png';
import wave3 from '../assets/wave3.png';
import wave4 from '../assets/wave4.png';

const data = [
    {
        id: 1,
        image: graphic1,
        title: 'Join Zustech',
        text1:
      'We provide great support for our instructors to help them create great content and quality courses for our learners.',
        text2:
      'We make ready any resources that the instructor needs for optimal learning delivery.',
        button: 'Sign up',
        wave: wave1,
    },
    {
        id: 2,
        image: graphic2,
        title: 'Submit CV & Personal Statement',
        text1:
      'To be allowed to continue process of becoming an instructor you must follow rules and procedures shown in our code of conduct document in which you will agree to uphold during this application process.',
        text2:
      'Upon submission of your information, it will be processed to see if you are eligible of becoming an instructor.  You will hear back within up to 2 weeks or more, depending on the number of applicants received during the submission period.',
        button: null,
        wave: wave2,
    },
    {
        id: 3,
        image: graphic3,
        title: 'Create Course & Upload Content',
        text1:
      'Once your submission is successful, you will be emailed about the status of approval and will be able to access the “instructor portal” where you will be able to create and upload content for the course.',
        text2: null,
        button: null,
        wave: wave3,
    },
    {
        id: 4,
        image: graphic4,
        title: 'Start Earning',
        text1:
      'We have a lucrative earning package and arrangement with our instructors.',
        text2: null,
        button: null,
        wave: wave4,
    },
];

export default data;
