import React from 'react'
// import {
//     // findAllCourse,
//     getCourseByTypeId,
// } from '../../../services'
import Carousel from '../Courses/HeroSection/Carousel'
import SearchBar from '../../../components/Searchbar/searchbar.jsx'
import CoursesContent from '../premiumCourses/CoursesCards/Courses'
import './style.scss'

export const InstitutionalCourse = () => {

    return (
        <div id="institutional-course-page">
            <div>
                <Carousel />
                <SearchBar type="course" />
            </div>
            <CoursesContent courseType="Single" />
        </div>
    )
}