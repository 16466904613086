import {
    SET_USER_LOCATION
} from '../../actions/userLocation/types'

const INIT_STATE = {
    location: null,
    isLocationSet: false,
    currencySymbol: null
}

export const locationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USER_LOCATION:
            return {
                ...state,
                location: action.payload.location,
                isLocationSet: true,
                currencySymbol: action.payload.symbol,
            }
        default:
            return state;
    }
}