// import { Bar } from 'react-chartjs-2'
import Polygon from '../assets/polygon.png'
import Chat from '../assets/chat.png'
import Profile from '../assets/profile.png'
import Frame from '../assets/Frame.png'
import './styles.scss'

export const AdminDB = () => {
    return (
        <div className="admin-dashboard">
            <div className="header">Dashboard</div>
            <div className="admin-dashboard-container">
                <div className="review">
                    <div className="review-box">
                        <h6>Application</h6>
                        <p>00 Pending Review</p>
                    </div>
                    <div className="review-box">
                        <h6>Status</h6>
                        <p>00 Pending Review</p>
                    </div>
                </div>
                <h5>Recent Activity</h5>
                <div className="table-wrapper">
                    <div className="table-scroll fixed-head">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><div>Users <img src={Polygon} alt="" /></div></th>
                                    <th><div>Topics <img src={Polygon} alt="" /></div></th>
                                    <th><div>Status <img src={Polygon} alt="" /></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><div className="profileData"><img src={Profile} alt="" /><div className="postedBy">Posted by</div>    John Doe</div></td>
                                    <td className="text"><div><img className="chat" src={Chat} alt="" />Does anyone have any useful UX resource...</div></td>
                                    <td className="status"><div><img className="index" src={Frame} alt="" /><div className="red">Awaiting response</div></div></td>
                                </tr>
                                <tr>
                                    <td><div className="profileData"><img src={Profile} alt="" /><div className="postedBy">Posted by</div>    John Doe</div></td>
                                    <td className="text"><div><img className="chat" src={Chat} alt="" />Does anyone have any useful UX resource...</div></td>
                                    <td className="status"><div><img className="index" src={Frame} alt="" /><div className="amber">Under Review</div></div></td>
                                </tr>
                                <tr>
                                    <td><div className="profileData"><img src={Profile} alt="" /><div className="postedBy">Posted by</div>    John Doe</div></td>
                                    <td className="text"><div><img className="chat" src={Chat} alt="" />Does anyone have any useful UX resource...</div></td>
                                    <td className="status"><div><img className="index" src={Frame} alt="" /><div className="amber">Under Review</div></div></td>
                                </tr>
                                <tr>
                                    <td><div className="profileData"><img src={Profile} alt="" /><div className="postedBy">Posted by</div>    John Doe</div></td>
                                    <td className="text"><div><img className="chat" src={Chat} alt="" />Does anyone have any useful UX resource...</div></td>
                                    <td className="status"><div><img className="index" src={Frame} alt="" /><div className="green">Issue Resolved</div></div></td>
                                </tr>
                                <tr>
                                    <td><div className="profileData"><img src={Profile} alt="" /><div className="postedBy">Posted by</div>    John Doe</div></td>
                                    <td className="text"><div><img className="chat" src={Chat} alt="" />Does anyone have any useful UX resource...</div></td>
                                    <td className="status"><div><img className="index" src={Frame} alt="" /><div className="green">Issue Resolved</div></div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <Bar
                height={279}
                width={557}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: false
                        }
                    }
                }}
                data={{
                    labels: ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri'],
                    datasets: [{
                        data: [3, 4, 5, 6, 7],
                        backgroundColor: 'blue'
                    }]
                }}
            /> */}
        </div>
    )
}