'use client';

import {
    ResponsiveContainer,
    BarChart as BC,
    Bar,
    YAxis,
    XAxis,
    CartesianGrid
} from 'recharts'

export default function BarChart({ data }) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BC barSize={5} width={350} height={600} data={data}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="short" />
                <YAxis />
                <Bar fill="#67C587" dataKey="amount" />
            </BC>
        </ResponsiveContainer>
    )
}