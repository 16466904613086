import './studentReviewCards.scss'

import reviewerProfilePic from './Assets/reviewerProfilePic.png'
import thumbsUp from './Assets/thumbsUp.png'
import thumbsDown from './Assets/thumbsDown.png'

import getStars from '../../../shared/courseCard/helpers/getStars'
import getStar from '../../../shared/courseCard/helpers/getStar'

const StudentReviewCards = ({ rating, name, reviewText, likes, dislikes }) => {
    function roundHalf(num) {
        return (Math.round(num * 2) / 2).toFixed(1)
    }

    return (
        <section id="StudentReviewCards">
            <div id="cardTitle">
                <img
                    id="reviewerImage"
                    src={reviewerProfilePic}
                    alt="reviewer profile"
                />
                <h3 id="reviewerName">{name}</h3>
                <div className="stars">
                    {getStars(roundHalf(rating)).map((rating, index) => (
                        <img
                            src={getStar(rating)}
                            width={16}
                            key={index}
                            alt="star"
                        />
                    ))}
                </div>
            </div>
            <div id="reviewText">{reviewText}</div>
            <div id="cardFooter">
                <p id="helpful">Was this review helpful</p>
                <div id="likesAndDislikes">
                    <img id="thumbsUp" src={thumbsUp} alt="Thumbs up" />
                    <p id="thumbsUpCount">{likes}</p>
                    <img
                        id="thumbsDown"
                        src={thumbsDown}
                        alt="Thumbs down"
                    />
                    <p id="thumbsDownCount">{dislikes}</p>
                </div>
                <p id="reportLink">Report</p>
            </div>
        </section>
    )
}

export default StudentReviewCards
