export const GET_INSTRUCTOR_ID = 'GET_INSTRUCTOR_ID'
export const FETCH_DATA = 'FETCH_DATA'
export const FETCH_FAILED = 'FETCH_FAILED'
export const REQUEST_PASSED = 'REQUEST_PASSED'
export const GET_INSTRUCTOR_COURSE = 'GET_INSTRUCTOR_COURSE'
export const GET_INSTRUCTOR_MODULE = 'GET_INSTRUCTOR_MODULE'
export const GET_INSTRUCTOR_LESSON = 'GET_INSTRUCTOR_LESSON'
export const GET_COURSE_CATEGORIES = 'GET_COURSE_CATEGORIES'
export const GET_LANGUAGES = 'GET_LANGUAGES'
export const GET_INSTRUCTOR_APPLICATION_STATUS = 'GET_INSTRUCTOR_APPLICATION_STATUS'
export const SET_FILTERD_COURSE = 'SET_FILTERD_COURSE'
export const SET_FILTERD = 'SET_FILTERD'