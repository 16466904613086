import { useState, useEffect } from 'react'
import { Collapse } from 'antd';
import './contact.scss'
import imageTop from './assets/Rectangle-138.svg'
// import Question from './Question'
import StudentContactExplore from './StudentExplore'
import InstructorContactExplore from './InstructorExplore'
import { getFaq, getFaqRes } from '../../../../services'
import Search from '../../../../components/Searchbar/searchbar'

export const ContactUs = () => {
    const [isStudentContact, setIsStudentContact] = useState(true)
    const [isInstructorContact, setIsInstructorContact] = useState(false)
    const [questions, setQuestions] = useState([])
    const [responses, setResponses] = useState([])

    // GET QUESTION ENDPOINT
    useEffect(() => {
        const getFAQquestions = async () => {
            try {
                const response = await getFaq()
                // console.log(response.data)
                setQuestions(response?.data)
                // setAnswers(response?.data?.faqResponseModels)
            } catch (error) {
                console.error(error)
            }
        }
        getFAQquestions()
    }, [])

    // GET RESPONSE ENDPOINT
    useEffect(() => {
        const getFAQResponse = async () => {
            try {
                const response = await getFaqRes()
                setResponses(response?.data)
            } catch (error) {
                console.log(error)
            }
        }
        getFAQResponse()
    }, [])

    function handleClick() {
        setIsStudentContact(!isStudentContact)
        setIsInstructorContact(!isInstructorContact)
    }

    const { Panel } = Collapse;
    // const onChange = (key) => {
    //     console.log(key);
    // };
    return (
        <div className="contact">
            <div className="wrapper">
                {/* TOP SECTION */}
                <section className="top-section">
                    <div className="img-container">
                        <img src={imageTop} className="image-top" alt="" />
                        <h3 className="top-text">We are here to help you!</h3>
                    </div>
                    <div className="search-box">
                        {/* <input placeholder="Search articles" name="search-article" id="search-article" className="search-article" /> */}
                        <Search type="article" />
                    </div>
                    <div className="btn-faq">
                        <button
                            type="button"
                            style={{
                                backgroundColor: isStudentContact ? '#3A86FF' : 'white',
                                color: isStudentContact ? 'white' : '#0837E5'
                            }}
                            className="btn-student btn"
                            onClick={handleClick}
                        >
                            Learners
                        </button>
                        <button
                            type="button"
                            style={{
                                backgroundColor: isStudentContact ? 'white' : '#3A86FF',
                                color: isStudentContact ? '#0837E5' : 'white'
                            }}
                            className="btn-instructor btn"
                            onClick={handleClick}
                        >
                            Instructors
                        </button>
                    </div>
                </section>

                {/* BOTTOM SECTION EXPLORE */}
                {isStudentContact ? <StudentContactExplore /> : <InstructorContactExplore /> }

                {/* FAQ */}
                <section className="faq-container">
                    <div className="question-faq">
                        <h3 className="faq-head">Frequently Asked Questions</h3>
                        <div className="questions-container">
                            <div className="questions questions-box-1">
                                {questions.slice(0, 10).map((question) => {
                                    const newResponses = responses.filter(response => response.questionId === question.questionId)
                                    return (
                                        <Collapse className="">
                                            <Panel key={question.questionId} header={question.question}>
                                                {newResponses.map(response => {
                                                    return (
                                                        <p>{response.response}</p>
                                                    )
                                                })}
                                            </Panel>
                                        </Collapse>
                                        // <Question key={index} question={question} responses={responses} />
                                    )
                                })}
                            </div>
                            <div className="questions questions-box-2">
                                {questions.slice(0, 10).map((question) => {
                                    const newResponses = responses.filter(response => response.questionId === question.questionId)
                                    return (
                                        <Collapse className="">
                                            <Panel key={question.questionId} header={question.question}>
                                                {newResponses.map(response => {
                                                    return (
                                                        <p>{response.response}</p>
                                                    )
                                                })}
                                            </Panel>
                                        </Collapse>
                                        // <Question key={index} question={question} responses={responses} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}