import { useState } from 'react'
import stutick from './assets/icons/tick-icon.png'

const Button = ({ task }) => {
    const [isDone, setIsDone] = useState(false)

    // Displays tick depending on state
    const toggleTick = () => {
        if (isDone === true) {
            setIsDone(false)
        } else if (isDone === false) {
            setIsDone(true)
        }
    }

    return (
        <div>
            <div onClick={toggleTick}>
                <div className={`stu-circle ${isDone ? 'ticked-task' : ''} `}>
                    <img
                        className={`stu-tick ${
                            isDone ? '' : 'stu-remove-display'
                        }`}
                        src={stutick}
                        alt="tick"
                    />
                </div>
            </div>
            <p className={`title-of-task ${isDone ? 'crossed' : ''}`}>
                {task.title}
            </p>
            <p className={`date-task ${isDone ? 'crossed' : ''}`}>
                {task.deadline}
            </p>
        </div>
    )
}

export default Button
