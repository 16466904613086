import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
// import { PaystackButton } from 'react-paystack'
import { FiChevronLeft, FiCheckCircle } from 'react-icons/fi'
import { useAuth, useLocation } from '../../../hooks'
import { createPremiumPayment, applyPromoCode } from '../../../services'
import { openNotification } from '../../../utils'
import { Input } from '../../Input/Input'
import { CartContext } from '../../../context/Cart/cartContext'
import './styles.scss'
import CheckoutForm from '../CheckoutStripe/CheckoutForm'
import ImageCourse from '../assets/thumbnail.png'
import '../CheckoutStripe/checkoutStripe.scss'

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
    // 'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
) // FOR STRIPE
// const paystack_publicKey = 'pk_test_5c1375018d92a63e0328366a82e7f55f09d464ed' // FOR PAYSTACK
// const paystack_publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY // FOR PAYSTACK

export const CartItems = () => {
    const {
        state: { data: userData },
    } = useAuth()
    // clearCart
    const {
        state: { currencySymbol },
    } = useLocation()
    const { cart, removeItem, cartItems } = useContext(CartContext)
    const [clientSecret, setClientSecret] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [promoCodeMessage, setPromoCodeMessage] = useState(null)
    const [amount, setAmount] = useState('')
    const [paystackAmount, setPaystackAmount] = useState('')
    const [discountPercent, setDiscountPercent] = useState('0')
    const [discountedAmount, setDiscountedAmount] = useState('')
    const [subtractedAmount, setSubtractedAmount] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // const [symbol, setSymbol] = useState('')
    // const [isProcessing, setIsProcessing] = useState(false)

    const handlePromoCodeSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const promoData = {
            price: cart.total,
            code: promoCode,
        }
        try {
            const response = await applyPromoCode(promoData)
            // console.log(response)
            const { discountedAmount, originalAmount } = response.data
            setAmount(discountedAmount)
            setDiscountedAmount(discountedAmount)
            // calculate discount amount percentage
            const discountPercentage = ((originalAmount - discountedAmount) / originalAmount) * 100
            setDiscountPercent(discountPercentage)
            const subtractAmount = originalAmount - discountedAmount
            setSubtractedAmount(subtractAmount)
            // Set promoCode successfully applied message
            if (discountedAmount) {
                setPromoCodeMessage('Promo code applied!')
            }
        } catch (error) {
            setPromoCodeMessage(error.message)
        }
        setIsLoading(false)
    }

    // stores the ids of all cart items in an array
    const premiumCourseIds = cartItems.map((item) => item.id)
    // returns "ng" if country is nigeria to action paystack else returns "gb" for stripe
    const cuRR = () => {
        if (currencySymbol === '£') {
            // return 'ng'
            return 'gb'
        } else {
            return 'gb'
        }
    }

    const checkoutData = {
        premiumCourseId: premiumCourseIds,
        promoCode: promoCode,
        currency: cuRR(),
        email: userData.email,
        amount: '',
        cardNumber: '',
        cardCvv: '',
        cardExpiryMonth: '',
        cardExpiryYear: '',
        pin: '',
    }

    const handleCheckout = async () => {
        // setIsProcessing(true)
        setIsLoading(true)
        try {
            const response = await createPremiumPayment(checkoutData)
            console.log('One-time payment intent', response)
            // Checks currency if "gb" it runs for stripe else if "ng" it runs for paystack
            if (checkoutData.currency === 'gb') {
                setClientSecret(response?.data?.clientSecret)
            } else {
                setPaystackAmount(
                    response?.data?.discountedAmount
                        ? response.data.discountedAmount
                        : response.data.amount
                )
            }
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Checkout',
                message: error.message,
            })
            // setIsProcessing(false)
            setIsLoading(false)
        }
    }

    // FOR STRIPE
    const appearance = {
        theme: 'stripe',
    }
    const options = {
        clientSecret: clientSecret,
        appearance: appearance,
    }

    // FOR PAYSTACK
    // const componentProps = {
    //     email: userData.email,
    //     amount: paystackAmount * 100,
    //     metadata: {
    //         name: userData.firstName + ' ' + userData.lastName,
    //         // phone: userData.phone,
    //     },
    //     publicKey: paystack_publicKey,
    //     text: 'Pay Now',
    //     onSuccess: () => {
    //         // setTimeout(() => {
    //         //     clearCart()
    //         // }, [3000])
    //         clearCart();
    //         navigateSuccessPage();
    //     },
    //     onClose: () => {
    //         setIsLoading(false)
    //         setPaystackAmount(false)
    //         // setIsProcessing(false)
    //     },
    // }

    return (
        <div className="cart-page">
            <h4 className="title">Shopping Cart</h4>
            <div className="content-group">
                <div className="left-content">
                    <div className="left-heading">
                        <h4 className="course-detail-head">Course Details</h4>
                        <h4 className="price-head">Price</h4>
                    </div>
                    <div className="left-details">
                        <div>
                            {cartItems.length === 0 ? (
                                <div>Cart is Empty.</div>
                            ) : (
                                cartItems.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className="info-container"
                                        >
                                            <div className="section-one">
                                                <img
                                                    src={ImageCourse}
                                                    className="img-course"
                                                    alt=""
                                                />
                                                <div className="course-desc">
                                                    <p>Learn {item.name}</p>
                                                    <p>
                                                        By {item.instructorName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="section-two">
                                                <h6 className="price">
                                                    {currencySymbol}
                                                    {item.amount}
                                                </h6>
                                                <button
                                                    className="remove-btn"
                                                    onClick={() =>
                                                        removeItem(item.id)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>

                <div className="right-content">
                    <div className="summary">
                        <h4>Summary</h4>
                    </div>
                    <div className="items-count">
                        <p>No. of Items {cartItems.length}</p>
                        {/* * <p>
                            {symbol}
                            {cart.total}
                        </p> * */}
                    </div>
                    <form onSubmit={handlePromoCodeSubmit}>
                        {/* * <p>Promo code</p> * */}
                        <Input
                            className="promo-code"
                            placeholder="Enter Promo Code"
                            name="promo-code"
                            id="promo-code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            // disabled={isLoading}
                        />
                        {promoCodeMessage ? (
                            <p
                                className={
                                    discountedAmount
                                        ? `promo-applied`
                                        : `promo-error`
                                }
                            >
                                {discountedAmount ? <FiCheckCircle /> : null}{' '}
                                {promoCodeMessage}
                            </p>
                        ) : null}
                        <button
                            disabled={discountedAmount}
                            className="apply-btn"
                            type="submit"
                        >
                            Apply
                        </button>
                    </form>
                    <div className="subtotal">
                        <p>Subtotal</p>
                        <p
                            style={{
                                textDecoration: discountedAmount
                                    ? 'line-through'
                                    : 'none',
                            }}
                        >
                            {currencySymbol}
                            {cart.total}
                        </p>
                    </div>
                    <div className="discount">
                        <p>Discount ({discountPercent}%)</p>
                        <p>
                            {discountedAmount
                                ? `${currencySymbol + subtractedAmount}`
                                : null}
                        </p>
                    </div>
                    <div className="pay-item">
                        <p>Pay</p>
                        {paystackAmount || amount ? (
                            <p>
                                {currencySymbol} {paystackAmount || amount}
                            </p>
                        ) : (
                            currencySymbol + cart.total
                        )}
                    </div>
                    {/* CHECKOUT BUTTON */}
                    <div>
                        <button
                            // style={{ display: isLoading ? 'none' : 'block' }}
                            style={{
                                background: isLoading ? '#99999d' : '#3A86FF',
                            }}
                            className="checkout"
                            type="submit"
                            onClick={handleCheckout}
                            disabled={isLoading}
                        >
                            Checkout
                        </button>
                        {/* Triggers paystack if amount has been passed to props on clicking checkout btn */}
                        {/* {paystackAmount && (
                            <PaystackButton
                                className="paystack-btn"
                                {...componentProps}
                            />
                        )} */}
                        {/* <button onClick={() => clearCart()}>Clearrr</button> */}
                    </div>

                    {/* Triggers stripe checkout form */}
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                                email={userData?.email}
                                clientSecret={clientSecret}
                            />
                        </Elements>
                    )}
                    {/* <button className="checkout">Checkout</button> */}
                </div>
            </div>
            <div>
                <Link className="link" to="/me/premium">
                    <FiChevronLeft />
                    Continue Shopping
                </Link>
            </div>
        </div>
    )
}