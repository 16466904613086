import Pricing from './Pricing'
import { Testimonial } from '../shared'

export const PricingPage = () => {
    return (
        <>
            <div
                style={{
                    width: '90%',
                    margin: '0 auto',
                }}
            >
                <Pricing />
            </div>
            <section id="testimonial" style={{ paddingBottom: '100px' }}>
                <Testimonial />
            </section>
        </>
    )
}
