import { useSelector, useDispatch } from 'react-redux'
import {
    setTitle as lessonTitle,
    setTopic as lessonTopic,
    setDescrip as lessonDescription,
    setMedia as lessonMedia,
    setLang as lessonLanguage,
    setDoc as lessonDoc,
    clearLessonForm,
    removeDoc
} from '../store/actions/lessonForm/index'
import {
    setModuleSummary as moduleSummary,
    setLesson as moduleLessons,
    setModuleTitle as moduleTitle,
    clearModuleForm
} from '../store/actions/moduleForm/index'
import {
    setCategory as courseCategory,
    setCourseTitle as courseTitle,
    setCoursePrice as coursePrice,
    setCourseLanguage as courseLang,
    setCourseType as courseType,
    setCourseImg as coverImg,
    setPrerequisite as coursePrerequisite,
    setDiffLvl as courseDiffLvl,
    setModules as courseModules,
    setObjective as courseObjectives,
    setDescription as courseDescription,
    setIntroLessons as introLessons,
    clearCourseForm,
    removeFromObj as removeItemFormObj,
    removeFromPrequi as removeItemFromPrere,
    removeCoverImg as removeImg
} from '../store/actions/courseForm/index'
import {
    setQuizTitle as quizTitle,
    setQuizQuestion  as quizQuestion,
    setQuestionType as questionType,
    setOptionA as optionA,
    setOptionB as optionB,
    setOptionC as optionC,
    setOptionD as optionD,
    setQuizOptions  as quizOptions,
    setQuestionAns  as quizAns,
    setQuizModules  as quizAppliedModule,
    clearQuizfield
} from '../store/actions/quizForm/index'

export const useForm = () => {
    const dispatch = useDispatch()
    const lessonForm = useSelector(({ lessonForm }) => lessonForm)
    const moduleForm = useSelector(({ moduleForm }) => moduleForm)
    const courseForm = useSelector(({ courseForm }) => courseForm)
    const quizForm = useSelector(({ quizForm }) => quizForm)

    const setLessonTitle = (value) => {
        dispatch(lessonTitle(value))
    }

    const setLessonTopic = (value) => {
        dispatch(lessonTopic(value))
    }

    const setLessonDescription = (value) => {
        dispatch(lessonDescription(value))
    }

    const setLessonMedia = (value) => {
        dispatch(lessonMedia(value))
    }

    const setLessonLanguage = (value) => {
        dispatch(lessonLanguage(value))
    }

    const setLessonDoc = (value) => {
        dispatch(lessonDoc(value))
    }

    const clearLessonField = () => {
        dispatch(clearLessonForm())
    }

    const removeDocs = (inp) => {
        dispatch(removeDoc(inp))
    }

    // functions related to modules
    const setModuleSummary = (value) => {
        dispatch(moduleSummary(value))
    }

    const setModuleLessons = (value) => {
        dispatch(moduleLessons(value))
    }

    const setModuleTitle = (value) => {
        dispatch(moduleTitle(value))
    }

    const setIntroLessons = (value) => {
        dispatch(introLessons(value))
    }

    const clearModuleField = () => {
        dispatch(clearModuleForm())
    }

    // functions related to course
    const setCourseCategory = (value) => {
        dispatch(courseCategory(value))
    }

    const setCourseTitle = (value) => {
        dispatch(courseTitle(value))
    }

    const setCoursePrice = (value) => {
        dispatch(coursePrice(value))
    }

    const setCourseLang = (value) => {
        dispatch(courseLang(value))
    }

    const setCourseType = (value) => {
        dispatch(courseType(value))
    }

    const setCoverImg = (value) => {
        dispatch(coverImg(value))
    }

    const setCourseDiffLvl = (value) => {
        dispatch(courseDiffLvl(value))
    }

    const setCourseObjectives = (value) => {
        dispatch(courseObjectives(value))
    }

    const setCourseModules = (value) => {
        dispatch(courseModules(value))
    }

    const setCoursePrerequisite = (value) => {
        dispatch(coursePrerequisite(value))
    }

    const setCourseDescription = (value) => {
        dispatch(courseDescription(value))
    }

    const clearCourseField = () => {
        dispatch(clearCourseForm())
    }
    const removeCoverImg = () => {
        dispatch(removeImg())
    }
    const removeFromObj = (value) => {
        dispatch(removeItemFormObj(value))
    }
    const removeFromPrequi = (value) => {
        dispatch(removeItemFromPrere(value))
    }

    // Functions related to Quiz

    const setQuizTitle = (value) => {
        dispatch(quizTitle(value))
    }

    const setQuizQuestion = (value) => {
        dispatch(quizQuestion(value))
    }

    const setQuestionType = (value) => {
        dispatch(questionType(value))
    }

    const setOptionA = (value) => {
        dispatch(optionA(value))
    }

    const setOptionB = (value) => {
        dispatch(optionB(value))
    }

    const setOptionC = (value) => {
        dispatch(optionC(value))
    }

    const setOptionD = (value) => {
        dispatch(optionD(value))
    }

    const setQuizOptions = (value) => {
        dispatch(quizOptions(value))
    }

    const setQuestionAns = (value) => {
        dispatch(quizAns(value))
    }

    const setQuisAppliedModule = (value) => {
        dispatch(quizAppliedModule(value))
    }

    const clearQuizForm = () => {
        dispatch(clearQuizfield())
    }
    return {
        lessonForm,
        moduleForm,
        courseForm,
        quizForm,
        setLessonTopic,
        setLessonTitle,
        setLessonMedia,
        setLessonLanguage,
        setLessonDescription,
        setLessonDoc,
        clearLessonField,
        removeDocs,
        setModuleTitle,
        setModuleLessons,
        setModuleSummary,
        clearModuleField,
        setCourseCategory,
        setCourseDiffLvl,
        setCourseLang,
        setCourseModules,
        setCourseObjectives,
        setCourseTitle,
        setIntroLessons,
        setCourseType,
        setCoursePrice,
        setCoursePrerequisite,
        setCoverImg,
        setCourseDescription,
        clearCourseField,
        removeCoverImg,
        removeFromObj,
        removeFromPrequi,
        setQuizTitle,
        setQuizQuestion,
        setQuestionType,
        setOptionA,
        setOptionB,
        setOptionC,
        setOptionD,
        setQuizOptions,
        setQuestionAns,
        setQuisAppliedModule,
        clearQuizForm
    }
}