import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Banner from '../../../assets/icons/termsBanner.svg'
import './style.scss'

export const TermsLayout = ({ children }) => {
    const location = useLocation()

    console.log(location)
    return (
        <main id="terms_page_layout">
            <div className="hero_cont">
                <img src={Banner} alt="banner" />
            </div>
            <div className="content_cont">
                <section className="sidebar">
                    <NavLink
                        to="/terms"
                        className={
                            location.pathname === '/terms' ? 'Active' : ''
                        }
                    >
                        Terms and Conditions
                    </NavLink>
                    <NavLink
                        to="/terms/privacy"
                        className={
                            location.pathname === '/terms/privacy'
                                ? 'Active'
                                : ''
                        }
                    >
                        Privacy Policy
                    </NavLink>
                    <NavLink
                        to="/terms/instructor-term-of-use"
                        className={
                            location.pathname ===
                            '/terms/instructor-term-of-use'
                                ? 'Active'
                                : ''
                        }
                    >
                        Instructor Terms of Use
                    </NavLink>
                </section>
                <section className="main_cont">{children}</section>
            </div>
        </main>
    )
}
