import React from 'react'
import TermsAndCoIcon from './assets/Component 53.png'

export const InstructorHandbook = () => {
    const handbookUrl =
        'https://courseresource.s3.us-east-2.amazonaws.com/Flexmonster-Software-License-Agreement.pdf'

    const handleDownloadHandbook = () => {
        // Create an anchor element
        const anchor = document.createElement('a')
        anchor.href = handbookUrl
        anchor.target = '_blank' // Open in a new tab
        anchor.download = 'InstructorHandbook.pdf' // Set the desired file name

        // Simulate a click on the anchor element to trigger the download
        document.body.appendChild(anchor)
        anchor.click()

        // Remove the anchor element from the DOM
        document.body.removeChild(anchor)
    }

    return (
        <React.Fragment>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                }}
                onClick={handleDownloadHandbook}
            >
                <img
                    style={{
                        width: '35%',
                    }}
                    src={TermsAndCoIcon}
                    alt="term and co icon"
                />
            </div>
        </React.Fragment>
    )
}

// export default TermsAndConditionDoc
