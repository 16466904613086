export const StripeSupported = [
    {
        countryCode: 'AU',
        currencyCode: 'AUD',
        currencySymbol: '&#36;',
        countryName: 'Australia'
    },
    {
        countryCode: 'AT',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Austria'
    },
    {
        countryCode: 'BE',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Belgium'
    },
    {
        countryCode: 'BR',
        currencyCode: 'BRL',
        currencySymbol: '&#82;',
        countryName: 'Brazil'
    },
    {
        countryCode: 'BG',
        currencyCode: 'BGN',
        currencySymbol: '&#1083;&#1074;',
        countryName: 'Bulgaria'
    },
    {
        countryCode: 'CA',
        currencyCode: 'CAD',
        currencySymbol: '&#36;',
        countryName: 'Canada'
    },
    {
        countryCode: 'HR',
        currencyCode: 'HRK',
        currencySymbol: '&#107;&#110;',
        countryName: 'Croatia'
    },
    {
        countryCode: 'CY',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Cyprus'
    },
    {
        countryCode: 'CZ',
        currencyCode: 'CZK',
        currencySymbol: '&#75;&#269;',
        countryName: 'Czech Republic'
    },
    {
        countryCode: 'DK',
        currencyCode: 'DKK',
        currencySymbol: '&#107;&#114;',
        countryName: 'Denmark'
    },
    {
        countryCode: 'EE',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Estonia'
    },
    {
        countryCode: 'FI',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Finland'
    },
    {
        countryCode: 'FR',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'France'
    },
    {
        countryCode: 'DE',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Germany'
    },
    {
        countryCode: 'GI',
        currencyCode: 'GIP',
        currencySymbol: '&#163;',
        countryName: 'Gibraltar'
    },
    {
        countryCode: 'GR',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Greece'
    },
    {
        countryCode: 'HK',
        currencyCode: 'HKD',
        currencySymbol: '&#36;',
        countryName: 'Hong Kong'
    },
    {
        countryCode: 'HU',
        currencyCode: 'HUF',
        currencySymbol: '&#70;&#116;',
        countryName: 'Hungary'
    },
    {
        countryCode: 'IN',
        currencyCode: 'INR',
        currencySymbol: '&#8377;',
        countryName: 'India'
    },
    {
        countryCode: 'ID',
        currencyCode: 'IDR',
        currencySymbol: '&#82;&#112;',
        countryName: 'Indonesia'
    },
    {
        countryCode: 'IE',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Ireland'
    },
    {
        countryCode: 'IT',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Italy'
    },
    {
        countryCode: 'JP',
        currencyCode: 'JPY',
        currencySymbol: '&#165;',
        countryName: 'Japan'
    },
    {
        countryCode: 'LV',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Latvia'
    },
    {
        countryCode: 'LI',
        currencyCode: 'CHF',
        currencySymbol: '&#67;&#72;&#70;',
        countryName: 'Liechtenstein'
    },
    {
        countryCode: 'LT',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Lithuania'
    },
    {
        countryCode: 'LU',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Luxembourg'
    },
    {
        countryCode: 'MY',
        currencyCode: 'MYR',
        currencySymbol: '&#82;&#77;',
        countryName: 'Malaysia'
    },
    {
        countryCode: 'MT',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Malta'
    },
    {
        countryCode: 'MX',
        currencyCode: 'MXN',
        currencySymbol: '&#36;',
        countryName: 'Mexico'
    },
    {
        countryCode: 'NL',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Netherlands'
    },
    {
        countryCode: 'NZ',
        currencyCode: 'NZD',
        currencySymbol: '&#36;',
        countryName: 'New Zealand'
    },
    {
        countryCode: 'NO',
        currencyCode: 'NOK',
        currencySymbol: '&#107;&#114;',
        countryName: 'Norway'
    },
    {
        countryCode: 'PL',
        currencyCode: 'PLN',
        currencySymbol: '&#122;&#322;',
        countryName: 'Poland'
    },
    {
        countryCode: 'PT',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Portugal'
    },
    {
        countryCode: 'RO',
        currencyCode: 'RON',
        currencySymbol: '&#108;&#101;&#105;',
        countryName: 'Romania'
    },
    {
        countryCode: 'SG',
        currencyCode: 'SGD',
        currencySymbol: '&#36;',
        countryName: 'Singapore'
    },
    {
        countryCode: 'SK',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Slovakia'
    },
    {
        countryCode: 'SI',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Slovenia'
    },
    {
        countryCode: 'ES',
        currencyCode: 'EUR',
        currencySymbol: '&#128;',
        countryName: 'Spain'
    },
    {
        countryCode: 'SE',
        currencyCode: 'SEK',
        currencySymbol: '&#107;&#114;',
        countryName: 'Sweden'
    },
    {
        countryCode: 'CH',
        currencyCode: 'CHF',
        currencySymbol: '&#67;&#72;&#70;',
        countryName: 'Switzerland'
    },
    {
        countryCode: 'TH',
        currencyCode: 'THB',
        currencySymbol: '&#3647;',
        countryName: 'Thailand'
    },
    {
        countryCode: 'AE',
        currencyCode: 'AED',
        currencySymbol: '&#1583;&#46;&#1573;',
        countryName: 'United Arab Emirates'
    },
    {
        countryCode: 'GB',
        currencyCode: 'GBP',
        currencySymbol: '&#163;',
        countryName: 'United Kingdom'
    },
    {
        countryCode: 'US',
        currencyCode: 'USD',
        currencySymbol: '&#36;',
        countryName: 'United States'
    }
]