import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'
import axios from 'axios';
import { useAuth, useInstructorData } from '../../../hooks'
import './instructorDB.scss'
import { Coursecard } from '../MyCourses/courseCard/index';
import { RequestLoader } from '../../../components/Loading';
import  { Filter } from '../../../components/InstructorCourseFilter/courseFilter'

export const InstructorDB = () => {
    const {
        state: { data: user },
    } = useAuth()

    const {
        state: { isLoading, courses, isFiltered, filteredCourse, instructorID },
        getAllCourses,
        getAllModules,
        getAllLessons,
        getLanguages,
        getCourseCategory,
    } = useInstructorData()

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getLanguages(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getCourseCategory(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getAllCourses(instructorID, source.token)
            await getAllModules(instructorID, source.token)
            await getAllLessons(instructorID, source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    return (
        <div id="instructor-dashboard">
            <div id="header">
                <h2 className="title">Hello {user?.firstName}</h2>
            </div>
            <div className="hero">
                <div className="img__container">
                    <div className="hero__overlay">&nbsp;</div>
                    {/* <h3>Course Marketplace</h3> */}
                </div>
            </div>
            <section className="course__cont">
                <div className="section__heading">
                    <h3>My Courses</h3>
                    <NavLink
                        to="/tutor/apageCreation"
                        id="homeToCreateNewCourse"
                    >
                        <Button
                            style={{
                                borderRadius: '10px',
                                paddingInline: '15px',
                            }}
                            type="primary"
                            ghost
                        >
                            Create New Course
                        </Button>
                    </NavLink>
                </div>
                <div className="filter__section">
                    <Filter />
                </div>
                <div className="card__cont">
                    {/* Error message for empty filter result */}
                    {(isFiltered && filteredCourse.length < 1) &&
                        <div className="empty_data">
                            <h4>
                                No course for selected filter
                            </h4>
                        </div>}
                    {/* Error message for no course from DB */}
                    {(!isLoading && courses.length < 1) &&
                        <div className="empty_data">
                            <h4>
                                You have not created your first course yet.
                                Start creating your first course now!
                            </h4>
                        </div>}
                    {/* Rendering fectched course after loading and when filter is not in use */}
                    {(!isLoading && !isFiltered) &&
                        courses &&
                        courses.map((listCont, idx) => {
                            return <Coursecard key={idx} type={listCont} />
                        })}
                    {/* Rendering filtered course */}
                    {isFiltered &&
                        filteredCourse.map((listCont, idx) => {
                            return <Coursecard key={idx} type={listCont} />
                        })}
                    {/* Request Loader */}
                    {isLoading && (
                        <div className="loader_cont">
                            <RequestLoader />
                        </div>
                    )}
                </div>
            </section>
            <section className="course_regulation">
                <h3>Course creation guidelines</h3>
                <div className="content__to__come">&nbsp;</div>
            </section>
        </div>
    )
}