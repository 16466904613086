import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Input } from '../../../../../components'

export default function CheckoutForm() {
    // Declare use of stripe
    const stripe = useStripe()
    const elements = useElements()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // If stripe isn't detected, this returns nothing
    useEffect(() => {
        if (!stripe) {
            return
        }

        // Get the value of the client secret by grabbing it from the URL
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        )

        // If you're unable to grab the client secret properly, which likely would mean an error occured on stripe, quit the process
        if (!clientSecret) {
            return
        }

        // Stripe declaration to set the message that displays to the user upon payment process
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            // If the status of the payment ==
            switch (paymentIntent.status) {
                // success, then tell them the payment has happened successfully
                case 'succeeded':
                    setMessage('Payment succeeded!')
                    break
                // processing, then tell them the payment is currently happening
                case 'processing':
                    setMessage('Your payment is processing.')
                    break
                // payment could not be detected, then tell them the site was unable to detect a payment method and they will need to enter one
                case 'requires_payment_method':
                    setMessage(
                        'Your payment was not successful, please try again.'
                    )
                    break
                // else send a default message that something wrong happened if no specific case is met
                default:
                    setMessage('Something went wrong.')
                    break
            }
        })
    }, [stripe])

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Another check to ensure stripe has loaded and the elements are there
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Use this link on the test branch, comment out on deploying
                // return_url: 'http://localhost:3000/me/settings/checkout',

                // Use this link on the live site, comment out if testing
                return_url: 'http://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/settings/checkout',
                receipt_email: email,
            },
        })

        // This state is only achieved if there is an error when confirming the payment, otherwise the user gets redirected to the return url specified above
        // If there is an error detected when confirming a payment, display the error message or display a generic unidentified error error message if error type could not be identified
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message)
        } else {
            setMessage('An unexpected error occured.')
        }
        setIsLoading(false)
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {/* <CardElement /> */}
            <PaymentElement id="payment-element" />
            <p>Email address</p>
            <Input
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                validationRules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                    {
                        type: 'email',
                        message: 'Please input a valid email!',
                    },
                ]}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {/* Little loading animation */}
                    {isLoading ? (
                        <div className="spinner" id="spinner">
                            {' '}
                        </div>
                    ) : (
                        'Pay now'
                    )}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}
