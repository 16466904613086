import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
// import { FindLessonsById } from '../../../../services'
import { openNotification } from '../../../../../../utils'

import './styles.scss'

// import ReactPlayer from 'react-player/youtube'
// import Video from './vid/video.mp4'

export const CourseVideoResource = ({
    url,
    handleSubmitAndNextVideo,
    isVideoPlaying,
    handleProgress,
}) => {
    // const [watchComplete, setWatchComplete] = useState(false)
    // const [firstLessonUrl, setFirstLessonUrl] = useState('')
    // const [lessonUrl, setLessonUrl] = useState('')

    const courseIdAddress = window.location.pathname
    const lessonId = courseIdAddress.split('/').pop()

    useEffect(() => {
        // console.log(setInstructor)
        const getLessonsById = async () => {
            try {
                // console.log(instructor)
                // const response = await FindLessonsById(lessonId)
                // console.log(response)
                // setVideo(response.data)
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }
        getLessonsById()
    }, [lessonId])

    return (
        <div
            className="vid-container"
            onContextMenu={(e) => e.preventDefault()}
        >
            <ReactPlayer
                className="vid"
                url={url}
                controls="true"
                width="100%"
                autoPlay={true}
                playing={isVideoPlaying}
                onEnded={() => handleSubmitAndNextVideo()}
                // onProgress={() => handleProgress()}
                onProgress={handleProgress}
                // onProgress={(progress) => {
                //     setPlayed(progress.playedSeconds)
                // }}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload',
                        },
                    },
                    tracks: [
                        {
                            kind: 'subtitles',
                            src: '',
                            srcLang: 'en',
                            default: true,
                        },
                    ],
                }}
            />
            {/* <div className={watchComplete ? 'not-complete' : 'complete'}>
                Hello
            </div> */}
        </div>
    )
}
