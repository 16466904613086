import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useGeoLocation } from '../../utils'
import { useLocation } from '../../hooks'
import { HomePage } from './home'
import { CoursesPage, CoursesSubPage } from './courses'
import { InstructorPage } from './instructors'
import { PricingPage } from './pricing'
import { Payment } from './pricing/Payment'
import { NotFound } from './NotFound'
import { AppLayout } from '../../components'
import { CourseDetails } from './home/CourseCategories/CourseDetails'
import TermsPageLayout from './terms/termsPageLayout'
import {
    ContactUs,
    ContactForm,
    StudentSupport,
    InstructorSupport,
} from './contact'
import GettingStarted from './contact/StudentSupport/GettingStarted/GettingStarted'
import AccountProfile from './contact/StudentSupport/AccountProfile/AccountProfile'
import SubscriptionBilling from './contact/StudentSupport/SubscriptionBilling/SubscriptionBilling'
import TakeCourse from './contact/StudentSupport/TakeCourse/TakeCourse'
import Troubleshooting from './contact/StudentSupport/Troubleshooting/Troubleshooting'
import { NonAuthUserCourseOverview as CourseOverview } from './courseForNonUser/index'
import GettingStarted2 from './contact/InstructorSupport/GettingStarted/GettingStarted'
import AccountProfile2 from './contact/InstructorSupport/AccountProfile/AccountProfile'
import PaymentWithdrawal from './contact/InstructorSupport/PaymentWithdrawal/PaymentWithdrawal'
import CreateCourse from './contact/InstructorSupport/CreateCourse/CreateCourse'
import Troubleshooting2 from './contact/InstructorSupport/Troubleshooting/Troubleshooting'
// import { Context } from '../../context/Context'

const landingRoutes = [
    {
        path: '/',
        name: 'Home',
        exact: true,
        component: HomePage,
    },
    {
        path: '/courses',
        name: 'Courses',
        exact: true,
        component: CoursesPage,
    },
    {
        path: '/teach',
        name: 'Teach',
        exact: true,
        component: InstructorPage,
    },
    {
        path: '/contact',
        name: 'Contact Us',
        exact: true,
        component: ContactUs,
    },
    {
        path: '/contact-form',
        name: 'Contact Form',
        exact: true,
        component: ContactForm,
    },
    {
        path: '/student-support',
        name: 'Student Support',
        exact: true,
        component: StudentSupport,
    },
    {
        path: '/student-support/getting-started',
        name: 'Student Getting started',
        exact: true,
        component: GettingStarted,
    },
    {
        path: '/student-support/account-setting',
        name: 'Student Account Setting',
        exact: true,
        component: AccountProfile,
    },
    {
        path: '/student-support/subscription',
        name: 'Student-Support Subscription',
        exact: true,
        component: SubscriptionBilling,
    },
    {
        path: '/student-support/take-course',
        name: 'Student-Support Course',
        exact: true,
        component: TakeCourse,
    },
    {
        path: '/student-support/troubleshoot',
        name: 'Student-Support Troubleshoot',
        exact: true,
        component: Troubleshooting,
    },
    {
        path: '/instructor-support',
        name: 'Instructor Support',
        exact: true,
        component: InstructorSupport,
    },
    {
        path: '/instructor-support/getting-started',
        name: 'Student Getting started',
        exact: true,
        component: GettingStarted2,
    },
    {
        path: '/instructor-support/account-setting',
        name: 'Student Account Setting',
        exact: true,
        component: AccountProfile2,
    },
    {
        path: '/instructor-support/payment-withdrawal',
        name: 'Student-Support Subscription',
        exact: true,
        component: PaymentWithdrawal,
    },
    {
        path: '/instructor-support/create-course',
        name: 'Student-Support Course',
        exact: true,
        component: CreateCourse,
    },
    {
        path: '/instructor-support/troubleshoot',
        name: 'Student-Support Troubleshoot',
        exact: true,
        component: Troubleshooting2,
    },
    {
        path: '/pricing',
        name: 'Pricing',
        exact: true,
        component: PricingPage,
    },
    {
        path: '/payment',
        name: 'Payment',
        exact: false,
        component: Payment,
    },
    {
        path: '/courses/sub-course/:title',
        name: 'Sub Courses',
        exact: false,
        component: CoursesSubPage,
    },
    {
        path: '/courses/course/:id',
        name: 'Course Overview',
        exact: false,
        component: CourseOverview,
    },
    {
        path: '/course-content',
        name: 'Course Details',
        exact: false,
        component: CourseDetails,
    },
    {
        path: '/terms',
        name: 'Company legal documents',
        exact: false,
        component: TermsPageLayout,
    },

    // Add new pages aboved this or they will not work ↓↓
    {
        path: '',
        name: 'Not Found',
        exact: true,
        component: NotFound,
    },
]

export const Landing = () => {
    const { location } = useGeoLocation()
    const { addUserLocation } = useLocation()

    // Use effect to set user location when the page is loaded for the first time.
    useEffect(() => {
        const setUsersLocation = () => {
            const storedCountrycode = JSON.parse(
                localStorage.getItem('zustech:user-location')
            )
            if (location !== null) {
                if (
                    storedCountrycode === null ||
                    storedCountrycode.city !== location.city
                ) {
                    localStorage.setItem(
                        'zustech:user-location',
                        JSON.stringify(location)
                    )
                    addUserLocation(location)
                } else {
                    addUserLocation(storedCountrycode)
                }
            } else {
                // setUsersLocation()
                console.log('Yet to query user location')
            }
        }

        setUsersLocation()

        return () => {
            localStorage.removeItem('zustech:user-location')
            addUserLocation(null)
        }

        // eslint-disable-next-line
    }, [location])
    return (
        <AppLayout>
            <div style={{ marginTop: '80px', backgroundColor: '#f8f8f8' }}>
                <Switch>
                    {landingRoutes.map((route) => {
                        return <Route {...route} key={route.name} />
                    })}
                </Switch>
            </div>
        </AppLayout>
    )
}
