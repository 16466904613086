import React from 'react'
import { useHistory } from 'react-router-dom'
import './blankPage.scss'

export default function Blankpage({ spec }) {
    const history = useHistory()
    return (
        <main className="blankPage">
            <h2>Welcome, <br />You do not have any revenue and payment details{spec === 'Overview' ? '.' : ` for the ${spec} courses` }</h2>
            <p>Start earning today by creating your first course.</p>
            <button
                onClick={() => {
                    history.push('/tutor/create-course')
                }}
            >Create course
            </button>
        </main>
    )
}
