// import styles
import './careers.scss'
// import images
import handshake from './assets/handshake.png'
import aws from './assets/aws.png'
import jira from './assets/jira.png'
import microsoft from './assets/microsoft.png'
import stripe from './assets/stripe.png'
import paystack from './assets/Paystack.png'
import zoho from './assets/zoho.png'

const Careers = () => {
    return (
        <section id="careers">
            <div className="container">
                <div className="title">
                    <div className="header_img_cont">
                        <img
                            className="handshake"
                            src={handshake}
                            alt="handshake"
                        />
                    </div>
                    <h1>OUR PARTNERS</h1>
                </div>
                {/* <p>It is a great pleasure to work with all our best partners</p> */}
                <div className="imgs">
                    <div className="img_cont">
                        <img className="partner-logo" src={stripe} alt="stripe" />
                    </div>
                    <div className="img_cont">
                        <img className="partner-logo" src={paystack} alt="paystack" />
                    </div>
                    <div className="img_cont">
                        <img className="partner-logo" src={aws} alt="aws" />
                    </div>
                    <div className="img_cont">
                        <img className="partner-logo" src={microsoft} alt="microsoft" />
                    </div>
                    <div className="img_cont">
                        <img className="partner-logo" src={jira} alt="jira" />
                    </div>
                    <div className="img_cont">
                        <img className="partner-logo" src={zoho} alt="zoho" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Careers
