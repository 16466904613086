import React, { useEffect, useState, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Collapse } from 'antd'
import { isEqual } from 'lodash'
import { useAuth, useInterface } from '../../../../../hooks'
// import { openNotification } from '../../../../../utils'
import {
    getUsersProgressOnCourse,
    findQuizByModuleId,
    // getUserTestResults
} from '../../../../../services'
import Lesson from './Lesson'

const ModuleHeader = ({ itm }) => {
    return (
        <div className="module_item">
            <p title={itm.moduleName}>{itm.moduleName}</p>
            <div>
                <span>{itm.lessons ? itm.lessons.length : 0} Lessons</span>
            </div>
        </div>)
}

const Module = ({ reRun, courseId, mod, subscribed, progress, updateUrl, subModal,
    // testResult
}) => {
    const {
        state: { data: user, isLoggedIn },
    } = useAuth()
    const {
        state: { commenceQuiz },
        // setQuizMode,
        // setQuizModuleId,
        // setQuizType
    } = useInterface()
    const location = useLocation()
    const currentUrl = location.pathname
    const { Panel } = Collapse;
    const [isPreviewPage, setIsPreviewPage] = useState(false)
    const [fetched, setFetched] = useState({})
    const [modulesWithQuiz, setModulesWithQuiz] = useState({})
    const [show, setShow] = useState({})
    // const [testResult, setTestResult] = useState({})
    useEffect(() => {
        // const source = axios.CancelToken.source()
        const getData = async () => {
            if (!isLoggedIn) {
                const res = await getUsersProgressOnCourse(courseId)
                setFetched(res.data)
            } else {
                const res = await getUsersProgressOnCourse(courseId, user.id)
                setFetched(res.data)
            }
        }
        // const getUserResults = async () => {
        //     try {
        //         const res = await getUserTestResults(user?.id)
        //         const result = {}
        //         if (res.data.length >= 1) {
        //             for (let i = 0; i < res.data.length; i++) {
        //                 if (res.data[i].score === 0) {
        //                     result[res.data[i].testId] = 0
        //                 } else {
        //                     result[res.data[i].testId] = res.data[i].score
        //                 }
        //             }
        //             setTestResult(result)
        //         }
        //     } catch (err) {
        //         console.error(err.message)
        //     }
        // }

        getData()
        // getUserResults()

        return () => {
            setFetched({})
            // setTestResult({})
        }
    }, [courseId, isLoggedIn, user?.id, reRun, commenceQuiz])

    useEffect(() => {
        for (let i = 0; i < fetched?.modules?.length; i++) {
            const lessons = fetched?.modules?.[i]?.lessons
            for (let j = 0; j < lessons?.length; j++) {
                setShow(prev => ({
                    ...prev,
                    [lessons[j]?.lessonId]: lessons[j]?.isCompleted
                }))
            }
        }
        const paths = currentUrl.split('/')
        for (let i = 0; i < paths.length; i++) {
            if (paths[i] === 'preview-quiz') {
                setIsPreviewPage(true)
            }
        }
    }, [fetched, reRun, currentUrl])
    // FETCHING MODULE QUIZ IF IT EXIST
    useEffect(() => {
        const getModuleId = (array) => {
            const newArry = []
            for (let i = 0; i < array.length; i++) {
                if (array[i].moduleName !== 'Introduction') {
                    newArry.push(array[i].moduleId)
                }
            }
            return newArry
        }
        const compactModules = Object.values(mod)
        if (compactModules.length >= 1) {
            const moduleIds = getModuleId(compactModules)
            const hasQuiz = async (moduleId) => {
                const res = await findQuizByModuleId(moduleId)
                if (res.data.length >= 1) {
                    console.log(res.data)
                    return res.data[0].id
                } else {
                    return false
                }
            }
            if (isPreviewPage) {
                const quizInfo = JSON.parse(localStorage.getItem('QuizInfo'))
                if (quizInfo !== null) {
                    const value = {}
                    value[quizInfo.moduleId] = quizInfo
                    setModulesWithQuiz({ ...value })
                }
            } else {
                for (let i = 0; i < moduleIds.length; i++) {
                    const res = hasQuiz(moduleIds[i])
                    res.then((val) => {
                        setModulesWithQuiz((prev) => {
                            const value = {}
                            value[moduleIds[i]] = val
                            return ({ ...prev, ...value })
                        })
                    })
                }
            }
        }
    }, [mod, isPreviewPage])

    return (
        <div>
            <Collapse
                defaultActiveKey={['100', '105']}
                expandIconPosition="end"
                bordered="false"
            >
                {/* Filtering for introductory module */}
                {mod && Object.values(mod).filter((module) => module.moduleName === 'Introduction').map((itm) => (
                    <Panel header={<ModuleHeader itm={itm} />} key={100} showArrow="false">
                        {itm.lessons.sort((a, b) => a.index - b.index) && itm.lessons.map((les) => (
                            <Lesson
                                key={les.index * Math.random() * 10}
                                les={les}
                                itm={itm}
                                show={show}
                                progress={false}
                                updateUrl={updateUrl}
                                subscribed={true}
                            />
                        ))}
                    </Panel>
                ))}
                {mod && Object.values(mod).filter((module) => module.moduleName !== 'Introduction').map((itm, idx) => (
                    <Panel header={<ModuleHeader itm={itm} />} key={(isPreviewPage && modulesWithQuiz?.[itm.moduleId]) ? '105' : idx} showArrow="false">
                        {itm.lessons.sort((a, b) => a.index - b.index) && itm.lessons.map((les) => (
                            <Lesson
                                key={les.index * Math.random()}
                                les={les}
                                itm={itm}
                                show={show}
                                progress={progress}
                                updateUrl={updateUrl}
                                subscribed={subscribed}
                                subModal={subModal}
                            />
                        ))}
                    </Panel>
                ))}
            </Collapse>
        </div>
    )
}

export default memo(Module, isEqual);
