import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
// import components
import { Divide as Hamburger } from 'hamburger-react'
import LogoBtn from './logo-btn/LogoBtn'
import { Button } from '../../../Button'
import NavList from './NavList'
import { CartIcon } from '../../../PremiumCourse/CartIcon'
// import styles
import './nav.scss'

const Header = ({ currentUser }) => {
    // State
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [showLinks, setShowLinks] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [pageName, setPageName] = useState()
    const planIdAddress = window.location.pathname

    // track change in width
    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', changeWidth)
        // Clean up
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [screenWidth])

    useEffect(() => {
        const plnId = planIdAddress.split('/').pop()
        setPageName(plnId)
    }, [planIdAddress])

    useEffect(() => {
        // Toggles overflow hidden on the body
        const preventScroll = () => {
            // toggle when menu's open and only for mobile viewport
            showLinks && screenWidth < 768
                ? (document.body.style.overflowY = 'hidden')
                : (document.body.style.overflowY = 'visible')
        }
        preventScroll()
    }, [showLinks, screenWidth])

    return (
        <section id="top-nav">
            <div className="banner">
                <p>
                    Start {pageName === 'teach' ? 'teaching' : 'learning'} today at Zustech and {pageName === 'teach' ? 'tutor' : 'learn'} course skills:
                    Business Analysis, Project Management, Software Testing,
                    Test Automation and more!
                </p>
                <Link to={pageName === 'teach' ? '/me/instructor-onboarding-application' : '/pricing'}>
                    <button className="start-trial">{pageName === 'teach' ? 'Get Started' : 'Start Free Trial'}</button>
                </Link>
            </div>

            <nav>
                <div
                    className={`${
                        showLinks ? 'nav-center menu-open' : 'nav-center'
                    }`}
                >
                    <div className="nav-header">
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <LogoBtn />
                        </Link>
                        <Hamburger
                            className="nav-toggle"
                            color="#3a86ff"
                            direction="left"
                            hideOutline={false}
                            rounded
                            label="Show menu"
                            toggled={isOpen}
                            toggle={setOpen}
                            onToggle={(toggled) => {
                                if (toggled) {
                                    // open a menu
                                    setShowLinks(true)
                                } else {
                                    // close a menu
                                    setShowLinks(false)
                                }
                            }}
                        />
                    </div>
                    <div className="links-container">
                        <NavList
                            setShowLinks={setShowLinks}
                            setOpen={setOpen}
                        />
                    </div>
                    <div className="nav-btns">
                        {currentUser ? (
                            <Button className="to-dashboard" to="/me">
                                Go to Dashboard
                            </Button>
                        ) : (
                            <Fragment>
                                <Button to="/account/login" className="sign-in">
                                    SIGN IN
                                </Button>
                                <Button
                                    className="sign-up"
                                    to="/account/register"
                                >
                                    SIGN UP
                                </Button>
                            </Fragment>
                        )}
                        <CartIcon />
                    </div>
                </div>
            </nav>
        </section>
    )
}

export default Header
