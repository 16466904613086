import { Link, useHistory } from 'react-router-dom'
// import Form from ant design
import { Form } from 'antd'
import { useAuth } from '../../../hooks'
import { Input, Button } from '../../../components'
// import usePasswordToggle from '../shared/helpers/usePasswordToggle'
// import PasswordStrengthMeter from '../shared/helpers/PasswordStrengthMeter'
import Exit from '../shared/assets/exit.svg'
// import Socials from '../shared/helpers/socials'
import { getDeviceId } from '../../../utils'
import ZusLogo from '../shared/zus-logo/ZusLogo'
import Signup from '../shared/assets/sign-up-img.svg'

import '../shared/FormStyling.scss'

export const Register = () => {
    const {
        state: { isLoading, redirectTo },
        register: registerUser,
    } = useAuth()
    const history = useHistory()

    // Ant Form
    const [form] = Form.useForm()

    const handleRegister = (values) => {
        console.log(values)
        try {
            const res = registerUser({
                ...values,
                deviceId: getDeviceId(),
                countryId: 1,
                userName: values.email,
                userType: 1,
                isEnterpriseUser: false,
                isEnterpriseAdmin: false,
            })
            if (res.status === true) {
                history.push('/account/login')
            }
        } catch (error) {
            console.error(error.message)
        }
    }
    if (redirectTo) {
        // Either needs to set a modal state or redirect to a different page
        history.push(redirectTo);
    }
    return (
        <section id="register">
            <div className="contain">
                <div className="form-content-left">
                    <div className="form-container">
                        <div className="logo-zustech-form">
                            <Link to="/">
                                <ZusLogo />
                            </Link>
                        </div>
                        <div className="signup-text">
                            <h1 className="title">Let's get started</h1>
                            <p className="cta-text">
                                Enter your details to begin learning
                            </p>
                        </div>
                        <div className="img-1">
                            <img
                                src={Signup}
                                alt="signup-img"
                                className="signup-img"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-content-right">
                    <div className="exit-to-home-right">
                        <Link to="/">
                            <img
                                src={Exit}
                                alt="exit-cross"
                                className="return-home-right"
                            />
                        </Link>
                    </div>
                    <Form
                        className="signup-form"
                        name="form"
                        form={form}
                        initialValues={{
                            allowedMarketing: false,
                            deviceId: 'deviceId',
                        }}
                        onFinish={handleRegister}
                        autoComplete="on"
                        scrollToFirstError
                    >
                        <div className="form-btn">
                            <Link to="/account/login">
                                <button type="button" id="signin-btn" className="signIn-btn">
                                    Sign in
                                </button>
                            </Link>
                            <Link to="/account/register">
                                <button
                                    type="button"
                                    id="signup-btn"
                                    className="signUp-btn active"
                                >
                                    Sign up
                                </button>
                            </Link>
                        </div>
                        <div className="signup-text-right">
                            <h1 className="title" data-testid="title">Let's get started</h1>
                            <p className="cta-text">
                                Enter your details to begin learning
                            </p>
                        </div>

                        <div className="pb-2 row">
                            <div className="col-sm-6">
                                <Input
                                    name="firstName"
                                    id="firstName"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your first name',
                                        },
                                    ]}
                                    placeholder="First Name"
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    name="lastName"
                                    id="lastName"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your last name',
                                        },
                                    ]}
                                    placeholder="Last Name"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <div className="pb-2">
                            <Input
                                name="email"
                                id="email"
                                validationRules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter your email address',
                                    },
                                    {
                                        type: 'email',
                                        message:
                                            'This email address is not valid',
                                    },
                                ]}
                                placeholder="Email"
                                disabled={isLoading}
                            />
                        </div>
                        <div className="pb-2">
                            <Input.Password
                                name="password"
                                id="password"
                                validationRules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                    {
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{9,}$/,
                                        message:
                                            'Password should contain at least 8 characters including 1-uppercase, 1-lowercase, 1-alphanumeric and 1-number',
                                    },
                                ]}
                                placeholder="Password"
                                disabled={isLoading}
                            />
                            {/* <PasswordStrengthMeter
                                passwordInput={values.password}
                            /> */}
                        </div>
                        <div className="pb-2">
                            <Input.Password
                                name="confirmPassword"
                                id="confirmPassword"
                                dependencies={['password']}
                                validationRules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match!'
                                                )
                                            )
                                        },
                                    }),
                                ]}
                                placeholder="Confirm Password"
                                disabled={isLoading}
                            />
                        </div>

                        <Input.Checkbox
                            name="terms"
                            id="input-terms"
                            label={
                                <span>
                                    I have read the{' '}
                                    <a href="/t&c">Terms & Conditions </a>
                                </span>
                            }
                            validationRules={[
                                {
                                    validator: (_, value) =>
                                        (value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                new Error(
                                                    'Should accept agreement'
                                                )
                                            )),
                                },
                            ]}
                            disabled={isLoading}
                        />

                        <Input.Checkbox
                            name="allowedMarketing"
                            id="allowedMarketing"
                            label="Opt in for Marketing emails"
                            disabled={isLoading}
                        />

                        <Button
                            type="submit"
                            id="submit-btn"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Preparing your account...' : 'Submit' }
                        </Button>
                        {/* <div className="separator">
                            <div className="line" />
                            or
                            <div className="line" />
                        </div> */}
                        {/* <Socials /> */}
                    </Form>
                </div>
            </div>
        </section>
    )
}
