import { Layout } from 'antd'
import './Content.scss'

export const Content = ({ children }) => {
    return (
        <Layout.Content className="Dashboard__content">
            {children}
        </Layout.Content>
    )
}
