import {
    SET_CATEGORY,
    SET_COURSE_PRICE,
    SET_COURSE_TYPE,
    SET_COVER_IMAGE,
    SET_PREREQUISITE,
    SET_DIFFICULTY_LEVEL,
    SET_MODULES,
    SET_OBJECTIVES,
    SET_COURSE_TITLE,
    SET_INTRODUCTORY_LESSONS,
    SET_COURSE_LANGUAGE,
    SET_DESCRIPTION,
    CLEAR_COURSE_FORM,
    REMOVE_FROM_PREREQUISITE,
    REMOVE_FROM_OBJECTIVES,
    REMOVE_COVERIMG
} from '../../actions/courseForm/types'

const INIT_STATE = {
    title: '',
    description: '',
    difficultyLevel: 'Select difficulty level...',
    type: '',
    introLessons: {},
    category: 'Select category...',
    coverImage: {},
    language: 'Select language...',
    coursePrice: '',
    objectives: [],
    prerequisite: [],
    modules: {}
}

export const courseFormReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_COURSE_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case SET_DIFFICULTY_LEVEL:
            return {
                ...state,
                difficultyLevel: action.payload
            }
        case SET_COURSE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case SET_COURSE_PRICE:
            return {
                ...state,
                coursePrice: action.payload
            }
        case SET_COVER_IMAGE:
            return {
                ...state,
                coverImage: action.payload
            }
        case SET_PREREQUISITE:
            if (!state.prerequisite.some(itm => itm === action.payload)) {
                return {
                    ...state,
                    prerequisite: [...state.prerequisite, action.payload]
                };
            }
            return state;
        case SET_INTRODUCTORY_LESSONS:
            return {
                ...state,
                introLessons: action.payload
            }
        case REMOVE_FROM_PREREQUISITE:
            return {
                ...state,
                prerequisite: state.prerequisite.filter(str => str !== action.payload)
            }
        case SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload
            }
        case SET_COURSE_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case SET_MODULES:
            return {
                ...state,
                modules: action.payload
            }
        case SET_OBJECTIVES:
            if (!state.objectives.some(itm => itm === action.payload)) {
                return {
                    ...state,
                    objectives: [...state.objectives, action.payload]
                };
            }
            return state;
        case REMOVE_FROM_OBJECTIVES:
            return {
                ...state,
                objectives: state.objectives.filter(str => str !== action.payload)
            }
        case REMOVE_COVERIMG:
            return {
                ...state,
                coverImage: ''
            }
        case CLEAR_COURSE_FORM:
            return {
                ...state,
                title: '',
                description: '',
                difficultyLevel: 'Select difficulty level...',
                type: '',
                category: 'Select category...',
                coverImage: {},
                language: 'Select language...',
                coursePrice: '',
                objectives: [],
                prerequisite: [],
                modules: {},
            }
        default:
            return state
    }
}