import { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
// import Form from ant design
import { Form, Modal } from 'antd'
import { Input, Button } from '../../../components'
// import Socials from '../shared/helpers/socials'
import { useAuth } from '../../../hooks'
import { getDeviceId } from '../../../utils'
import ZusLogo from '../shared/zus-logo/ZusLogo'
import Exit from '../shared/assets/exit.svg'
import image from '../shared/assets/sign-up-img.svg'
// import styles
import '../shared/FormStyling.scss'

export const Login = () => {
    const {
        state: { isLoading, redirectTo, activeSso },
        login: loginUser,
        logout,
        closeModal
    } = useAuth()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Ant Form
    const [form] = Form.useForm()

    const handleLogin = (values) => {
        loginUser({ ...values, deviceId: getDeviceId() })
    }

    // const setCookie = () => {
    //     const e = email;
    //     const p = password;

    //     document.cookie = 'email=' + e + ';path=http://localhost/account/login/'
    //     document.cookie =
    //         'password=' + p + ';path=http://localhost/account/login/'
    // }

    // const getCookie = cname => {
    //     const name = cname + '='
    //     const cDecoded = decodeURIComponent(document.cookie)
    //     const cArr = cDecoded.split('; ')
    //     let res
    //     cArr.forEach((val) => {
    //         if (val.indexOf(name) === 0) res = val.substring(name.length)
    //     })
    //     return res
    // }

    // useEffect(() => {
    //     const getCookieData = () => {
    //         console.log(document.cookie);
    //         console.log(getCookie('email'))
    //         console.log(getCookie('password'));

    //         const user = getCookie('email')
    //         const pass = getCookie('password')

    //         setEmail(user)
    //         setPassword(pass);
    //     }
    //     getCookieData();
    // }, [])

    /* function checkboxDisplay() {
        const checkbox = document.getElementById('rememberCheckbox')
        const checkboxWarning = document.getElementsByClassName('remember-warning')
        if (checkbox.checked === true) {
            checkboxWarning.style.display = 'block'
        } else {
            checkboxWarning.style.display = 'none'
        }
    } */

    if (redirectTo) {
        if (redirectTo === '/account/login') {
            window.location.reload(true)
        } else {
            return <Redirect to={redirectTo} />
        }
    }

    return (
        <section id="signin">
            <div className="container">
                <main>
                    <Form
                        name="form"
                        form={form}
                        initialValues={{
                            rememberMe: false,
                        }}
                        onFinish={handleLogin}
                        autoComplete="on"
                    >
                        <div className="form-container">
                            <div className="form-btn">
                                <Link to="/account/login">
                                    <button
                                        type="button"
                                        className="signIn-btn"
                                        id="signin-btn"
                                    >
                                        Sign in
                                    </button>
                                </Link>
                                <Link to="/account/register">
                                    <button
                                        type="button"
                                        className="signUp-btn"
                                        id="signup-btn"
                                    >
                                        Sign up
                                    </button>
                                </Link>
                            </div>
                            <div className="header">
                                <h1>Welcome back</h1>
                                <p className="cta-signin">
                                    Sign in to continue
                                </p>
                            </div>
                            <div className="py-1">
                                <Input
                                    name="userName"
                                    id="username"
                                    validationRules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please input a valid email!',
                                        },
                                    ]}
                                    placeholder="Email"
                                    disabled={isLoading}
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(e.target.value)}
                                />
                            </div>

                            <div className="py-1">
                                <Input.Password
                                    name="password"
                                    id="password"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your password!',
                                        },
                                    ]}
                                    placeholder="Password"
                                    disabled={isLoading}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)}
                                />
                            </div>

                            <Input.Checkbox
                                type="checkbox"
                                name="rememberMe"
                                label="Remember me"
                                disabled={isLoading}
                                id="rememberCheckbox"
                                // onClick={() => setCookie()}
                            />

                            <div className="error-1">
                                <p className="remember-warning">
                                    If this is a public or shared computer other
                                    uses may be able to access your account, be
                                    aware of this when selecting remember me
                                </p>
                            </div>

                            <Button
                                type="submit"
                                id="signin-submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Authenticating user...' : 'Sign in'}
                            </Button>

                            <div className="forgot-password">
                                <p className="forgot-pwd-link">
                                    <Link to="/account/forgot-password">
                                        Forgot password?
                                    </Link>
                                </p>
                            </div>

                            {/* <div className="separator">
                                <div className="line" />
                                or
                                <div className="line" />
                            </div> */}
                            {/* SOCIALS */}
                            {/* <Socials /> */}
                        </div>
                    </Form>

                    <div className="exit-to-home-right">
                        <Link to="/">
                            <img
                                src={Exit}
                                alt="exit-cross"
                                className="return-home-right"
                            />
                        </Link>
                    </div>
                    <div className="welcome-message">
                        <div className="logo-exit">
                            <div className="logo-zustech-form">
                                <Link to="/">
                                    <ZusLogo />
                                </Link>
                            </div>
                        </div>
                        <div className="welcome-container">
                            <div className="content">
                                <h1>Welcome back</h1>
                                <p>Sign in to continue</p>
                            </div>
                            <img src={image} alt="" className="image" />
                        </div>
                    </div>
                </main>
            </div>
            <Modal
                open={activeSso}
                // open={true}
                centered
                className="signin"
                footer={null}
                onCancel={() => { closeModal() }}
            >
                <h3 className="heading">Are you sure you want to leave the device?</h3>
                <div className="para_content">
                    <p>You won't be able to log in on this system or device until you log out from the device where you were previously signed in.</p>
                </div>
                <div className="btn_cont">
                    <button
                        onClick={() => {
                            closeModal()
                        }}
                        className="cancel_btn"
                    > Close
                    </button>
                    <button
                        onClick={() => {
                            // closeModal()
                            logout(email)
                        }}
                        className="logout_btn"
                    > Logout
                    </button>
                </div>
            </Modal>
        </section>
    )
}
