import { request } from '../utils'

export const loginAdmin = async (values) => {
    try {
        const url = `/Admin/AdminSignIn`
        const response = await request.post(url, values)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const approveInstructorCourse = async (values) => {
    try {
        const url = `/Course/UpdateCourseStatus`
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const rejectInstructorCourse = async (values) => {
    try {
        const url = `/Course/UpdateCourseStatus`
        const response = await request.put(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
// /api/Course/PublishCourse
export const publishCourse = async (values) => {
    try {
        const url = `/Course/PublishCourse`
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
// /api/Course/ApproveCourse
export const approveCourse = async (id) => {
    try {
        const url = `/Course/ApproveCourse/?Id=${id}`
        const response = await request.put(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}