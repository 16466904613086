import { React, useState } from 'react'
import './Preferences.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { updateUserLanguage } from '../../../../services'
import { openNotification } from '../../../../utils'
import { Button } from '../../../../components/Button'

export const Preferences = () => {
    const lngs = {
        en: { nativeName: 'English', value: 'en', id: 0 },
        de: { nativeName: 'Deutsch', value: 'de', id: 1 },
        fr: { nativeName: 'Français', value: 'fr', id: 2 },
    }

    const defaultLanguage = localStorage.getItem('i18nextLng')

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)
    const [selectedLanguageId, setSelectedLanguageId] = useState()
    const [loading, setLoading] = useState(false)

    const { t, i18n } = useTranslation()

    const onChange = (e) => {
        setSelectedLanguage(e.target.value)

        const selectedIndex = e.target.options.selectedIndex;
        setSelectedLanguageId(selectedIndex + 1)
    }

    const handleUpdateUserLanguage = async (languageId) => {
        setLoading(true)
        try {
            const response = await updateUserLanguage({
                languageId,
            })
            openNotification({
                type: 'success',
                title: 'Update language',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Update language',
                message: error.message,
            })
        }
        setLoading(false)
    }

    const onSubmit = () => {
        i18n.changeLanguage(selectedLanguage)
        handleUpdateUserLanguage(selectedLanguageId)
    }

    return (
        <div id="Preferences">
            <h2 id="Title">{t('Settings.Profile.preferences.title')}</h2>

            <h3 className="SubTitle">
                {t('Settings.Profile.preferences.sub1')}
            </h3>
            <select
                defaultValue={defaultLanguage}
                name="LanguageSelector"
                id="LanguageSelector"
                onChange={onChange}
            >
                {Object.keys(lngs).map((lng, i) => (
                    <option key={i} value={lng} id={i} data-key={i}>
                        {lngs[lng].nativeName}
                    </option>
                ))}
            </select>
            <div>
                <Button
                    id="saveBtn"
                    type="submit"
                    onClick={onSubmit}
                    loading={loading}
                >
                    {t('Settings.Profile.preferences.save')}
                </Button>
                <Link to="/me">
                    <Button id="cancelBtn">
                        {t('Settings.Profile.preferences.cancel')}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
