import './studentReviews.scss'

import getStars from '../../shared/courseCard/helpers/getStars'
import getStar from '../../shared/courseCard/helpers/getStar'
// import getLevel from '../../shared/courseCard/helpers/getLevel'
import StudentReviewCards from './studentReviewCards/StudentReviewCards'

const StudentReviews = ({ rating, rateCount }) => {
    function roundHalf(num) {
        return (Math.round(num * 2) / 2).toFixed(1)
    }

    return (
        <section id="studentReviews">
            <h1>Student Reviews</h1>
            <div id="section1">
                <div id="averageRating">
                    <span className="rating-value">{rating}</span>
                    <div className="stars">
                        {getStars(roundHalf(rating)).map((rating, index) => (
                            <img
                                src={getStar(rating)}
                                width={16}
                                key={index}
                                alt="star"
                            />
                        ))}
                    </div>
                </div>

                {/* Percentages set in scss */}
                <div id="ratingBars">
                    <div className="row">
                        <div className="side">
                            <div>5</div>
                        </div>
                        <div className="middle">
                            <div className="bar-container">
                                <div className="bar" id="bar-5" />
                            </div>
                        </div>
                        <div className="side">
                            <div>4</div>
                        </div>
                        <div className="middle">
                            <div className="bar-container">
                                <div className="bar" id="bar-4" />
                            </div>
                        </div>
                        <div className="side">
                            <div>3</div>
                        </div>
                        <div className="middle">
                            <div className="bar-container">
                                <div className="bar" id="bar-3" />
                            </div>
                        </div>
                        <div className="side">
                            <div>2</div>
                        </div>
                        <div className="middle">
                            <div className="bar-container">
                                <div className="bar" id="bar-2" />
                            </div>
                        </div>
                        <div className="side">
                            <div>1</div>
                        </div>
                        <div className="middle">
                            <div className="bar-container">
                                <div className="bar" id="bar-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="section2">
                <div id="reviewControls">
                    <span className="rating-count">{rateCount} reviews</span>
                    <select name="card-ratings" id="cardRatings">
                        <option value="">All Ratings</option>
                        <option value="">five star</option>
                        <option value="">four star</option>
                        <option value="">three star</option>
                        <option value="">two star</option>
                        <option value="">one star</option>
                    </select>
                </div>

                <StudentReviewCards
                    rating={4.7}
                    name="Joss Smith"
                    reviewText="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic rem repudiandae consequatur vero omnis autem vitae velit libero? Esse ipsam officiis voluptatum optio temporibus. Neque cum nisi rem repudiandae in!"
                    likes={5}
                    dislikes={1}
                />
            </div>
        </section>
    )
}

export default StudentReviews
