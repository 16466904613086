import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { TermsLayout } from '../../../components'
import { Privacy } from './components/Privacy'
import { Termspage } from './components/Termspage'
import { InstructorTerms } from './components/InstructorTerms'

const TermsPageLayout = () => {
    const routes = [
        {
            path: '/terms/',
            name: 'Terms and Condition',
            exact: true,
            component: Termspage,
        },
        {
            path: '/terms/privacy',
            name: 'Privacy',
            exact: true,
            component: Privacy,
        },
        {
            path: '/terms/instructor-term-of-use',
            name: 'Instructor-term-of-use',
            exact: true,
            component: InstructorTerms,
        },
    ]
    return (
        <TermsLayout>
            <Switch>
                {routes.map((route) => {
                    return <Route {...route} key={route.name} />
                })}
            </Switch>
        </TermsLayout>
    )
}

export default TermsPageLayout
