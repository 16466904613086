// React imports
import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
// API imports
import { createIntentCardPayment } from '../../../../../services'
import CheckoutForm from './CheckoutForm'
// Styling
import './stripe.scss'

// Stripe is initialized with the key from the .env file
const stripePromise = loadStripe(
    'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
)

export function StripeApp(props) {
    const [clientSecret, setClientSecret] = useState('')

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads so it can be passed through
        const response = createIntentCardPayment({
            productId: props.plan.id,
        })

        // Receive the response, assign the clientsecret value to the value received
        response.then((data) => {
            const secret = data
            setClientSecret(secret.data.clientSecret)
        })
    }, [props])

    useEffect(() => {
        // Set the plan value in local storage to pass it across the site, it's called back in checkout to display the users plan
        localStorage.setItem('plan', props.plan.id)
    })

    // Sets the stripe styling to the selected stripe theme
    const appearance = {
        theme: 'stripe',
    }

    // Pass the appearance object to the elements instance
    const options = {
        clientSecret,
        appearance,
    }

    return (
        <div id="stripeContainer">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    )
}
