import {
    SHOW_HIDE_CART,
    ADD_TO_CART,
    REMOVE_ITEM,
    CLEAR_CART,
    GET_TOTALS,
    UPDATE_GRAND_TOTAL,
} from '../types'

export const CartReducer = (state, action) => {
    switch (action.type) {
        case SHOW_HIDE_CART: {
            return {
                ...state,
                showCart: !state.showCart,
            }
        }
        case ADD_TO_CART: {
            // localStorage.setItem(
            //     'cartItems',
            //     JSON.stringify(state.cartItems)
            // )
            return {
                ...state,
                cartItems: [...state.cartItems, action.payload],
                premSuccessItems: [...state.premSuccessItems, action.payload],
            }
        }
        case REMOVE_ITEM: {
            return {
                ...state,
                cartItems: state.cartItems.filter(
                    (item) => item.id !== action.payload
                ),
                premSuccessItems: state.premSuccessItems.filter(
                    (item) => item.id !== action.payload
                ),
            }
        }
        case CLEAR_CART: {
            return {
                ...state,
                cartItems: [],
                total: 0,
                grandTotal: 0,
            }
        }
        case GET_TOTALS: {
            const { cartItems } = state
            const total = cartItems.reduce((acc, curr) => acc + curr.amount, 0)
            const grandTotal = total
            return { ...state, total, grandTotal }
        }
        case UPDATE_GRAND_TOTAL:
            return {
                ...state,
                grandTotal: action.payload,
            }
        default:
            return state
    }
}