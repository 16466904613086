import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Progress, Rate } from 'antd'
import beginner from '../../../assets/icons/plain_beginner.svg'
import Intermediate from '../../../assets/icons/plain_intermediate.svg'
import Advance from '../../../assets/icons/Plain_advance.svg'
import { useAuth } from '../../../hooks'
import { getUsersProgressOnCourse, getCourseReview } from '../../../services'

export function EnrolledCourse({ course }) {
    const {
        state: { data }
    } = useAuth()

    const [progress, setProgress] = useState()
    const [diffLevel, setDifflevel] = useState()
    const [reviewObj, setReviewObj] = useState({
        'TotalRate': 0,
        'Average_rate': 0,
    })
    const [coverImg, setCoverImg] = useState('https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg')

    useEffect(() => {
        switch (course.courseLevel) {
            case 1:
                setDifflevel(beginner)
                break;
            case 2:
                setDifflevel(Intermediate)
                break;
            case 3:
                setDifflevel(Advance)
                break;
            default:
                setDifflevel(beginner)
        }
        // Function to get course Progress
        const getCourseProgress = async () => {
            try {
                const res = await getUsersProgressOnCourse(course.id, data.id)
                setProgress(res.data.progress)
            } catch (err) {
                console.error(err.message)
            }
        }

        if (course.coursePicture !== null && course.coursePicture !== 'mediaFD' && course.coursePicture !== '{}' && course.coursePicture !== 'string') {
            setCoverImg(course.coursePicture)
        }

        // Function to get course review
        const getCrsReview = async () => {
            try {
                const res = await getCourseReview(course.id, 1, 100)
                if (res.data.reviews !== null) {
                    setReviewObj({
                        'TotalRate': res.data.totalRate,
                        'Average_rate': (res.data.totalRate / res.data.reviews.length).toFixed(1),
                    })
                } else {
                    setReviewObj({
                        'TotalRate': 0,
                        'Average_rate': 0,
                    })
                }
            } catch (error) {
                console.error(error.message)
            }
        }

        getCourseProgress()
        getCrsReview()

        return () => {
            setReviewObj({})
        }
        // eslint-disable-next-line
    }, [course.id, course.courseLevel, course.coursePicture])

    return (
        <div id={`enrolled-dash-${course.id}`}>
            <Link className="Course-1" to={`/me/courses/course/${course.id}`}>
                <div className="stu-course-container">
                    <div className="upper_section">
                        <div className="img_cont">
                            <img src={coverImg} alt="course_coverImg" />
                        </div>
                        <div className="progress">
                            <span>{progress} %</span>
                            <Progress percent={progress} showInfo={false} strokeColor="#ffcc00" strokeWidth=".6em" />
                        </div>
                    </div>
                    <div className="lower_section">
                        <div title={course.name} className="course_heading">{course.name}</div>
                        <div className="middle_belt">
                            <div className="difficulty_indicator">
                                <img src={diffLevel} alt="beginner" />
                            </div>
                            <div className="divider">&nbsp;</div>
                            <div className="course_rating">
                                <span>{reviewObj.Average_rate}</span>
                                <Rate
                                    disabled
                                    allowHalf
                                    value={parseFloat(reviewObj.Average_rate)}
                                    style={{
                                        'fontSize': '1em',
                                    }}
                                />
                                <span>({reviewObj.TotalRate})</span>
                            </div>
                        </div>
                        <div className="author"> By {course.instructorName}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
