import { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top';
import ReactPlayer from 'react-player/lazy'
import { Progress, Tabs, Modal, Button } from 'antd'
import { useAuth, useInterface, useForm } from '../../../hooks'
import {
    findCourseById,
    getUsersProgressOnCourse,
    setProgressOnCourse,
    getCourseReview,
    checkIfEnrolled,
    courseEnroll,
    createModuleTest,
    createCourseTest,
    addQuestionToTest,
    findQuizByCourseId,
    getUserTestResults
} from '../../../services'
import { openNotification } from '../../../utils';
import './courseOverview.scss'
import Arrow_left from '../../../assets/icons/arrow-left.svg'
import Celebration_icon from '../../../assets/icons/Celebration_icon.svg'
import Check from '../../../assets/icons/Done_loading.svg'
import { RequestLoader } from '../../../components/Loading';
import Overview from './component/overview'
import Review from './component/review'
import Module from './component/module'
import QuizScreen from './component/quizFramer/QuizScreen';
import { ReviewForm } from './component/reviewForm/reviewForm'
// ../../EnrolledCourse/ActiveEnrolled/component/DownloadCertButton/index
import { DownloadCertButton } from '../EnrolledCourse/ActiveEnrolled/component/DownloadCertButton/index'

export const CourseDetails = () => {
    // Using the user id to get the progress and set progress
    // so now i need a default value for userId, why? {the modules won't load without the user id}
    // I'll also need to create a dummy introduction lesson
    const {
        state: { data: user, isLoggedIn, activeSub, courseSubs },
    } = useAuth()
    const {
        state: { quizMode, clicked },
        setQuizMode,
        beginQuiz,
        setQuizType,
        setQuizCourseId
    } = useInterface()
    const {
        clearQuizForm
    } = useForm()
    // Route was prepare to accept a parameter, destructured the parameter with the hook
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation()
    const currentUrl = location.pathname
    const [total, setTotal] = useState(0)
    const [watched, setWatched] = useState(0)
    const [bool, setBool] = useState()
    const [objStore, setObjStore] = useState({
        url: 'https://youtu.be/BhX6dU3tH3s',
        lessonId: 1,
        moduleId: 1,
    })
    // Testing how well the state will work
    const [courseInfo, setCourseInfo] = useState('')
    const [modules, setModules] = useState({})
    const [progressStatus, setProgressStatus] = useState(0)
    const [courseProgress, setCourseProgress] = useState({})
    const [send, setSend] = useState(false)
    // const [fstLesson, setFstLesson] = useState(false)
    const [success, setSuccess] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [isSubActive, setIsSubActive] = useState()
    const [isSingleSubActive, setIsSingleSubActive] = useState()
    const [lockLesson, setLockLesson] = useState()
    const [reviewObj, setReviewObj] = useState({})
    const [coverImg, setCoverImg] = useState('https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg')
    // const [duration, setDuration] = useState('02:10')
    const [openMultiSubModal, setOpenMultiSubModal] = useState(false)
    const [openSingleSubModal, setOpenSingleSubModal] = useState(false)
    const [openReviewModal, setOpenReviewModal] = useState(false)
    const [courseCompleted, setCourseCompleted] = useState(false)
    const [canReview, setCanReview] = useState(false)
    const [downloadCert, setDownloadCert] = useState(false)
    const [isPreviewPage, setIsPreviewPage] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [quizDataSource, setQuizDataSource] = useState([])
    const [courseHasQuiz, setCourseHasQuiz] = useState()
    const [quizId, setQuizId] = useState('')
    const [testResult, setTestResult] = useState({})
    // Memoiezing objects passed as prop to the memoed component
    const memoCourseInfo = useMemo(() => ({ ...courseInfo }), [courseInfo])
    const memoReviewObj = useMemo(() => ({ ...reviewObj }), [reviewObj])
    const memoModuleObj = useMemo(() => ({ ...modules }), [modules])
    // Memoie functions passed as prop to memoed component
    const updateObjStore = useCallback((url, lessonId, moduleId) => {
        setObjStore({ url, lessonId, moduleId })
    }, [])
    const updateFetch = useCallback((a) => setRefetch(a), [])
    const updateProgress = useCallback((a, lesId, modId) => {
        if (a < 1) {
            setBool(false)
        } else {
            setBool(true)
        }
        setWatched(prev => prev + a)
        setObjStore(prev => ({ ...prev, 'lessonId': lesId, 'moduleId': modId }))
        setSend(true)
    }, [])
    // Optimize Subscription Modal
    const subModalState = useCallback((a) => {
        // state to open modal for unsubscribed user
        if (courseInfo.courseType === 1) {
            setOpenMultiSubModal(a)
        }
        if (courseInfo.courseType === 3) {
            setOpenSingleSubModal(a)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        // })
        // Checking if current page is the preview page
        const paths = currentUrl.split('/')
        for (let i = 0; i < paths.length; i++) {
            if (paths[i] === 'preview-quiz') {
                setIsPreviewPage(true)
            }
        }
        const quizInfo = JSON.parse(localStorage.getItem('QuizInfo'))
        if (isPreviewPage) {
            if (quizInfo !== null) {
                setQuizDataSource(quizInfo)
            } else {
                history.push('/tutor/courses')
            }
        }
        // eslint-disable-next-line
    }, [currentUrl, isPreviewPage])

    useEffect(() => {
        // Check if user has general subscription
        const userSubscribe = async (courseId) => {
            try {
                // getting active subscription for this course
                if (activeSub && activeSub.subscriptionStatus === 1) {
                    setIsSubActive(true)
                } else {
                    setIsSubActive(false)
                }
                if (courseInfo.courseType === 3) {
                    const listOfCourseSub = courseSubs.filter((a) => a.courseId === parseInt(courseId, 10))
                    console.log(listOfCourseSub)
                    if (listOfCourseSub.length !== 0) {
                        setIsSingleSubActive(true)
                    } else {
                        setIsSingleSubActive(false)
                    }
                }
            } catch (error) {
                console.error(error.message)
            }
        }

        // get course Quiz
        const getCourseQuiz = async (courseId) => {
            try {
                const res = await findQuizByCourseId(courseId)
                if (res.data.length >= 1) {
                    const latestQuiz = res.data.sort((a, b) => a.id - b.id).pop()
                    setCourseHasQuiz(true)
                    setQuizId(latestQuiz.id)
                }
                // res.data.sort((a, b) => b.id - a.id)
                // setCourseQuiz(res.data[0])
            } catch (error) {
                console.error(error.message)
            }
        }

        // Check if user enrolled for the course
        const userEnrolled = async (id, userId) => {
            try {
                const res = await checkIfEnrolled(id)
                // enroll user to the course if not enrolled and progress is greater than one
                if (res.data === false && progressStatus >= 1) {
                    const res = await courseEnroll(userId, id)
                    console.log(res.data)
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        const getReview = async (id) => {
            try {
                const res = await getCourseReview(id, 1, 100)
                if (res.data.reviews !== null) {
                    setReviewObj({
                        courseId: id,
                        Reviews: res.data.reviews,
                        Review_len: res.data.reviews.length,
                        TotalRate: res.data.totalRate,
                        Average_rate: (
                            res.data.totalRate / res.data.reviews.length
                        ).toFixed(1),
                    })
                } else {
                    setReviewObj({
                        courseId: id,
                        Reviews: [],
                        Review_len: 0,
                        TotalRate: 0,
                        Average_rate: 0,
                    })
                }
            } catch (err) {
                console.error(err.message)
            }
        }
        // Get User test Result
        const getUserResults = async () => {
            try {
                const res = await getUserTestResults(user?.id)
                const result = {}
                if (res.data.length >= 1) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].score === 0) {
                            result[res.data[i].testId] = 0
                        } else {
                            result[res.data[i].testId] = res.data[i].score
                        }
                    }
                    setTestResult(result)
                }
            } catch (err) {
                console.error(err.message)
            }
        }
        if (!isPreviewPage) {
            if (isLoggedIn) {
                // Subscription check logic per courseType
                if (courseInfo.courseType !== 2) {
                    userSubscribe(id)
                    userEnrolled(id, user.id)
                }
                getCourseQuiz(id)
                getUserResults()
            } else {
                setIsSubActive(false)
            }
        }
        getReview(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, isLoggedIn, id, refetch, progressStatus, isPreviewPage, courseInfo.courseType])

    // LOCK LESSON LOGIC CHECK
    useEffect(() => {
        switch (courseInfo.courseType) {
            case 1:
                // handle lock lesson for standard course
                isSubActive ? setLockLesson(false) : setLockLesson(true)
                break
            case 2:
                setLockLesson(false)
                break
            case 3:
                isSingleSubActive ? setLockLesson(false) : setLockLesson(true)
                break
            default:
                setLockLesson(true)
        }
    }, [isSubActive, isSingleSubActive, courseInfo.courseType])
    // Get related materials of the course
    useEffect(() => {
        // get modules
        const fetchDatum = async (id) => {
            const modules = {}
            const lessonObj = {}
            try {
                // The below request gets all available infomation about a course using the course id
                const res = await findCourseById(id)
                const cos = res?.data?.courseModel?.courseInfor
                setCourseInfo(cos)
                if (cos.coursePicture !== null && cos.coursePicture !== 'mediaFD' && cos.coursePicture !== '{}' && cos.coursePicture !== 'string') {
                    setCoverImg(cos.coursePicture)
                }
                // in the data returned there exist the module ids specific to the course
                // Had to convert it from a string to an array so i can iterate
                const topics = res.data.courseContent
                // Loop through the topics to get the structure with their position as key
                if (topics.length >= 1) {
                    // first thing to do is sort them
                    topics.sort((a, b) => a.index - b.index)
                    for (let i = 0; i < topics.length; i++) {
                        modules[topics[i].index] = topics[i]
                        if (topics[i].moduleName !== 'Introduction') {
                            for (let j = 0; j < topics[i].lessons.length; j++) {
                                lessonObj[topics[i].lessons[j].id] = topics[i].lessons[j]
                            }
                        }
                    }
                    setModules(modules)
                    setTotal(Object.keys(lessonObj).length)
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        fetchDatum(id)
        return () => {
            setCourseInfo('')
            setModules([])
            setTotal(0)
        }
    }, [id])

    const getFirstLesson = (obj) => {
        let url;
        Object.values(obj).forEach((module) => {
            if (module.moduleName === 'Introduction') {
                module.lessons.sort((a, b) => a.index - b.index)
                url = module.lessons[0]?.content
            }
        })
        return url
    }
    // CREATE QUIZ LOGICS
    async function createQuiz() {
        setIsCreating(true)
        setOpenModal(true)
        const quizCreation = async (type, productId, title, totalScore, totalQuestion) => {
            try {
                const data = {
                    'testLevel': 1,
                    'totalScore': totalScore,
                    'totalQuestions': totalQuestion,
                    'title': title,
                    'testType': 1
                }
                if (type === 'Course') {
                    data['courseId'] = productId
                    const res = await createCourseTest(data)
                    if (res.status) {
                        return res.data.id
                    }
                } else if (type === 'Module') {
                    data['moduleId'] = productId
                    const res = await createModuleTest(data)
                    if (res.status) {
                        return res.data.id
                    }
                }
            } catch (err) {
                console.error(err.message)
                setOpenModal(false)
                openNotification({
                    title: 'Quiz Creation',
                    message: err.message,
                    type: 'error'
                })
                return null
            }
        }
        const appendQuestToQuiz = async (questionIds, quizId) => {
            try {
                if (quizId === null) {
                    throw new Error("QuizId isn't set")
                }
                const res = await Promise.all(
                    questionIds.map(async questionId => {
                        const data = {
                            'questionId': questionId.id,
                            'testId': quizId
                        }
                        try {
                            const res = await addQuestionToTest(data)
                            return res.data
                        } catch (err) {
                            console.error(err.message)
                        }
                    })
                );
                return res
            } catch (err) {
                console.error(err.message)
                setOpenModal(false)
                openNotification({
                    title: 'Adding Question to Quiz',
                    message: err.message,
                    type: 'error'
                })
                return false
            }
        }
        try {
            const totalScore = (quizDataSource.question.length * 5)
            const totalQuestion = quizDataSource.question.length
            const title = quizDataSource.quizTitle
            const quizId = await quizCreation(quizDataSource.quizType, quizDataSource.Id, title, totalScore, totalQuestion)
            if (quizId) {
                const value = await appendQuestToQuiz(quizDataSource.question, quizId)
                if (value.includes(true)) {
                    // setSelectedModules([])
                    clearQuizForm()
                    beginQuiz(false)
                    setQuizMode(false)
                    setIsCreating(false)
                    // Created quiz data
                    localStorage.removeItem('QuizInfo')
                    const unCreatedQuiz = JSON.parse(localStorage.getItem('SavedQuiz'))
                    // Remove created quiz from saved quiz
                    if (unCreatedQuiz !== null) {
                        for (let i = 0; i < unCreatedQuiz.length; i++) {
                            if (unCreatedQuiz[i].quizTitle === title) {
                                unCreatedQuiz.splice(i, 1)
                                // when saved quiz is empty, delete saved questions
                                if (unCreatedQuiz.length === 0) {
                                    localStorage.removeItem('QuestStore')
                                }
                                localStorage.setItem('SavedQuiz', JSON.stringify(unCreatedQuiz))
                                break
                            }
                        }
                    }
                }
            } else {
                throw new Error('An Error Occured')
            }
            // Quiz for multiple module can be created at once
            // Loop through the selected module, then create a quiz and add all question to the created quiz
        } catch (err) {
            setOpenModal(false)
            console.error(err.message)
        }
    }
    // Get Users progress and update num of total lessons
    useEffect(() => {
        // const source = axios.CancelToken.source()
        const getData = async () => {
            try {
                if (isLoggedIn && isSubActive === true) {
                    const res = await getUsersProgressOnCourse(id, user.id)
                    setCourseProgress(res.data)
                    // Logic to show modal for review
                    // what if the calculated progress skipped all this value because of Num of lessons???
                    if (progressStatus === 100) {
                        setCanReview(true)
                        setCourseCompleted(true)
                    } else if (progressStatus >= 25 && progressStatus < 100) {
                        setCanReview(true)
                        if ((progressStatus >= 25 && progressStatus <= 28) || (progressStatus >= 50 && progressStatus <= 53) || (progressStatus >= 75 && progressStatus <= 78)) {
                            setOpenReviewModal(true)
                        } else {
                            setOpenReviewModal(false)
                        }
                    } else {
                        setCanReview(false)
                    }
                    let count = 0
                    for (let i = 0; i < res.data?.modules?.length; i++) {
                        const lessons = res.data?.modules?.[i]?.lessons
                        for (let j = 0; j < lessons?.length; j++) {
                            if (lessons[j]?.isCompleted === true) {
                                count++
                            }
                        }
                    }
                    setWatched(count)
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        if (!isPreviewPage) {
            getData()
        }
        return () => {
            setCourseProgress({})
        }
    }, [isLoggedIn, isSubActive, id, user?.id, progressStatus, isPreviewPage])
    // Setting users progress
    useEffect(() => {
        const value = Math.ceil((watched / total) * 100)
        const setData = async (data) => {
            try {
                const res = await setProgressOnCourse(data)
                if (res.message === 'Success') {
                    return true
                } else {
                    return false
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        if (watched < 0) {
            setWatched(0)
        }
        // Keeping the progress value intact at different scenario
        if (isNaN(value) || value <= 0) {
            setProgressStatus(0)
            if (send) {
                const lesson = {
                    'lessonId': parseInt(objStore.lessonId, 10),
                    'isCompleted': bool
                }
                const module = {
                    'moduleId': parseInt(objStore.moduleId, 10),
                    'lessons': [{ ...lesson }]
                }
                const data = {
                    'courseId': parseInt(id, 10),
                    'progress': (value < 1 ? 0 : value),
                    'modules': [{ ...module }]
                }
                const res = setData(data)
                setSuccess(res)
            }
        } else if (value > 100 && !isPreviewPage) {
            setProgressStatus(courseProgress?.progress)
        } else {
            // this happens when the watch value has been updated (state)
            setProgressStatus(value)
            if (send) {
                const lesson = {
                    'lessonId': parseInt(objStore.lessonId, 10),
                    'isCompleted': bool
                }
                const module = {
                    'moduleId': parseInt(objStore.moduleId, 10),
                    'lessons': [{ ...lesson }]
                }
                const data = {
                    'courseId': parseInt(id, 10),
                    'progress': (value < 1 ? 0 : value),
                    'modules': [{ ...module }]
                }
                const res = setData(data)
                setSuccess(res)
            }
        }

        return () => {
            setProgressStatus(prev => prev)
            setSend(false)
        }
    // eslint-disable-next-line
    }, [watched, bool, send, total])

    const tabs = [
        {
            key: '1',
            label: 'Overview',
            children: <Overview course={memoCourseInfo} total={total} />,
        },
        {
            key: '2',
            label: 'Review',
            children: <Review userProgress={progressStatus} reviewData={memoReviewObj} refetch={updateFetch} />,
        },
        {
            key: '3',
            label: 'Q&A',
            children: 'Q&A tab',
        },
        {
            key: '4',
            label: 'Tasks',
            children: 'Task tab',
        },
        {
            key: '5',
            label: 'Annoucement',
            children: 'Annoucement tab',
        },
        {
            key: '6',
            label: 'Transcript',
            children: 'Transcript tab',
        }
    ]
    return (
        <>
            <ScrollToTop />
            <main className="course_overview">
                <div className="heading_cont">
                    <div
                        className="img_cont"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <img src={Arrow_left} alt="arrow_icon" />
                    </div>
                    <h2 style={{ width: '96%' }}>{courseInfo?.name}</h2>
                    {/* {!isSingleSubActive && courseInfo?.courseType === 3 ? (
                        <Link to={`/me/single-subscription/${id}`}>
                            <Button
                                id="subscribeSingleCos"
                                type="primary"
                                // style={{
                                //     borderRadius: '7px',
                                //     marginRight: '20px',
                                //     height: '200%',
                                // }}
                            >
                                Subscribe
                            </Button>
                        </Link>
                    ) : null} */}
                    {isPreviewPage ? (
                        <div className="control_btn">
                            <Button
                                className="edit_btn"
                                type="primary"
                                onClick={() => {
                                    // also on the preview page we've got edit button, (leads to a form, getting data as prop)
                                    history.push('/tutor/create-quiz')
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                className="submit_btn"
                                onClick={() => {
                                    // on the preview page we submit (function, modal, and redirect)
                                    // setOpenModal(true)
                                    createQuiz()
                                }}
                                type="primary"
                            >
                                Submit
                            </Button>
                        </div>
                    ) : (!activeSub && courseInfo?.courseType === 3) && (
                        <Link id="SubButton" to={`/me/single-subscription/${id}`}>
                            <Button
                                id="subscribeSingleCos"
                                type="primary"
                                style={{
                                    borderRadius: '7px',
                                    marginRight: '20px',
                                    height: '100%'
                                }}
                            >
                                Subscribe
                            </Button>
                        </Link>
                    )}
                </div>
                {!isPreviewPage && (
                    <div className="progress_cont">
                        <div className="top_bar">
                            <p>
                                <span>Progess</span>{' '}
                                <span>{progressStatus}%</span>
                            </p>
                            <Progress
                                percent={progressStatus}
                                status="active"
                                showInfo={false}
                            />
                        </div>
                    </div>
                )}
                <section className="course_content">
                    <div className="content">
                        <div className="video_cont">
                            {quizMode ? (
                                <QuizScreen />
                            ) : (
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    playing={true}
                                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                                    light={
                                        <img src={coverImg} alt="Thumbnail" />
                                    }
                                    url={
                                        !clicked
                                            ? getFirstLesson(modules)
                                            : objStore.url
                                    }
                                    controls={true}
                                    config={{
                                        file: {
                                            attributes: {
                                                disablepictureinpicture: 'true',
                                                controlslist:
                                                    'nodownload noplaybackrate nofullscreen',
                                            },
                                        },
                                    }}
                                    onEnded={() => {
                                        // wanna set an array of lesson source for continuous videos
                                        // After watching all intro lessons, i want to check for if user has subscribed and has enrolled for the course
                                        // @ diff check, if falsy open an infomative modal
                                        // If user is subscribed and enrolled go on to next video, and importantly update progress on course
                                        if (!lockLesson) {
                                            // update progress on course
                                            updateProgress(
                                                1,
                                                objStore.lessonId,
                                                objStore.moduleId
                                            )
                                        } else {
                                            // state to open modal for unsubscribed user
                                            if (courseInfo.courseType === 1) {
                                                setOpenMultiSubModal(true)
                                            }
                                            if (courseInfo.courseType === 3) {
                                                setOpenSingleSubModal(true)
                                            }
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className="tab_cont">
                            <Tabs
                                defaultActiveKey="1"
                                items={tabs}
                                centered
                                size="large"
                                tabBarGutter="3em"
                            />
                        </div>
                    </div>
                    <aside>
                        <h2 className="heading">Content</h2>
                        <div className="module_cont">
                            {modules && (
                                <Module
                                    reRun={success}
                                    courseId={id}
                                    mod={memoModuleObj}
                                    subscribed={!lockLesson}
                                    progress={updateProgress}
                                    updateUrl={updateObjStore}
                                    subModal={subModalState}
                                    testResult={testResult}
                                />
                            )}
                        </div>
                        {/* course quiz is coming here */}
                        <div className="quiz_cont">
                            <div
                                className="quizControl_cont"
                                onClick={() => {
                                    if (
                                        isSubActive === true &&
                                        progressStatus >= 1
                                    ) {
                                        setQuizMode(true)
                                        setQuizType('Course')
                                        setQuizCourseId(id)
                                    } else if (isPreviewPage) {
                                        setQuizMode(true)
                                    } else {
                                        openNotification({
                                            title: "User Can't Take Quiz",
                                            type: 'error',
                                            message:
                                                'Go through the module first',
                                        })
                                    }
                                }}
                            >
                                {isPreviewPage && (
                                    <p className="previewQuiz">View Quiz</p>
                                )}
                                {courseHasQuiz && !isPreviewPage && (
                                    <div className="quizCTA">
                                        <p className="quizcont">
                                            {testResult[quizId] < 1 ||
                                            testResult[quizId] === undefined
                                                ? 'Take Test'
                                                : 'Re-take test'}
                                        </p>
                                        <p className="quizScore">
                                            {testResult[quizId] &&
                                                (testResult[quizId] < 2
                                                    ? `${testResult[quizId]} mark`
                                                    : `${testResult[quizId]} marks`)}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </aside>
                </section>
            </main>
            {/* Multi Subscription Modal */}
            <Modal
                // open={true}
                open={openMultiSubModal}
                centered
                footer={null}
                onCancel={() => {
                    setOpenMultiSubModal(false)
                }}
            >
                <h3 className="title">No Subscription</h3>
                <div className="subInfo">
                    <p>You do not have an Active Subscription.</p>
                    <p>
                        You cannot proceed past the introduction video(s)
                        without subscribing to one of our pricing plans.
                    </p>
                    <p>
                        Would you like to visit our pricing page to see all the
                        available plans?
                    </p>
                </div>
                <div className="ctrl_btn">
                    <button
                        onClick={() => {
                            setOpenMultiSubModal(false)
                        }}
                        className="negative"
                    >
                        No, Thank You
                    </button>
                    <button
                        onClick={() => {
                            if (isLoggedIn) {
                                history.push('/me/subscription')
                            } else {
                                history.push('/pricing')
                            }
                        }}
                        className="positive"
                    >
                        Yes, Go to Pricing Page
                    </button>
                </div>
            </Modal>
            {/* Single or Institutional Subcription Modal */}
            <Modal
                // open={true}
                open={openSingleSubModal}
                centered
                footer={null}
                onCancel={() => {
                    setOpenSingleSubModal(false)
                }}
            >
                <h3 className="title">No Subscription</h3>
                <div className="subInfo">
                    <p>You do not have a Single/Institutional Subscription.</p>
                    <p>
                        You cannot proceed past the introduction video(s)
                        without subscribing to one of our pricing plans.
                    </p>
                    <p>
                        Would you like to visit our single pricing page for
                        institutional courses to see all the available plans?
                    </p>
                </div>
                <div className="ctrl_btn">
                    <button
                        onClick={() => {
                            setOpenSingleSubModal(false)
                        }}
                        className="negative"
                    >
                        No, Thank You
                    </button>
                    <button
                        onClick={() => {
                            if (isLoggedIn) {
                                history.push(`/me/single-subscription/${id}`)
                            }
                        }}
                        className="positive"
                    >
                        Yes, Go to Pricing Page
                    </button>
                </div>
            </Modal>
            {/* Download Certificate Modal */}
            <Modal
                // open={true}
                open={downloadCert}
                centered
                footer={null}
                onCancel={() => {
                    setDownloadCert(false)
                }}
            >
                <h3 className="review_title">Course Certificate Download</h3>
                <div className="review_subInfo">
                    <div className="img_cont">
                        <img src={Check} alt="check" />
                    </div>
                    <p>Click the button below to view certificate</p>
                </div>
                <div className="review_ctrl_btn">
                    <DownloadCertButton courseId={id} />
                </div>
            </Modal>
            {/* Course Review Modal */}
            <Modal
                // open={true}
                open={openReviewModal}
                centered
                footer={null}
                onCancel={() => {
                    setOpenReviewModal(false)
                }}
            >
                <div className="review_cont">
                    <h3 className="title">Course Review</h3>
                    <ReviewForm
                        progress={progressStatus}
                        canReview={canReview}
                        courseId={reviewObj.courseId}
                        refetch={setRefetch}
                    />
                </div>
            </Modal>
            {/* Course Completed modal */}
            <Modal
                // open={true}
                open={courseCompleted}
                centered
                footer={null}
                onCancel={() => {
                    setCourseCompleted(false)
                    setDownloadCert(true)
                }}
            >
                <div className="celeb_cont">
                    <h3 className="title">Congratulations!</h3>
                    <div className="content">
                        <div className="img_cont">
                            <img
                                src={Celebration_icon}
                                alt="celebration_Icon"
                            />
                        </div>
                        <p className="styled_text">
                            You have successfully completed this course.
                        </p>
                        <div className="cert_info">
                            You are almost there to getting your certificate.
                        </div>
                        <p className="styled_text">
                            Kindly leave a review to help out instructors create
                            better learning experience.
                        </p>
                        <div className="ctrl_btn">
                            <Button
                                onClick={() => {
                                    setCourseCompleted(false)
                                    setDownloadCert(true)
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setCourseCompleted(false)
                                    setOpenReviewModal(true)
                                }}
                            >
                                Leave a review
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* Quiz creation Modal */}
            <Modal
                centered
                id="ModalCourseCreation"
                footer={null}
                open={openModal}
                // open={true}
                style={{
                    textAlign: 'center',
                }}
            >
                <div>
                    <h4 style={{ color: '#3a86ff' }}>
                        {isCreating
                            ? 'Quiz Creation'
                            : 'Quiz created successfully'}
                    </h4>
                    <div style={{ marginBlock: '2em' }}>
                        {isCreating && <RequestLoader />}
                        {!isCreating && <img src={Check} alt="done_loading" />}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link
                            id="ToQuizSuccessCreationPage"
                            to="/tutor/courses"
                        >
                            <Button
                                /** button to submit the form */
                                key={Math.random() * 100}
                                size="middle"
                                disabled={isCreating && true}
                                id="GoToQuizSuccesspage"
                                type="primary"
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    // marginLeft: '200px',
                                    height: '40px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setOpenModal(false)
                                }}
                            >
                                {isCreating
                                    ? 'Creating quiz'
                                    : 'Go to Quiz Tab'}
                            </Button>
                        </Link>
                    </div>
                </div>
            </Modal>
        </>
    )
}