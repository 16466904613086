import { Form } from 'antd'
import './Input.scss'

export const TextArea = ({
    rows = 3,
    className,
    label,
    validationRules,
    disabled,
    name,
    placeholder,
    onChange,
    ...props
}) => {
    return (
        <>
            <div>
                {label && (
                    <label className="font-weight-bold w-100">{label}</label>
                )}
                <Form.Item name={name} rules={validationRules}>
                    <textarea
                        rows={rows}
                        className={`form-control ${className}`}
                        onChange={onChange}
                        disabled={disabled}
                        placeholder={placeholder}
                        {...props}
                    />
                </Form.Item>
            </div>
        </>
    )
}
