// import styles
import { Link } from 'react-router-dom'
import { Button } from '../../../../../components'
import Cry from '../assets/cry.svg'
import Group from '../assets/Group.svg'
import './styles.scss'

export const CancelSub = () => {

    return (
        <div id="cancelSub">
            <div className="left-content">
                <img src={Cry} alt="" className="cry" />
                <h3>YOU HAVE SUCCESSFULLY UNSUBSCRIBED</h3>
                <p>
                    You've unsubscribed from your monthly subscription
                    which ends in <span>7 days</span>
                </p>
                <p>
                    You will recieve an email with instructions on how to
                    recover your subscription, in case you’d like to
                    reconsider re-subscribing
                </p>
                <p>
                    After your subscription expires you will loose all privileges that come with having the monthly subscription package
                </p>
                <Button className="btn">Re-subscribe</Button>
                <Link className="back-to-course" to="/me/courses">Back to course page</Link>
            </div>
            <div className="right-content">
                <img src={Group} alt="" className="image" />
            </div>
        </div>
    )
}

// { /* <div className="content">
// <h3 className="title">
//     <span id="status">Subscriptions and Billing</span>
// </h3>
// <p id="subTitles">Subscription plan</p>
// <Row>
//     <Col flex={7}>
//         <div className="subPlan">
//             <div className="subContainer">
//                 <div className="subContent">
//                     <div>
//                         <h3 id="m1" className="cancel">Plan Cancelled</h3>
//                         <p><span className="bold">Renew Now </span>and continue using our amazing resources!</p>
//                     </div>
//                     <div className="d-flex">
//                         <Button
//                             primary
//                             className="changePlan"
//                             onClick={() =>
//                                 setChangePlanPage(true)}
//                         >
//                             <p>Subscribe</p>
//                         </Button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </Col>
//     <Col flex={5}>
//         <div className="subPlan">
//             <div
//                 className="subContainer"
//                 id="subContainer-2"
//             >
//                 <div className="subContent">
//                     <span>You will lose access on:</span>
//                     <h4>
//                         {'On ' +
//                             FindUserSubscription?.subscription_end_timestamp?.substring(
//                                 0,
//                                 10
//                             )}
//                     </h4>
//                     <Button
//                         primary
//                         className="changePlan"
//                         onClick={() =>
//                             setManagePaymentPage(true)}
//                     >
//                         <p>Payment History</p>
//                     </Button>
//                 </div>
//             </div>
//         </div>
//     </Col>
// </Row>
// </div> */ }