import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover, Rate } from 'antd'
// import { CartContext } from '../../../../context/Cart/cartContext'
import { useAuth, useLocation } from '../../../../hooks'
// import { useGeoLocation } from '../../../../utils'
import { findCourseById, getCourseReview } from '../../../../services'
import { AddToCart } from '../AddToCart'
import DifficultyBeginner from '../../../../assets/icons/difficulty_beginner.svg'
import DifficultyIntermediate from '../../../../assets/icons/difficulty_intermediate.svg'
import DifficultyAdvanced from '../../../../assets/icons/difficulty_advanced.svg'
// import Badge from '../../../../assets/icons/badge.svg'
// import StndBadge from '../../../../assets/icons/Standard_Tag.svg'
// import LessonBadge from '../../../../assets/icons/lesson_badge.svg'
// import StndLessonBgd from '../../../../assets/icons/Standard_play.svg'
import Info from '../../../../assets/icons/alert-circle.svg'
import './styles.scss'

const PopoverContent = ({
    course,
    // diffTxt,
    linkPrefix,
    premium,
    standard }) => {
    const history = useHistory()
    // const { addToCart } = useContext(CartContext)

    return (
        <div className={premium ? 'popContainer prem' : 'popContainer'}>
            <h2>{course.name}</h2>
            {!premium &&
                <div
                    onClick={() => {
                        if (premium !== true) {
                            history.push(`${linkPrefix}courses/course/${course.id}`)
                        }
                    }}
                    title="view"
                    className="view"
                >
                    <img src={Info} alt="" />
                </div>}
            { /* <div>
                <div className="date">
                    <span className={premium ? 'prem' : standard ? 'stand' : 'institutional'}>Uploaded</span>. July 2022
                </div>
                <div className="info">
                    <div className="wrap">
                        <div className="img_cont">
                            {premium === true ? (<img src={Badge} alt="" />) : (<img src={StndBadge} alt="" />)}
                        </div>
                        <p>{course.totalHours} Hour</p>
                    </div>
                    <div className="wrap">
                        <div className="img_cont">
                            {premium === true ? (<img src={LessonBadge} alt="" />) : (<img src={StndLessonBgd} alt="" />)}
                        </div>
                        <p>{course.totalLectures} Lessons</p>
                    </div>
                    <p>{diffTxt} Level</p>
                </div>
            </div> */ }
            <p>{course.description}</p>
            <div className="whatToLearn">
                {course?.courseObjective?.length >= 1 ? <h4 className={premium ? 'prem' : standard ? 'stand' : 'institutional'}>&nbsp;&nbsp;What you will learn:</h4> : ''}
                <ul>
                    {course.courseObjective && course.courseObjective.map((obj, idx) => (
                        <li key={idx}>{obj}</li>
                    ))}
                </ul>
            </div>
            {premium ? (
                <AddToCart course={course} />
            ) : ''}
        </div>
    )
}

const CourseCard = ({ course }) => {
    const [difficultyIcon, setDifficultyIcon] = useState(null)
    const [diffText, setDiffText] = useState('')
    const [indiCourse, setIndiCourse] = useState({})
    const [linkPrefix, setLinkPrefix] = useState('')
    const {
        state: { isLoggedIn },
    } = useAuth()

    const {
        state: { currencySymbol }
    } = useLocation()

    const history = useHistory()
    const [premium, setPremium] = useState()
    // const [institutional, setInstitutional] = useState()
    const [standard, setStandard] = useState()
    const [reviewObj, setReviewObj] = useState({
        TotalRate: 0,
        Average_rate: 0,
    })
    // console.log(course)
    const [coverImg, setCoverImg] = useState('https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg')

    useEffect(() => {
        // Dynamically set course type variable to differentiate styling on card
        switch (course?.courseType) {
            case 1:
                setStandard(true)
                break
            case 2:
                setPremium(true)
                break
            // case 3:
            //     setInstitutional(true)
            //     break
            default:
                setStandard(false)
                setPremium(false)
                // setInstitutional(false)
                break
        }

        if (
            course.coursePicture !== null &&
            course.coursePicture !== 'mediaFD' &&
            course.coursePicture !== '{}' &&
            course.coursePicture !== 'string'
        ) {
            setCoverImg(course.coursePicture)
        }
    }, [course?.courseType, course?.coursePicture])

    useEffect(() => {
        if (isLoggedIn) {
            setLinkPrefix('/me/')
        } else {
            setLinkPrefix('/')
        }
    }, [isLoggedIn])

    useEffect(() => {
        const getCourse = async () => {
            try {
                const res = await findCourseById(course.id)
                setIndiCourse(res?.data?.courseModel?.courseInfor)
            } catch (error) {
                console.error(error)
            }
        }
        const getCrsReview = async () => {
            try {
                const res = await getCourseReview(course.id, 1, 100)
                if (res.data.reviews !== null) {
                    setReviewObj({
                        TotalRate: res.data.totalRate,
                        Average_rate: (
                            res.data.totalRate / res.data.reviews.length
                        ).toFixed(1),
                    })
                } else {
                    setReviewObj({
                        TotalRate: 0,
                        Average_rate: 0,
                    })
                }
            } catch (error) {
                console.error(error.message)
            }
        }

        getCrsReview()
        getCourse()

        return () => {
            setIndiCourse({})
            setReviewObj({})
        }
    }, [course.id])

    useEffect(() => {
        const getDifficulty = () => {
            switch (course?.courseLevel) {
                case 1:
                    setDifficultyIcon(DifficultyBeginner)
                    setDiffText('Beginner')
                    break
                case 2:
                    setDifficultyIcon(DifficultyIntermediate)
                    setDiffText('Intermediate')
                    break
                case 3:
                    setDifficultyIcon(DifficultyAdvanced)
                    setDiffText('Advance')
                    break
                default:
                    setDifficultyIcon(DifficultyAdvanced)
                    setDiffText('Advance')
                    break
            }
        }
        getDifficulty()

        return () => {
            setDiffText('Beginner')
            setDifficultyIcon(DifficultyBeginner)
        }
    }, [course.courseLevel])

    return (
        <Popover
            trigger="hover"
            showArrow={false}
            content={
                <PopoverContent
                    course={indiCourse}
                    diffTxt={diffText}
                    linkPrefix={linkPrefix}
                    premium={premium}
                    standard={standard}
                />
            }
            placement="rightBottom"
        >
            <div
                onClick={() => {
                    if (premium !== true) {
                        history.push(`${linkPrefix}courses/course/${course.id}`)
                    }
                }}
                className="course-container"
            >
                <div
                    className={`course-img-con ${
                        premium ? 'prem' : standard ? 'stand' : 'institutional'
                    }`}
                >
                    <img
                        className="course-img"
                        src={coverImg}
                        alt="enrolledCourse"
                    />
                </div>
                <div className="box">
                    <h4
                        title={course?.name}
                        className={`course-title ${
                            premium
                                ? 'prem'
                                : standard
                                    ? 'stand'
                                    : 'institutional'
                        }`}
                    >
                        {course?.name}
                    </h4>
                    {/* <h4 className="course-title">Javascript complete course masterclass</h4> */}
                </div>
                <div className="within-card">
                    <div className="first_layer">
                        <div className="img_cont">
                            <img
                                className="difficulty"
                                src={difficultyIcon}
                                alt="difficulty"
                            />
                        </div>
                        <p className="info-divider">|</p>
                        <p className="course-numeric-rating">
                            {reviewObj.Average_rate}
                        </p>
                        <div className="rate">
                            <Rate
                                disabled
                                allowHalf
                                value={reviewObj.Average_rate}
                                style={{
                                    fontSize: '0.9em',
                                    marginInline: '3px',
                                }}
                            />
                        </div>
                        <p className="course-number-of-ratings">
                            {reviewObj.TotalRate}
                        </p>
                    </div>
                    <div className="group">
                        <p className="course-author">
                            By {course?.instructorName}
                        </p>
                        {premium && (
                            <h3 className="course-price">
                                {currencySymbol}
                                {course.amount}
                            </h3>
                        )}
                    </div>
                </div>
            </div>
        </Popover>
    )
}
export default CourseCard