import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import LoadingIcon from '../../assets/icons/loading.png'
import './Loading.scss'

export const Loading = () => {
    return (
        <div id="loading">
            <Logo />
        </div>
    )
}

export const RequestLoader = () => {
    return (
        <div className="loader__cont">
            <img className="Load_spinner" src={LoadingIcon} alt="" />
        </div>
    )
}
