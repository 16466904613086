import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import Form from ant design
import { Form } from 'antd'
import { Input, Button, AppLayout } from '../../../components'
import { forgetPassword } from '../../../services'
import { openNotification } from '../../../utils'
// Redux connectOptions
import '../shared/FormStyling.scss'
// import { forgotAsync } from '../../redux/action/auth';

export const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()

    const handleForgotPassword = async (values) => {
        setLoading(true)
        try {
            await forgetPassword({ email: values.email })
            openNotification({
                type: 'success',
                title: 'Forgot Password',
                message: 'Check your email',
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Forgot Password',
                message: error.message,
            })
        }
        setLoading(false)
    }

    return (
        <AppLayout>
            <section id="change-password">
                <div>
                    <h3>Forgot password</h3>
                    <p className="py-3">
                        Enter your email address that you used to sign up, then
                        click the submit button
                    </p>
                    <Form
                        name="form"
                        form={form}
                        onFinish={handleForgotPassword}
                        autoComplete="on"
                    >
                        <div className="pb-2">
                            <Input
                                name="email"
                                validationRules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                    {
                                        type: 'email',
                                        message: 'This email is not valid',
                                    },
                                ]}
                                placeholder="Email"
                                disabled={loading}
                            />
                        </div>

                        <Button
                            loading={loading}
                            type="submit"
                            disabled={loading}
                        >
                            Submit
                        </Button>
                        <p className="py-3 text-center text-small">
                            Back to{' '}
                            <Link
                                to="/account/login"
                                style={{ color: '#3a86ff' }}
                            >
                                Login
                            </Link>
                        </p>
                    </Form>
                </div>
            </section>
        </AppLayout>
    )
}
