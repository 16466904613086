import { Carousel } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

import './relatedCourses.scss'
import { useState, useEffect } from 'react'
import { CourseCard } from '../../shared'

// const Arrow = ({ type, style, className, onClick }) => (
//     <Icon type={type} style={style} className={className} onClick={onClick} />
//   );

const RelatedCourses = ({ data }) => {
    const [matches, setMatches] = useState(
        window.matchMedia('(min-width: 768px)').matches
    )

    useEffect(() => {
        const handler = (e) => setMatches(e.matches)
        window.matchMedia('(min-width: 768px)').addListener(handler)
    }, [])

    return (
        <section id="relatedCourses">
            <div id="content">
                <h2>Related courses</h2>
                {/* An outside container with a width must be set to have the carousel visible */}
                <div id="carouselContainer">
                    {!matches && (
                        <Carousel
                            id="smallCarousel"
                            arrows
                            nextArrow={<RightOutlined />}
                            prevArrow={<LeftOutlined />}
                            dots={false}
                        >
                            {data.map((val, index) => {
                                return (
                                    <div className="course-item" key={index}>
                                        <CourseCard
                                            id={index}
                                            title={val.title}
                                            thumbnail={val.thumbnail}
                                            author={val.author}
                                            rating={val.rating}
                                            rateCount={val.rateCount}
                                            level={val.difficulty}
                                            timeEstimate={val.timeEstimate}
                                        />
                                    </div>
                                )
                            })}
                        </Carousel>
                    )}

                    {matches && (
                        <Carousel
                            id="largeCarousel"
                            arrows
                            nextArrow={<RightOutlined />}
                            prevArrow={<LeftOutlined />}
                            dots={false}
                            slidesToShow={2}
                            slidesToScroll={0.5}
                        >
                            {data.map((val, index) => {
                                return (
                                    <div className="course-item" key={index}>
                                        <CourseCard
                                            id={index}
                                            title={val.title}
                                            thumbnail={val.thumbnail}
                                            author={val.author}
                                            rating={val.rating}
                                            rateCount={val.rateCount}
                                            level={val.difficulty}
                                            timeEstimate={val.timeEstimate}
                                        />
                                    </div>
                                )
                            })}
                        </Carousel>
                    )}
                </div>
            </div>
        </section>
    )
}

export default RelatedCourses
