export const DownloadHanbook = () => {
    const handbookUrl = 'https://courseresource.s3.us-east-2.amazonaws.com/Flexmonster-Software-License-Agreement.pdf'
    // Create an anchor element
    const anchor = document.createElement('a')
    anchor.href = handbookUrl
    anchor.target = '_blank' // Open in a new tab
    anchor.download = 'InstructorHandbook.pdf' // Set the desired file name

    // Simulate a click on the anchor element to trigger the download
    document.body.appendChild(anchor)
    anchor.click()

    // Remove the anchor element from the DOM
    document.body.removeChild(anchor)
}