import { useEffect, useState } from 'react'
import HeroCourses from './HeroCourses'
// import { HeroSwiper } from './Carousel/Carousel.jsx'
import Searchbar from '../../../../components/Searchbar/searchbar.jsx'
import { findAllCourse } from '../../../../services'
import LandingCourseCard from '../../home/CourseCategories/LandingCourseCard'

export const CoursesPage = () => {
    const [course, setCourse] = useState(null)

    useEffect(() => {
        const getCourse = async () => {
            try {
                const course = await findAllCourse(15)
                // console.log(course)
                if (course.data.length >= 1) {
                    const newCourse = course.data.filter(course => course.courseType === 1)
                    // console.log(newCourse)
                    setCourse(newCourse)
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        getCourse()
        return () => {
            setCourse(null)
        }
    }, [])
    return (
        <>
            {/* <HeroSwiper course={course} /> */}
            <HeroCourses course={course} />
            <Searchbar type="course" />
            <LandingCourseCard typeOneCourse={course} />
        </>
    )
}