import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { useInstructorData } from '../../hooks'
import './courseFilter.scss'

export const Filter = () => {
    const [showMenu, setShowMenu] = useState(false)
    const [checkValue, setCheckValue] = useState([])

    const {
        state: { courses },
        setFilteredCourse,
        setFilterState
    } = useInstructorData()
    // const courseIntialState = useRef(courses)

    const onChange = (e) => {
        setCheckValue(e)
    };

    useEffect(() => {
        const course = []
        const filterCourse = () => {
            for (let i = 0; i < checkValue.length; i++) {
                switch (checkValue[i]) {
                    case 'Active':
                        for (let j = 0; j < courses.length; j++) {
                            if (courses[j].courseApproval === 2) {
                                course.push(courses[j])
                            }
                        }
                        break
                    case 'In-review':
                        for (let j = 0; j < courses.length; j++) {
                            if (courses[j].courseApproval === 1) {
                                course.push(courses[j])
                            }
                        }
                        break
                    case 'Retired':
                        for (let j = 0; j < courses.length; j++) {
                            if (courses[j].courseApproval === 3) {
                                course.push(courses[j])
                            }
                        }
                        break
                    default:
                        course.push(courses)
                        break
                }
            }
            setFilteredCourse(course)
        }

        if (checkValue.length >= 1) {
            setFilterState(true)
            filterCourse()
        } else {
            setFilterState(false)
        }

        // eslint-disable-next-line
    }, [checkValue])

    const checkboxOptions = [
        {
            label: 'Active courses',
            value: 'Active',
        },
        {
            label: 'In-review courses',
            value: 'In-review',
        },
        {
            label: 'Retired course',
            value: 'Retired',
        },
    ];
    return (
        <div id="course_filter">
            <div className="filter_title">
                <p>Filter by Status:</p>
            </div>
            <div
                className="filter_cta"
                onClick={() => {
                    setShowMenu(!showMenu)
                }}
            >
                <DownOutlined />
            </div>
            {showMenu &&
            <div className="filter_menu">
                <Checkbox.Group
                    value={checkValue}
                    options={checkboxOptions}
                    onChange={onChange}
                />
            </div>}
        </div>
    )
}