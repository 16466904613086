import React, { useState } from 'react'
import { Form } from 'antd'
import './profile.scss'
import { Input, Button, CircularLoader } from '../../../../components'
import { useAuth } from '../../../../hooks'
import { updateUserDetails, imageUpload } from '../../../../services'
import { openNotification } from '../../../../utils'

export const Profile = () => {
    const [imageLoading, setImageLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(undefined)
    const [selectedFile, setSelectedFile] = useState(undefined)

    const {
        state: { data: user },
        getProfile,
    } = useAuth()

    const reformatDate = (user) => {
        const dateOfB = user?.dateOfBirth
        const parseDate = new Date(dateOfB)
        let day = parseDate.getDate()
        let month = parseDate.getMonth() + 1
        const year = parseDate.getFullYear()
        if (day < 10) {
            day = `0` + day
        }
        if (month < 10) {
            month = `0` + month
        }
        const refDate = `${year}-${month}-${day}`
        if (refDate !== 'NaN-NaN-NaN') {
            user[`dateOfBirth`] = refDate
        }
    }
    reformatDate(user)

    const handleChangeProfilePicture = async (e) => {
        setImageLoading(true)
        const file = Array.from(e.target.files)[0]
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every((type) => file.type !== type)) {
        } else {
            const reader = new FileReader()
            reader.onload = (e) => {
                setSelectedImage(e.target.result)
            }
            reader.readAsDataURL(file)
            setSelectedFile(file)
        }
        setImageLoading(false)
    }

    const handleSubmit = async (values) => {
        setLoading(true)
        try {
            if (selectedFile) {
                const formData = new FormData()

                formData.append('file', selectedFile)
                formData.append(
                    'upload_preset',
                    'docs_upload_example_us_preset'
                )
                setImageLoading(true)
                const imageResponse = await imageUpload(formData)
                setImageLoading(false)
                // addded an if statement to test for undefined response
                if (imageResponse.url !== undefined) {
                    await updateUserDetails({
                        ...values,
                        pictureUrl: imageResponse.url,
                    })
                    openNotification({
                        type: 'success',
                        title: 'Profile',
                        message: 'User profile successfully updated!',
                    })
                }
            } else {
                // chenged it so that the users profile pic is used as default
                await updateUserDetails({
                    ...values,
                    pictureUrl: user.profilePic,
                })
                openNotification({
                    type: 'success',
                    title: 'Profile',
                    message: 'User profile successfully updated!',
                })
            }
            await getProfile()
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Profile',
                message: error.message,
            })
        }
        setLoading(false)
    }

    const handleRemoveImage = (e) => {
        e.preventDefault()
        setSelectedImage(undefined)
    }

    return (
        <div id="profile">
            <div className="profile-content">
                <h4>Basic Information</h4>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="left-col">
                            {imageLoading ? (
                                <div className="profile_loadingImage">
                                    <CircularLoader />
                                </div>
                            ) : (
                                <div
                                    className="profile_uploadImage"
                                    style={{ opacity: imageLoading ? 0.2 : 1 }}
                                    loading={imageLoading}
                                    onClick={() => {
                                        document
                                            .getElementsByName(
                                                'profilePictureUrl'
                                            )[0]
                                            .click()
                                    }}
                                >
                                    <img
                                        src={
                                            user?.profilePic ||
                                            selectedImage ||
                                            `https://ui-avatars.com/api/?name=${user?.firstName}+${user?.lastName}&font-size=0.40&background=808080&color=fff`
                                        }
                                        alt={user?.firstName}
                                    />
                                    <input
                                        onChange={handleChangeProfilePicture}
                                        type="file"
                                        disabled={loading || imageLoading}
                                        name="profilePictureUrl"
                                        accept="image/*"
                                        hidden
                                    />
                                </div>
                            )}
                            {selectedImage && (
                                <button
                                    type="button"
                                    onClick={handleRemoveImage}
                                    className="btn-remove"
                                >
                                    Remove Image
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-9 mt-4">
                        <Form initialValues={user} onFinish={handleSubmit}>
                            <Input
                                disabled={loading}
                                name="firstName"
                                placeholder="First name"
                            />
                            <Input
                                disabled={loading}
                                name="lastName"
                                placeholder="Last name"
                            />
                            <Input
                                disabled={loading}
                                name="headline"
                                placeholder="Headline"
                            />
                            <Input
                                type="date"
                                disabled={loading}
                                name="dateOfBirth"
                                placeholder="Date of birth"
                            />
                            <Input.TextArea
                                disabled={loading}
                                name="bio"
                                placeholder="Bio"
                            />
                            <Button loading={loading} type="submit">
                                Save changes
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
