import { useHistory } from 'react-router-dom';
import { DownloadHanbook } from '../../../../utils/downloadHandbook'
import './ctaIns.scss';

const CtaIns = () => {
    const history = useHistory()

    return (
        <section id="cta-ins">
            <div className="container">
                <h1 className="title">Need more information</h1>
                <p className="info-text">
                    Check out our instructor’s frequently asked questions and our instructor handbook for more relevant information
                    on how to become an instructor on our platform.
                </p>
                <div className="buttons">
                    <button
                        className="faqs"
                        onClick={() => {
                            history.push('/contact')
                        }}
                    >Instructor FAQs
                    </button>
                    <button
                        className="instructor-handbook"
                        onClick={DownloadHanbook}
                    >Instructor Handbook
                    </button>
                </div>
            </div>
        </section>
    );
};

export default CtaIns;
