import React from 'react'
import { Link } from 'react-router-dom'
import Yellow from './icons/Yellow.svg'
import DarkBlue from './icons/DarkBlue.svg'
import Blue from './icons/Blue.svg'
import Green from './icons/Green.svg'
import woman from './assets/woman.png'
import './WhyZus.scss'

function whyZus() {
    return (
        <section id="about-section">
            <div className="custom-shape-divider-top-1621535573">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                        className="shape-fill"
                    />
                </svg>
            </div>
            <div className="container">
                <div className="info-text">
                    <h1 className="why-title">WHY CHOOSE</h1>
                    <h1 className="zustech-title">ZUSTECH</h1>
                    <p>
                        At Zustech, We have developed an online learning platform offering
                        impactful courses delivered by dedicated and experience instructors
                        who are leaders in their respective industries.
                    </p>
                </div>
                <div className="info-cards">
                    <div className="feature-card">
                        <div className="card-icon">
                            <img
                                src={Yellow}
                                alt="card-icon"
                                className="yellow-icon"
                                size="3em"
                            />
                        </div>
                        <div className="card-content">
                            <h3>Experienced</h3>
                            <p>
                                Our Courses are provided by committed
                                and experienced professionals who are
                                passionate about providing the best learning
                                experience for students.
                            </p>
                        </div>
                    </div>
                    <div className="feature-card">
                        <div className="card-icon">
                            <img
                                src={DarkBlue}
                                alt="card-icon"
                                className="DarkBlue-icon"
                                size="3em"
                            />
                        </div>
                        <div className="card-content">
                            <h3>Flexibility</h3>
                            <p>
                                Our courses are structured to enable access at any time,
                                allowing for learning to occur at your own pace and according
                                to your schedule.
                            </p>
                        </div>
                    </div>
                    <div className="feature-card">
                        <div className="card-icon">
                            <img
                                src={Blue}
                                alt="card-icon"
                                className="blue-icon"
                                size="3em"
                            />
                        </div>
                        <div className="card-content">
                            <h3>Variety</h3>
                            <p>
                                Provides you with access to a wide pool of courses
                                offered by committed and experienced  professionals.
                                You will be able to find a course that uniquely
                                suits your learning style in our large pool.
                            </p>
                        </div>
                    </div>
                    <div className="feature-card">
                        <div className="card-icon">
                            <img
                                src={Green}
                                alt="card-icon"
                                className="green-icon"
                                size="3em"
                            />
                        </div>
                        <div className="card-content">
                            <h3>Communication</h3>
                            <p>
                                Encourages transparent and smooth communication between
                                students and instructors to facilitate a valuable learning experience
                                for both parties, with a strong emphasis on soliciting feedback.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="second-container">
                    <div>
                        <svg
                            width="399"
                            height="455"
                            viewBox="0 0 399 455"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M210.995 1.31423C160.442 3.65295 110.474 14.4933 74.0786 59.6448C30.4022 113.83 -7.53691 184.291 1.28504 261.48C10.7624 344.404 56.8853 419.2 118.368 447.156C174.446 472.655 229.838 426.148 281.85 389.052C328.505 355.776 375.781 318.497 390.94 252.749C407.218 182.15 399.643 100.574 360.857 46.3777C324.318 -4.67795 264.936 -1.18121 210.995 1.31423Z"
                                fill="#FFCC00"
                            />
                        </svg>

                        <img className="woman-img" src={woman} alt="" />
                    </div>
                    <div className="left-text-container">
                        <h2>BECOME AN INSTRUCTOR</h2>
                        <p>
                            Join our team of instructors from all five
                            continents. We provide all the best resources needed
                            for effective teaching and learning to support our
                            students.
                        </p>
                        <Link className="earning" to="/teach">
                            <button>Start Earning Today</button>
                        </Link>
                    </div>
                </div>
                <div className="custom-shape-divider-top-1621535573 bottom-wave">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                            className="bottom-wave-fill"
                        />
                    </svg>
                </div>
            </div>
        </section>
    )
}

export default whyZus
