import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import './App.css'
import data from './data.json'

// import InputField from './InputField'
import ToDoList from './ToDoList'

export const TaskMain = () => {
    const [toDoList, setToDoList] = useState(data)

    // const dateObj = new Date()
    // const month = dateObj.getUTCMonth() + 1
    // const day = dateObj.getUTCDate() + 7
    // const year = dateObj.getUTCFullYear()

    // const newdate = day + '/' + month + '/' + year

    const handleOnDragEnd = (result) => {
        if (!result.destination) return
        const items = Array.from(toDoList)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        setToDoList(items)
    }

    // const addTask = (inputBox) => {
    //     const tasksCopy = [...toDoList]
    //     tasksCopy.push({
    //         id: toDoList.length + 1,
    //         task: inputBox,
    //         date: newdate,
    //         complete: false,
    //     })
    //     setToDoList(tasksCopy)
    // }

    const handleToggle = (id) => {
        const items = toDoList.map((item) => {
            return item.id.toString() === id
                ? { ...item, complete: !item.complete }
                : { ...item }
        })
        setToDoList(items)
    }

    // const handleFilter = () => {
    //     const filteredItems = toDoList.filter((item) => {
    //         return !item.complete
    //     })
    //     setToDoList(filteredItems)
    // }

    return (
        <div className="App">
            <div id="content">
                {/* <InputField addTask={addTask} /> */}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <ToDoList
                        toDoList={toDoList}
                        handleToggle={handleToggle}
                        // handleFilter={handleFilter}
                    />
                </DragDropContext>
            </div>
        </div>
    )
}
