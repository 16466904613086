import React from 'react'
import { CourseDetails as CourseDet } from '../../userDashboard/CourseDetails/index'

export function NonAuthUserCourseOverview() {
    return (
        <div
            style={{
                width: '85%',
                margin: '0 auto'
            }}
        >
            <CourseDet />
        </div>
    )
}
