import {
    SET_USER_LOCATION,
} from './types'
import { StripeSupported } from '../../../pages/landing/pricing/Payment/currencySymbols'

export const setUserLocation = (input) => {
    const setSymbol = () => {
        if (input !== null) {
            for (let i = 0; i < StripeSupported.length; i++) {
                if (
                    StripeSupported[i].countryCode ===
                    input.country_code
                ) {
                    return '£'
                }
            }
            return '₦'
        } else {
            return ''
        }
    }
    const symbol = setSymbol()

    return {
        type: SET_USER_LOCATION,
        payload: { location: input, symbol }
    }
}
