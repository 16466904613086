import React, { useContext } from 'react'
import { CartContext } from '../../../../context/Cart/cartContext'
import './AddToCartBtn.scss'

export const AddToCart = ({ course }) => {
    const { addToCart } = useContext(CartContext)

    return (
        <div>
            <button
                className="add-cart-btn"
                onClick={() => {
                    addToCart(course)
                }}
            >Add to Cart
            </button>
        </div>
    )
}

// export default AddToCart
