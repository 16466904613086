import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar'
import DatePicker from 'react-datepicker'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Cell,
} from 'recharts'

// Image imports
import studentDash from './assets/student_dash.png'
import plus from './assets/icons/plusIcon.png'
import chevron from './assets/icons/chevron-icon.png'
import calendarIcon from './assets/icons/calendar.png'
import { getEnrolledCourses } from '../../../services'
import { EnrolledCourse } from './EnrolledCourse'

import Button from './Button'

import './studentDB.scss'

export const Dashboard = () => {
    const [focusBar, setFocusBar] = useState(null)
    const [date, setDate] = useState(new Date())
    const [modal, setModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [eCourse, setECourse] = useState([])

    // Dummy data for line graph
    const line_data = [
        {
            name: 'M',
            uv: 3,
        },
        {
            name: 'T',
            uv: 6,
        },
        {
            name: 'W',
            uv: 4,
        },
        {
            name: 'T',
            uv: 10,
        },
        {
            name: 'F',
            uv: 7,
        },
        {
            name: 'S',
            uv: 2,
        },
        {
            name: 'S',
            uv: 3,
        },
    ]

    // Dummy data for bar chart
    const bar_data = [
        {
            name: 'Grade 1',
            uv: 512,
        },
        {
            name: 'Grade 2',
            uv: 240,
        },
        {
            name: 'Grade 3',
            uv: 480,
        },
        {
            name: 'Grade 4',
            uv: 360,
        },
        {
            name: 'Grade 5',
            uv: 440,
        },
        {
            name: 'Grade 6',
            uv: 640,
        },
        {
            name: 'Grade 7',
            uv: 480,
        },
        {
            name: 'Grade 8',
            uv: 360,
        },
    ]

    const taskData = [
        {
            id: 1,
            title: 'Complete Lesson 7',
            deadline: '10/05/2021',
            completed: true,
            border: 'green',
        },
        {
            id: 2,
            title: 'Complete UX Theory Task',
            deadline: '15/05/2021',
            completed: false,
            border: 'yellow',
        },
        {
            id: 3,
            title: 'Complete UX Theory Quiz',
            deadline: '30/05/2021',
            completed: false,
            border: 'red',
        },
    ]

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const res = await getEnrolledCourses(1, 100)
                if (res.data.length !== 0) {
                    setECourse(res.data)
                }
            } catch (error) {
                console.error(error.message)
            }
        }

        fetchCourse()

        return () => {
            setECourse([])
        }
    }, [])

    // Displays modal depending on state
    const toggleModal = () => {
        if (modal === true) {
            setModal(false)
        } else if (modal === false) {
            setModal(true)
        }
    }

    // Displays date selected
    const onChangeDate = (date) => {
        setDate(date)
    }

    // Labels for line graph
    function getIntroOfPage(label) {
        if (label === 'M') {
            return 'Monday'
        }
        if (label === 'T') {
            return 'Tuesday'
        }
        if (label === 'W') {
            return 'Wednesday'
        }
        if (label === 'T') {
            return 'Thursday'
        }
        if (label === 'F') {
            return 'Friday'
        }
        if (label === 'S') {
            return 'Saturday'
        }
        if (label === 'S') {
            return 'Sunday'
        }
    }

    // Custom tool tip for line graph
    function CustomLineTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <p className="intro">{getIntroOfPage(label)}</p>
                    <p className="label">{`Hours : ${payload[0].value}`}</p>
                </div>
            )
        }

        return null
    }

    // Custom tool tip for bar chart
    function CustomBarTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <p className="intro">{getIntroOfPage(label)}</p>
                    <p className="label-bar">
                        <span className="grade-highlight">Grade: </span>{' '}
                        {`${payload[0].value}`}
                    </p>
                </div>
            )
        }

        return null
    }

    return (
        // Head in navigation
        <div className="stu-container">
            {/* <h2 className="stu-title">Hello Amelia</h2>
            <div className="stu-header">
                <p>
                    <Link className="change-to-instructor" to="/tutor">
                        Switch to Instructor Dashboard
                    </Link>
                </p>
            </div> */}

            {/* Head on page */}
            <div className="Header">
                <Link to="me/courses">
                    <div className="header-wrapper">
                        <div className="stu-img-both-titles">
                            <div className="stu-img-title">Course</div>
                            <div className="stu-img-title stu-new-line">
                                Marketplace
                            </div>
                        </div>
                        <img
                            className="stu-header-img"
                            src={studentDash}
                            alt="headerImage"
                        />
                    </div>
                </Link>
            </div>

            {/* Enrolled courses */}
            <section className="enrolled_course">
                <h3 className="stu-enrolled-title">Enrolled Courses</h3>
                <div className="course_container">
                    {eCourse.length >= 1 && eCourse.slice(1, 5).map((course) => {
                        return (
                            <EnrolledCourse key={course?.id} course={course} />
                        )
                    })}
                </div>
                {eCourse.length < 1 && (<p>User Has no enrolled course</p>)}
            </section>
            <div className="Line-Graph">
                <h3 className="stu-enrolled-title">Grades & Statistics</h3>
                <div className="stu-grades-stats-container">
                    <h2 className="stu-views-title">HOURS SPENT</h2>
                    <ResponsiveContainer width="95%" height={200}>
                        <LineChart
                            data={line_data}
                            margin={{
                                top: 5,
                                right: 10,
                                bottom: 5,
                                left: -30,
                            }}
                        >
                            <XAxis dataKey="name" tick={{ fontWeight: 700 }} />
                            <YAxis tick={false} axisLine={false} />
                            <Tooltip content={<CustomLineTooltip />} />
                            <Line
                                type="monotone"
                                dataKey="uv"
                                stroke="#3a86ff"
                                strokeWidth="2.5"
                                dot={{
                                    stroke: '#3a86ff',
                                    strokeWidth: 1,
                                    r: 7,
                                    fill: '#3a86ff',
                                    strokeDasharray: '',
                                }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Bar chart */}
            <div className="Bar-Chart">
                <div className="stu-grades-stats-container">
                    <h2 className="stu-views-title">GRADES</h2>
                    <ResponsiveContainer width="95%" height={200}>
                        <BarChart
                            data={bar_data}
                            margin={{
                                top: 5,
                                right: 10,
                                bottom: 5,
                                left: -30,
                            }}
                            onMouseMove={(state) => {
                                if (state.isTooltipActive) {
                                    setFocusBar(state.activeTooltipIndex)
                                } else {
                                    setFocusBar(null)
                                }
                            }}
                        >
                            <XAxis tick={false} axisLine={false} />
                            <Tooltip
                                content={<CustomBarTooltip />}
                                cursor={{ fill: 'transparent' }}
                            />
                            <YAxis tick={false} axisLine={false} />
                            <Bar
                                dataKey="uv"
                                radius={[10, 10, 10, 10]}
                                barSize={22}
                                fill="#2B5CE7"
                            >
                                {bar_data.map((entry, index) => (
                                    <Cell
                                        fill={
                                            focusBar === index
                                                ? '#2B5CE7'
                                                : 'rgba(43, 92, 231, 0.2)'
                                        }
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Right side of the page */}
            <div className="Cal-and-To-do">
                {/* Calendar */}
                <div className="cal-container">
                    <Calendar onChange={onChangeDate} value={date} />
                </div>
                {/* Tasks */}
                <div className="tasks-container">
                    <div className="tasks-header">
                        <h3 className="tasks-title">Tasks</h3>
                        <div className="modal-click" onClick={toggleModal}>
                            <img
                                className="stu-plus-icon"
                                src={plus}
                                alt="plus"
                            />
                        </div>
                    </div>

                    {taskData.map((task, index) => (
                        <div
                            className={`stu-task-card ${task.border}-border`}
                            key={index}
                        >
                            <Button
                                task={task}
                                key={index}
                                taskData={taskData}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Pop-up modal (invisible by default) */}
            {modal && (
                <div className="overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img
                                className="chevron"
                                src={chevron}
                                alt="chevron"
                            />
                            <Link to="/me/tasks">
                                <p className="go-tasks"> Tasks</p>
                            </Link>
                            <button
                                onClick={toggleModal}
                                className="close-modal"
                            >
                                Close
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="modal-new-task">New Task</p>
                            <input
                                type="text"
                                className="modal-input-task"
                                placeholder="Enter new task..."
                            />
                            <p className="modal-new-task">Set Deadline</p>
                            <div className="enter-date-container">
                                <label>
                                    <DatePicker
                                        className="date-picker"
                                        placeholderText="Select Date..."
                                        selected={selectedDate}
                                        onChange={(date) =>
                                            setSelectedDate(date)}
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    <img
                                        className="calendarIcon"
                                        src={calendarIcon}
                                        alt="calendar"
                                    />
                                </label>
                            </div>
                            <p className="modal-colour">Colour</p>
                            <div className="colours-container">
                                <div className="ball-colour light-blue" />
                                <div className="ball-colour dark-blue" />
                                <div className="ball-colour green" />
                                <div className="ball-colour yellow" />
                                <div className="ball-colour red" />
                                <div className="ball-colour pick-colour">
                                    <img
                                        className="pick-color-plus"
                                        src={plus}
                                        alt="plus"
                                    />
                                </div>
                            </div>
                            <button className="modal-create-task">
                                Create Task
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
