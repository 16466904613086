import { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { Header } from './Header'
import { SideBar } from './SideBar'
import { Content } from './Content'
import { RouteMenu } from './RouteMenu'
import { Loading } from '../../Loading'
import { useAuth } from '../../../hooks'
import './style.scss'

export const DashboardLayout = ({ routeList, children, activeTitle }) => {
    const [isCollapsed, setIsCollapsed] = useState()
    const [showSideBar, setShowSideBar] = useState(false)
    const [isOpen, setOpen] = useState(false)

    const {
        state: { data, isLoading },
        logout,
    } = useAuth()

    const Menu = (
        <RouteMenu
            routeList={routeList}
            collapsed={isCollapsed}
            logout={logout}
            email={data?.email}
        />
    )
    const handleResize = () => {
        if (window.innerWidth < 1024) {
            setIsCollapsed(true)
        } else {
            setIsCollapsed(false)
        }
    }
    useEffect(() => {
        handleResize()
        // Add event listener for window resize
        window.addEventListener('resize', handleResize)
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (isLoading) {
        return <Loading />
    }

    return (
        <div id="parent-dash-layout">
            <Layout style={{ height: '100vh', overflow: 'hidden' }}>
                <SideBar
                    menu={Menu}
                    showSideBar={showSideBar}
                    isCollapsed={isCollapsed}
                    collapseToggle={() => setIsCollapsed(!isCollapsed)}
                />
                <Layout>
                    <Header
                        isOpen={isOpen}
                        setOpen={setOpen}
                        setShowSideBar={setShowSideBar}
                        title={activeTitle}
                        currentUser={data}
                    />
                    <Content>{children}</Content>
                </Layout>
            </Layout>
        </div>
    )
}
