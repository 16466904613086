import { Link } from 'react-router-dom'

import beginner from '../../../../../../../assets/icons/difficulty_beginner.svg'
import intermediate from '../../../../../../../assets/icons/difficulty_intermediate.svg'
import advanced from '../../../../../../../assets/icons/difficulty_advanced.svg'
import avatar from '../assets/avatar.png'
import teacherStar from '../assets/teacherStar.svg'
import teacherRibbon from '../assets/teacherRibbon.svg'
import teacherStudents from '../assets/teacherStudents.svg'
import teacherVideos from '../assets/teacherVideos.svg'
import './styles.scss'

export const Overview = ({ course, objectives }) => {
    const courseDifficultyLevel = (level) => {
        let difficulty

        switch (level) {
            case 1:
                difficulty = beginner
                break
            case 2:
                difficulty = intermediate
                break
            case 3:
                difficulty = advanced
                break
            default:
                break
        }
        return difficulty
    }

    const courseDifficultyName = (level) => {
        let difficultyName

        switch (level) {
            case 1:
                difficultyName = 'Beginner'
                break
            case 2:
                difficultyName = 'Intermediate'
                break
            case 3:
                difficultyName = 'Advanced'
                break
            default:
                break
        }
        return difficultyName
    }

    const courseLengthPlural = (length) => {
        if (length === 1) {
            return `${length} Hour`
        } else {
            return `${length} Hours`
        }
    }

    return (
        <div id="overview">
            <div className="overviewDetails">
                <h4>About This Course</h4>
                <p>{course?.courseModel?.courseInfor?.description}</p>
            </div>

            <div className="overviewStats">
                <div>
                    <img
                        src={courseDifficultyLevel(
                            course?.courseModel?.courseInfor?.courseLevel
                        )}
                        alt={courseDifficultyName(
                            course?.courseModel?.courseInfor?.courseLevel
                        )}
                    />
                    <p>
                        {courseDifficultyName(
                            course?.courseModel?.courseInfor?.courseLevel
                        )}
                    </p>
                </div>
                <div>
                    <h4>{course?.courseModel?.courseInfor?.students}</h4>
                    <p>Students Enrolled</p>
                </div>
                <div>
                    <h4>{course?.courseContent.length}</h4>
                    <p>Modules</p>
                </div>
                <div>
                    <h4>
                        {courseLengthPlural(
                            course?.courseModel?.courseInfor?.totalHours
                        )}
                    </h4>
                    <p>Of Video Content</p>
                </div>
            </div>

            <div className="overviewObjectives">
                <h4>What You Will Learn</h4>
                <ul className="custom-bullet heart">
                    {objectives?.map((objective) => (
                        <li>{objective}</li>
                    ))}
                    <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi tempor urna vitae felis convallis varius non eget
                        turpis.
                    </li>
                    <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi tempor urna vitae felis convallis varius non eget
                        turpis.
                    </li>
                    <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi tempor urna vitae felis convallis varius non eget
                        turpis.
                    </li>
                </ul>
            </div>

            <div className="OverviewPrerequisites">
                <h4>Prerequisites</h4>
                <ul>
                    {course?.courseModel?.courseInfor?.prerequisites.map(
                        (prerequisite, i) => (
                            <li key={i}>{prerequisite}</li>
                        )
                    )}
                </ul>
            </div>

            <div className="overviewTeacherInformation">
                <h4>Teacher Information</h4>
                <div className="teacherDetailsContainer">
                    <div className="teacherProfileImg">
                        <img
                            src={avatar}
                            alt={
                                course?.courseModel?.courseInfor?.instructorName
                            }
                        />
                    </div>
                    <div className="teacherDetails">
                        <div className="teacherName">
                            <b>
                                {
                                    course?.courseModel?.courseInfor
                                        ?.instructorName
                                }
                            </b>
                            <Link to="/">Follow</Link>
                            <Link to="/">View My Courses</Link>
                        </div>
                        <div className="teacherPosition">
                            <p>Senior Front End Devloper at Google</p>
                        </div>
                        <div className="teacherStats">
                            <div>
                                <img src={teacherStar} alt="" />
                                <p>4.5 Star Rating</p>
                            </div>
                            <div>
                                <img src={teacherRibbon} alt="" />
                                <p>253 Videos</p>
                            </div>
                            <div>
                                <img src={teacherStudents} alt="" />
                                <p>13,400 Students</p>
                            </div>
                            <div>
                                <img src={teacherVideos} alt="" />
                                <p>12 Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <p>{course?.courseModel?.courseInfor?.instructorBiography}</p> */}
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum habitasse vitae amet cras integer morbi diam.
                    Tortor tincidunt velit pellentesque sit. Ultrices pharetra
                    facilisis risus elementum pharetra quam. Eget vestibulum
                    integer quis a. Proin nunc condimentum ac nunc lectus dui
                    mus sit nunc. Ultricies urna, ultricies quis urna molestie
                    amet. Quis turpis euismod vulputate tortor nulla commodo.
                </p>
            </div>
        </div>
    )
}
