import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    login as loginUser,
    register as registerUser,
    // priceRegister as signUp,
    logout as logoutUser,
    getProfile as getProfileUser,
    deleteAccount as deleteUser,
    adminLogin as loginAdmin,
    closeSignInModal
} from '../store/actions/auth'

export const useAuth = () => {
    const dispatch = useDispatch()

    const state = useSelector(({ auth }) => auth)

    const adminState = useSelector(({ auth }) => auth)

    const login = (values) => {
        dispatch(loginUser(values))
    }

    const register = (values) => {
        dispatch(registerUser(values))
    }

    const closeModal = () => {
        dispatch(closeSignInModal())
    }

    const getProfile = useCallback(async () => {
        dispatch(await getProfileUser())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logout = (value) => {
        dispatch(logoutUser(value))
    }

    const deleteAccount = (values) => {
        dispatch(deleteUser(values))
    }

    const adminlogin = (values) => {
        dispatch(loginAdmin(values))
    }

    return {
        state,
        adminState,
        register,
        login,
        getProfile,
        logout,
        deleteAccount,
        adminlogin,
        closeModal
        // priceRegister,
    }
}
