import React, { useState, useEffect } from 'react'
import {
    Link,
    useParams,
} from 'react-router-dom'

import { findPlanByCourseId, getBenefitsByPlanId } from '../../../../services'
import { StripeSupported } from '../../../landing/pricing/Payment/currencySymbols'
import './institutepricing.scss'
import { RequestLoader } from '../../../../components/Loading'
import Ongreen from '../../../../assets/icons/Ongreen.svg'
import Ongray from '../../../../assets/icons/Ongray.svg'
import Onblue from '../../../../assets/icons/Onblue.svg'
// import Naira from './assets/naira.png'
// import Pound from './assets/pound.png'
// import DataDisplay from '../../../../components/Display/DataDisplay'
// import { Loading } from '../../../../components'
// import { StripeApp } from '../../../userDashboard/Settings/Subscription/Stripe/stripe'
// import { Modal, Table, Button, Switch, Radio, Input, Space } from 'antd'
// import { useAuth } from '../../../../hooks'
// import { Payment } from '../../../landing/pricing/Payment/index'
// import { openNotification } from '../../../../utils/helpers'

export const SingleCoursePricing = () => {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [symbol, setSymbol] = useState('')
    // Get Course Id as Page Param
    const { id } = useParams()

    // handles dynamic currency for user country location
    useEffect(() => {
        const storedUserLocation = JSON.parse(
            localStorage.getItem('zustech:user-location')
        )
        const symbol = () => {
            if (storedUserLocation !== null) {
                for (let i = 0; i < StripeSupported.length; i++) {
                    if (
                        StripeSupported[i].countryCode ===
                        storedUserLocation.country_code
                    ) {
                        return '£'
                    }
                }
                return '₦'
            } else {
                return '₦'
            }
        }

        setSymbol(symbol())
    }, [])

    useEffect(() => {
        const getPlanBenefits = async (id) => {
            try {
                const planBenefit = await getBenefitsByPlanId(id)
                return planBenefit
            } catch (error) {
                console.error(error.message)
            }
        }
        // Function to get plans created for individual course
        const getAvailablePlanForCourse = async (courseId) => {
            setIsLoading(true)
            try {
                // fetch plans assigned to a course
                const response = await findPlanByCourseId(courseId)
                // fetch plan benefits
                for (let i = 0; i < response.length; i++) {
                    const planBenefit = getPlanBenefits(response[i].id)
                    planBenefit.then((data) => {
                        setSubscriptionPlans((prev) =>
                            [
                                ...prev,
                                { ...response[i], benefits: data.benefits },
                            ].sort((a, b) => a.id - b.id)
                        )
                    })
                }
                setIsLoading(false)
            } catch (err) {
                console.error(err.message)
                setIsLoading(false)
            }
        }

        getAvailablePlanForCourse(id)
    }, [id])

    // const { TextArea } = Input

    // useEffect(() => {
    //     const getSubscriptions = async () => {
    //         try {
    //             const response = await getAllPlans()
    //             setSubscriptionPlans(response)
    //         } catch (error) {
    //             openNotification({
    //                 type: 'error',
    //                 title: 'Error',
    //                 message: error.message,
    //             })
    //         }
    //     }

    //     const getActiveSubscription = async () => {
    //         try {
    //             const res = await findSubscriptionByUserId(data.id)
    //             if (res.statusCode !== 404 && Object.keys(res).length > 0) {
    //                 const subInfo = {}
    //                 switch (res.planId) {
    //                     case 1:
    //                         subInfo['name'] = 'Free Trial'
    //                         break;
    //                     case 2:
    //                         subInfo['name'] = 'Monthly'
    //                         break;
    //                     case 11:
    //                         subInfo['name'] = 'Annual'
    //                         break;
    //                     default:
    //                         subInfo['name'] = 'Free Trial'
    //                         break;
    //                 }
    //                 switch (res.subscriptionStatus) {
    //                     case 1:
    //                         subInfo['status'] = 'Active'
    //                         subInfo['color'] = '#3a86ff'
    //                         break;
    //                     case 2:
    //                         subInfo['status'] = 'Inactive'
    //                         subInfo['color'] = '#4f4f4f'
    //                         break;
    //                     case 3:
    //                         subInfo['status'] = 'Successfully'
    //                         subInfo['color'] = '#46d5b3'
    //                         break;
    //                     case 4:
    //                         subInfo['status'] = 'Cancelled'
    //                         subInfo['color'] = '#ffcc00'
    //                         break;
    //                     case 5:
    //                         subInfo['status'] = 'Expired'
    //                         subInfo['color'] = '#eb5757'
    //                         break;
    //                     default:
    //                         subInfo['status'] = 'Inactive'
    //                         subInfo['color'] = '#4f4f4f'
    //                         break;
    //                 }
    //                 subInfo['key'] = res.id
    //                 subInfo['planId'] = res.planId
    //                 subInfo['startDate'] = getReadableDate(res.subscription_start_timestamp)
    //                 subInfo['endDate'] = getReadableDate(res.subscription_end_timestamp)
    //                 if (res.subscriptionStatus === 1 || res.subscriptionStatus === 4) {
    //                     setIsUserSubscribed(true)
    //                 } else {
    //                     setIsUserSubscribed(false)
    //                 }
    //                 setActiveSubInfo(subInfo)
    //             } else {
    //                 setIsUserSubscribed(false)
    //                 setActiveSubInfo({})
    //             }
    //         } catch (err) {
    //             console.error(err.message)
    //         }
    //     }
    //     getSubscriptions()
    //     getActiveSubscription()

    //     // eslint-disable-next-line
    // }, [data?.id])

    // function getReadableDate(data) {
    //     const option = { month: 'short', day: 'numeric', year: 'numeric' }
    //     const newDate = new Date(data).toLocaleString('en-US', option)
    //     return newDate
    // }

    const planData = {
        First: {
            name: '1 Month',
            sellPoint: ''
        },
        Second: {
            name: '6 Month',
            sellPoint: 'Most Popular'
        },
        Third: {
            name: 'Yearly',
            sellPoint: 'Best Value'
        },
    }

    const planUnit = {
        // keeping track of plans off and payment duration in weeks
        'Weekly': 'wk',
        'Monthly': 'mon',
        'Three months': '3 mons',
        'Six months': '6 mons',
        'Yearly': 'yr',
    }

    // async function endplan(planId, reason) {
    //     try {
    //         const values = {
    //             'subscriptionId': planId,
    //             'reason': reason
    //         }
    //         const res = await cancelSubscription(values)
    //         if (res.message === 'Success') {
    //             // set state for data
    //             setCancelledPlan(res.data)
    //             setThirdCancelPlanModal(false)
    //             openNotification({
    //                 type: 'success',
    //                 title: 'Subscription Cancellation',
    //                 message: res.message
    //             })
    //             setFourthCancelPlanModal(true)
    //         }
    //     } catch (err) {
    //         console.error(err)
    //         openNotification({
    //             type: 'error',
    //             title: 'Subscription Cancellation',
    //             message: err.message
    //         })
    //     }
    // }

    // function getPlanDetail(id) {
    //     let amount
    //     let name
    //     if (subscriptionPlans) {
    //         subscriptionPlans.forEach(plan => {
    //             if (plan.id === id) {
    //                 amount = plan.amount
    //                 name = plan.name
    //             }
    //         })
    //     }

    //     return { amount, name }
    // }

    // useEffect(() => {
    //     const getSubHistory = async () => {
    //         try {
    //             const arr = []
    //             const res = await getSubscriptionHistory(1, 300)
    //             if (res.data.length >= 1) {
    //                 const data = res.data
    //                 data.forEach((obj, idx) => {
    //                     const { amount, name } = getPlanDetail(obj.planId)
    //                     const subInfo = {}
    //                     switch (obj.subscriptionStatus) {
    //                         // Check style variable file for the color codes
    //                         // Active - secondaryColor, Inactive - grey2, Sucessfully - successColor, cancelled - tertiaryColor, expired - errorColor
    //                         case 1:
    //                             subInfo['status'] = 'Active'
    //                             subInfo['color'] = '#3a86ff'
    //                             break;
    //                         case 2:
    //                             subInfo['status'] = 'Inactive'
    //                             subInfo['color'] = '#4f4f4f'
    //                             break;
    //                         case 3:
    //                             subInfo['status'] = 'Successfully'
    //                             subInfo['color'] = '#46d5b3'
    //                             break;
    //                         case 4:
    //                             subInfo['status'] = 'Cancelled'
    //                             subInfo['color'] = '#ffcc00'
    //                             break;
    //                         case 5:
    //                             subInfo['status'] = 'Expired'
    //                             subInfo['color'] = '#eb5757'
    //                             break;
    //                         default:
    //                             subInfo['status'] = 'Inactive'
    //                             subInfo['color'] = '#4f4f4f'
    //                             break;
    //                     }
    //                     arr.push({
    //                         'key': idx,
    //                         'startDate': getReadableDate(obj.subscription_start_timestamp),
    //                         'plan': name,
    //                         'amount': `${symbol} ${amount}`,
    //                         'endDate': getReadableDate(obj.subscription_end_timestamp),
    //                         'status': <h6 style={{ fontSize: '1em', color: `${subInfo.color}` }}>{subInfo.status}</h6>
    //                     })
    //                 })
    //                 setDataSource(arr)
    //             }
    //         } catch (error) {
    //             console.log(error.message)
    //         }
    //     }
    //     if (loc === '/me/subscription') {
    //         getSubHistory()
    //     }
    // // eslint-disable-next-line
    // }, [subscriptionPlans])

    // const columns = [
    //     {
    //         title: 'Plan:',
    //         dataIndex: 'plan',
    //         key: 'plan',
    //         width: 160
    //     },
    //     {
    //         title: 'Amount:',
    //         dataIndex: 'amount',
    //         key: 'amount'
    //     },
    //     {
    //         title: 'Start Date:',
    //         dataIndex: 'startDate',
    //         key: 'startDate',
    //         width: 180
    //     },
    //     {
    //         title: 'End Date:',
    //         dataIndex: 'endDate',
    //         key: 'endDate',
    //         width: 180
    //     },
    //     {
    //         title: 'Status:',
    //         dataIndex: 'status',
    //         key: 'status',
    //         width: 100
    //     }
    // ];

    return (
        <section id="course_pricingg">
            <div className="wave-bg">
                <div className="header">
                    <h2>Single Course Subscription Pricing</h2>
                    {subscriptionPlans.length >= 1 && (
                        <p>
                            We offer competitive pricing for our comprehensive
                            courses.
                        </p>
                    )}
                </div>
                {subscriptionPlans.length < 1 && isLoading && (
                    <div className="loader_cont">
                        <RequestLoader />
                    </div>
                )}
                {subscriptionPlans.length < 1 && !isLoading && (
                    <div className="noplan">
                        <h3>
                            There is no plan currently available for this course
                        </h3>
                        <h5>
                            Please contact the customer support team for
                            assistance.
                        </h5>
                    </div>
                )}
                {subscriptionPlans.length >= 1 && (
                    <div className="pricing-container">
                        <div className="content container">
                            {/* Start of pricing cards */}
                            <div className="pricing-cards">
                                {/* FIRST PRICE CARD */}
                                {/* this card (first card) should not show when user has no active subs */}
                                <div className="first-card cards course_sub_card">
                                    {subscriptionPlans
                                        .slice(0, 1)
                                        .map((plan) => (
                                            <div key={plan.id}>
                                                <div className="top left">
                                                    <p className="frequency-long">
                                                        {plan?.name} Plan
                                                    </p>
                                                    <h4 id="currency-price">
                                                        {symbol}
                                                        {plan?.amount}{' '}
                                                        <span id="span">
                                                            /
                                                            {
                                                                planUnit[
                                                                    plan.name
                                                                ]
                                                            }
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div>
                                                    <ul className="first-feature feature">
                                                        {plan.benefits.map(
                                                            (goods, idx) => (
                                                                <li
                                                                    key={idx}
                                                                    className="first-feature-item"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                Ongray
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {goods}
                                                                    </p>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <Link
                                                    id={`paymentBtn-${plan?.id}`}
                                                    to={`/me/payment/${plan?.id}`}
                                                    className="btn-subscription btn-first"
                                                    onClick={() => {
                                                        // Storing the id of course to subscribe for and the duration for calculating total amount
                                                        const paymentInfo = {
                                                            courseId: id,
                                                            subscriptionType: plan.planType,
                                                        }
                                                        localStorage.setItem(
                                                            'SubCourseId',
                                                            JSON.stringify(
                                                                paymentInfo
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Get Started
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                {/* MIDDLE PRICE CARD */}
                                <div className="second-card center cards course_sub_card">
                                    {subscriptionPlans
                                        .slice(1, 2)
                                        .map((plan) => (
                                            <div key={plan.id} className="head">
                                                <div className="top top-center">
                                                    <p id="statement">
                                                        {
                                                            planData['Second'][
                                                                'sellPoint'
                                                            ]
                                                        }
                                                    </p>
                                                    <p className="frequency-long">
                                                        {plan?.name} Plan
                                                    </p>
                                                    <h4 id="currency-price">
                                                        {symbol}
                                                        {plan?.amount}{' '}
                                                        <span id="span">
                                                            /
                                                            {
                                                                planUnit[
                                                                    plan.name
                                                                ]
                                                            }
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div>
                                                    <ul className="second-feature feature">
                                                        {plan.benefits.map(
                                                            (goods, idx) => (
                                                                <li
                                                                    key={idx}
                                                                    className="second-feature-item"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                Ongreen
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {goods}
                                                                    </p>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <Link
                                                    id={`paymentBtn-${plan?.id}`}
                                                    to={`/me/payment/${plan?.id}`}
                                                    className="btn-subscription btn-second"
                                                    onClick={() => {
                                                        // Storing the id of course to subscribe for and the duration for calculating total amount
                                                        const paymentInfo = {
                                                            courseId: id,
                                                            subscriptionType: 2,
                                                        }
                                                        localStorage.setItem(
                                                            'SubCourseId',
                                                            JSON.stringify(
                                                                paymentInfo
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Get Started
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                {/* LAST PRICE CARD */}
                                <div className="last-card cards course_sub_card">
                                    {subscriptionPlans
                                        .slice(2, 3)
                                        .map((plan) => (
                                            <div key={plan.id} className="head">
                                                <div className="top right">
                                                    <p id="statement">
                                                        {
                                                            planData['Third'][
                                                                'sellPoint'
                                                            ]
                                                        }
                                                    </p>
                                                    <p className="frequency-long">
                                                        {plan?.name} Plan
                                                    </p>
                                                    <h4 id="currency-price">
                                                        {symbol}
                                                        {plan?.amount}{' '}
                                                        <span id="span">
                                                            /{planUnit[plan.name]}
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div>
                                                    <ul className="last-feature feature">
                                                        {plan.benefits.map(
                                                            (goods, idx) => (
                                                                <li
                                                                    key={idx}
                                                                    className="third-feature-item"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                Onblue
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {goods}
                                                                    </p>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <Link
                                                    id={`paymentBtn-${plan?.id}`}
                                                    to={`/me/payment/${plan?.id}`}
                                                    className="btn-subscription btn-last"
                                                    onClick={() => {
                                                        // Storing the id of course to subscribe for and the duration for calculating total amount
                                                        const paymentInfo = {
                                                            courseId: id,
                                                            subscriptionType: 2,
                                                        }
                                                        localStorage.setItem(
                                                            'SubCourseId',
                                                            JSON.stringify(
                                                                paymentInfo
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Get Started
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}