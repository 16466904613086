import React from 'react'
import { Link } from 'react-router-dom'
import './Cta.scss'

function Cta() {
    return (
        <>
            <section id="cta-section">
                <div className="container">
                    <h2 className="heading">Start a career</h2>
                    <p className="info">
                        Start learning today at Zustech and learn core skills
                        in: Business Analysis, Project Management, Software
                        Testing, Test Automation and more!
                    </p>
                    <div className="btn-wrapper">
                        <Link to="/pricing">
                            <button className="cta-trial-btn">
                                Start Free Trial
                            </button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cta
