// import images
import blob1 from '../assets/blob-icon_1.png';
import blob2 from '../assets/blob-icon_2.png';
import blob3 from '../assets/blob-icon_3.png';
// import styles
import './why.scss';

const Why = () => {
    return (
        <section id="why">
            <div className="container">
                <h2>Why become an Instructor</h2>
                <div className="content">
                    <div className="item">
                        <div className="img_cont">
                            <img src={blob1} alt="blob icon" />
                        </div>
                        <p>A chance to contribute to the growth and development of our learners
                            by providing great content in your own unique teaching style to build and
                            increase their skill levels and help them grow in their career paths.
                        </p>
                    </div>
                    <div className="item">
                        <div className="img_cont">
                            <img src={blob2} alt="blob icon" />
                        </div>
                        <p>It also provides you with the opportunity to expand your reach and
                            professional expertise to a wide range of audience and your earning capacity
                            grows and increases as your learners increase and the level of your expertise
                            improves.
                        </p>
                    </div>
                    <div className="item">
                        <div className="img_cont">
                            <img src={blob3} alt="blob icon" />
                        </div>
                        <p>Say goodbye to the constraints of traditional classroom settings. As an instructor
                            on our LMS, you have the flexibility to create and deliver courses on your schedule, catering
                            to the needs of both full-time learning and working professionals seeking to enhance their skills.
                            With the convenience of online learning you can empower learners to pursue education at their own pace,
                            anytime, anywhere.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Why;
