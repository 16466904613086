import { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import { Tabs } from 'antd'
import {
    // useAuth,
    useInterface,
    // useForm
} from '../../../../../hooks'
import {
    findCourseById,
    // getUsersProgressOnCourse,
    // setProgressOnCourse,
    // findSubscriptionByUserId,
    // getCourseReview,
    // checkIfEnrolled,
    // courseEnroll,
    // createModuleTest,
    // createCourseTest,
    // addQuestionToTest,
    // findQuizByCourseId,
    // getUserTestResults,
} from '../../../../../services'
// import { openNotification } from '../../../../../utils'
// import Arrow_left from '../../../../../assets/icons/arrow-left.svg'
// import Celebration_icon from '../../../../../assets/icons/Celebration_icon.svg'
// import Check from '../../../../../assets/icons/Done_loading.svg'
// import { RequestLoader } from '../../../../../components/Loading'
// import Review from './review'
import Module from './Module'
import './courseContent.scss'

const CourseContent = () => {
    // Using the user id to get the progress and set progress
    // so now i need a default value for userId, why? {the modules won't load without the user id}
    // I'll also need to create a dummy introduction lesson

    // const {
    //     state: { data: user, isLoggedIn },
    // } = useAuth()

    const {
        state: {
            // quizMode,
            clicked
        },
        // setQuizMode,
        // beginQuiz,
        // setQuizType,
        // setQuizCourseId,
    } = useInterface()

    // const { clearQuizForm } = useForm()

    // Route was prepare to accept a parameter, destructured the parameter with the hook
    const { id } = useParams()
    // const history = useHistory()
    // const location = useLocation()
    // const currentUrl = location.pathname
    const [total, setTotal] = useState(0)
    const [watched, setWatched] = useState(0)
    const [bool, setBool] = useState()
    console.log(bool)
    const [objStore, setObjStore] = useState({
        url: 'https://youtu.be/BhX6dU3tH3s',
        lessonId: 1,
        moduleId: 1,
    })
    // Testing how well the state will work
    const [courseInfo, setCourseInfo] = useState('')
    const [modules, setModules] = useState({})
    // const [progressStatus, setProgressStatus] = useState(0)
    // const [courseProgress, setCourseProgress] = useState({})
    const [send, setSend] = useState(false)
    // const [fstLesson, setFstLesson] = useState(false)
    const [
        success,
        // setSuccess
    ] = useState(false)
    // const [refetch, setRefetch] = useState(false)
    // const [isSubActive, setIsSubActive] = useState()
    // const [isSingleSubActive, setIsSingleSubActive] = useState()
    // const [lockLesson, setLockLesson] = useState()
    const [reviewObj, setReviewObj] = useState({})
    const [coverImg, setCoverImg] = useState(
        'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    )
    // const [duration, setDuration] = useState('02:10')
    // const [openMultiSubModal, setOpenMultiSubModal] = useState(false)
    // const [openSingleSubModal, setOpenSingleSubModal] = useState(false)
    // const [openReviewModal, setOpenReviewModal] = useState(false)
    // const [courseCompleted, setCourseCompleted] = useState(false)
    // const [canReview, setCanReview] = useState(false)
    // const [downloadCert, setDownloadCert] = useState(false)
    // const [isPreviewPage, setIsPreviewPage] = useState(false)
    // const [isCreating, setIsCreating] = useState(false)
    // const [openModal, setOpenModal] = useState(false)
    // const [quizDataSource, setQuizDataSource] = useState([])
    // const [courseHasQuiz, setCourseHasQuiz] = useState()
    // const [quizId, setQuizId] = useState('')
    // const [testResult, setTestResult] = useState({})

    // Memoiezing objects passed as prop to the memoed component
    const memoCourseInfo = useMemo(() => ({ ...courseInfo }), [courseInfo])
    const memoReviewObj = useMemo(() => ({ ...reviewObj }), [reviewObj])
    const memoModuleObj = useMemo(() => ({ ...modules }), [modules])
    // Memoie functions passed as prop to memoed component
    const updateObjStore = useCallback((url, lessonId, moduleId) => {
        setObjStore({ url, lessonId, moduleId })
    }, [])
    // const updateFetch = useCallback((a) => setRefetch(a), [])
    const updateProgress = useCallback((a, lesId, modId) => {
        if (a < 1) {
            setBool(false)
        } else {
            setBool(true)
        }
        setWatched((prev) => prev + a)
        setObjStore((prev) => ({ ...prev, lessonId: lesId, moduleId: modId }))
        setSend(true)
        // eslint-disable-next-line
    }, [])

    // CONSOLE LOGSSSS (TO BE DELETED AFTER PEER REVIEW)
    console.log(watched, bool, send, total, setReviewObj, memoReviewObj, memoCourseInfo)

    // Get related materials of the course
    useEffect(() => {
        // get modules
        const fetchDatum = async (id) => {
            const modules = {}
            const lessonObj = {}
            try {
                // The below request gets all available infomation about a course using the course id
                const res = await findCourseById(id)
                const cos = res?.data?.courseModel?.courseInfor
                setCourseInfo(cos)
                if (
                    cos.coursePicture !== null &&
                    cos.coursePicture !== 'mediaFD' &&
                    cos.coursePicture !== '{}' &&
                    cos.coursePicture !== 'string'
                ) {
                    setCoverImg(cos.coursePicture)
                }
                // in the data returned there exist the module ids specific to the course
                // Had to convert it from a string to an array so i can iterate
                const topics = res.data.courseContent
                // Loop through the topics to get the structure with their position as key
                if (topics.length >= 1) {
                    // first thing to do is sort them
                    topics.sort((a, b) => a.index - b.index)
                    for (let i = 0; i < topics.length; i++) {
                        modules[topics[i].index] = topics[i]
                        if (topics[i].moduleName !== 'Introduction') {
                            for (let j = 0; j < topics[i].lessons.length; j++) {
                                lessonObj[topics[i].lessons[j].id] =
                                    topics[i].lessons[j]
                            }
                        }
                    }
                    setModules(modules)
                    setTotal(Object.keys(lessonObj).length)
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        fetchDatum(id)
        return () => {
            setCourseInfo('')
            setModules([])
            setTotal(0)
        }
        // eslint-disable-next-line
    }, [id])

    const getFirstLesson = (obj) => {
        let url
        Object.values(obj).forEach((module) => {
            if (module.moduleName === 'Introduction') {
                module.lessons.sort((a, b) => a.index - b.index)
                url = module.lessons[0]?.content
            }
        })
        return url
    }
    return (
        <>
            <main className="course_overview">
                <div className="heading_cont">
                    <h2 style={{ width: '96%' }}>{courseInfo?.name}</h2>
                </div>

                <section className="course_content">
                    <div className="content">
                        <div className="video_cont">
                            <ReactPlayer
                                width="100%"
                                height="100%"
                                playing={true}
                                // You can pass the thumbnail to the light attribute, this will be the coursePicture
                                light={<img src={coverImg} alt="Thumbnail" />}
                                url={
                                    !clicked
                                        ? getFirstLesson(modules)
                                        : objStore.url
                                }
                                controls={true}
                                config={{
                                    file: {
                                        attributes: {
                                            disablepictureinpicture: 'true',
                                            controlslist:
                                                'nodownload noplaybackrate nofullscreen',
                                        },
                                    },
                                }}
                                onEnded={() => {
                                    // wanna set an array of lesson source for continuous videos
                                    // After watching all intro lessons, i want to check for if user has subscribed and has enrolled for the course
                                    // @ diff check, if falsy open an infomative modal
                                    // If user is subscribed and enrolled go on to next video, and importantly update progress on course
                                    // if (!lockLesson) {
                                    //     // update progress on course
                                    //     updateProgress(
                                    //         1,
                                    //         objStore.lessonId,
                                    //         objStore.moduleId
                                    //     )
                                    // }
                                }}
                            />
                        </div>
                        <div className="tab_cont">
                            <Tabs
                                defaultActiveKey="1"
                                // items={tabs}
                                centered
                                size="large"
                                tabBarGutter="3em"
                            />
                        </div>
                    </div>
                    <aside>
                        <h2 className="heading">Content</h2>
                        <div className="module_cont">
                            {modules && (
                                <Module
                                    reRun={success}
                                    courseId={id}
                                    mod={memoModuleObj}
                                    // subscribed={!lockLesson}
                                    progress={updateProgress}
                                    updateUrl={updateObjStore}
                                    // subModal={subModalState}
                                    // testResult={testResult}
                                />
                            )}
                        </div>
                        {/* course quiz is coming here */}

                    </aside>
                </section>
            </main>
        </>
    )
}
export default CourseContent;

// {/* course quiz is coming here */}
// <div className="quiz_cont">
//     <div
//         className="quizControl_cont"
//         onClick={() => {
//             if (
//                 isSubActive === true &&
//                 progressStatus >= 1
//             ) {
//                 setQuizMode(true)
//                 setQuizType('Course')
//                 setQuizCourseId(id)
//             } else if (isPreviewPage) {
//                 setQuizMode(true)
//             } else {
//                 openNotification({
//                     title: "User Can't Take Quiz",
//                     type: 'error',
//                     message:
//                         'Go through the module first',
//                 })
//             }
//         }}
//     >
//         {isPreviewPage && (
//             <p className="previewQuiz">View Quiz</p>
//         )}
//         {courseHasQuiz && !isPreviewPage && (
//             <div className="quizCTA">
//                 <p>
//                     {testResult[quizId] < 1 ||
//                     testResult[quizId] === undefined
//                         ? 'Take Test'
//                         : 'Re-take test'}
//                 </p>
//                 <p className="quizScore">
//                     {testResult[quizId] &&
//                         (testResult[quizId] < 2
//                             ? `${testResult[quizId]} mark`
//                             : `${testResult[quizId]} marks`)}
//                 </p>
//             </div>
//         )}
//     </div>
// </div>