import './NotFound.scss'
import { Link } from 'react-router-dom'
import notFoundImg from './assets/404_Illustration.svg'
import { Button } from '../../../components'

export const NotFound = () => {
    return (
        <div className="UserDashboard__Notfound">
            <div className="container">
                <h1>sorry, this page could not be found </h1>
                <img src={notFoundImg} alt="404 error message illustration" />
                <Link to="/tutor">
                    <Button>Return home</Button>
                </Link>
            </div>
        </div>
    )
}
