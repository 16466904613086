import { useState, useEffect } from 'react'
import {
    getCourseByTypeId
} from '../../../../services'
import CourseCard from '../../premiumCourses/CourseCard/CourseCard'
import './style.scss'

const CoursesContent = () => {
    const [topPicksCourses, setTopPicksCourses] = useState([])
    const [popularCourses, setPopularCourses] = useState([])
    const [errorMsgOne, setErrorMsgOne] = useState('')
    const [errorMsgTwo, setErrorMsgTwo] = useState('')

    useEffect(() => {
        const getThreeCourses = async () => {
            try {
                const res = await getCourseByTypeId(1, 10, 1)
                if (res.status && res.data.length >= 1) {
                    setTopPicksCourses(res.data)
                } else {
                    setErrorMsgTwo('Course Not Available for this Type')
                }
            } catch (err) {
                console.error(err)
            }
        }
        getThreeCourses()

        const getSixCourses = async () => {
            try {
                const res = await getCourseByTypeId(1, 10, 3)
                if (res.status && res.data.length >= 1) {
                    setPopularCourses(res.data)
                } else {
                    setErrorMsgOne('Course Not Available for this Type')
                }
            } catch (err) {
                console.error(err)
            }
        }
        getSixCourses()

        return (() => {
            setTopPicksCourses([])
            setPopularCourses([])
        })
    }, [])

    return (
        <div>
            <h4 style={{ color: '#3A86FF' }}>Course Marketplace</h4>
            <div className="cards-section top-card">
                <h3 className="top-picks-title">Top Picks For You</h3>
                <div className="top-picks-container">
                    {topPicksCourses &&
                        topPicksCourses
                            .filter((course) => course.courseApproval === 2)
                            .map((course, i) => (
                                <CourseCard key={i} course={course} />
                            ))}

                    {errorMsgOne !== '' && (
                        <div className="no_courseError">{errorMsgOne}</div>
                    )}
                </div>
            </div>

            <div className="cards-section">
                <h3>Popular Courses</h3>
                <div className="popular-container">
                    {popularCourses &&
                        popularCourses
                            .filter((course) => course.courseApproval === 2)
                            .map((course, i) => (
                                <CourseCard key={i} course={course} />
                            ))}
                    {errorMsgTwo !== '' && (
                        <div className="no_courseError">{errorMsgTwo}</div>
                    )}
                </div>
            </div>

            <div className="cards-section">
                <h3>Trending Courses</h3>
                <div className="trending-container">
                    {popularCourses &&
                        popularCourses
                            .filter((course) => course.courseApproval === 2)
                            .map((course, i) => (
                                <CourseCard key={i} course={course} />
                            ))}
                    {errorMsgTwo !== '' && (
                        <div className="no_courseError">{errorMsgTwo}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CoursesContent