import React from 'react'
import { useHistory } from 'react-router-dom'
import { Collapse } from 'antd'
import '../studentsupport.scss'
import { MdArrowForwardIos } from 'react-icons/md'
import group1 from '../assets/group1.svg'
import group2 from '../assets/group2.svg'
import { troubleshootingFAQs } from '../component/constant'

const Troubleshooting = () => {
    const history = useHistory()

    const { Panel } = Collapse

    return (
        <div id="student-support">
            <div className="top-background">
                <img src={group1} alt="" className="group1-img" />
                <img src={group2} alt="" className="group2-img" />
                <input
                    placeholder="Search articles"
                    name="search-article"
                    id="search-article"
                    className="search-article"
                />
            </div>
            <div className="top-link">
                <a href="/s">Students support</a>
                <MdArrowForwardIos />
                <a href="/s">Accounts</a>
                <MdArrowForwardIos />
            </div>
            <nav className="nav-btns">
                <button
                    onClick={() => {
                        history.push('/student-support/getting-started')
                    }}
                    className="btn"
                >
                    Getting started
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/account-setting')
                    }}
                    className="btn"
                >
                    Account/Profile
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/subscription')
                    }}
                    className="btn"
                >
                    Subscription/billing
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/take-course')
                    }}
                    className="btn"
                >
                    Take course
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/troubleshoot')
                    }}
                    className="btn"
                >
                    Troubleshooting
                </button>
            </nav>
            <section className="content-settings">
                <h2>Troubleshooting</h2>
                <p>
                    Welcome to Zustech! These FAQs will you navigate and use the
                    system effectively.
                </p>

                <div className="questions-container">
                    <div className="questions questions-box-1">
                        {troubleshootingFAQs.map((faq) => {
                            return (
                                <Collapse className="">
                                    <Panel
                                        key={faq.question}
                                        header={faq.question}
                                    >
                                        <p style={{ fontSize: '14px' }}>
                                            {faq.response}
                                        </p>
                                    </Panel>
                                </Collapse>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Troubleshooting
