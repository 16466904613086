import React from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import './sidebar.scss'
import { useTranslation } from 'react-i18next'

const routeList = [
    {
        match: '/tutor/settings/profile',
        path: '/tutor/settings/profile',
        title: 'Profile',
    },
    {
        match: '/tutor/settings/info',
        path: '/tutor/settings/info',
        title: 'Account Info',
    },
    {
        match: '/tutor/settings/notification',
        path: '/tutor/settings/notification',
        title: 'Notification',
    },
    // {
    //     match: '/tutor/settings/linked-account',
    //     path: '/tutor/settings/linked-account',
    //     title: 'Linked Accounts',
    // },
    {
        match: '/tutor/settings/preferences',
        path: '/tutor/settings/preferences',
        title: 'Language Preference',
    },
    {
        match: '/tutor/settings/close-account',
        path: '/tutor/settings/close-account',
        title: 'Close Account',
    },
]

export const SideBar = () => {
    const { pathname } = useLocation()
    const { t } = useTranslation()
    const translateTitle = (value) => {
        return t('Settings.' + value + '.pageName')
    }
    return (
        <div id="sideBar">
            <ul>
                {routeList.map((route) => {
                    const active = matchPath(pathname, route.match)?.isExact
                    return (
                        <li key={route.path}>
                            <Link
                                to={route.path}
                                className={active ? 'active_link' : 'link'}
                            >
                                {translateTitle(route.title)}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
