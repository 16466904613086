import React, { useState, useEffect } from 'react'
import { Form, Select, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
// import { useHistory } from 'react-router-dom'
// import { LessonList } from './LessonList'
import { useAuth } from '../../../../hooks'
import { Button, Input } from '../../../../components'
import {
    updateModule,
    findInstructorByUserId,
    FindLessonsByInstructorId,
    addLessonToModule,
    findModuleById,
    findLessonByModuleId,
    deleteModule,
    removeLessonFromModule,
} from '../../../../services'
import { openNotification } from '../../../../utils/helpers'

import './styles.scss'

export const EditModule = () => {
    const {
        state: { data: user },
    } = useAuth()

    const [moduleId, setModuleId] = useState(0)
    const [instructor, setInstructor] = useState([])
    const [instructorLessons, setInstructorLessons] = useState([])
    const [selectedLessons, setSelectedLessons] = useState([])
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(false)
    // const [formValues, setFormValues] = useState()
    // const history = useHistory()
    const [moduleName, setModuleName] = useState('')
    const [moduleSummary, setModuleSummary] = useState('')
    const [module, setModule] = useState({})
    const [moduleLessons, setModuleLessons] = useState([])
    const [loading, setLoading] = useState(true)
    const [arrayOfModules, setArrayOfModules] = useState([])

    useEffect(() => {
        const moduleIdAddress = window.location.pathname
        setModuleId(parseInt(moduleIdAddress.split('/').pop(), 10))
    }, [])

    const { Option } = Select

    const handleDelete = (id) => {
        DeleteModule(id)
        setIsModalVisible(false)
    }
    const history = useHistory()
    const DeleteModule = async (id) => {
        // e.preventDefault()
        try {
            const response = await deleteModule(id)
            openNotification({
                type: 'success',
                title: 'Delete Module',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Delete Module',
                message: error.message,
            })
        }

        history.push('/tutor')
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleChange = (value) => {
        const lessonList = []
        value.map((val) => {
            const result = instructorLessons.find(({ name }) => name === val)
            lessonList.push(result.id)
            return lessonList
        })
        setSelectedLessons(lessonList)
    }

    // find module by ID
    useEffect(() => {
        const findModule = async () => {
            try {
                const response = await findModuleById(moduleId)
                setModule(response.data)
                setModuleName(response.data.name)
                setModuleSummary(response.data.summary)
            } catch (err) {
                console.error(err)
            }
            // setQuestionLoading(false)
        }
        findModule()
    }, [moduleId])

    useEffect(() => {
        const getLessonsByInstructorId = async () => {
            try {
                const response = await FindLessonsByInstructorId(instructor)
                setInstructorLessons(response.data)
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }
        getLessonsByInstructorId()
    }, [instructor])

    useEffect(() => {
        const getLessonsByModuleId = async () => {
            try {
                const response = await findLessonByModuleId(moduleId)
                setModuleLessons(response.data)
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }
        getLessonsByModuleId()
    }, [moduleId])

    useEffect(() => {
        const generateArray = () => {
            const mappedModules = moduleLessons.map((lesson) => lesson.name)
            setArrayOfModules(mappedModules)
        }
        generateArray()
    }, [moduleLessons])

    useEffect(() => {
        setLoading(false)
        if (arrayOfModules.length === 0 && moduleLessons.length > 0) {
            setLoading(true)
        }
    }, [arrayOfModules, moduleLessons])

    useEffect(() => {
        const getInstructorId = async () => {
            try {
                const response = await findInstructorByUserId(user.id)
                setInstructor(response.data.id)
            } catch (err) {
                console.error(err)
            }
        }
        getInstructorId()
        return () => {
            getInstructorId([])
        }
    }, [user.id])

    const UpdateModule = async () => {
        try {
            const response = await updateModule({
                id: moduleId,
                name: moduleName,
                summary: moduleSummary,
            })
            openNotification({
                type: 'success',
                title: 'Edit Module',
                message: response.message,
            })
            const ModuleId = response.data.id
            return ModuleId
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Edit Module',
                message: error.message,
            })
        }
        history.push('/admin/applications')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const ModuleId = await UpdateModule()
        try {
            // if add lesson cant do multipul lessons at once use a .map here to call addlesson for each lesson id
            moduleLessons.map((lesson) => {
                const response = removeLessonFromModule(moduleId, lesson.id)
                return response
            })
            selectedLessons.map((lesson) => {
                const response = addLessonToModule({
                    instructorId: instructor,
                    moduleId: ModuleId,
                    // hand in lesson ids here
                    lessonId: lesson,
                })
                return response
            })

            // history.push('/tutor/courses')
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Add Lesson to Modules',
                message: error.message,
            })
        }
    }

    return (
        <div>
            <Form form={form}>
                <h3 className="titleText">Module Title*</h3>
                <input
                    className="inputTitle"
                    placeholder="Name of module..."
                    type="text"
                    name="name"
                    required="required"
                    onChange={(e) => {
                        setModuleName(e.target.value)
                    }}
                    defaultValue={module.name}
                />

                <h3 className="titleText">Module Summary*</h3>
                <Input.TextArea
                    placeholder="Brief summary of module"
                    type="text"
                    name="summary"
                    required="required"
                    onChange={(e) => {
                        setModuleSummary(e.target.value)
                    }}
                    defaultValue={module.summary}
                />

                <h3 className="titleText">Attach Lessons</h3>
                {loading ? (
                    <div />
                ) : (
                    <Form form={form} onFinish={handleSubmit}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder=" Select lesson..."
                            onChange={handleChange}
                            optionLabelProp="label"
                            defaultValue={arrayOfModules}
                        >
                            {instructorLessons.map((lesson) => {
                                return (
                                    <Option
                                        value={lesson.name}
                                        label={lesson.name}
                                    />
                                )
                            })}
                        </Select>
                    </Form>
                )}
            </Form>
            <div className="btn-container">
                <Button id="backBtn" onClick={() => setIsModalVisible(true)}>
                    Delete Module
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                    Save and Exit
                </Button>
            </div>
            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[null]}
                closable={false}
            >
                <div className="modalText">
                    <h3> Are you sure you want to delete {module.name}?</h3>
                    <br />
                    <p>
                        {' '}
                        If you decide to delete {module.name} you'll never get a
                        chance to bring it back{' '}
                    </p>
                    <br />
                </div>
                <div className="modalButtons">
                    <div className="modalButton">
                        <Button
                            primary
                            id="finishCancelBtn"
                            onClick={() => handleCancel()}
                        >
                            <p>Cancel</p>
                        </Button>
                    </div>
                    <div className="modalButton">
                        <Button
                            primary
                            className="goBackBtn"
                            onClick={() => handleDelete(moduleId)}
                        >
                            <p>Confirm</p>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
