import { useSelector, useDispatch } from 'react-redux';
import {
    setQuizMode as quizMode,
    setQuizModuleId as quizModuleId,
    setQuizCourseId as quizCourseId,
    setQuizType as quizType,
    isLessonURL as lessonURL,
    beginQuiz as commenceQuiz,
    setUserAnswer,
    isQuizCompleted as completeQuiz,
    setCurrentAns,
    setQuizScore as quizScore,
    clearAllState
} from '../store/actions/interface'

export const useInterface = () => {
    const dispatch = useDispatch()
    const state = useSelector(({ interFace }) => interFace)

    const setQuizMode = (inp) => {
        dispatch(quizMode(inp))
    }

    const setQuizModuleId = (inp) => {
        dispatch(quizModuleId(inp))
    }

    const setQuizCourseId = (inp) => {
        dispatch(quizCourseId(inp))
    }

    const setQuizType = (inp) => {
        dispatch(quizType(inp))
    }

    const isLessonUrl = (inp) => {
        dispatch(lessonURL(inp))
    }

    const beginQuiz = (inp) => {
        dispatch(commenceQuiz(inp))
    }

    const setAnswer = (inp) => {
        dispatch(setUserAnswer(inp))
    }

    const setSelectedAnswer = (inp) => {
        dispatch(setCurrentAns(inp))
    }

    const isQuizCompleted = (inp) => {
        dispatch(completeQuiz(inp))
    }

    const setQuizScore = (inp) => {
        dispatch(quizScore(inp))
    }
    const clearAll = () => {
        dispatch(clearAllState())
    }

    return {
        state,
        setQuizMode,
        setQuizModuleId,
        setQuizCourseId,
        setQuizType,
        isLessonUrl,
        beginQuiz,
        setAnswer,
        setSelectedAnswer,
        isQuizCompleted,
        setQuizScore,
        clearAll
    }
}