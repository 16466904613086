import React from 'react'
import { CircularLoader } from '../CircularLoader'
import { ButtonElement, LinkButton } from './Button.styled'

export const Button = ({
    variant = 'primary',
    children,
    loading,
    to,
    ...props
}) => {
    return to ? (
        <LinkButton to={to} variant={variant} {...props}>
            {loading ? (
                <CircularLoader variant={variant} state={props.state} />
            ) : (
                children
            )}
        </LinkButton>
    ) : (
        <ButtonElement variant={variant} {...props}>
            {loading ? (
                <CircularLoader variant={variant} state={props.state} />
            ) : (
                children
            )}
        </ButtonElement>
    )
}
