import React from 'react'
import { Displaycontent } from './Displaycontent'
import './privacy.scss'

export const Privacy = () => {
    const content = [
        {
            key: 1,
            title: 'Information We Collect',
            content: '',
            subcontent: [
                {
                    key: 1.1,
                    title: 'Personal Information',
                    content:
                        'When you register for an account on our LMS, we may collect personal information such as your name, email address, and other contact details.',
                },
                {
                    key: 1.2,
                    title: 'User Content',
                    content:
                        'We collect the content that you create, upload, or share on our platform, including assignments, discussion posts, and other materials.',
                },
                {
                    key: 1.3,
                    title: 'Usage Information',
                    content:
                        'We may collect information about how you interact with our LMS, including your activity logs, access times, and browsing history.',
                },
                {
                    key: 1.4,
                    title: 'Device Information',
                    content:
                        'We may collect information about the device(s) you use to access our platform, including the device type, operating system, and IP address.',
                },
            ],
        },
        {
            key: 2,
            title: 'How We Use Your Information',
            content: '',
            subcontent: [
                {
                    key: 2.1,
                    title: 'Providing Services',
                    content:
                        'We use your personal information to provide and personalize our services, including delivering course content, facilitating communication with instructors and other users, and assessing your progress.',
                },
                {
                    key: 2.2,
                    title: 'Improving Our Platform',
                    content:
                        'We may use aggregated usage data to analyse trends, monitor the performance of our platform, and enhance user experience.',
                },
                {
                    key: 2.3,
                    title: 'Communications',
                    content:
                        'We may use your contact information to send you administrative messages, updates about our services, and promotional materials. You can opt-out of receiving promotional communications at any time.',
                },
                {
                    key: 2.4,
                    title: 'Legal Compliance',
                    content:
                        'We may use your information to comply with applicable laws, regulations, or legal processes, or to respond to lawful requests from government authorities.',
                },
            ],
        },
        {
            key: 3,
            title: 'How We Share Your Information',
            content: '',
            subcontent: [
                {
                    key: 3.1,
                    title: 'Third-Party Service Providers',
                    content:
                        'We may share your information with third-party service providers who assist us in operating our platform, such as cloud hosting providers, payment processors, and analytics services. These service providers are contractually obligated to only use your information as necessary to provide the services to us.',
                },
                {
                    key: 3.2,
                    title: 'Business Transfers',
                    content:
                        'If we are involved in a merger, acquisition, or sale of assets, your information may be transferred or disclosed as part of that transaction. However, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.',
                },
                {
                    key: 3.3,
                    title: 'Legal Requirements',
                    content:
                        'We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders or subpoenas).',
                },
            ],
        },
        {
            key: 4,
            title: 'Data Security',
            content:
                'We employ technical and organizational measures to safeguard the information we collect and prevent unauthorized access, disclosure, alteration, or destruction of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.',
        },
        {
            key: 5,
            title: 'Your Rights',
            content:
                'You have the right to access, correct, or delete your personal information. You can also request that we restrict the processing of your information or object to its processing under certain circumstances. Please note that some of these rights may be subject to exceptions or limitations under applicable data protection laws.',
        },
        {
            key: 6,
            title: "Children's Privacy",
            content:
                'Our platform is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe that we have collected personal information from a child under 13, please contact us immediately, and we will take steps to delete such information.',
        },
        {
            key: 7,
            title: 'Changes to This Privacy Policy',
            content:
                'We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or by other means of communication.',
        },
    ]
    return (
        <section className="privacy_content_cont">
            <h1>Privacy Policy</h1>
            <section className="content">
                <div className="data_modify">
                    <p className="date">Last Updated: [12th April, 2024]</p>
                    <p className="text">
                        Zustechlearning.com is committed to protecting the
                        privacy and security of your personal information. This
                        Privacy Policy outlines how we collect, use, and
                        disclose information when you use our website.
                    </p>
                </div>
                <div className="readable">
                    <Displaycontent content={content} />
                </div>
            </section>
        </section>
    )
}
