import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'
import Facebook from './social-icons/fb.svg'
import LinkedIn from './social-icons/linkedin.svg'
import Instagram from './social-icons/instagram.svg'
import Twitter from './social-icons/twitter.svg'
import { DownloadHanbook } from '../../../../utils/downloadHandbook'
// import Tiktok from './social-icons/tiktok.svg'

const Footer = () => {
    return (
        <section id="footer-features">
            <div className="container">
                <div className="main-footer">
                    <div className="footer-links row">
                        {/* Column1 */}
                        <div className="company col">
                            <h4>Company</h4>
                            <ul className="list">
                                <li>
                                    <Link to="/About">About</Link>
                                </li>
                                {/* <li>
                                    <a href="Jobs at Zustech">
                                        Jobs at Zustech
                                    </a>
                                </li>
                                <li>
                                    <a href="Press">Press</a>
                                </li>
                                <li>
                                    <a href="teach">Become a Teacher</a>
                                </li> */}
                                <li>
                                    <Link to="/terms">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/terms/privacy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                        {/* Column2 */}
                        <div className="resources col">
                            <h4>Resources</h4>
                            <ul className="list">
                                <li>
                                    <Link to="/teach">Become an Instructor</Link>
                                </li>
                                <li>
                                    <button onClick={DownloadHanbook}>
                                        Instructor Handbook
                                    </button>
                                </li>
                                {/* <li>
                                    <a href="Company">Company</a>
                                </li> */}
                            </ul>
                        </div>
                        {/* Column3 */}
                        {/* <div className="community col">
                            <h4>Community</h4>
                            <ul className="list">
                                <li>
                                    <a href="Go premium">Go Premium</a>
                                </li>
                                <li>
                                    <a href="Partners">Partners</a>
                                </li>
                                <li>
                                    <a href="Blog">Blog</a>
                                </li>
                            </ul>
                        </div> */}
                        {/* Column4 */}
                        <div className="support col">
                            <h4>Support</h4>
                            <ul className="list">
                                <li>
                                    <Link to="/contact">Help Center</Link>
                                </li>
                                <li>
                                    <Link to="/contact">FAQ</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="bottom-row">
                        <p className="latest-date-footer col">
                            &copy;{new Date().getFullYear()} Zustech Ltd
                        </p>

                        <ul className="sm-icon-container">
                            <li className="social-media-icons">
                                <a
                                    href="https://www.facebook.com/Zustech"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src={Facebook}
                                        alt="facebook"
                                        className="fb-icon"
                                    />
                                </a>
                            </li>
                            {/* <li className="social-media-icons">
                                <a
                                    href="https://www.tiktok.com/@zustech"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src={Tiktok}
                                        alt="Tiktok"
                                        className="tk-icon"
                                    />
                                </a>
                            </li> */}
                            <li className="social-media-icons">
                                <a
                                    href="https://www.linkedin.com/company/zustech-limited/about/"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src={LinkedIn}
                                        alt="linkedin"
                                        className="li-icon"
                                    />
                                </a>
                            </li>
                            <li className="social-media-icons">
                                <a
                                    href="https://www.instagram.com/zustech/"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src={Instagram}
                                        alt="instagram"
                                        className="insta-icon"
                                    />
                                </a>
                            </li>
                            <li className="social-media-icons">
                                <a
                                    href="https://twitter.com/zustech"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src={Twitter}
                                        alt="twitter"
                                        className="tw-icon"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
