// Imports:
import { Collapse } from 'antd';
import Arrow from './assets/ArrowVector.svg'
import './FAQSection.scss'

const { Panel } = Collapse;
const text = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta numquam voluptates dignissimos. Itaque corrupti omnis iure laudantium ipsam vitae consequatur laboriosam commodi debitis totam error, est ducimus reiciendis esse. Ipsa!';

const FAQSection = () => {
    return (
        <section id="FAQSection">
            <div id="content">
                <h2>Frequently Asked Questions</h2>
                <div id="accordion">
                    <Collapse defaultActiveKey={[1]} expandIcon={({ isActive }) => (isActive ? <img src={Arrow} alt="Directional arrow" /> : <img src={Arrow} alt="Directional arrow" style={{ transform: 'rotate(-90deg)' }} />)}>
                        <Panel header="Will this course have live lessons?" key="1">
                            <p>{text}</p>
                        </Panel>
                        <Panel header="Lorem ipsum dolor sit amet consectetur?" key="2">
                            <p>{text}</p>
                        </Panel>
                        <Panel header="Can I do this course with the free trial?" key="3">
                            <p>{text}</p>
                        </Panel>
                        <Panel header="Lorem ipsum dolor sit amet consectetur?" key="4">
                            <p>{text}</p>
                        </Panel>
                    </Collapse>
                </div>
            </div>

        </section>
    );
}

export default FAQSection;