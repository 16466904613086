import SubPageHero from './subPageHero/SubPageHero'
import WhatYouWillLearn from './whatYouWillLearn/whatYouWillLearn'
import Description from './description/description'
import TeacherInfo from './teacherInformation/Teacher'
import StudentReviews from './studentReviews/StudentReviews'
import WhyUseZustech from './whyUseZustech/whyUseZustech'
import CourseContentSection from './courseContentSection/CourseContentSection'
import DATA from '../shared/MOCK_DATA.js'
import RelatedCourses from './relatedCourses/RelatedCourses'
import FAQSection from './FAQSection/FAQSection'

export const CoursesSubPage = ({ match }) => {
    const {
        params: { title },
    } = match
    // console.log(title)
    // console.log('match: ', JSON.stringify(match, null, 2))
    // console.log('location: ', JSON.stringify(location, null, 2))
    // console.log('data: ', DATA)

    return (
        <div>
            {DATA.filter(
                (val) => val.title.toUpperCase() === title.toUpperCase()
            ).map((val, index) => {
                return (
                    <>
                        <SubPageHero
                            key={index}
                            id={index}
                            title={val.title}
                            thumbnail={val.thumbnail}
                            author={val.author}
                            description={val.desc}
                            rating={val.rating}
                            rateCount={val.rateCount}
                            level={val.difficulty}
                            timeEstimate={val.timeEstimate}
                            students={val.students}
                            projects={val.projects}
                            videoContent={val.videoContent}
                        />
                        <WhatYouWillLearn />
                        <Description />
                        <TeacherInfo />
                        <StudentReviews
                            rating={val.rating}
                            rateCount={val.rateCount}
                        />
                        <WhyUseZustech />
                        <CourseContentSection />
                        <RelatedCourses data={DATA} />
                        <FAQSection />
                    </>
                )
            })}
        </div>
    )
}
