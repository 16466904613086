import React from 'react'
import magnifying from '../assets/magnifying-glass-icon.png'
import arrowup from '../assets/arrow-up.png'
import avatar from '../assets/avatar.png'
import './styles.scss'

export const QnaMain = ({ isQuestionClicked }) => {
    return (
        <div className="qna-container">
            <h4 className="filter-by">Filter by:</h4>
            <div className="qna-header">
                <div className="qna-by-course">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            By Course
                        </option>
                        <option value="">All</option>
                        <option value="">Option 1</option>
                        <option value="">Option 2</option>
                    </select>
                </div>
                <div className="qna-recommended">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            Recommended
                        </option>
                        <option value="">All</option>
                        <option value="">Recommended 1</option>
                        <option value="">Recommended 2</option>
                    </select>
                </div>
                <div className="qna-questions">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            Questions
                        </option>
                        <option value="">All</option>
                        <option value="">Option 1</option>
                        <option value="">Course 2</option>
                    </select>
                </div>
            </div>
            <div className="qna-search-container">
                <input
                    type="text"
                    placeholder="Search all course questions"
                    className="qna-search-bar"
                />
                <img src={magnifying} className="magnify-icon" alt="" />
            </div>
            <div className="qna-ask">
                <textarea
                    type="text"
                    placeholder="Ask a question..."
                    className="qna-ask-bar"
                />
            </div>
            {/* Card 1 */}
            <div className="qna-card-container">
                <button className="qna-card-title" onClick={isQuestionClicked}>Does anyone have any helpful UX resources, they could share?</button>
                <div className="qna-tags">
                    <div className="qna-tag-yellow">UX Resources</div>
                    <div className="qna-tag-grey">UX Design</div>
                    <div className="qna-interactions">
                        <img src={arrowup} className="arrow-up" alt="" />
                        <p className="number-of-upvotes">8</p>
                    </div>
                </div>
                <p className="qna-card-body">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores omnis officiis aperiam possimus eligendi, quidem
                    odio natus consequuntur, nobis modi quam repellendus?
                    Quibusdam, beatae! Maiores.
                </p>
                <div className="qna-card-footer">
                    <img src={avatar} className="qna-card-avatar" alt="" />
                    <p className="qna-avatar-name">
                        Posted by{' '}
                        <span className="qna-highlight">John Doe</span>
                    </p>
                    <p className="qna-card-responses">25 Responses</p>
                </div>
            </div>
            {/* Card 2 */}
            <div className="qna-card-container">
                <button className="qna-card-title" onClick={isQuestionClicked}>Does anyone have any helpful UX resources, they could share?</button>
                <div className="qna-tags">
                    <div className="qna-tag-yellow">UX Resources</div>
                    <div className="qna-tag-grey">UX Design</div>
                    <div className="qna-interactions">
                        <img src={arrowup} className="arrow-up" alt="" />
                        <p className="number-of-upvotes">8</p>
                    </div>
                </div>
                <p className="qna-card-body">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores omnis officiis aperiam possimus eligendi, quidem
                    odio natus consequuntur, nobis modi quam repellendus?
                    Quibusdam, beatae! Maiores.
                </p>
                <div className="qna-card-footer">
                    <img src={avatar} className="qna-card-avatar" alt="" />
                    <p className="qna-avatar-name">Posted by John Doe</p>
                    <p className="qna-card-responses">25 Responses</p>
                </div>
            </div>
        </div>
    )
}
