// import categoryData from './categoryData'
import { Link } from 'react-router-dom'
import LandingCourseCard from './LandingCourseCard'

// import computer from './assets/computer.jpg'
// import stars from './assets/stars.png'
// import beginner from './assets/beginner.svg'

// import styles
import './courseCategories.scss'

const CourseCategories = () => {

    return (
        <section id="course-section">
            <div className="courses-section-title">
                <h1 className="our-courses-title">OUR COURSES</h1>
                <p className="our-courses-info">
                    Our wide range of courses, delivered by experienced
                    instructors alongside a growing collection of learning
                    materials, continues to expand.
                </p>
            </div>
            <div>
                <LandingCourseCard />
            </div>
            <div className="view-all-btn-container">
                <Link to="/courses">
                    <button className="view-all-btn">VIEW ALL</button>
                </Link>
            </div>
        </section>
    )
}

export default CourseCategories
