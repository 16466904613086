import React from 'react'
import { Link } from 'react-router-dom'
import success from '../shared/assets/success.png'
import error from '../shared/assets/error.png'

import './linked.scss'

export const LinkedAccount = () => {
    return (
        <div id="linked-account">
            <div className="contents">
                <h4>Linked Account</h4>
                <div className="flexbox">
                    <div className="flex-content">
                        <img src={error} alt="" />
                        <p>Facebook</p>
                    </div>
                    <Link className="link" to="/me">Connect</Link>
                </div>
                <div className="flexbox">
                    <div className="flex-content">
                        <img src={success} alt="" />
                        <p>Google</p>
                    </div>
                    <Link to="/me" className="disconnect">Disconnect</Link>
                </div>
                <div className="flexbox">
                    <div className="flex-content">
                        <img src={error} alt="" />
                        <p>Twitter</p>
                    </div>
                    <Link className="link" to="/me">Connect</Link>
                </div>
            </div>
        </div>
    )
}
