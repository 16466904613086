import React from 'react'

export function Displaycontent({ content, type = 'regular' }) {
    return (
        <div id="content_cont">
            {content.map((items, idx) => {
                return (
                    <div key={items.key ? items.key : idx}>
                        {items.title !== '' && (
                            <h2 className="docs_heading">
                                {`${items?.key}. ${items.title}`}
                            </h2>
                        )}
                        {type === 'regular' && items.content !== '' && (
                            <p>{items.content}</p>
                        )}
                        {type === 'regular' &&
                            (typeof items.subcontent === 'object' ? (
                                items.subcontent.map((subitems) => {
                                    return (
                                        <div
                                            className="subcontent"
                                            key={subitems.key}
                                        >
                                            <h3>
                                                {`${subitems.key} ${subitems.title}:`}
                                            </h3>
                                            {typeof subitems.content !==
                                            'object' ?
                                                <p>{subitems.content}</p>
                                                : (
                                                    <div>
                                                        <p>
                                                            {
                                                                subitems.content
                                                                    .heading
                                                            }
                                                            :
                                                        </p>
                                                        <ul>
                                                            {subitems.content.miniContent.map(
                                                                (itm, id) => (
                                                                    <li key={id}>
                                                                        {itm}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                        </div>
                                    )
                                })
                            ) : (
                                <div>
                                    <p>{items.subcontent}</p>
                                </div>
                            ))}
                        {type !== 'regular' &&
                            (items.type
                                ? items.content.map((itm) => {
                                    return (
                                        <div
                                            className="subcontent"
                                            key={itm.key}
                                        >
                                            <p>{itm.subtitle}</p>
                                            {typeof itm.subcontent !==
                                                'object' && (
                                                <p>{itm.subcontent}</p>
                                            )}
                                            {typeof itm.subcontent ===
                                                'object' && (
                                                <ul>
                                                    {itm.subcontent.map(
                                                        (itm, idx) => (
                                                            <li key={idx}>
                                                                {itm}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    )
                                })
                                : items.content.map((item, idx) => {
                                    return <p key={idx}>{item}</p>
                                }))}
                    </div>
                )
            })}
        </div>
    )
}
