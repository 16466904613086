import React, { useState } from 'react'
// import queryString from 'query-string'
import { Form } from 'antd'
import { Input, Button } from '../../../../components'
import { resetPassword } from '../../../../services'
import { openNotification } from '../../../../utils'
import './accountinfo.scss'
import { useAuth } from '../../../../hooks'

export const AccountInfo = () => {
    const {
        state: { data },
        logout,
    } = useAuth()

    const email = data?.email

    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()

    const handlePasswordReset = async (values) => {
        setLoading(true)

        try {
            const response = await resetPassword({
                email,
                ...values,
            })
            openNotification({
                type: 'success',
                title: 'Change Password',
                message: response.message,
            })
            await logout()
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Change Password',
                message: error.message,
            })
        }
        setLoading(false)
    }

    return (
        <div id="account-info">
            <div className="contents">
                <h4>Account Information</h4>

                <div>
                    <h5 className="py-4">Change password</h5>
                    <Form
                        name="form"
                        form={form}
                        onFinish={handlePasswordReset}
                        autoComplete="on"
                    >
                        <div className="pb-2">
                            <Input.Password
                                name="password"
                                validationRules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                disabled={loading}
                                placeholder="Enter Current Password"
                            />
                        </div>
                        <div className="pb-2">
                            <Input.Password
                                name="newPassword"
                                disabled={loading}
                                validationRules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter your new password',
                                    },
                                    {
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{9,}$/,
                                        message:
                                            'Password should contain at least 8 characters including 1-uppercase, 1-lowercase, 1-alphanumeric and 1-number',
                                    },
                                ]}
                                placeholder="Enter New Password"
                            />
                        </div>

                        <Input.Password
                            name="confirmNewPassword"
                            dependencies={['newPassword']}
                            validationRules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('newPassword') ===
                                                value
                                        ) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered do not match!'
                                            )
                                        )
                                    },
                                }),
                            ]}
                            placeholder="Re-Type New Password"
                            disabled={loading}
                        />

                        <Button loading={loading} type="submit">
                            Change Password
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}
