import React from 'react'
import Corousel from '../Courses/HeroSection/Carousel'
import CoursesContent from './CoursesCards/Courses'
import SearchBar from '../../../components/Searchbar/searchbar.jsx'
import './styles.scss'
// import { Premium } from '../../../components/PremiumCourse'
// import { Cart } from '../../../components/PremiumCourse/cart'
// import CoursesContent from '../Courses/CoursesCards/Courses'
// import SearchBar from '../Courses/SearchBar/SearchBar'

export const PremiumCourses = () => {

    return (
        <div id="premium-page">
            <div className="premium-heading">
                <p>
                    Our courses are designed to assist you in achieving your learning
                    goals
                </p>
            </div>
            <div>
                <Corousel />
                <SearchBar type="course" />
            </div>
            <CoursesContent courseType="One-time" />
        </div>
    )
}