import { Button } from 'antd'
import './courseCard.scss'
import { ActiveCourse } from './activeCourse'
import { RetiredCourse } from './retiredCourse'
import { SicCourse } from './sicCourse'
import { Module } from '../../ManageCourses/moduleCard/module'
import { Lesson } from '../../ManageCourses/lessonCard/lesson'
import { QuizCard } from '../../ManageCourses/quizCard/quizCard'
import CourseThumbnail from '../../../../assets/icons/ModuleImage.svg'
import { ReviewCourse } from './inreviewCourse'

export function Coursecard({ type }) {
    switch (type) {
        case 'active':
            return <ActiveCourse type={type} thumbnail={CourseThumbnail} CTA={Button} />
        case 'SIC':
            return <SicCourse type={type} thumbnail={CourseThumbnail} CTA={Button} />
        case 'retired':
            return <RetiredCourse type={type} thumbnail={CourseThumbnail} CTA={Button} />
        case 'review':
            return <ReviewCourse type={type} thumbnail={CourseThumbnail} CTA={Button} />
        default:
            return <ActiveCourse type={type} thumbnail={CourseThumbnail} CTA={Button} />
    }
}
export function Modulecard({ type }) {
    return <Module type={type} thumbnail={CourseThumbnail} CTA={Button} />
}
export function Lessoncard({ type }) {
    return <Lesson type={type} thumbnail={CourseThumbnail} CTA={Button} />
}
export function Quizcard({ type }) {
    return <QuizCard type={type} thumbnail={CourseThumbnail} CTA={Button} />
}
