import 'antd/dist/antd.css'
import './subPageHero.scss'
import VideoJS from './videoPlayer/VideoJS'

import getStars from '../../shared/courseCard/helpers/getStars'
import getStar from '../../shared/courseCard/helpers/getStar'
import getLevel from '../../shared/courseCard/helpers/getLevel'

// Images:
import posterImage from './assets/hero-bg.png'

// Video:
import video from './assets/MOCK_VIDEO.mp4'

const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    controls: true,
    responsive: true,
    fluid: true,
    preload: 'metadata',
    poster: posterImage,
    sources: [
        {
            src: video,
            type: 'video/mp4',
        },
    ],
}

// Data will be use to make it dynamic
const SubPageHero = ({
    title,
    // id,
    description,
    rating,
    rateCount,
    level,
    students,
    projects,
    videoContent,
}) => {
    // round start to nearest half (0.5)
    function roundHalf(num) {
        return (Math.round(num * 2) / 2).toFixed(1)
    }

    return (
        <section id="SubPageHero">
            <div id="path">
                {/* Anchors to be changed to routing Links */}
                {/* <h3><a href="#">Development</a> &gt; <a href="#">Data Science</a> &gt; <a href="#">Python</a></h3> */}
            </div>

            <div id="section-1">
                <div className="courseInfo">
                    <h1>Learn {title}</h1>
                    <div className="courseRating">
                        <span className="rating-value">{rating}</span>
                        <div className="stars">
                            {getStars(roundHalf(rating)).map(
                                (rating, index) => (
                                    <img
                                        src={getStar(rating)}
                                        width={16}
                                        key={index}
                                        alt="star"
                                    />
                                )
                            )}
                        </div>
                        <span className="rating-count">({rateCount})</span>
                    </div>
                    <p id="courseDescription">{description}</p>
                    <div id="courseTrialOne">
                        <button className="trialStart">Start Free Trial</button>
                        <p>
                            start a 7 day free trial and see if you like this
                            course{' '}
                        </p>
                    </div>
                </div>

                <div id="HeroImage">
                    {/* <button id="previewButton">Preview</button> */}
                    <VideoJS id="VideoImage" options={videoJsOptions} />
                </div>
            </div>
            <div className="courseStats">
                <div id="courseDifficulty">
                    <img
                        id="difficultyBar"
                        src={getLevel(level)}
                        alt={`${level} difficulty`}
                    />
                    <p>{level}</p>
                </div>
                <div id="studentEnrolled">
                    <h3>{students}</h3>
                    <p>students enrolled</p>
                </div>
                <div id="numProjects">
                    <h3>{projects}</h3>
                    <p>projects</p>
                </div>
                <div id="hoursOfContent">
                    <h3>{videoContent} hours</h3>
                    <p>of video content</p>
                </div>
            </div>
            <div id="courseTrialTwo">
                <button className="trialStart">Start Free Trial</button>
                <p>start a 7 day free trial and see if you like this course </p>
            </div>
        </section>
    )
}

export default SubPageHero
