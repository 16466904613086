import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ShoppingCart from '../assets/cartIconn.svg'
import { CartContext } from '../../../context/Cart/cartContext'
import './styles.scss'

export const CartIcon = () => {
    // const [cartItems, setCartItems] = useState([])
    const { cartItems } = useContext(CartContext)

    return (
        <div>
            <Link className="cart-btn" to="/me/premium/cart">
                <img className="cart" src={ShoppingCart} alt="shopping-cart" />
                {/* <div className="cart-count">{cartItems.length}</div> */}
                {cartItems.length > 0 && <span className="cart-count">{cartItems.length}</span>}
            </Link>
        </div>
    )
}
