import { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import tickIcon from '../assets/tick-icon.png'
import plus from '../assets/plusIcon.png'
import chevron from '../assets/chevron-icon.png'
import calendarIcon from '../assets/calendar.png'

const Tasks = () => {
    const [checkmark, setCheckmark] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)

    // For tick button on Tasks tab
    const isTaskChecked = () => {
        if (checkmark === false) {
            setCheckmark(true)
        } else if (checkmark === true) {
            setCheckmark(false)
        }
    }

    const toggleModal = () => {
        if (openModal === true) {
            setOpenModal(false)
        } else if (openModal === false) {
            setOpenModal(true)
        }
    }

    return (
        <div>
            <div className="task-card-container">
                <div className="task-card-header">
                    <h4 className="task-card-title">Task 1:</h4>
                    <h4 className="task-card-title">Introduce Yourself</h4>
                </div>
                <div
                    className={`task-checkmark ${
                        checkmark === true ? 'ticked' : ''
                    }`}
                    onClick={isTaskChecked}
                >
                    <input
                        type="image"
                        src={tickIcon}
                        className={`task-tick-icon ${
                            checkmark === true
                                ? 'task-tick-icon'
                                : 'toggle-display-tick'
                        }`}
                        alt="tick"
                        onClick={isTaskChecked}
                    />
                </div>
                <div className="task-card-body">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ipsam nulla suscipit qui. Suscipit hic quasi enim
                        doloribus, nulla, facere nemo sapiente quos, odio
                        nesciunt fugiat!
                    </p>
                </div>
                <div>
                    <div className="task-btn-group">
                        <button className="task-card-btn">
                            Go to the forum
                        </button>
                        <button className="task-card-btn" onClick={toggleModal}>
                            Set task in To Do
                        </button>
                        <button className="task-mobile-btn">Forum</button>
                        <button
                            className="task-mobile-btn"
                            onClick={toggleModal}
                        >
                            Save task
                        </button>
                    </div>
                </div>
            </div>

            <div className="task-card-container">
                <div className="task-card-header">
                    <h4 className="task-card-title">Task 2:</h4>
                    <h4 className="task-card-title">[Name Here]</h4>
                </div>
                <div className="task-checkmark" />
                <div className="task-card-body">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ipsa deleniti eligendi repudiandae praesentium numquam
                        iusto, exercitationem ullam dolore, adipisci pariatur
                        iure ex nobis laudantium recusandae!
                    </p>
                </div>
                <div className="task-btn-group">
                    <button className="task-card-btn">Go to the forum</button>
                    <button className="task-card-btn">Set task in To Do</button>
                    <button className="task-mobile-btn">Forum</button>
                    <button className="task-mobile-btn">Save task</button>
                </div>
            </div>
            {openModal && (
                <div className="overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img
                                className="chevron"
                                src={chevron}
                                alt="chevron"
                            />
                            <Link to="/me/tasks">
                                <p className="go-tasks"> Tasks</p>
                            </Link>
                            <button
                                onClick={toggleModal}
                                className="close-modal"
                            >
                                Close
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="modal-new-task">New Task</p>
                            <input
                                type="text"
                                className="modal-input-task"
                                placeholder="Enter new task..."
                            />
                            <p className="modal-new-task">Set Deadline</p>
                            <div className="enter-date-container">
                                <label>
                                    <DatePicker
                                        className="date-picker"
                                        placeholderText="Select Date..."
                                        selected={selectedDate}
                                        onChange={(date) =>
                                            setSelectedDate(date)}
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    <img
                                        className="calendarIcon"
                                        src={calendarIcon}
                                        alt="calendar"
                                    />
                                </label>
                            </div>
                            <p className="modal-colour">Colour</p>
                            <div className="colours-container">
                                <div className="ball-colour light-blue" />
                                <div className="ball-colour dark-blue" />
                                <div className="ball-colour green" />
                                <div className="ball-colour yellow" />
                                <div className="ball-colour red" />
                                <div className="ball-colour pick-colour">
                                    <img
                                        className="pick-color-plus"
                                        src={plus}
                                        alt="plus"
                                    />
                                </div>
                            </div>
                            <button className="modal-create-task">
                                Create Task
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Tasks
