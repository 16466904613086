import React from 'react'
import { useHistory } from 'react-router-dom'
import { Collapse } from 'antd'
import '../instructorsupport.scss'
import { MdArrowForwardIos } from 'react-icons/md'
import group1 from '../assets/group1.svg'
import group2 from '../assets/group2.svg'
// import { accountsProfileFAQs } from '../component/constant'

const AccountProfile = () => {
    const history = useHistory()

    // const { Panel } = Collapse
    const onChange = (key) => {
        console.log(key)
    }

    // const text = 'Log in to your account.'
    const itemsNest1 = [
        {
            key: '1',
            label: 'To update your profile information, follow these steps: (Step 1)',
            children: <p>Log in to your account.</p>,
            // children: <p>{text}</p>,
        },
        {
            key: '2',
            label: 'Step 2',
            children: <p>On the dashboard, click on the setting sections.</p>,
        },
        {
            key: '3',
            label: 'Step 3',
            children: <p>Select "Account info."</p>,
        },
        {
            key: '4',
            label: 'Step 4',
            children: <p>Make the necessary changes and click "Save changes."</p>,
        },
    ]

    const items = [
        {
            key: '1',
            label: 'How do I update my profile information?',
            children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        },
        // {
        //     key: '2',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
        // {
        //     key: '3',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
        // {
        //     key: '4',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
        // {
        //     key: '5',
        //     label: 'Can I connect my social media accounts to my profile?',
        //     children: <p>At the moment, this feature is not yet ready. It’s one of the many features we will be updating on our site soon.</p>,
        // },
        // {
        //     key: '6',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
        // {
        //     key: '7',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
        // {
        //     key: '8',
        //     label: '',
        //     children: <Collapse defaultActiveKey="1" items={itemsNest1} />,
        // },
    ]

    return (
        <div id="student-support">
            <div className="top-background">
                <img src={group1} alt="" className="group1-img" />
                <img src={group2} alt="" className="group2-img" />
                <input
                    placeholder="Search articles"
                    name="search-article"
                    id="search-article"
                    className="search-article"
                />
            </div>
            <div className="top-link">
                <a href="/s">Instructors support</a>
                <MdArrowForwardIos />
                <a href="/s">Accounts</a>
                <MdArrowForwardIos />
            </div>
            <nav className="nav-btns">
                <button
                    onClick={() => {
                        history.push('/instructor-support/getting-started')
                    }}
                    className="btn"
                >
                    Getting started
                </button>
                <button
                    onClick={() => {
                        history.push('/instructor-support/account-setting')
                    }}
                    className="btn"
                >
                    Account/Profile
                </button>
                <button
                    onClick={() => {
                        history.push('/instructor-support/payment-withdrawal')
                    }}
                    className="btn"
                >
                    Payment/Withdrawal
                </button>
                <button
                    onClick={() => {
                        history.push('/instructor-support/create-course')
                    }}
                    className="btn"
                >
                    Create course
                </button>
                <button
                    onClick={() => {
                        history.push('/instructor-support/troubleshoot')
                    }}
                    className="btn"
                >
                    Troubleshooting
                </button>
            </nav>
            <section className="content-settings">
                <h2>Account Settings</h2>
                <p>
                    Welcome to Zustech! These FAQs will you navigate and use the
                    system effectively.
                </p>

                <div className="questions-container">
                    <div className="questions questions-box-1">
                        <Collapse onChange={onChange} items={items} />
                        <Collapse onChange={onChange} items={items} />
                        <Collapse onChange={onChange} items={items} />
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AccountProfile

// <div className="questions-container">
//     <div className="questions questions-box-1">
//         {accountsProfileFAQs.map((faq) => {
//             return (
//                 <Collapse className="">
//                     <Panel
//                         key={faq.question}
//                         header={faq.question}
//                     >
//                         <p style={{ fontSize: '14px' }}>
//                             {faq.answer}
//                         </p>
//                     </Panel>
//                 </Collapse>
//             )
//         })}
//     </div>
// </div>