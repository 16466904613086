import React from 'react'
// import { FaArrowDownLong } from 'react-icons/fa6'

import './ScrollButton.scss'

const ScrollButton = ({ feedbackRef }) => {

    return (
        <div className="scroll-buttons">
            <button
                onClick={() => feedbackRef.current?.scrollIntoView({
                    behavior: 'smooth'
                })}
                className="scroll-to-bottom"
                href="feedback--section"
                label="scroll-to-feedback"
            >
                Feedback
            </button>
        </div>
    )
}

export default ScrollButton

// <button onClick={scrollToTop} className="scroll-to-top" label="t" />