import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Loading } from '../../Loading'
import { useAuth } from '../../../hooks'

export const AppLayout = ({ children }) => {
    const {
        state: { data, isLoading },
    } = useAuth()

    if (isLoading) {
        return <Loading />
    }
    return (
        <div className="full_layout">
            <Header currentUser={data} />
            {children}
            <Footer />
        </div>
    )
}
