import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const ButtonElement = styled.button`
    border: ${(props) => {
        switch (props.variant) {
            case 'secondary':
                return '2px solid #3A86FF'
            case 'tertiary':
                return '0px solid transparent'
            case 'deletion':
                return '2px solid red'
            default:
                return '2px solid #3A86FF'
        }
    }};
    border-color: ${({ state }) => {
        switch (state) {
            case 'error':
                return '#F50C0C'
            case 'success':
                return '#0DC671'
            default:
                return ''
        }
    }};
    margin: 0.2rem 0;
    padding: 0.7rem;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    min-width: fit-content;
    /* height: ${(props) => props.height || '100%'}; */
    width: ${(props) => props.width || '100%'};
    color: ${(props) => {
        switch (props.variant) {
            case 'primary':
                switch (props.state) {
                    case 'success':
                        return '#fff'
                    case 'error':
                        return '#fff'
                    default:
                        return '#ffffff'
                }
            case 'secondary':
                switch (props.state) {
                    case 'error':
                        return '#F50C0C'
                    case 'success':
                        return '#0DC671'
                    default:
                        return '#3A86FF'
                }
            case 'deletion':
                switch (props.state) {
                    case 'error':
                        return '#F50C0C'
                    case 'success':
                        return '#0DC671'
                    default:
                        return 'red'
                }
            case 'tertiary':
                switch (props.state) {
                    case 'error':
                        return '#F50C0C'
                    case 'success':
                        return '#0DC671'
                    default:
                        return '#3A86FF'
                }

            default:
                return props.color || '#ffffff'
        }
    }};
    background-color: ${(props) => {
        switch (props.variant) {
            case 'primary':
                switch (props.state) {
                    case 'success':
                        return '#0DC671'
                    case 'error':
                        return '#F50C0C'
                    default:
                        return '#3A86FF'
                }
            default:
                return 'transparent'
        }
    }};
    transition: box-shadow 0.2s ease-out;
    &:focus {
        outline: none;
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &:hover {
    }
`

export const LinkButton = styled(Link)`
    border: ${(props) => {
        switch (props.variant) {
            case 'secondary':
                return '2px solid #3A86FF'
            case 'tertiary':
                return '0px solid transparent'
            default:
                return '2px solid #3A86FF'
        }
    }};
    border-color: ${({ state }) => {
        switch (state) {
            case 'error':
                return '#F50C0C'
            case 'success':
                return '#0DC671'
            default:
                return ''
        }
    }};
    margin: 0.2rem 0;
    padding: 0.7rem 0.7rem;
    border-radius: 6px;
    text-align: center;
    min-width: fit-content;
    width: ${(props) => props.width || '100%'};
    color: ${(props) => {
        switch (props.variant) {
            case 'primary':
                switch (props.state) {
                    case 'success':
                        return '#fff'
                    case 'error':
                        return '#fff'
                    default:
                        return '#ffffff'
                }
            case 'secondary':
                switch (props.state) {
                    case 'error':
                        return '#F50C0C'
                    case 'success':
                        return '#0DC671'
                    default:
                        return '#3A86FF'
                }
            case 'tertiary':
                switch (props.state) {
                    case 'error':
                        return '#F50C0C'
                    case 'success':
                        return '#0DC671'
                    default:
                        return '#3A86FF'
                }

            default:
                return props.color || '#ffffff'
        }
    }};
    background-color: ${(props) => {
        switch (props.variant) {
            case 'primary':
                switch (props.state) {
                    case 'success':
                        return '#0DC671'
                    case 'error':
                        return '#F50C0C'
                    default:
                        return '#3A86FF'
                }
            default:
                return 'transparent'
        }
    }};
    transition: box-shadow 0.2s ease-out;
    &:focus {
        outline: none;
    }

    &:hover {
    }
`
