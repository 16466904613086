import React, { useState } from 'react'
import { useInterface } from '../../../../../hooks'
// import Uncheck from '../../../../../assets/icons/uncheck_circle.svg'
// import Check from '../../../../../assets/icons/check_circle.svg'
// import Lock from '../../../../../assets/icons/Lock.svg'

function Lesson({
    les,
    itm,
    // show,
    // progress,
    updateUrl,
    // subscribed,
    // subModal
}) {
    const {
        isLessonUrl
    } = useInterface()
    const video = document.createElement('video')
    const [duration, setDuration] = useState('')

    video.src = les.content
    video.addEventListener('loadedmetadata', () => {
        const num = (video.duration / 60) < 10 ? `0${(video.duration / 60).toFixed()}` : `${(video.duration / 60).toFixed()}`
        const num2 = (video.duration % 60) < 10 ? `0${(video.duration % 60).toFixed()}` : `${(video.duration % 60).toFixed()}`
        setDuration(`${num}:${num2}`)
    })
    return (
        <div
            id={itm.moduleId}
            className="lesson_item"
            key={les.id}
            onClick={(e) => {
                const ownId = e.target.id
                const parId = e.target.parentElement.id
                // if (e.target.localName === 'img') {
                //     if (e.target.attributes.class.value === 'uncheck') {
                //         // using vanilla js to change the icon and className as well as
                //         // calling the progress function so as to update it's value
                //         e.target.attributes.src.value = Check
                //         e.target.attributes.class.value = 'check'
                //         if (progress) {
                //             progress(1, ownId, parId)
                //         }
                //     } else {
                //         e.target.attributes.src.value = Uncheck
                //         e.target.attributes.class.value = 'uncheck'
                //         if (progress) {
                //             progress(-1, ownId, parId)
                //         }
                //     }
                // } else {
                //     console.log('calling')
                //     if (les.name === 'Introduction') {
                //         isLessonUrl(true)
                //     } else {
                //         isLessonUrl(true)
                //         updateUrl(les.content, ownId, parId)
                //     }
                // }

                // TESTINGG
                // console.log("Clickkkinggg to play")
                isLessonUrl(true)
                updateUrl(les.content, ownId, parId)
                // console.log(les.content)
            }}
        >
            <p id={les.id}>{les.name}</p>
            <div>
                <p>{duration}</p>
            </div>
        </div>
    )
}

export default Lesson

// <div>
//     <p>{duration}</p>
//     <div id={itm.moduleId}>
//         {subscribed ? (
//             <img
//                 id={les.id}
//                 className={show?.[les.id] === true ? 'check' : 'uncheck'}
//                 src={show?.[les.id] === true ? Check : Uncheck}
//                 alt=""
//             />
//         ) : (
//             <img
//                 id={les.id}
//                 className="locked"
//                 src={Lock}
//                 alt=""
//             />
//         )}
//     </div>
// </div>

// SEPARATE COPY

// return (
//     <div
//         id={itm.moduleId}
//         className="lesson_item"
//         key={les.id}
//         onClick={(e) => {
//             if (subscribed) {
//                 const ownId = e.target.id
//                 const parId = e.target.parentElement.id
//                 if (e.target.localName === 'img') {
//                     if (e.target.attributes.class.value === 'uncheck') {
//                         // using vanilla js to change the icon and className as well as
//                         // calling the progress function so as to update it's value
//                         e.target.attributes.src.value = Check
//                         e.target.attributes.class.value = 'check'
//                         if (progress) {
//                             progress(1, ownId, parId)
//                         }
//                     } else {
//                         e.target.attributes.src.value = Uncheck
//                         e.target.attributes.class.value = 'uncheck'
//                         if (progress) {
//                             progress(-1, ownId, parId)
//                         }
//                     }
//                 } else {
//                     console.log('calling')
//                     if (les.name === 'Introduction') {
//                         isLessonUrl(true)
//                     } else {
//                         isLessonUrl(true)
//                         updateUrl(les.content, ownId, parId)
//                     }
//                 }
//             } else {
//                 // openSubscribed modal
//                 // subModal(true)
//             }
//         }}
//     ></div>