import React, { useEffect } from 'react'
import { Radio, Space } from 'antd'
import { useInterface } from '../../../../../hooks'

export default function QuestionInterface({ question, questionNo, showAnswer }) {
    const {
        state: { currentOption, selectedOptions },
        setAnswer,
        setSelectedAnswer
    } = useInterface()
    const onChange = (e) => {
        const selectedObj = {
            'id': question.id,
            'studentResonse': e.target.value
        }
        setSelectedAnswer(e.target.value)
        if (selectedOptions.length !== 0) {
            const newOption = selectedOptions.filter((a) => a.id !== selectedObj.id)
            newOption.push(selectedObj)
            setAnswer(newOption)
        } else {
            setAnswer([selectedObj])
        }
    }
    useEffect(() => {
        if (selectedOptions.length >= 1) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (selectedOptions[i].id === question.id) {
                    setSelectedAnswer(selectedOptions[i].studentResonse)
                }
            }
        }

        // eslint-disable-next-line
    }, [question.id])
    return (
        <div className="questionCont">
            <section className="questionCont_top">
                <div className="questionNum">{questionNo + 1}.</div>
                <div className="main_quest">{question.testQuestion}</div>
            </section>
            <section className="question_options">
                {/* options coming inform of checkbox */}
                <Radio.Group
                    disabled={showAnswer}
                    value={showAnswer ? question.correctAns : currentOption}
                    onChange={onChange}
                >
                    <Space direction="vertical">
                        {question.optionA !== null && <Radio value={question.optionA}>{question.optionA}</Radio>}
                        {question.optionB !== null && <Radio value={question.optionB}>{question.optionB}</Radio>}
                        {question.optionC !== null && <Radio value={question.optionC}>{question.optionC}</Radio>}
                        {question.optionD !== null && <Radio value={question.optionD}>{question.optionD}</Radio>}
                        {question.optionE !== null && <Radio value={question.optionE}>{question.optionE}</Radio>}
                    </Space>
                </Radio.Group>
            </section>
        </div>
    )
}
