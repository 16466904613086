import React from 'react'

export const Transcripts = () => {
    return (
        <div className="transcript-container">
            <div className="transcript-section">
                <h4 className="transcript-intro">Introduction Transcript</h4>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Perferendis molestias assumenda, tempore soluta omnis sit
                    corrupti non voluptatibus ratione delectus?
                </p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
                    magni ipsum illo, dolorem similique quis? Eum tempora corporis
                    dolorum, quo exercitationem, labore eius vitae sapiente
                    praesentium officia blanditiis modi perferendis in accusantium
                    itaque vel iure saepe molestias nulla, quisquam nostrum odio
                    asperiores voluptatibus optio! Quidem dolore, quos
                    reprehenderit laboriosam recusandae odit ipsum dolorum ab
                    laborum voluptatum sit, ratione tenetur ducimus optio eum quod
                    inventore explicabo cum similique placeat iusto, saepe facilis
                    soluta! Aut dolores, facere eaque fugit quo quisquam ipsa!
                </p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex
                    corrupti neque saepe nisi sit, cumque possimus, eum nostrum
                    esse molestiae vel nihil ad nam facere reiciendis ratione
                    architecto distinctio reprehenderit.
                </p>
            </div>
            <div className="transcript-section blue">
                <p className="transcript-chapter">Chapter 2</p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                    est, fuga facere ad voluptate iusto explicabo porro amet
                    impedit, aspernatur atque sequi architecto magni praesentium
                    eligendi reprehenderit incidunt temporibus! Repellendus saepe
                    iste totam molestiae alias harum sapiente, porro consectetur
                    aut! Qui vel magni quis illo sequi officiis ipsum perferendis
                    temporibus impedit accusantium obcaecati veniam facere fuga
                    suscipit aliquid dolor porro dolorum tempora voluptatum,
                    quibusdam quas minus sint aspernatur at. Eaque architecto
                    sapiente labore voluptatem officiis iure iste laboriosam
                    aperiam molestiae magni necessitatibus in laborum,
                    voluptatibus consequatur maiores distinctio aspernatur
                    voluptates reiciendis atque quae facere, doloremque sed est.
                    Sed tempore quasi eaque neque aspernatur repudiandae
                    distinctio quaerat similique dolore, quisquam ipsam quis
                    provident possimus aliquam sequi, necessitatibus deleniti rem
                    veniam! Qui!
                </p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Corrupti quaerat repellat eligendi, odio eos ratione commodi
                    rerum soluta cum, dolorum expedita totam ab magnam. Delectus
                    aliquam adipisci non explicabo voluptate!
                </p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. In
                    commodi accusamus similique fuga ad labore aperiam, ex, totam
                    iste, inventore possimus exercitationem! Nostrum, molestias
                    magni. Quas sequi modi natus cum? Corporis placeat id
                    reiciendis aspernatur dolore asperiores numquam dolorum
                    debitis dicta? Provident cum perspiciatis, fuga accusamus
                    neque suscipit autem dolor?
                </p>
                <p className="transcript-paragraph">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Quasi culpa at labore numquam, explicabo, ex impedit,
                    architecto quas amet blanditiis rem id sit. Porro, fugit.
                </p>
            </div>
            <div className="transcript-section">
                <p className="transcript-chapter">Chapter 3</p>
                <p className="transcript-paragraph">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Beatae earum enim error voluptas perspiciatis asperiores
                    similique eum aliquid, nisi eaque autem explicabo corrupti
                    ducimus accusantium, atque dolorem aut pariatur ad?
                </p>
            </div>
        </div>
    )
}