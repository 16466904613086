import { Route, Switch } from 'react-router-dom'
import { Register } from './Register'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { Login } from './Login'
import { AdminLogin } from './Admin/Login'

const accountRoutes = [
    {
        key: 1,
        path: '/account/register',
        name: 'Register',
        exact: true,
        component: Register,
    },
    {
        key: 2,
        path: '/account/login',
        name: 'Login',
        exact: true,
        component: Login,
    },
    {
        key: 3,
        path: '/account/forgot-password',
        name: 'Forgot Password',
        exact: true,
        component: ForgotPassword,
    },
    {
        key: 4,
        path: '/account/reset-password',
        name: 'Reset Password',
        exact: true,
        component: ResetPassword,
    },
    {
        key: 5,
        path: '/account/admin-login',
        name: 'Admin Login',
        exact: true,
        component: AdminLogin,
    },
    {
        key: 6,
        path: '',
        name: 'Not Found',
        exact: true,
        component: () => <div>Not Found</div>,
    },
]

export const Account = () => {
    return (
        <Switch>
            {accountRoutes.map((route) => {
                return <Route {...route} />
            })}
        </Switch>
    )
}
