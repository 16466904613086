import React from 'react'
import magnifying from '../assets/magnifying-glass-icon.png'
import arrowup from '../assets/arrow-up.png'
import arrowupgrey from '../assets/arrow-up-grey.png'
import avatar from '../assets/avatar.png'
import './styles.scss'

export const QnaQuestion = ({ isQuestionClicked }) => {
    return (
        <div className="qna-container">
            <div className="back-btn">
                <h4 className="filter-by">Filter by:</h4>
                <button className="qna-back" onClick={isQuestionClicked}>
                    Back to Questions
                </button>
            </div>
            <div className="qna-header">
                <div className="qna-by-course">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            By Course
                        </option>
                        <option value="">All</option>
                        <option value="">Option 1</option>
                        <option value="">Option 2</option>
                    </select>
                </div>
                <div className="qna-recommended">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            Recommended
                        </option>
                        <option value="">All</option>
                        <option value="">Recommended 1</option>
                        <option value="">Recommended 2</option>
                    </select>
                </div>
                <div className="qna-questions">
                    <select className="drop">
                        <option value="" disabled selected hidden>
                            Questions
                        </option>
                        <option value="">All</option>
                        <option value="">Option 1</option>
                        <option value="">Course 2</option>
                    </select>
                </div>
            </div>
            <div className="qna-search-container">
                <input
                    type="text"
                    placeholder="Search all course questions"
                    className="qna-search-bar"
                />
                <img src={magnifying} className="magnify-icon" alt="" />
            </div>
            <div className="qna-ask">
                <textarea
                    type="text"
                    placeholder="Ask a question..."
                    className="qna-ask-bar"
                />
            </div>
            {/* Card 1 */}
            <div className="qna-card-container">
                <h4 className="qna-card-title">
                    Does anyone have any helpful UX resources, they could share?
                </h4>
                <div className="qna-tags">
                    <div className="qna-tag-yellow">UX Resources</div>
                    <div className="qna-tag-grey">UX Design</div>
                    <div className="qna-interactions">
                        <img src={arrowup} className="arrow-up" alt="" />
                        <p className="number-of-upvotes">8</p>
                    </div>
                </div>
                <p className="qna-card-body">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores omnis officiis aperiam possimus eligendi, quidem
                    odio natus consequuntur, nobis modi quam repellendus?
                    Quibusdam, beatae! Maiores.
                </p>
                <div className="qna-card-footer">
                    <img src={avatar} className="qna-card-avatar" alt="" />
                    <p className="qna-avatar-name">
                        Posted by{' '}
                        <span className="qna-highlight">John Doe</span>
                    </p>
                    <p className="qna-card-responses">
                        <span className="qna-reply-highlight">Reply</span>
                    </p>
                </div>
            </div>
            <p className="qna-responses-answers">25 Responses</p>
            <div className="qna-answer-container">
                <p className="qna-answer-body">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores omnis officiis aperiam possimus eligendi, quidem
                    odio natus consequuntur, nobis modi quam repellendus?
                    Quibusdam, beatae! Maiores.
                </p>
                <div className="qna-answer-footer">
                    <img src={avatar} className="qna-card-avatar" alt="" />
                    <p className="qna-avatar-name">
                        Answered by{' '}
                        <span className="qna-reply-highlight">
                            John Doe | Instructor
                        </span>
                    </p>
                    <p className="number-of-upvotes-ans">8</p>
                    <img src={arrowup} className="arrow-up-ans" alt="" />
                </div>
                <hr className="qna-answer-divider" />
                <p className="qna-answer-body">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur, perferendis tempore recusandae quo illo sed doloribus repellat, voluptates voluptate facere quod. Eaque quis iure repellat, debitis praesentium neque officiis adipisci.
                </p>
                <div className="qna-answer-footer">
                    <p className="qna-avatar-name-reply">
                        Reply from{' '}
                        <span className="qna-reply-highlight">Hannah Doe</span>
                    </p>
                    <p className="number-of-upvotes-ans">0</p>
                    <img
                        src={arrowupgrey}
                        className="arrow-up-grey-ans"
                        alt=""
                    />
                </div>
            </div>
            <div className="qna-card-container">
                <p className="qna-answer-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque quam eum adipisci minima, odit quod laborum animi magnam rerum nihil eveniet tenetur aspernatur ratione in quis dolorem praesentium saepe ipsa laboriosam neque enim eaque possimus dolorum? Quam fuga possimus error!
                </p>
                <div className="qna-answer-footer">
                    <img src={avatar} className="qna-card-avatar" alt="" />
                    <p className="qna-avatar-name">
                        Reply from{' '}
                        <span className="qna-reply-highlight">Johnathan Doe</span>
                    </p>
                    <p className="number-of-upvotes-ans">0</p>
                    <img
                        src={arrowupgrey}
                        className="arrow-up-grey-ans"
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
