import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import 'antd/dist/antd.css'
import './courses.scss'
import { useInstructorData } from '../../../hooks'
import { CourseSecCont } from './courseCard/courseSecCont'
import { LessonSecCont } from './lessonCard/lessonSecCont'
import { ModuleSecCont } from './moduleCard/moduleSecCont'
import { QuizSecCont } from './quizCard/quizSecCont'

export const InstructorCourses = () => {
    const {
        state: { instructorID },
        getAllCourses,
        getAllModules,
        getAllLessons,
        getLanguages,
        getCourseCategory,
    } = useInstructorData()

    useEffect(() => {
        const getInstructorProducts = async () => {
            await getLanguages()
            await getCourseCategory()
            if (instructorID) {
                await getAllCourses(instructorID)
                await getAllModules(instructorID)
                await getAllLessons(instructorID)
            }
        }

        getInstructorProducts()
        // eslint-disable-next-line
    }, [instructorID])

    const items = [
        {
            key: '1',
            label: `Lessons`,
            children: <LessonSecCont />,
        },
        {
            key: '2',
            label: `Modules`,
            children: <ModuleSecCont />,
        },
        {
            key: '3',
            label: `Courses`,
            children: <CourseSecCont />,
        },
        {
            key: '4',
            label: `Quiz`,
            children: <QuizSecCont />,
        }
    ];

    return (
        <div id="myCourses">
            <div id="header">
                <h1>My Courses</h1>
            </div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}
