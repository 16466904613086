// import { useState } from 'react'
import { Tooltip } from 'antd'
// import { Twirl as Hamburger } from 'hamburger-react'
import { LogoutOutlined } from '@ant-design/icons'
import { Link, matchPath, useLocation, useHistory } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg'
import { ReactComponent as LogoText } from '../../../../assets/icons/logo_text.svg'
import './Menu.scss'

export const RouteMenu = (props) => {
    const { routeList, collapsed, logout, email } = props
    const { pathname } = useLocation()
    const history = useHistory()

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'space-between',
            }}
        >
            <div>
                <Link to="/">
                    {collapsed ? (
                        <Logo
                            style={{
                                width: '100%',
                                margin: 'auto',
                                padding: '4px',
                                marginTop: '12px',
                                marginBottom: '32px',
                            }}
                        />
                    ) : (
                        <LogoText
                            style={{
                                width: '100%',
                                margin: 'auto',
                                marginTop: '12px',
                                marginBottom: '32px',
                            }}
                        />
                    )}
                </Link>

                <div className="Dashboard__routeList">
                    {routeList.map((route) => {
                        const active = matchPath(pathname, route.match)?.isExact
                        return (
                            <Tooltip
                                // title={translateTitle(route.title)}
                                title={route.title}
                                placement="right"
                                key={route.title}
                            >
                                <div
                                    id={route.id}
                                    style={{ marginBottom: '8px' }}
                                >
                                    <Link to={route.path}>
                                        <div
                                            className={`Dashboard__MenuItem ${
                                                active ? 'current' : ''
                                            }`}
                                        >
                                            <img
                                                src={route.icon}
                                                alt={route.title}
                                                width="24px"
                                                height="24px"
                                            />
                                            {!collapsed && (
                                                <h4 className="Dashboard__MenuItem__title">
                                                    {/* {translateTitle(
                                                        route.title
                                                    )} */}
                                                    {route.title}
                                                </h4>
                                            )}
                                        </div>
                                    </Link>
                                </div>
                            </Tooltip>
                        )
                    })}
                </div>
            </div>
            <Tooltip
                // title={t('Log Out')}
                title="Log Out"
                placement="right"
            >
                <div style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <button
                        className="Dashboard__Logout"
                        onClick={() => {
                            history.replace('/account/login')
                            logout(email)
                        }}
                    >
                        <div className="Dashboard__MenuItem">
                            <LogoutOutlined />
                            {!collapsed && (
                                <h4 className="Dashboard__MenuItem__title">
                                    {/* {t('Log Out')} */}
                                    Log Out
                                </h4>
                            )}
                        </div>
                    </button>
                </div>
            </Tooltip>
        </div>
    )
}
