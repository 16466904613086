import axios from 'axios';
import { getAuthToken, clearStorage } from './helpers';

// Create a function to fetch the country code from ipapi and store it in localStorage
const fetchCountryCode = async () => {
    try {
        const response = await fetch('https://ipapi.co/json');
        const data = await response.json();
        localStorage.setItem('currentCountry', data.country_code); // Store country code in localStorage
        return data.country_code;
    } catch (error) {
        console.error('Error fetching country code:', error);
        throw error;
    }
};

// Initialize options for axios instance
const initAxiosInstance = async () => {
    // Get country code, either from localStorage or by fetching it from ipapi
    let countryCode = localStorage.getItem('currentCountry');

    if (!countryCode) {
        // If no country code is stored, fetch it and update localStorage
        countryCode = await fetchCountryCode();
    }

    const options = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            Accept: 'application/json,text/plain,*/*',
            'Content-Type': 'application/json',
            Country: countryCode,  // Add country code to headers
        },
    };

    const axiosInstance = axios.create(options);

    // Set up interceptors for request and response
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = getAuthToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            try {
                const errorData = error?.response;
                if (errorData?.status === 401) {
                    clearStorage();
                    window.location.reload();
                }
                return Promise.reject(error);
            } catch (err) {
                return Promise.reject(err);
            }
        }
    );

    return axiosInstance;
};

// Export the axios instance, ensuring it's created asynchronously with country code
export const request = (async () => await initAxiosInstance())();