import React from 'react'
// Style sheet is in parent folder ../courseCard.scss

export function ReviewCourse({ thumbnail, CTA }) {
    return (
        <div className="course__card">
            <div className="course__thumbnail">
                <img src={thumbnail} alt="thumbnail" />
            </div>
            <div className="course__details">
                <h3>AI Technology Masterclass</h3>
                <div>
                    <p>Published 01/02/23</p>
                    <button>In-review</button>
                </div>
            </div>
            <div className="course__numericsI">
                <div className="top">
                    <h3>8</h3>
                    <p>Modules</p>
                </div>
                <div className="bottom">
                    <h3>46</h3>
                    <p>Lessons</p>
                </div>
            </div>
            <div className="course__numericsII">
                <div className="top">
                    <h3>180</h3>
                    <p>Number of hours</p>
                </div>
                <div className="bottom">
                    <h3>Beginner</h3>
                    <p>difficulty level</p>
                </div>
            </div>
            <div className="course__status">
                <CTA
                    type="primary"
                    danger
                    style={{
                        borderRadius: '6px',
                        cursor: 'default'
                    }}
                > Locked
                </CTA>
            </div>
        </div>
    )
}