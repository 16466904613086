import {
    SET_QUIZ_TITLE,
    SET_QUIZ_QUESTION,
    SET_QUESTION_TYPE,
    SET_OPTIONA,
    SET_OPTIONB,
    SET_OPTIONC,
    SET_OPTIOND,
    SET_QUIZ_OPTIONS,
    SET_ANSWER,
    SET_QUIZ_MODULES,
    CLEAR_QUIZ_FORM
} from '../../actions/quizForm/types'

const INIT_STATE = {
    title: '',
    question: '',
    type: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    options: [],
    answer: 'Select question type...',
    appliedModule: null
}

export const quizFormReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_QUIZ_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case SET_QUIZ_QUESTION:
            return {
                ...state,
                question: action.payload
            }
        case SET_QUESTION_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case SET_OPTIONA:
            return {
                ...state,
                optionA: action.payload
            }
        case SET_OPTIONB:
            return {
                ...state,
                optionB: action.payload
            }
        case SET_OPTIONC:
            return {
                ...state,
                optionC: action.payload
            }
        case SET_OPTIOND:
            return {
                ...state,
                optionD: action.payload
            }
        case SET_QUIZ_OPTIONS:
            return {
                ...state,
                options: action.payload
            }
        case SET_ANSWER:
            return {
                ...state,
                answer: action.payload
            }
        case SET_QUIZ_MODULES:
            return {
                ...state,
                appliedModule: action.payload
            }
        case CLEAR_QUIZ_FORM:
            return {
                ...state,
                title: '',
                question: '',
                type: '',
                optionA: '',
                optionB: '',
                optionC: '',
                optionD: '',
                options: [],
                answer: 'Select quiz type...',
            }
            // return state
        default:
            return state
    }
}