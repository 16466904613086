import React, { Fragment } from 'react'
import { Modal, Button } from 'antd'
import './style.scss'

export const TermsConditionModal = ({ openTermsModal, setOpenTermsModal, setIsTermsAgreed }) => {
    // const handleOk = () => {
    //     setOpenTermsModal(false)
    //     setIsTermsAgreed(true)
    // }
    const handleCancel = () => {
        setIsTermsAgreed(false)
        setOpenTermsModal(false)
    }

    const handleTermsAgreement = () => {
        setIsTermsAgreed(true)
        setOpenTermsModal(false) // Close the modal after agreement
    }

    return (
        <Fragment>
            <Modal
                // title="Terms and Condition"
                open={openTermsModal}
                onOk={handleTermsAgreement}
                onCancel={handleCancel}
                footer=""
            >
                <div
                    id="termsConditionModal"
                    style={{
                        maxHeight: '300px',
                        maxWidth: '700px',
                        overflowY: 'auto',
                        borderRadius: '10px',
                    }}
                >
                    <h3 className="main--heading">
                        Terms and Condition for the Instructor Application
                        Process
                    </h3>
                    <div className="block--1">
                        <p>Terms and Condition of Use</p>
                        <p>Privacy Policy</p>
                        <p>Revenue Sharing and Refund Policy</p>
                    </div>
                    <br />
                    <h3 className="heading--2">Terms and Conditions of Use</h3>
                    <br />
                    <h4 className="last--updated">
                        Last Updated: 13th October, 2023.
                    </h4>
                    <br />
                    <p>
                        Please read these terms and conditions and privacy
                        policy carefully before submitting your application to
                        become an instructor in Zustech learning management
                        system. These Terms and Privacy Policy are a legally
                        binding agreement between you, the instructor and
                        Zustech LMS. By submitting your application to become an
                        instructor in Zustech learning management system, you
                        agree to abide by these Terms and Privacy Policy.
                    </p>
                    <h4 className="content--head">1.1 Eligibility</h4>
                    <p>
                        By using our instructor application, you present and
                        warrant that you're at least 18 years and have the legal
                        capacity to enter into this agreement.
                    </p>
                    <h4 className="content--head">1.2 Application Usage</h4>
                    <p>
                        You agree to use the instructor application for the sole
                        purpose of creating and instructing courses within our
                        LMS. You may not use the application for any other
                        purpose, including but not limited to sharing, selling,
                        or distributing your access or credentials.
                    </p>
                    <h4 className="content--head">1.3 Account Registration</h4>
                    <p>
                        You may be required to create an account to access the
                        instructor application. You agree to provide accurate,
                        current, and complete information during the
                        registration process and to update such information to
                        keep it accurate, current, and complete. You are
                        responsible for maintaining the confidentiality of your
                        account credentials and for all activities that occur
                        under your account.
                    </p>
                    <h4 className="content--head">1.4 Content</h4>
                    <p>
                        Ownership You retain ownership of all content you create
                        or upload to the LMS through the instructor application.
                        By using the application, you grant us a non-exclusive,
                        worldwide, royalty-free license to use, copy, reproduce,
                        modify, adapt, publish, and distribute your content for
                        the purpose of operating and improving the LMS. You
                        agree not to upload or share any content that infringes
                        upon the intellectual property rights of others or
                        violates applicable laws.
                    </p>
                    <h4 className="content--head">1.5 Prohibited Conduct</h4>
                    <p>
                        You agree not to: · Use our LMS for any illegal or
                        unauthorized purpose. · Transmit any viruses, malware,
                        or other malicious code. · Harass, threaten, or harm
                        other users or us in any way. · Interfere with or
                        disrupt the operation of the LMS. · Attempt to gain
                        unauthorized access to the LMS, other accounts, or
                        computer systems. · Engage in any conduct that violates
                        these Terms or applicable laws.
                    </p>
                    <h4 className="content--head">1.6 Termination</h4>
                    <p>
                        We reserve the right to suspend or terminate your access
                        to the instructor application at our sole discretion,
                        with or without cause, and without notice.
                    </p>
                    <h4 className="content--head">1.7 Modification</h4>
                    <p>
                        We may modify, suspend, or discontinue the instructor
                        application at any time without notice. We are not
                        liable to you or any third party for any such
                        modifications, suspensions, or discontinuations.
                    </p>
                    <h4 className="content--head">
                        2.1 Information Collection
                    </h4>
                    <p>
                        We may collect and store personal information about you,
                        including but not limited to your name, email address,
                        and course-related data. We use this information to
                        provide you with access to the instructor application
                        and to improve our services.
                    </p>
                    <h4 className="content--head">2.2 Information Sharing</h4>
                    <p>
                        We do not sell or rent your personal information to
                        third parties. We may share your information with
                        service providers who assist us in providing the
                        instructor application, but they are required to protect
                        your information.
                    </p>
                    <h4 className="content--head">2.3 Security</h4>
                    <p>
                        We take reasonable steps to protect your personal
                        information, but we cannot guarantee its security. You
                        are responsible for safeguarding your account
                        credentials.
                    </p>
                    <h4 className="content--head">2.4 Cookies</h4>
                    <p>
                        We may use cookies and similar technologies to collect
                        information about your use of the instructor
                        application. You can manage your cookie preferences
                        through your browser settings.
                    </p>
                    <h4 className="content--head">2.5 Third-Party</h4>
                    <p>
                        Links The instructor application may contain links to
                        third-party websites. We are not responsible for the
                        privacy practices or content of these websites.
                    </p>
                    <h4 className="content--head">
                        2.6 Changes to Privacy Policy
                    </h4>
                    <p>
                        We may update this Privacy Policy from time to time. Any
                        changes will be posted on this page, and the "Effective
                        Date" at the top will be updated.
                    </p>
                    <h4 className="content--head">2.7 Contact</h4>
                    <p>
                        If you have questions or concerns about our Privacy
                        Policy or how we handle your personal information,
                        please contact us at [Insert Contact Information].
                    </p>
                    <br />
                    <p>
                        By using the instructor application, you acknowledge
                        that you have read, understood, and agree to these Terms
                        and the Privacy Policy. If you do not agree with these
                        Terms or the Privacy Policy, please do not submit your
                        instructor application.
                    </p>
                    <br />
                    <h4 className="content--head">
                        End of Terms and Conditions / Privacy Policy
                    </h4>
                    <br />
                    <div
                        className="button--button"
                        // style={{
                        //     width: '50%',
                        // }}
                    >
                        <Button
                            style={{
                                background: '#3A86FF',
                                borderRadius: '32px',
                                color: 'white',
                                marginRight: '20px',
                            }}
                            onClick={handleTermsAgreement}
                        >
                            I Agree
                        </Button>
                        <Button
                            style={{
                                // background: '#3A86FF',
                                borderRadius: '32px',
                                // color: 'white',
                            }}
                            onClick={handleCancel}
                        >
                            I Decline
                        </Button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}
// <Button type="primary" onClick={showModal}>
//     Open Modal
// </Button>