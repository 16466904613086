import React, { useState } from 'react'
import { Form } from 'antd'
import './Input.scss'

export const Checkbox = ({
    children,
    className,
    label,
    validationRules,
    disabled,
    name,
    placeholder,
    boolean = true,
    defaultChecked,
    ...props
}) => {
    const [checked, setChecked] = useState(defaultChecked)
    const handleCheckboxChange = () => {
        setChecked(!checked)
    }
    return (
        <>
            <div>
                <Form.Item
                    name={name}
                    rules={validationRules}
                    valuePropName={boolean && 'checked'}
                >
                    <div className="form-check mb-0 text-left">
                        <input
                            type="checkbox"
                            className="form-check-input my-0 "
                            id={name}
                            checked={checked}
                            onChange={handleCheckboxChange}
                            {...props}
                        />
                        {label && (
                            <label
                                className="form-check-label pb-0"
                                htmlFor={name}
                            >
                                {label}
                            </label>
                        )}
                    </div>
                </Form.Item>
            </div>
        </>
    )
}

export const CheckboxOption = ({
    children,
    className,
    label,
    validationRules,
    disabled,
    name,
    value,
    placeholder,
    ...props
}) => {
    return (
        <div className="form-check mb-4 text-left">
            <input
                type="checkbox"
                className="form-check-input my-0 "
                id={name}
                value={value}
                {...props}
            />
            {label && (
                <label className="form-check-label pb-0" htmlFor={name}>
                    {label}
                </label>
            )}
        </div>
    )
}
