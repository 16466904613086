import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import axios from 'axios'
import { CreateCourse } from '../CreateCourses/createCourse'
import { CreateLesson } from '../CreateLesson/createLesson'
import { CreateModule } from '../CreateModule/createModule'
import { useInstructorData } from '../../../../hooks'
// STYLES ARE IN /instructorDashboard/CreateEditCourses/styles.scss

export const CollapsedLesson = () => {
    const {
        state: { lessons }
    } = useInstructorData()
    return (
        <div className="product__summary">
            <p> {lessons.length} Lessons </p>
            <div className="button__like">
                edit
            </div>
        </div>
    )
}

export const CollapsedModule = () => {
    const {
        state: { modules }
    } = useInstructorData()

    return (
        <div className="product__summary">
            <p> {modules.length} Modules </p>
            <div className="button__like">
                edit
            </div>
        </div>
    )
}

export function Allinone() {
    const {
        state: { instructorID },
        getAllCourses,
        getAllModules,
        getAllLessons,
    } = useInstructorData()

    const [showLessonComp, setShowLessComp] = useState(true)
    const [showModuleComp, setShowModuleComp] = useState(true)
    // const [showCourseComp, setShowCourseComp] = useState(true)
    // const [openModal, setOpenModal] = useState(false)

    function toggleLessonForm() {
        const inverse = !showLessonComp
        setShowLessComp(inverse)
    }

    function toggleModuleForm() {
        const inverse = !showModuleComp
        setShowModuleComp(inverse)
    }

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            if (instructorID !== '') {
                await getAllCourses(instructorID, source.token)
                await getAllLessons(instructorID, source.token)
                await getAllModules(instructorID)
            }
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    return (
        <div>
            <h2
                style={{
                    fontSize: '28px',
                    color: '#3a86ff'
                }}
            >Create Courses
            </h2>
            <div className="form__wrapper">
                <div id="lesson_section">
                    {!showLessonComp && <div> <CollapsedLesson /> </div>}
                    {showLessonComp && <CreateLesson />}
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBlock: '2em 5em'
                        }}
                    >
                        <Button
                            onClick={() => toggleLessonForm()}
                            type="primary"
                            style={{
                                width: '200px',
                                height: '100%',
                                borderRadius: '8px',
                                fontSize: '15px',
                                fontWeight: '600',
                            }}
                        >{showLessonComp ? 'Save and Close' : 'Add more lessons'}
                        </Button>
                    </div>
                </div>
                <div id="module_section">
                    {!showModuleComp && <div> <CollapsedModule /> </div>}
                    {showModuleComp && <CreateModule />}
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBlock: '2em 5em'
                        }}
                    >
                        <Button
                            onClick={() => toggleModuleForm()}
                            type="primary"
                            style={{
                                width: '200px',
                                height: '100%',
                                borderRadius: '8px',
                                fontSize: '15px',
                                fontWeight: '600',
                            }}
                        >{showModuleComp ? 'Save and Close' : 'Add more modules'}
                        </Button>
                    </div>
                </div>
                <div id="course_section">
                    <CreateCourse />
                </div>
            </div>
        </div>
    )
}
