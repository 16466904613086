import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from 'antd'
import { useInterface, useAuth } from '../../../../../hooks'
import { openNotification } from '../../../../../utils'
import {
    findQuizByModuleId,
    startQuiz,
    endQuiz,
    findQuizByCourseId
} from '../../../../../services'
import QuestionInterface from './QuestionInterface'
import Arrow_left from '../../../../../assets/icons/arrow-left.svg'
import Celebrate from '../../../../../assets/icons/undraw_Celebration.svg'
import Dobetter from '../../../../../assets/icons/undraw_startup_life.svg'
import './quizScreen.scss'

export default function QuizScreen() {
    const {
        state: { moduleId, courseId, quizType, commenceQuiz, selectedOptions, quizCompleted, quizScore },
        setQuizMode,
        beginQuiz,
        isQuizCompleted,
        setQuizScore,
        setSelectedAnswer,
        clearAll
    } = useInterface()
    const {
        state: { data }
    } = useAuth()
    const location = useLocation()
    const currentUrl = location.pathname
    const [quizName, setQuizName] = useState('')
    const [quizId, setQuizId] = useState('')
    const [questionNo, setQuestionNo] = useState('')
    const [isPreviewPage, setIsPreviewPage] = useState()
    const [quizQuestions, setQuizQuestions] = useState([])
    const [questionState, setQuestionState] = useState(0)
    const [completionData, setCompletionData] = useState(null)
    // const [courseQuiz, setCourseQuiz] = useState({})

    useEffect(() => {
        const paths = currentUrl.split('/')
        for (let i = 0; i < paths.length; i++) {
            if (paths[i] === 'preview-quiz') {
                setIsPreviewPage(true)
                break
            } else {
                setIsPreviewPage(false)
            }
        }
    }, [currentUrl])

    useEffect(() => {
        const getCourseQuiz = async (courseId) => {
            try {
                const res = await findQuizByCourseId(courseId)
                if (res.data.length >= 1) {
                    const quiz = res.data.sort((a, b) => a.id - b.id).pop()
                    setQuizName(quiz.title)
                    setQuizId(quiz.id)
                    setQuestionNo(quiz.totalQuestions)
                }
                // setCourseQuiz(res.data[0])
                // setCourseHasQuiz(true)
            } catch (error) {
                console.error(error.message)
            }
        }
        const getModuleQuiz = async (modId) => {
            try {
                const res = await findQuizByModuleId(modId)
                if (res.data.length >= 1) {
                    // Quiz is the latest created
                    const quiz = res.data.sort((a, b) => a.id - b.id).pop()
                    setQuizName(quiz.title)
                    setQuizId(quiz.id)
                    setQuestionNo(quiz.totalQuestions)
                }
            } catch (err) {
                console.error(err.message)
            }
        }
        if (!isPreviewPage) {
            if (quizType === 'Module') {
                getModuleQuiz(moduleId)
            } else if (quizType === 'Course') {
                getCourseQuiz(courseId)
            }
        }
    }, [isPreviewPage, quizType, moduleId, courseId])

    useEffect(() => {
        const loadQuestfromStore = () => {
            const quizInfo = JSON.parse(localStorage.getItem('QuizInfo'))
            const savedQuestions = JSON.parse(localStorage.getItem('QuestStore'))
            const passedQuest = []
            if (quizInfo !== null && savedQuestions !== null) {
                const numQuest = quizInfo.question.length
                for (let i = 0; i < numQuest; i++) {
                    for (let j = 0; j < savedQuestions.length; j++) {
                        if (quizInfo.question[i].id === savedQuestions[j].id) {
                            const questObj = { ...savedQuestions[j] }
                            questObj['correctAns'] = quizInfo.question[i].answer
                            passedQuest.push(questObj)
                        }
                    }
                }
                shuffleArray(passedQuest)
                setQuizQuestions(passedQuest)
                setQuizName(quizInfo.quizTitle)
                setQuestionNo(numQuest)
            }
        }

        loadQuestfromStore()
    }, [isPreviewPage])

    useEffect(() => {
        if (quizCompleted && quizScore !== null) {
            if (quizScore >= 60) {
                setCompletionData({
                    'img': Celebrate,
                    'mainText': 'Excellent. Well done!!',
                    'subText': null,
                    'grade': quizScore,
                    'redirect': null
                })
            } else if (quizScore < 60 && quizScore >= 21) {
                setCompletionData({
                    'img': Celebrate,
                    'mainText': 'Good Work',
                    'subText': 'There is room for improvement',
                    'grade': quizScore,
                    'redirect': 'Retry'
                })
            } else {
                setCompletionData({
                    'img': Dobetter,
                    'mainText': 'Not good enough',
                    'subText': 'It will be best for you to relearn the lesson',
                    'grade': quizScore,
                    'redirect': 'Relearn and try again'
                })
            }
        }
    }, [quizScore, quizCompleted])

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
    }

    const getQuestions = async (userId, testId) => {
        try {
            const requestObj = {
                'testId': testId,
                'userId': userId
            }
            const res = await startQuiz(requestObj)
            if (res.statusCode !== 400) {
                shuffleArray(res.data.testQuestions)
                setQuizQuestions(res.data.testQuestions)
            } else {
                throw new Error(res.message)
            }
        } catch (err) {
            openNotification({
                type: 'error',
                title: 'Fetch quiz questions',
                message: err.message
            })
            beginQuiz(false)
            console.error(err.message)
        }
    }

    const submitQuizResponse = async (userId, testId, inp) => {
        try {
            const requestData = {
                'testid': testId,
                'userId': userId,
                'testQuestions': inp
            }
            const res = await endQuiz(requestData)
            if (res.data !== null) {
                isQuizCompleted(true)
                const scoreInPercent = ((res.data.correctScore / res.data.totalScore) * 100)
                setQuizScore(scoreInPercent)
                beginQuiz(false)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    return (
        <div className="quizCont">
            <section className="quiz_header">
                <div className="back_btn">
                    <div
                        className="img_cont"
                        onClick={() => {
                            if (commenceQuiz) {
                                // check if question is the first one to be answered
                                if (questionState === 0) {
                                    beginQuiz(false)
                                } else {
                                    setQuestionState(prev => prev - 1)
                                }
                            } else {
                                setQuizMode(false)
                            }
                        }}
                    >
                        <img src={Arrow_left} alt="arrow_icon" />
                    </div>
                    <p>Back</p>
                </div>
                {commenceQuiz &&
                <div className="quizInfo">
                    <section className="top">{quizName}</section>
                    <section className="bottom">
                        <div className="questionNum_indicator">{`${questionState + 1}/${quizQuestions.length}`}</div>
                        <div className="questionPoint">5 marks</div>
                    </section>
                </div>}
                {quizCompleted &&
                <div className="quizScore_header">
                    <h3 className="top">{quizName}</h3>
                </div>}
            </section>
            <main className="quizInfo">
                {(!commenceQuiz && !quizCompleted) &&
                <>
                    <h1>{quizName}</h1>
                    <div className="duration_et_num">
                        <p>{questionNo} Questions</p>
                        <p>10mins</p>
                    </div>
                    <p className="info">Get more than 60% or above to pass</p>
                    <div className="CTA">
                        <Button
                            type="primary"
                            onClick={() => {
                                if (!isPreviewPage) {
                                    getQuestions(data.id, quizId)
                                }
                                beginQuiz(true)
                                // fetch questions and info the backside that student it taking a course
                                // question mode
                                // get all answers and store dem
                            }}
                        >{isPreviewPage ? 'Start Preview' : 'Take Quiz'}
                        </Button>
                    </div>
                </>}
                {(commenceQuiz && quizQuestions.length >= 1) &&
                <>
                    <QuestionInterface
                        question={quizQuestions[questionState]}
                        questionNo={questionState}
                        showAnswer={isPreviewPage}
                    />
                    <div className="nav_btn_cont">
                        <Button
                            onClick={() => {
                                setQuestionState((prev) => prev - 1)
                            }}
                            type="primary"
                            disabled={questionState === 0}
                        >Previous
                        </Button>
                        <Button
                            onClick={() => {
                                if (quizQuestions.length === (questionState + 1)) {
                                    // hshsh
                                    if (!isPreviewPage) {
                                        submitQuizResponse(data.id, quizId, selectedOptions)
                                    } else {
                                        openNotification({
                                            title: 'Quiz Submission',
                                            message: 'User Provided No Response',
                                            type: 'error'
                                        })
                                    }
                                } else {
                                    setQuestionState((prev) => prev + 1)
                                    setSelectedAnswer('')
                                }
                            }}
                            type="primary"
                        >{quizQuestions.length === (questionState + 1) ? 'Submit' : 'Next'}
                        </Button>
                    </div>
                </>}
                {(quizCompleted && completionData !== null) &&
                <>
                    <section className="scoreInfo">
                        <div className="img_cont">
                            <img src={completionData.img} alt="" />
                        </div>
                        <div className="mainText">
                            <h1>{completionData.mainText}</h1>
                            {completionData.subText !== null && <h3 className="subText">{completionData.subText}</h3>}
                        </div>
                        <div className="gradeInfo">
                            <span>Grade:</span><span className="score">{`${completionData.grade}%`}</span>
                        </div>
                        <div className="CTA">
                            {completionData.redirect !== null &&
                            <div
                                className="retryLink"
                                onClick={() => {
                                    beginQuiz(false)
                                }}
                            >{completionData.redirect}
                            </div>}
                            <Button
                                type="primary"
                                onClick={() => {
                                    clearAll()
                                }}
                            >Continue Learning
                            </Button>
                        </div>
                    </section>
                </>}
            </main>
        </div>
    )
}
