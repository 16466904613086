import {
    SET_TOPIC,
    SET_TITLE,
    SET_LESSON_LANGUAGE,
    SET_DESCRIPTION,
    SET_MEDIA,
    SET_DOCUMENT,
    REMOVE_DOCUMENT,
    CLEAR_LESSON_FORM
} from '../../actions/lessonForm/types'

const INIT_STATE = {
    topic: '',
    title: '',
    language: '',
    description: '',
    media: {},
    document: [],
}

export const lessonReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case SET_TOPIC:
            return {
                ...state,
                topic: action.payload
            }
        case SET_LESSON_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        case SET_MEDIA:
            return {
                ...state,
                media: action.payload
            }
        case SET_DOCUMENT:
            return {
                ...state,
                document: [...state.document, action.payload]
            }
        case REMOVE_DOCUMENT:
            return {
                ...state,
                document: state.document.filter(file => file.file.name !== action.payload)
            }
        case CLEAR_LESSON_FORM:
            return {
                ...state,
                topic: null,
                title: null,
                language: null,
                description: null,
                media: {},
                document: [],
            }
        default:
            return state;
    }
}