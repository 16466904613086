import React from 'react'
import { Link } from 'react-router-dom'
import CelebrationImage from '../assets/undraw_Celebration_re_kc9k 1.png'
import './style.scss'

const OnboardSuccessPage = () => {
    return (
        <div className="onboard--success--container">
            <img src={CelebrationImage} className="celebration--logo" alt="celebration" />
            <h4 className="header--text">Congratulations!</h4>
            <p className="text--1">Your application has been approved.</p>
            <p className="text--2">Start creating your courses</p>
            <Link to="/tutor/courses">
                <button id="createCourse" className="btn--create--course">Create course</button>
            </Link>
        </div>
    )
}

export default OnboardSuccessPage
