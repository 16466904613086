export const gettingStartedFAQs = [
    {
        question: 'Zustech: Who are we?',
        response:
            'At Zustech, we are committed to revolutionizing education through our comprehensive training services and state-of-the-art Learning Management System (LMS). Our mission is to empower learners and educators by providing accessible, flexible, and user-friendly educational solutions. We offer a wide range of training services tailored to meet the needs of diverse learners and instructors. Whether you are looking to develop new skills, enhance your knowledge, or create engaging educational content, we have the resources and expertise to support you. Our LMS is designed to provide a seamless educational experience for both learners and instructors. With robust features and an intuitive interface, our platform facilitates effective learning and teaching.',
    },
    {
        question: 'How do I sign up?',
        response:
            'Sign Up by clicking on the ‘Sign up’ button at the top right of the home page. Enter your First name, Last name, your email address and password. Do well to read our terms and conditions and click on the tab picker to confirm that you agree with our terms and conditions and privacy policy. You can also opt-in to our insightful marketing emails. Then, proceed to click on the ‘Sign up’ button.',
    },
    {
        question: 'How do I sign in?',
        response:
            "On the home page, click on the Sign in button at the top right of the home page. Enter your username and password Click on the 'Sign in.' button.",
    },
    {
        question: 'Can I access Zustech on my mobile device?',
        response: 'Yes, Zustech has a mobile responsive website.',
    },
    {
        question: 'How do I contact Zustech support?',
        response:
            'To access our support, Go to the contact us page, Before the page footer, you will see, you can contact us here for more assistance Click on the here link to be directed to the support page. You will be required to fill in our contact us form. Fill out the form with · Name · Email address · Contact Number (optional) · Message (A detailed description of the issue) · Submit the form and wait for a response from the support team. Our support teams aim to respond within 48 - 72 hours (about 3 days).',
    },
]

export const accountsProfileFAQs = [
    {
        question: 'How do I update my profile information?',
        response:
            "To update your profile information, follow these steps: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Then Select 'Profile.' 4. Make the necessary changes and click 'Save changes.'",
    },
    {
        question: 'How do I change my password?',
        response:
            "To change your password: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Select 'Account info.' 4. Then, enter your current password, then your new password, and confirm the new password. 5. Click 'change password.'",
    },
    {
        question: 'I forgot my password. How do I reset it?',
        response:
            "If you forgot your password: 1. Go to the login page. 2. Click on 'Forgot Password?' 3. Enter your registered email address. 4. Follow the instructions sent to your email to reset your password.",
    },
    {
        question: 'How do I add or change my profile picture?',
        response:
            "To add or change your profile picture: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Then Select 'Profile.' 4. Click on your current profile picture and upload a new image. 5. Click on 'Save changes.'",
    },
    {
        question: 'Can I connect my social media accounts to my profile?',
        response:
            "At the moment, this feature is not yet ready. It's one of the many features we will be updating on our site soon.",
    },
    {
        question: 'How do I manage notifications?',
        response:
            "To manage your notifications: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Select 'Notification.' 4. Customize your notification preferences and click 'Update notifications.'",
    },
    {
        question: 'How do I close/deactivate my account?',
        response:
            "To close/deactivate your account: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Select 'close account.' 4. Enter your email address and click on 'close account.' 5. Follow the instructions to close the account.",
    },
    {
        question: 'How can I change my language preference?',
        response:
            "To change your language preference: 1. Log in to your account. 2. On the dashboard, click on the setting sections. 3. Select 'language preference.' 4. Choose your preferred language and click 'Save.'",
    },
]

export const subscriptionAndBillingFAQs = [
    {
        question: 'How do I subscribe to a course?',
        response:
            "To subscribe to a course, follow these steps: 1. Log in to your account. 2. Browse or search for the course you are interested in on the home page and from the courses page. 3. Click on the course to view its details. 4. Click the 'Subscribe' button. 5. Follow the prompts to complete the payment process.",
    },
    {
        question: 'How do I Enroll and pay for a One-Time Purchase course?',
        response:
            "To Enroll to a one-time purchase course, follow these steps: 1. Log in to your account. 2. Browse or search for the course you are interested in on the home page and from the courses page (under one-time purchase course). 3. Click on the course to view its details. 4. Click the 'Buy Now' button. 5. Follow the prompts to complete the payment process.",
    },
    {
        question: 'What payment methods are accepted?',
        response:
            'We accept the following payment methods: - Credit/Debit Cards (Visa, MasterCard, American Express) - Stripe - Paystack',
    },
    {
        question: 'How can I view my billing/payment history?',
        response:
            "To view your billing/payment history: 1. Log in to your account. 2. On the dashboard, click on the Subscription section. 3. Click on 'Payment History.' 4. You will see a list of all your past transactions.",
    },
    {
        question: 'How do I cancel my subscription?',
        response:
            "To cancel your subscription: 1. Log in to your account. 2. On the dashboard, click on the Subscription section. (This can also be achieved on the pricing page.) 3. Under the Active plan Title, click on 'Click here to extend or change your plan.' 4. Click on 'Cancel plan' at the bottom of the dialog box and follow the prompts to cancel subscription.",
    },
    {
        question: 'How do I switch to a different subscription plan?',
        response:
            "To switch to a different subscription plan: 1. Log in to your account. 2. On the dashboard, click on the Subscription section. (This can also be achieved on the pricing page.) 3. Under the Active plan Title, click on 'Change plan.' 4. Choose the plan you want to switch to and follow the prompts to confirm.",
    },
    {
        question: 'What should I do if my payment fails?',
        response:
            'If your payment fails: 1. Ensure that your payment details are correct. 2. Check with your bank or payment provider to make sure there are no issues with your account. 3. Try an alternative payment method. 4. Contact our support team if the problem persists.',
    },
    {
        question: 'Will I get a refund if I cancel my subscription?',
        response:
            'Refunds depend on our refund policy: - Please review our full refund policy in the terms and conditions.',
    },
    {
        question: 'How do I apply a discount code or coupon?',
        response:
            "To apply a discount code or coupon: 1. Add the course to your cart. 2. Go to the checkout page. 3. Enter your discount code or coupon in the designated field. 4. Click 'Apply' and the discount will be reflected in your total.",
    },
]

export const takeOurCoursesFAQs = [
    {
        question: 'How do I enroll in a course?',
        response:
            "To enroll in a course, follow these simple steps: 1. Log in to your LMS account. 2. Browse the course page or use the search bar to find your desired course. 3. Click on the course to view details on the course overview page. 4. Click the 'Subscribe' button. 5. Follow the prompts to complete payment so as to enroll in the course.",
    },
    {
        question: 'What are the prerequisites for enrolling in a course?',
        response:
            'Each course has its own set of prerequisites, which you can find in the course description. Be sure to review these requirements before enrolling to ensure you’re prepared.',
    },
    {
        question: 'How do I access my course materials?',
        response:
            "Once enrolled, access your course materials by: 1. Logging in to your LMS account. 2. Navigating to 'Enrolled Courses' from the dashboard. 3. Select the course you’re enrolled in. 4. On the Course Overview page, all course materials, including lectures and readings, will be available there.",
    },
    {
        question: 'Can I take multiple courses at the same time?',
        response:
            'Yes, if you have a multi-course subscription, you can enroll in multiple courses simultaneously that are available under the multi-subscription courses. Make sure to manage your time effectively to meet the demands of each course.',
    },
    {
        question: 'How long do I have to complete a course?',
        response:
            'The duration of each course varies. Check the course description for specific timeframes. Most of the courses are self-paced, while others have set schedules with deadlines.',
    },
    {
        question: 'What if I need help during the course?',
        response:
            "If you need assistance: - Use the course discussion forums to ask questions and interact with peers and instructors. - Visit the 'Help' section for additional resources and support options.",
    },
    {
        question: 'Will I receive a certificate upon course completion?',
        response:
            'Many courses offer certificates upon successful completion. Check the course details to see if a certificate is available and the criteria for earning it.',
    },
    {
        question: 'How do I track my progress in the course?',
        response:
            "Track your progress by: - Navigating to 'Enrolled Courses' from the dashboard. - Select the course and on the course overview page, view the progress made so far on the course.",
    },
    {
        question: 'Can I drop or withdraw from a course?',
        response: 'Yes, you can drop or withdraw from a course if necessary.',
    },
    {
        question: 'What technical requirements are needed to take a course?',
        response:
            'To ensure a smooth learning experience, you’ll need: - A reliable internet connection. - A modern web browser (Chrome, Firefox, Safari, Edge). - Necessary software or plugins, as specified in the course description.',
    },
    {
        question: 'How do I provide feedback about a course?',
        response:
            'Your feedback is valuable! After completing a course, you can: - Rate and review the course. - Contact support with any additional comments or suggestions.',
    },
    {
        question: 'What if I encounter technical issues?',
        response:
            "For technical issues: - Check the 'Contact Us' page for troubleshooting tips. - Make sure your system meets the technical requirements.",
    },
]

export const troubleshootingFAQs = [
    {
        question: 'I can’t log in to the LMS. What should I do?',
        response:
            "Check Your Credentials: Ensure you are using the correct username and password. Remember, they are case-sensitive. Reset Your Password: If you’ve forgotten your password, use the 'Forgot Password' link on the login page to reset it. Browser Issues: Clear your browser cache and cookies or try using a different browser. Account Lockout: After multiple failed login attempts, your account may be locked. Wait 15 minutes and try again.",
    },
    {
        question: 'The page is not loading correctly. What can I do?',
        response:
            'Refresh the Page: Sometimes a simple refresh can resolve loading issues. Clear Cache and Cookies: Clearing your browser’s cache and cookies can solve many page load problems. Different Browser: Some browsers may have compatibility issues. We recommend using the latest version of Chrome or Firefox. Disable Extensions: Browser extensions can sometimes interfere. Try disabling them temporarily.',
    },
    {
        question: 'I can’t access my course materials.',
        response:
            "Check Enrollment: Ensure you are properly enrolled on the course. Course Availability: Confirm that the course is currently active and not yet concluded. Browser Compatibility: Use a supported browser and clear cache and cookies. Contact Support: If you can't resolve the issue, contact support and provide the exact error message for further assistance.",
    },
    {
        question: 'My video/audio content won’t play.',
        response:
            'Update Browser: Ensure your browser is up to date. Check Internet Connection: A stable internet connection is required for streaming content. Disable Ad Blockers: Ad blockers can interfere with media playback. Enable Flash: Some content may require Flash. Ensure it is enabled in your browser settings. Try a Different Device: If possible, try accessing the content on another device. Lower Video Quality: If the video is buffering, try lowering the video quality to reduce buffering time.',
    },
    {
        question: 'I am not receiving any course notifications.',
        response:
            'Check Notification Settings: Ensure notifications are enabled in your LMS settings. Verify Email Address: Confirm that your email address is correct and up to date. Check Spam/Junk Folder: Sometimes, notifications may end up in these folders. Email Filters: Ensure that email filters are not blocking LMS emails.',
    },
    {
        question: 'I’m experiencing slow performance or lag.',
        response:
            'Close Unnecessary Tabs/Apps: Having too many tabs or applications open can slow down performance. Check Internet Speed: Ensure your internet connection is fast and stable. Clear Cache: Regularly clear your browser cache to improve performance. Restart Your Device: Sometimes, a simple restart can help improve performance. Update Software: Keep your browser and operating system updated.',
    },
    {
        question: 'I am encountering error messages. What should I do?',
        response:
            "Read the Error Message: Often, the error message will provide clues about what went wrong. Clear Cache and Cookies: This can often resolve many types of error messages. Try a Different Browser: Sometimes errors are browser specific. Contact Support: If you can't resolve the issue, contact support and provide the exact error message for further assistance.",
    },
]
