// import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    setUserLocation
} from '../store/actions/userLocation'

export const useLocation = () => {
    const dispatch = useDispatch()
    const state = useSelector(({ userLocation }) => userLocation)

    const addUserLocation = (inp) => {
        dispatch(setUserLocation(inp))
    }

    return {
        state,
        addUserLocation,
    }
}