import ReactDOM from 'react-dom'
import React from 'react'
import App from './App'

import './index.scss'
// import i18n:
import './services/i18n'
import { CartState } from './context/Cart/cartState'

ReactDOM.render(
    // <React.StrictMode>
    <CartState>
        <App />
    </CartState>,
    // </React.StrictMode>,
    document.getElementById('root')
)
