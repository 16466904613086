import React, { useState } from 'react'
import { Rate } from 'antd';
import Thumb_up from '../../../../../assets/icons/black_thumb_up.svg'
import Thumb_down from '../../../../../assets/icons/black_thumb_down.svg'
import Profile from '../../../../../assets/icons/Circular_DP.svg'

export function ReviewComment({ commentObj }) {
    const [thumbUp, setThumbUp] = useState(0)
    const [thumbDown, setThumbDown] = useState(0)

    return (
        <div className="user_comment_cont">
            <div className="comment_header">
                <div className="img_cont">
                    <img src={commentObj.profilePictureUrl === null ? Profile : commentObj.profilePictureUrl} alt="Profile Pic" />
                </div>
                <h3>{commentObj.name}</h3>
            </div>
            <div className="rate">
                <Rate
                    disabled
                    value={commentObj.rating}
                    style={{
                        'fontSize': '.7em'
                    }}
                />
            </div>
            <p>{commentObj.comment}</p>
            <div className="report">
                <div className="review">
                    <p>Was this review helpful</p>
                    <div className="thumbs">
                        <div className="thumb_up">
                            <div
                                className="img_cont"
                                onClick={() => setThumbUp(prev => prev + 1)}
                            >
                                <img src={Thumb_up} alt="thumb_up" />
                            </div>
                            <span>{thumbUp}</span>
                        </div>
                        <div className="thumb_down">
                            <div
                                className="img_cont"
                                onClick={() => setThumbDown(prev => prev + 1)}
                            >
                                <img src={Thumb_down} alt="thumb_down" />
                            </div>
                            <span>{thumbDown}</span>
                        </div>
                    </div>
                </div>
                <p>Report</p>
            </div>
        </div>
    )
}
