import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import { Modal } from 'antd'

import {
    // Input,
    Button
} from '../../../../components'
import {
    DeleteLessonById,
    deleteCourse,
    deleteModule,
    findCourseById,
    rejectInstructorCourse,
    // approveInstructorCourse,
    // publishCourse,
    approveCourse,
    setCourseReview,
} from '../../../../services'
import { useAuth } from '../../../../hooks'
import { openNotification } from '../../../../utils/helpers'
import Overview from './component/Overview'
import CourseContent from './component/CourseContent'
import ScrollButton from './component/ScrollButton'
import Feedback from './component/Feedback'
import './styles.scss'

export const CourseReview = () => {
    const {
        state: { data },
    } = useAuth()

    const feedbackRef = useRef()
    // State for the current input value
    const [inputValue, setInputValue] = useState('')
    const [listOfComments, setListOfComments] = useState([])
    // const [comment, setComment] = useState('')
    const [course, setCourse] = useState([])
    const [courseContent, setCourseContent] = useState([])
    console.log(courseContent)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    console.log(loading)

    const history = useHistory()
    const courseIdAddress = window.location.pathname
    const courseId = courseIdAddress.split('/').pop()

    const [dropdownId, setDropdownId] = useState(null)
    const [dropdownName, setDropdownName] = useState('')
    const [rerenderTrigger, setRerenderTrigger] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        setConfirmLoading(!confirmLoading)
        setTimeout(() => {
            setVisible(false)
            setConfirmLoading(false)
        }, 2000)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleDeletion = (dropdownClicked, id) => {
        switch (dropdownClicked) {
            case 'module':
                handleDeleteModule(id)
                setRerenderTrigger(true)
                setVisible(false)
                break
            case 'lesson':
                handleDeleteLesson(id)
                setRerenderTrigger(true)
                setVisible(false)
                break
            case 'course':
                handleDeleteCourse(id)
                setRerenderTrigger(true)
                setVisible(false)
                setTimeout(() => {
                    history.push('/admin/courses')
                }, 500)
                break
            default:
                setDropdownId(null)
                setDropdownName('')
                setRerenderTrigger(false)
                break
        }
    }

    useEffect(() => {
        const getCourseById = async () => {
            try {
                const response = await findCourseById(courseId)
                setCourse(response.data.courseModel.courseInfor)
                setCourseContent(response.data.courseContent)
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
            setLoading(false)
        }
        if (rerenderTrigger === true) {
            setRerenderTrigger(false)
        }
        getCourseById()
    }, [courseId, rerenderTrigger])

    const handleDeleteLesson = async (lessonId) => {
        try {
            const response = await DeleteLessonById(lessonId)

            openNotification({
                type: 'Success',
                title: 'Deleted lesson',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        setLoading(false)
    }

    const handleDeleteCourse = async (courseId) => {
        try {
            const response = await deleteCourse(courseId)
            openNotification({
                type: 'Success',
                title: 'Deleted Course',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        setLoading(false)
    }

    const handleDeleteModule = async (moduleId) => {
        try {
            const response = await deleteModule(moduleId)
            openNotification({
                type: 'Success',
                title: 'Deleted Module',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        setLoading(false)
    }

    const ApproveCourse = async (id) => {
        try {
            const response = await approveCourse(id)
            console.log(response)
            openNotification({
                type: 'success',
                title: 'Course is Approved!',
                message: 'Successfully approved course.',
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Approve Course',
                message: error.message,
            })
        }
    }

    const rejectCourse = async () => {
        try {
            const response = await rejectInstructorCourse({
                id: JSON.parse(courseId),
                courseApproval: 1,
            })
            console.log(response.message)
            openNotification({
                type: 'success',
                title: 'Reject Course',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Reject Course',
                message: error.message,
            })
        }
    }
    const sendFeedback = async () => {
        const userId = data.id
        // Join all comments into one string separated by a comma and a space
        const joinedComments = listOfComments.join(', ')
        try {
            const response = await setCourseReview({
                courseId: JSON.parse(courseId),
                userId: userId,
                comment: joinedComments,
                rating: 2,
                isAdmin: true,
            })
            console.log(response)
            console.log(joinedComments)
            openNotification({
                type: 'success',
                title: 'Review Feedback',
                message: 'Feedback sent successfully!',
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Review Feedback',
                message: error.message,
            })
        }
    }

    const handleApprove = async (e) => {
        e.preventDefault()
        await ApproveCourse(courseId)
        history.push('/admin/courses')
    }

    const handleRejectAndSendFeedback = async (e) => {
        e.preventDefault()
        await rejectCourse()
        await sendFeedback()
        history.push('/admin/courses')
    }

    const handleSaveAndExit = (e) => {
        e.preventDefault()
        history.push('/admin/courses')
    }

    return (
        <div>
            <ScrollToTop />
            <CourseContent />
            <Overview course={course} />
            <Modal
                open={visible}
                title="Delete Course"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        onClick={() => handleDeletion(dropdownName, dropdownId)}
                        variant="deletion"
                        key="delete"
                    >
                        Delete
                    </Button>,
                    <Button onClick={handleCancel} key="cancel">
                        Cancel
                    </Button>,
                ]}
            >
                <p>Are you sure you want to delete this course?</p>
            </Modal>

            <Feedback
                feedbackRef={feedbackRef}
                inputValue={inputValue}
                setInputValue={setInputValue}
                listOfComments={listOfComments}
                setListOfComments={setListOfComments}
            />

            <div
                className="submitButtons"
                style={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                    }}
                >
                    <Button
                        onClick={showModal}
                        style={{
                            background: 'white',
                            width: '200px',
                            color: '#EB5757',
                            border: '2px solid #EB5757',
                        }}
                    >
                        Delete Course
                    </Button>
                    <Button
                        onClick={handleSaveAndExit}
                        style={{
                            background: 'white',
                            width: '200px',
                            color: '#3A86FF',
                        }}
                    >
                        Save and exit
                    </Button>
                    <Button
                        onClick={handleRejectAndSendFeedback}
                        style={{
                            background: '#EB5757',
                            width: '200px',
                            border: '2px solid #EB5757',
                        }}
                    >
                        Reject and send feedback
                    </Button>
                </div>

                <Button
                    onClick={handleApprove}
                    style={{
                        width: '200px',
                        margin: '0 auto',
                        marginTop: '15px',
                    }}
                >
                    Approve and Publish
                </Button>
            </div>
            <ScrollButton feedbackRef={feedbackRef} />
        </div>
    )
}

// <Form>
//     <div id="textarea--wrapper">
//         <Input.TextArea
//             className="comment-textarea"
//             style={{
//                 width: '70%',
//                 height: '200px',
//                 margin: '0 auto',
//                 padding: '40px',
//             }}
//             // label="Feedback/Comment"
//             placeholder="Please inform the instructor of the sections requiring further attention."
//             type="text"
//             name="summary"
//             onChange={(e) => {
//                 setComment(e.target.value)
//             }}
//         />
//     </div>
// </Form>