import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Rate, Form, Input, Button, Modal } from 'antd';
import './reviewForm.scss'
import { openNotification } from '../../../../../utils';
import { setCourseReview } from '../../../../../services';
import { useAuth } from '../../../../../hooks';
import { DownloadCertButton } from '../../../EnrolledCourse/ActiveEnrolled/component/DownloadCertButton/index'
import Check from '../../../../../assets/icons/Done_loading.svg'

const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
export function ReviewForm({ progress, canReview, courseId, refetch }) {
    const {
        state: { data: user, isLoggedIn }
    } = useAuth()

    const [reviewValue, setReviewValue] = useState('')
    const [rateValue, setRateValue] = useState(0)
    const [isLoading, setIsloading] = useState(false)
    const [btnDisabled, setBtnDisable] = useState()
    const [openModal, setOpenModal] = useState(false)
    const { TextArea } = Input;
    const history = useHistory()

    const validate = () => {
        setBtnDisable(true)
        if (rateValue === 0) {
            openNotification({
                'title': 'Course Review',
                'type': 'error',
                'message': 'Rate should at least be 1'
            })
            setBtnDisable(false)
            return false
        }
        setBtnDisable(false)
        return true
    }

    const submitForm = async () => {
        const validt = validate()
        if (validt === true) {
            setIsloading(true)
            try {
                if (isLoggedIn) {
                    const data = {
                        'courseId': parseInt(courseId, 10),
                        'userId': user.id,
                        'comment': reviewValue,
                        'rating': rateValue,
                    }
                    const res = await setCourseReview(data)
                    if (res.status === true) {
                        setOpenModal(true)
                        refetch(true)
                        setRateValue(0)
                        setReviewValue('')
                    }
                }  else {
                    openNotification({
                        'title': 'Course Review',
                        'type': 'error',
                        'message': 'Sign-In / Sign-up'
                    })
                    setRateValue(0)
                    setReviewValue('')
                    history.push('/account/login')
                }
            } catch (err) {
                openNotification({
                    'title': 'Course Review',
                    'type': 'error',
                    'message': err.message
                })
                setRateValue(0)
                setReviewValue('')
            }
            setIsloading(false)
        }
    }

    return (
        <div className={canReview ? 'review_form_cont' : 'blur review_form_cont'}>
            <Form>
                <div className="form_item_cont">
                    <label>How will you rate this course?</label>
                    <Form.Item>
                        <Rate
                            name="Rate"
                            tooltips={desc}
                            onChange={setRateValue}
                            value={rateValue}
                        />
                    </Form.Item>
                </div>
                <div className="form_item_cont">
                    <label>Write a Review</label>
                    <div className="host_two">
                        <div className="formm">
                            <Form.Item>
                                <TextArea
                                    name="Review"
                                    rows={3}
                                    placeholder="share your thoughts about this course"
                                    value={reviewValue}
                                    onChange={(e) => { setReviewValue(e.target.value) }}
                                />
                            </Form.Item>
                        </div>
                        <Button
                            type="primary"
                            key={Math.random() * 100}
                            size="middle"
                            disabled={(isLoading || btnDisabled) && true}
                            style={{
                                borderRadius: '10px',
                                paddingInline: '2em',
                                height: '30px'
                            }}
                            onClick={() => {
                                validate()
                                submitForm()
                            }}
                        >{isLoading === true ? 'Sending...' : 'Submit'}
                        </Button>
                    </div>
                </div>
            </Form>
            <Modal
                // open={true}
                open={openModal}
                centered
                footer={null}
                onCancel={() => { setOpenModal(false) }}
            >
                <h3 className="review_title">Rating and Review Submitted</h3>
                <div className="review_subInfo">
                    <div className="img_cont">
                        <img src={Check} alt="check" />
                    </div>
                    <p>Thank you for leaving a review</p>
                </div>
                <div className="review_ctrl_btn">
                    {(progress !== 100) ?
                        <button
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            className="review_positive"
                        >Continue course
                        </button> : <DownloadCertButton courseId={courseId} />}
                </div>
            </Modal>
        </div>
    )
}
