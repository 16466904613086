import { useHistory } from 'react-router-dom';
import { DownloadHanbook } from '../../../../utils/downloadHandbook';
import './heroInstructor.scss';

const HeroInstructor = () => {
    // const handbookUrl =
    // 'https://courseresource.s3.us-east-2.amazonaws.com/Flexmonster-Software-License-Agreement.pdf'

    // const handleDownloadHandbook = () => {
    //     // Create an anchor element
    //     const anchor = document.createElement('a')
    //     anchor.href = handbookUrl
    //     anchor.target = '_blank' // Open in a new tab
    //     anchor.download = 'InstructorHandbook.pdf' // Set the desired file name

    //     // Simulate a click on the anchor element to trigger the download
    //     document.body.appendChild(anchor)
    //     anchor.click()

    //     // Remove the anchor element from the DOM
    //     document.body.removeChild(anchor)
    // }

    const history = useHistory()

    return (
        <section id="heroInstructor">
            <div className="background">
                <div className="container-hero">
                    <h1 className="title">Become an Instructor</h1>
                    <p className="info-text">The biggest quest to learn anything is curiosity. Join us and kindle the fire of curiosity and learning in our learners. Join us as we inspire our members to lead, grow and care.
                    </p>
                    <div className="buttons">
                        <button
                            className="get-started"
                            onClick={() => {
                                history.push('/me/instructor-onboarding-application')
                            }}
                        >Get Started
                        </button>
                        <button
                            className="instructor-handbook"
                            onClick={DownloadHanbook}
                        >Instructor Handbook
                        </button>
                        {/* <form action="">
                            <Link
                                // to="me/become-an-instructor"
                                to="me/instructor-onboarding-application"
                            >
                                <input
                                    className="get-started"
                                    type="submit"
                                    value="Get started"
                                />
                            </Link>
                        </form>
                        <input
                            className="instructor-handbook"
                            type="submit"
                            value="Instructor Handbook"
                            onClick={handleDownloadHandbook}
                        /> */}
                    </div>
                </div>
            </div>
        </section>
    )
};

export default HeroInstructor;
