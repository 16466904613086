import { request as RQ } from '../utils'

export const updateUserMarketingNotifications = async (values) => {
    try {
        const url = `/Notification/updateUserMarketingNotifications`
        const request = await RQ
        const response = await request.put(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// export const updateUserCommunityNotification = async (values) => {
//     try {
//         const url = `/Notification/updateUserCommunityNotification`
//         const response = await request.put(url, values)
//         return response?.data
//     } catch (err) {
//         if (err?.response?.data?.data?.message) {
//             throw new Error(err.response.data.data.message.join('\n\n'))
//         }
//         const error = err?.response?.data?.message || err?.message
//         throw new Error(error)
//     }

// }