import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    getAllModules as allModule,
    getAllCourses as allCourse,
    getAllLessons as allLesson,
    getInstructorID as instructorID,
    getAllCourseCategory as courseCategory,
    getAllLanguages as languages,
    setFilteredCourse as filter,
    setFiltered as isFiltered
} from '../store/actions/instrucProd/index'

export const useInstructorData = () => {
    const dispatch = useDispatch()

    const state = useSelector(({ instruct }) => instruct)

    const instructorState = useSelector(({ instruct }) => instruct)

    const getAllCourses = useCallback(async (name, signal) => {
        dispatch(await allCourse(name, signal))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInstructorID = (id, signal) => {
        dispatch(instructorID(id, signal))
    }

    const setFilteredCourse = (course) => {
        dispatch(filter(course))
    }

    const setFilterState = (bool) => {
        dispatch(isFiltered(bool))
    }

    const getAllLessons = useCallback(async (id, signal) => {
        dispatch(await allLesson(id, signal))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllModules = useCallback(async (id, signal) => {
        dispatch(await allModule(id, signal))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCourseCategory = useCallback(async (signal) => {
        dispatch(await courseCategory(signal))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getLanguages = useCallback(async (signal) => {
        dispatch(await languages(signal))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        state,
        instructorState,
        getInstructorID,
        getAllLessons,
        getAllModules,
        getAllCourses,
        getCourseCategory,
        getLanguages,
        setFilteredCourse,
        setFilterState
    }
}