import { Account, Landing, UserDashboard, InstructorDashboard, AdminDashboard } from '../pages'

export const routes = [
    {
        name: 'Authentication',
        path: '/account',
        exact: false,
        component: Account,
        roles: ['Manager', 'Merchant'],
        protected: false,
    },
    {
        name: 'User Dashboard',
        path: '/me',
        exact: false,
        component: UserDashboard,
        roles: ['Manager', 'Merchant'],
        protected: true,
    },
    {
        name: 'Instructor Dashboard',
        path: '/tutor',
        exact: false,
        component: InstructorDashboard,
        roles: ['Manager', 'Merchant'],
        protected: true,
    },
    {
        name: 'Admin Dashboard',
        path: '/admin',
        exact: false,
        component: AdminDashboard,
        roles: ['Manager', 'Merchant'],
        protected: true,
    },
    {
        name: 'Website',
        path: '/',
        exact: false,
        component: Landing,
        roles: ['Manager', 'Merchant'],
        protected: false,
    },
]
