import React from 'react'
import { Link } from 'react-router-dom'
import './NoEnrolled.scss'
// import { Button } from 'antd'

export const NoEnrolled = () => {
    return (
        <div className="noEnrolled--">
            <section className="sec1--">
                <p>
                    You have not enrolled to any of our courses. <br /> Checkout
                    our pricing page to see the best plan for you.
                </p>
                <Link to="/me/subscription">
                    <button className="btn--">Go to pricing page</button>
                </Link>
            </section>
            <section className="sec2--">
                <p>
                    You can also checkout our premium courses and buy.
                    <br /> You have access to the course at all times after
                    purchase
                </p>
                <Link to="/me/premium">
                    <button type="button" className="btn--">
                        Go to premium course
                    </button>
                </Link>
            </section>
        </div>
    )
}