import {
    SET_TOPIC,
    SET_TITLE,
    SET_LESSON_LANGUAGE,
    SET_DESCRIPTION,
    SET_MEDIA,
    SET_DOCUMENT,
    CLEAR_LESSON_FORM,
    REMOVE_DOCUMENT
} from './types'

export const setTitle = (inp) => {
    return {
        type: SET_TITLE,
        payload: inp
    }
}
export const setTopic = (inp) => {
    return {
        type: SET_TOPIC,
        payload: inp
    }
}
export const setDescrip = (inp) => {
    return {
        type: SET_DESCRIPTION,
        payload: inp
    }
}
export const setMedia = (inp) => {
    return {
        type: SET_MEDIA,
        payload: inp
    }
}
export const setLang = (inp) => {
    return {
        type: SET_LESSON_LANGUAGE,
        payload: inp
    }
}
export const setDoc = (inp) => {
    return {
        type: SET_DOCUMENT,
        payload: inp
    }
}
export const clearLessonForm = () => {
    return {
        type: CLEAR_LESSON_FORM
    }
}
export const removeDoc = (inp) => {
    return {
        type: REMOVE_DOCUMENT,
        payload: inp
    }
}
