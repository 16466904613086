import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import './styles.scss'
import logoBorderImg from './assets/Graphic Elements 2.svg'
import ZustechLogo from './assets/Group 10.svg'
import ZustechSeal from './assets/zustech seal 1.svg'
import ImageLineIcon from './assets/Graphic Elements1 1.svg'
import Signature from './assets/emma anya1 1.svg'
import { useAuth } from '../../../../hooks'
import { findCourseById, getUsersProgressOnCourse } from '../../../../services'

export const CertTemplate = ({ pdfRef }) => {
    const {
        state: { data, isLoggedIn }
    } = useAuth()
    const [courseName, setCourseName] = useState('')
    const [instructorName, setInstructorName] = useState('')
    const [certRef, setCertRef] = useState('ZUS-')

    const { id } = useParams()
    const history = useHistory()

    // console.log(CryptoJS.MD5(`${}`).toString())
    useEffect(() => {
        const confirmProgess = async (userId, id) => {
            try {
                const res = await getUsersProgressOnCourse(id, userId)
                if (res.data.progress === 100) {
                    const res = await findCourseById(id)
                    const cos = res?.data?.courseModel?.courseInfor
                    setCourseName(cos.name)
                    setInstructorName(cos.instructorName)
                    const value = `${data.firstName}${data.lastName}${cos.name}${userId}`
                    const ref = CryptoJS.MD5(value).toString().slice(0, 11)
                    setCertRef(`ZUS-${ref.toLocaleUpperCase()}`)
                } else {
                    history.push('/me/learning')
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        // userName-courseName-instructorname

        if (isLoggedIn) {
            confirmProgess(data?.id, id)
        }
        return () => {
            setCertRef('ZUS-')
            setCourseName()
            setInstructorName()
        }
        // eslint-disable-next-line
    }, [id, data?.id])

    function getCurrentDate() {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(currentDate.getFullYear()).slice(-2);

        return `${day}/${month}/${year}`;
    }

    return (
        <div ref={pdfRef} id="cert-container">
            <div className="section--1">
                {/* section 1 background that contains logo and seal (logo border image) */}
                <div className="logo_border_cont">
                    <img
                        src={logoBorderImg}
                        className="logo--border--img"
                        alt="logo--border"
                    />
                </div>

                <div className="sec_cont">
                    {/* Zustech Logo */}
                    <div className="logo__cont">
                        <img
                            src={ZustechLogo}
                            className="zustech--logo"
                            alt="zustech logo"
                        />
                    </div>

                    {/* Zustech SEAL */}
                    <div className="seal__cont">
                        <img
                            src={ZustechSeal}
                            className="zustech--seal"
                            alt="zustech seal"
                        />
                    </div>

                    <div className="cert--number">
                        Certificate Number: <br />
                        {certRef}
                    </div>
                </div>
            </div>
            <div className="section--2">
                <div className="top_layer">
                    <h4 className="cert--title">
                        <div className="one">Certificate</div>
                        <div className="two">of Completion</div>
                    </h4>

                    {/* Line-iike icon below Certificate of completion text title */}
                    <div className="imgLine__cont">
                        <img
                            src={ImageLineIcon}
                            className="image--line"
                            alt="line-icon"
                        />
                    </div>

                    <p className="we--hereby">
                        We hereby present this certificate to
                    </p>
                </div>
                <div className="lower_layer">
                    <p className="assignee--name">
                        {data?.firstName} {data?.lastName}
                    </p>
                    <p className="text--">For successfully completing the</p>
                    <div className="text--course">
                        <span>Course: {courseName}</span>
                        <span>By Instructor: {instructorName}</span>
                    </div>
                    <div className="bottom--signature">
                        <div className="date--section">
                            <p className="cert--date">
                                (Certificate Issuance Date)
                            </p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className="assigner--section">
                            <img
                                src={Signature}
                                alt="signature"
                                className="signature"
                            />
                            <p>Director of Learning</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// export default CertTemplate
