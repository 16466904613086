import React from 'react'
import { Displaycontent } from './Displaycontent'

export function InstructorTerms() {
    const content = [
        {
            key: 1,
            title: '',
            content: '',
            subcontent: [
                {
                    key: 1.1,
                    title: 'Eligibility',
                    content:
                        'When you register for an account on our LMS, we may collect personal information such as your name, email address, and other contact details.',
                },
                {
                    key: 1.2,
                    title: 'Application Usage',
                    content:
                        'You agree to use the instructor application for the sole purpose of creating and instructing courses within our LMS. You may not use the application for any other purpose, including but not limited to sharing, selling, or distributing your access or credentials.',
                },
                {
                    key: 1.3,
                    title: 'Account Registration',
                    content:
                        'You may be required to create an account to access the instructor application. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.',
                },
                {
                    key: 1.4,
                    title: 'Content Ownership',
                    content: `You retain ownership of all content you create or upload to the LMS through the instructor application. By using the application, you grant us a non-exclusive, worldwide, royalty-free license to use, copy, reproduce, modify, adapt, publish, and distribute your content for the purpose of operating and improving the LMS.
                    You agree not to upload or share any content that infringes upon the intellectual property rights of others or violates applicable laws.`,
                },
                {
                    key: 1.5,
                    title: 'Prohibited Conduct',
                    content: {
                        heading: 'You agree not to',
                        miniContent: [
                            'Use our LMS for any illegal or unauthorized purpose.',
                            'Transmit any viruses, malware, or other malicious code.',
                            'Harass, threaten, or harm other users or us in any way.',
                            'Interfere with or disrupt the operation of the LMS.',
                            'Attempt to gain unauthorized access to the LMS, other accounts, or computer systems.',
                            'Engage in any conduct that violates these Terms or applicable laws.',
                        ],
                    },
                },
                {
                    key: 1.6,
                    title: 'Termination',
                    content:
                        'We reserve the right to suspend or terminate your access to the instructor application at our sole discretion, with or without cause, and without notice.',
                },
                {
                    key: 1.7,
                    title: 'Modification',
                    content:
                        'We may modify, suspend, or discontinue the instructor application at any time without notice. We are not liable to you or any third party for any such modifications, suspensions, or discontinuations.',
                },
            ],
        },
        {
            key: 2,
            title: 'Privacy Policy',
            content: '',
            subcontent: [
                {
                    key: 2.1,
                    title: 'Information Collection',
                    content:
                        'We may collect and store personal information about you, including but not limited to your name, email address, and course-related data. We use this information to provide you with access to the instructor application and to improve our services.',
                },
                {
                    key: 2.2,
                    title: 'Information Sharing',
                    content:
                        'We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in providing the instructor application, but they are required to protect your information.',
                },
                {
                    key: 2.3,
                    title: 'Security',
                    content:
                        'We take reasonable steps to protect your personal information, but we cannot guarantee its security. You are responsible for safeguarding your account credentials.',
                },
                {
                    key: 2.4,
                    title: 'Cookies',
                    content:
                        'We may use cookies and similar technologies to collect information about your use of the instructor application. You can manage your cookie preferences through your browser settings.',
                },
                {
                    key: 2.5,
                    title: 'Third-Party Links',
                    content:
                        'The instructor application may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.',
                },
                {
                    key: 2.6,
                    title: 'Changes to Privacy Policy',
                    content:
                        'We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top will be updated.',
                },
                {
                    key: 2.7,
                    title: 'Contact',
                    content:
                        'If you have questions or concerns about our Privacy Policy or how we handle your personal information, please contact us at Zustech.',
                },
            ],
        }
    ]
    return (
        <section className="instructorTU_content_cont">
            <h1>Instructor Terms of Use</h1>
            <section className="content">
                <div className="data_modify">
                    <p className="date">Last Updated: [12th April, 2024]</p>
                    <p className="text">
                        Please read these terms and conditions and privacy
                        policy carefully before submitting your application to
                        become an instructor in Zustech learning management
                        system. These Terms and Privacy Policy are a legally
                        binding agreement between you, the instructor and
                        Zustech LMS. By submitting your application to become an
                        instructor in Zustech learning management system, you
                        agree to abide by these Terms and Privacy Policy.
                    </p>
                </div>
                <div className="readable">
                    <Displaycontent content={content} />
                    <p>
                        By using the instructor application, you acknowledge
                        that you have read, understood, and agree to these Terms
                        and the Privacy Policy. If you do not agree with these
                        Terms or the Privacy Policy, please do not submit your
                        instructor application.
                    </p>
                </div>
            </section>
        </section>
    )
}
