import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Modal } from 'antd'
import { Input } from '../../../../components'
import { postContactForm } from '../../../../services'
import { openNotification } from '../../../../utils'
import './contactform.scss'
// import { useAuth } from '../../../../hooks'

export const ContactForm = () => {
    const history = useHistory
    const initialValues = { name: '', email: '', phone: '', message: '' }
    const [formValues, setFormValues] = useState(initialValues)
    const [isSending, setIsSending] = useState()
    const [isSubmit, setIsSubmit] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const captchaContainerRef = useRef(null)

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        // This ensures that the container is visible when showMyCaptcha is called.
        // If the modal is not open, the ref may not be linked to an actual DOM element.
        // (I almost died before resolving this issue lmao)
        if (isModalOpen) {
            showMyCaptcha()
        }
        // eslint-disable-next-line
    }, [isModalOpen])
    console.log(process.env.REACT_APP_AWS_CAPTCHA_KEY)

    // Function to initialize the captcha
    const showMyCaptcha = () => {
        if (window.AwsWafCaptcha && captchaContainerRef.current) {
            window.AwsWafCaptcha.renderCaptcha(captchaContainerRef.current, {
                apiKey: process.env.REACT_APP_AWS_CAPTCHA_KEY,
                onSuccess: captchaExampleSuccessFunction,
                onError: captchaExampleErrorFunction,
                dynamicWidth: true,
            })
        } else {
            console.error('Captcha library not loaded.')
        }
    }

    // Handle captcha success
    const captchaExampleSuccessFunction = async (wafToken) => {
        // Use WAF token to access protected resources
        console.log('Captcha Verified (wafToken):', wafToken) // i need to do something with this token, dont know yet. It serves as a reference to timestamp and other details for verified captcha.
        submitFormData()
        setIsModalOpen(false)
    }

    // Handle captcha error
    const captchaExampleErrorFunction = (error) => {
        console.error('Captcha Error:', error.message)
    }

    // Antd Form
    const [form] = Form.useForm()

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    const handleSubmit = () => {
        setIsModalOpen(true)
    }

    const submitFormData = async (values) => {
        try {
            setIsSending(true)
            const res = await postContactForm({
                ...values,
                name: formValues.name,
                email: formValues.email,
                phoneNumber: formValues.phone,
                message: formValues.message,
            })
            if (res.status === true) {
                setIsSubmit(true)
            }
        } catch (error) {
            setIsSending(false)
            setIsSubmit(false)
            console.log('Form submission error:', error)
            openNotification({
                type: 'error',
                title: 'Submission Error',
                message: 'Failed to submit form.',
            })
        } finally {
            history.push('/contact')
        }
    }

    const handleSuccessMessage = () => {
        setIsSubmit(false)
        setIsSending(false)
        setIsModalOpen(false)
        setFormValues(initialValues)
    }

    return (
        <div id="contact-form">
            <div className="section-1">
                <p className="section-1-head">Get in Touch</p>
                <p className="section-1-content">
                    We are delighted that you're reaching out to us. Kindly
                    share with us how we might help you.
                </p>
            </div>
            <div className="section-2">
                <p className="section-2-head">Contact Us</p>
                <hr />
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="on"
                    scrollToFirstError
                >
                    <input
                        className="name-input"
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formValues.name}
                        disabled={isSending}
                        required
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                name: e.target.value,
                            })
                        }}
                    />
                    <Input
                        className="email-input h-100"
                        // type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formValues.email}
                        required
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                email: e.target.value,
                            })
                        }}
                        validationRules={[
                            {
                                required: true,
                                message: 'Please enter your email address',
                            },
                            {
                                type: 'email',
                                message: 'This email address is not valid',
                            },
                        ]}
                        disabled={isSending}
                    />

                    <input
                        className="contact-input"
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Contact No. (Optional)"
                        value={formValues.phone}
                        onChange={handleChange}
                        disabled={isSending}
                    />
                    <input
                        className="message-input"
                        type="text"
                        id="message"
                        name="message"
                        placeholder="Message"
                        required
                        value={formValues.message}
                        onChange={handleChange}
                        disabled={isSending}
                    />
                    <button
                        className="btn-submit"
                        type="submit"
                        disabled={isSending}
                    >
                        {!isSending ? 'Submit' : 'Submission in Progress...'}
                    </button>
                </Form>
            </div>

            <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
                <div id="my-captcha-container" ref={captchaContainerRef}>
                    {/* This container will be replaced by the CAPTCHA widget */}
                </div>
            </Modal>

            {/* Success Message after form successfully submits */}
            {isSubmit ? (
                <div className="overlay">
                    <div className="successMessage">
                        <h4 className="successMessage--text">
                            Thank you for contacting us!
                        </h4>
                        <p className="successMessage--subtext">
                            Message delivered successfully. We will give you
                            feedback as soon as possible.
                        </p>
                        <button
                            className="successMessage--btn"
                            onClick={handleSuccessMessage}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    )
}