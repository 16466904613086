import { Tabs } from 'antd'
import { Button } from '../../../components/Button'
import { TaskMain } from '../Home/taskComponents/taskMain'
import './setTasks.scss'
import '../Home/instructorDB.scss'

export const setTasks = () => {
    const { TabPane } = Tabs
    return (
        <div id="set-tasks">
            <div className="contents">
                <div className="App">
                    <div id="content">
                        <div className="TaskMain">
                            <div id="content">
                                <div id="submissionTracker">
                                    <h1>Tasks</h1>
                                    <p> Set your own personal tasks here </p>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="To Do" key="1">
                                            <div>
                                                <Button className="frontEndBtn">
                                                    {' '}
                                                    Front End Development{' '}
                                                </Button>
                                                <Button className="uxBtn">
                                                    {' '}
                                                    UX Design{' '}
                                                </Button>
                                                <Button className="userTestingBtn">
                                                    {' '}
                                                    User Testing{' '}
                                                </Button>
                                            </div>
                                            <br />
                                            <h1> To Do </h1>
                                            <TaskMain />
                                        </TabPane>
                                        <TabPane tab="Management Board" key="2">
                                            PLACEHOLDER CONTENT FOR MANAGEMENT
                                            BOARD
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
