import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    courseEnroll,
    findCourseById
} from '../../../../services'

export const SingleSubSuccess = ({
    paymentSuccess,
    startDate,
    data,
    message,
    courseId
}) => {
    const history = useHistory()
    const [courseName, setCourseName] = useState('')

    // Enroll user to course after succesfully subscription
    const enrollUserToCourse = async (userId, crsId) => {
        try {
            const response = await courseEnroll(userId, crsId)
            if (response.status) {
                return response.data
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    // FETCH COURSE BY ID AND GET COURSE NAME
    useEffect(() => {
        const FetchCourse = async (id) => {
            try {
                const res = await findCourseById(id)
                console.log(res)
                setCourseName(res.data?.courseModel?.courseInfor?.name)
            } catch (err) {
                console.log(err.message)
            }
        }
        if (courseId) {
            FetchCourse(courseId)
        }
    }, [courseId])

    return (
        <main id="subscription_cont">
            {paymentSuccess && (
                <div className="content_cont">
                    <section className="left_Hside">
                        {/* <h3>{day}/{month}/{year}</h3> */}
                        <h3>{startDate}</h3>
                        <div className="subs_info">
                            <h4>Single Course Subscription</h4>
                            <div>
                                <p>
                                    This subscription grants you exclusive
                                    access to <b>{courseName}</b> until your
                                    subscription expires.
                                </p>
                            </div>
                            <p>Click the button below to get started</p>
                        </div>
                    </section>
                    <section className="right_Hside">
                        <h2>Thank you for subscribing!</h2>
                        <div className="topper">
                            <p className="userInfo">
                                Hello{' '}
                                <b>
                                    {data.firstName} {data.lastName}
                                </b>
                                , you now have access to <b>{courseName}</b>
                            </p>
                            <p> We hope you find your time here with us to be fun. If there’s anything we can do to make the experience better. Please let us know!</p>
                        </div>
                        <div className="not_topper">
                            <p>
                                Please visit our support page if you are
                                experiencing any issues or have any questions!
                            </p>
                        </div>
                        <h4>
                            Thanks again, <br /> Zustech Team!
                        </h4>
                    </section>
                </div>
            )}
            {!paymentSuccess && <div>{message}</div>}
            {paymentSuccess && (
                <div className="btn_cont">
                    <button
                        id="redirectBtn"
                        onClick={() => {
                            // Enroll user to subscribed course
                            const response = enrollUserToCourse(
                                data.id,
                                courseId
                            )
                            const Subscribed_Data = JSON.parse(
                                localStorage.getItem('Subscribed_Data')
                            )
                            // remove key from storage and redirect to course enrolled page
                            if (response) {
                                if (Subscribed_Data !== null) {
                                    localStorage.removeItem('Subscribed_Data')
                                }
                                history.push('/me/learning')
                            }
                        }}
                    >
                        Start Learning
                    </button>
                </div>
            )}
        </main>
    )
}
