import { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Collapse, Tabs, Progress, Form, Modal, Checkbox } from 'antd'

// import { findCourseById, FindCourseObjectiveByCourseId } from '../../../services'
import {
    findCourseById,
    checkIfEnrolled,
    courseEnroll,
    // getPremiumCourses
} from '../../../../services'
import { CourseVideoResource } from './shared/CourseVideo'
import { Overview } from './shared/Overview'
// import QA from './QnaMain';
import Reviews from './shared/Reviews'
import playBtn from './shared/assets/playBtn.svg'
// import './styles.scss'

import { QnaMain } from './shared/QA/QnaMain'
import { QnaQuestion } from './shared/QA/QnaQuestion'
import Announcement from './shared/Announcement'
import { Transcripts } from './shared/Transcripts'
import Tasks from './shared/Tasks'
import { useAuth } from '../../../../hooks'
import { Button } from '../../../../components'
// import Checkbox from './Checkbox'
import { CartContext } from '../../../../context/Cart/cartContext'

const { TabPane } = Tabs

export const PremiumCourseDetails = () => {
    const {
        state: { data: user },
    } = useAuth()
    // const { store: { cart }, dispatch } = CartState()
    const { addToCart } = useContext(CartContext)

    const history = useHistory()

    const [course, setCourse] = useState(null)
    // const [premCourses, setPremCourse] = useState(null)
    const [courseObjectives, setCourseObjectives] = useState(null)
    const [firstLessonUrl, setFirstLessonUrl] = useState('')
    const [lessonUrl, setLessonUrl] = useState('')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [showModal, setShowModal] = useState(false)

    const { Panel } = Collapse
    const courseIdAddress = window.location.pathname
    const courseId = courseIdAddress.split('/').pop()

    // const [checkmark, setCheckmark] = useState(false);
    const [isQuestion, setIsQuestion] = useState(false)
    const [isEnrolled, setIsEnrolled] = useState({})
    const [check, setCheck] = useState(false)

    const [currentIndex, setCurrentIndex] = useState(0)
    const [played, setPlayed] = useState(0)
    const [isVideoPlaying, setIsVideoPlaying] = useState(true)
    const [canUserCheck, setCanUserCheck] = useState(false)
    const [selection, setSelection] = useState([])
    const [totalLessons, setTotalLessons] = useState(0)
    // const [isComplete, setIsComplete] = useState(false)
    // const [totalProgress, setTotalProgress] = useState(0)
    console.log(isEnrolled)

    useEffect(() => {
        const getStatus = async () => {
            try {
                const response = await checkIfEnrolled(courseId)
                setCheck(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getStatus()
    }, [courseId])

    useEffect(() => {
        if (check === false) {
            try {
                setCanUserCheck(false)
            } catch (error) {
                console.error(error)
            }
        } else {
            setCanUserCheck(true)
        }
    }, [canUserCheck, check])

    // const checkIfEnrolled = async () => {
    //     try {
    //         const response = await courseEnroll(user.id, courseId)
    //         setIsActive(response)
    //         console.log(setIsActive)
    //     } catch (error) {
    //         console.error(error)
    //     }

    // }

    const handleOk = () => {
        history.push('/me/settings/subscription')
    }

    // const handleEnrol = () => {
    //     setIsActive(setIsActive.isActive)
    //     if (isActive === 'Not Found') {
    //         setShowModal(true)
    //         // console.log(isActive)
    //     } else {
    //         console.log(isActive)
    //         // openNotification({
    //         //     type: 'error',
    //         //     message: 'User Have No Active Subscription',
    //         // })
    //         // // window.alert('No Active Subscription')
    //         // history.push('/me/settings/subscription')
    //     }
    // }

    const handleSubmit = async () => {
        if (check === false) {
            try {
                const reponse = await courseEnroll(user.id, courseId)
                setIsEnrolled(reponse?.data)
            } catch (error) {
                setShowModal(true)
                setIsVideoPlaying(false)
            }
        }

        // setIsEnrolled(status.isEnrolled);
        // try {
        //     const response = await courseEnroll(user.id, courseId)
        //     setIsEnrolled(response)
        //     console.log(isEnrolled)
        // } catch (error) {
        //     setShowModal(true)
        //     console.error(error)
        // }
    }

    useEffect(() => {
        const getCourse = async () => {
            try {
                const res = await findCourseById(courseId)
                setCourse(res?.data)
                setCourseObjectives(res?.data?.courseModel?.courseObjectives)
                setFirstLessonUrl(
                    res?.data?.courseContent[0]?.lessons[0]?.content
                )
            } catch (error) {
                console.error(error)
            }
        }
        getCourse()

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [courseId])

    // GET PREMIUM COURSE TESTINNGGG - MARV
    // useEffect(() => {
    //     const getPremCourses = async () => {
    //         try {
    //             const res = await getPremiumCourses()
    //             // console.log(res?.data?.courseModel?.courseInfor)
    //             console.log(res?.data)
    //             setPremCourse(res?.data)
    //         } catch (error) {
    //             console.error(error)
    //         }
    //     }
    //     getPremCourses()

    //     window.scrollTo({ top: 0, behavior: 'smooth' })
    // }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])

    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    }

    const isQuestionClicked = () => {
        setIsQuestion(isQuestion => !isQuestion)
    }

    const handleSubmitAndNextVideo = async () => {
        if (check === false) {
            try {
                const reponse = await courseEnroll(user.id, courseId)
                setIsEnrolled(reponse?.data)
            } catch (error) {
                setShowModal(true)
                setIsVideoPlaying(false)
            }
        } else {
            nextVideo()
        }
    }

    const vidAndIndex = (lesson, i) => {
        setCurrentIndex(i)
        setLessonUrl(lesson?.content)
    }

    const nextVideo = async () => {
        const res = await findCourseById(courseId)
        const arrLength = res?.data?.courseContent[0]?.lessons.length - 1

        let incIndex = currentIndex

        if (currentIndex <= arrLength) {
            setCurrentIndex((incIndex += 1))

        } else {
            setCurrentIndex((incIndex = 0))
        }
        setLessonUrl(res?.data?.courseContent[0]?.lessons[incIndex]?.content)
    }

    const calcLessonLength = (duration) => {
        const h = Math.floor(duration / 60)
        const m = Math.floor(duration % 60)

        if (h === 0) {
            return m + 'm'
        } else if (m === 0) {
            return h + 'hr'
        } else if (h > 1) {
            return h + 'hrs ' + m + 'm'
        } else {
            return h + 'hr ' + m + 'm'
        }
    }

    const onCheck = (e) => {
        const item = e.target.value
        // const isChecked = e.target.checked
        // setIsComplete(isChecked)
        // checkAfterVid()

        if (selection.includes(item)) {
            setSelection(selection.filter(v => v !== item))
        } else {
            setSelection([...selection, item])
        }
    }

    const updateProgress = () => {
        const selectionLength = selection.length
        const calc = (selectionLength / totalLessons)
        const total = Math.floor(calc * 100)

        return total;
    }

    useEffect(() => {
        const getLessonsLength = async () => {
            try {
                const res = await findCourseById(courseId)
                const arrLength = res?.data?.courseContent[0]?.lessons.length
                setTotalLessons(arrLength)
                return totalLessons;
            } catch (error) {
                console.error(error)
            }
        }
        getLessonsLength()
    }, [courseId, totalLessons])

    // const checkAfterVid = (lesson, i) => {
    //     const lessonID = lesson + i
    //     let count = isComplete
    //     const checkAfter = selection.filter((checkedLesson) => {
    //         if (checkedLesson === lessonID) {
    //             count = !count
    //         }
    //         return checkedLesson
    //     });
    //     setIsComplete(checkAfter)
    // }

    return (
        <div id="courseDetails">
            <div className="learn-title">
                {/* <h3>Learn {course?.courseModel?.courseInfor?.name}</h3> */}
                <div className="cart-group">
                    <h3>Learn {course?.courseModel?.courseInfor?.name}</h3>
                </div>
                <div className="learn-btn">
                    <p>${course?.courseModel?.courseInfor?.amount}</p>
                    <Link to="/me/premium/cart">
                        <button onClick={() => addToCart(course?.courseModel?.courseInfor)}>
                            Buy Now
                        </button>
                    </Link>
                </div>
            </div>
            <div className="learn-content-container">
                <div className="learn-content">
                    <div className="learn-video">
                        {!lessonUrl ? (
                            <div className="learn-video-thumbnail">
                                <div className="learn-play-btn">
                                    <div
                                        onClick={() => setLessonUrl(firstLessonUrl)}
                                    >
                                        <img src={playBtn} alt="Play" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <CourseVideoResource
                                url={lessonUrl}
                                handleSubmitAndNextVideo={
                                    handleSubmitAndNextVideo
                                }
                                nextVideo={nextVideo}
                                currentIndex={currentIndex}
                                played={played}
                                setPlayed={setPlayed}
                                isVideoPlaying={isVideoPlaying}
                            />
                        )}
                    </div>
                    <div className="learn-tabs">
                        {screenWidth > 850 ? (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Overview" key="1">
                                    <Overview
                                        course={course}
                                        objectives={courseObjectives}
                                    />
                                </TabPane>
                                <TabPane tab="Tasks" key="2">
                                    <Tasks />
                                </TabPane>
                                <TabPane tab="Q&A" key="3">
                                    {!isQuestion ? (
                                        <QnaMain
                                            isQuestionClicked={
                                                isQuestionClicked
                                            }
                                        />
                                    ) : (
                                        <QnaQuestion
                                            isQuestionClicked={
                                                isQuestionClicked
                                            }
                                        />
                                    )}
                                </TabPane>
                                <TabPane tab="Reviews" key="4">
                                    <Reviews />
                                </TabPane>
                                <TabPane tab="Announcements" key="5">
                                    <Announcement />
                                </TabPane>
                                <TabPane tab="Transcripts" key="6">
                                    <Transcripts />
                                </TabPane>
                            </Tabs>
                        ) : (
                            <Collapse bordered={false}>
                                <Panel className="" header="Overview">
                                    <Overview
                                        course={course}
                                        objectives={courseObjectives}
                                    />
                                </Panel>
                                <Panel className="" header="Tasks">
                                    <Tasks />
                                </Panel>
                                <Panel className="" header="Q&A">
                                    {!isQuestion ? (
                                        <QnaMain
                                            isQuestionClicked={
                                                isQuestionClicked
                                            }
                                        />
                                    ) : (
                                        <QnaQuestion
                                            isQuestionClicked={
                                                isQuestionClicked
                                            }
                                        />
                                    )}
                                </Panel>
                                <Panel className="" header="Reviews">
                                    <Reviews />
                                </Panel>
                                <Panel className="" header="Announcements">
                                    <Announcement />
                                </Panel>
                                <Panel className="" header="Transcripts">
                                    <Transcripts />
                                </Panel>
                            </Collapse>
                        )}
                    </div>
                </div>
                <div className="learn-sidebar">
                    <Progress
                        percent={updateProgress()}
                        className="learn-progress"
                    />
                    <h4>Content</h4>
                    <div className="learn-collapse-container">
                        {course?.courseContent.map((content, i) => (
                            <Collapse key={i} bordered={false}>
                                <Panel
                                    className="learn-collapse-panel"
                                    header={content?.moduleName}
                                    extra={totalLessons + ' Lessons'}
                                >
                                    {content?.lessons.map((lesson, i) => (
                                        <Form onClick={handleSubmit}>
                                            <div
                                                key={i}
                                                className="learn-collapse-lesson"
                                                onClick={() => vidAndIndex(lesson, i)}
                                            >
                                                <div>
                                                    <p>{lesson?.name}</p>
                                                </div>
                                                <div className="learn-collapse-lesson-info">
                                                    <p>
                                                        {calcLessonLength(
                                                            lesson.totalLength
                                                        )}
                                                    </p>
                                                    {canUserCheck ? (
                                                        <Checkbox
                                                            key={
                                                                lesson.name + i
                                                            }
                                                            id={lesson.name + i}
                                                            value={
                                                                lesson.name + i
                                                            }
                                                            onChange={(e) => onCheck(e)}
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            value={
                                                                lesson.name + i
                                                            }
                                                            disabled={true}
                                                        />
                                                    )}
                                                    {/* <div className={isLessonCompleted ? 'lesson-bg-ticked' : 'lesson-bg-not-ticked'} key={i} onClick={toggleCompletedLesson}>
                                                        <div className="lesson-tick" />

                                                    </div> */}

                                                    {/* <div className="lesson-checkbox">
                                                        <label
                                                            htmlFor={
                                                                lesson.name + i
                                                            }
                                                        >
                                                            {
                                                                lesson?.totalLength
                                                            }
                                                            m
                                                        </label>
                                                        <input
                                                            id={lesson.name + i}
                                                            type="checkbox"
                                                        />
                                                        <span className="check" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Form>
                                    ))}
                                </Panel>
                            </Collapse>
                        ))}
                    </div>
                </div>
            </div>
            <Modal
                title={null}
                visible={showModal}
                footer={null}
                closeIcon={null}
                closable={false}
            >
                <h3>No Active Subscription</h3>
                <p>You must have an active subscription in order to proceed</p>
                {/* <Button onClick={() => setShowModal(false)} type="button"> */}
                <Button onClick={() => handleOk()} type="button">
                    OK
                </Button>
            </Modal>
        </div>
    )
}