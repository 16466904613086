import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SideBar } from './shared'
import { Profile } from './Profile'
import { Notification } from './Notification'
import { AccountInfo } from './AccountInfo'
import { Subscription } from './Subscription'
import { LinkedAccount } from './LinkedAccount'
import { Preferences } from './Preferences'
import { CloseAccount } from './CloseAccount'
import { CheckoutPage } from './Subscription/Stripe/checkout'
import './shared/settings.scss'
import { Payment } from './Payment'
import { CancelSub } from './Subscription/CancelSub'

export const routes = [
    {
        path: '/me/settings/profile',
        name: 'Profile',
        component: Profile,
        exact: true,
        id: '1',
    },
    {
        path: '/me/settings/info',
        name: 'Account Info',
        component: AccountInfo,
        exact: true,
        id: '2',
    },
    {
        path: '/me/settings/notification',
        name: 'Notification',
        component: Notification,
        exact: true,
        id: '3',
    },
    {
        path: '/me/settings/subscription',
        name: 'Subscription & Billing',
        exact: true,
        component: Subscription,
        id: '4',
    },
    {
        path: '/me/settings/linked-account',
        name: 'Linked account',
        exact: true,
        component: LinkedAccount,
        id: '5',
    },
    {
        path: '/me/settings/preferences',
        name: 'Language preferences',
        exact: true,
        component: Preferences,
        id: '6',
    },
    {
        path: '/me/settings/close-account',
        name: 'Close account',
        exact: true,
        component: CloseAccount,
        id: '7',
    },
    {
        path: '/me/settings/checkout',
        name: 'Checkout',
        exact: true,
        component: CheckoutPage,
        id: '8',
    },
    {
        path: '/me/settings/payment',
        name: 'Payment',
        exact: true,
        component: Payment,
        id: '9',
    },
    {
        path: '/me/settings/cancel-sub',
        name: 'Cancel Subscription',
        exact: true,
        component: CancelSub,
        id: '10',
    },
]

export const Settings = () => {
    return (
        <div className="row">
            <div className="col-sm-3">
                <SideBar />
            </div>
            <div className="col-sm-9">
                <Switch>
                    {routes.map((route) => {
                        return <Route key={route.id} {...route} />
                    })}
                </Switch>
            </div>
        </div>
    )
}
