import { Route, Switch } from 'react-router-dom'
import { ContactForm } from './ContactForm'

export * from './ContactUs'
export * from './ContactForm'
export * from './StudentSupport'
export * from './InstructorSupport'

const contactRoutes = [
    {
        path: '/contact-form',
        name: 'Contact Form',
        exact: true,
        component: ContactForm,
    },
]

export const Contact = () => {
    return (
        <div style={{ marginTop: '80px' }}>
            <Switch>
                {contactRoutes.map((route) => {
                    return <Route {...route} />
                })}
            </Switch>
        </div>
    )
}