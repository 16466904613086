export const SET_COURSE_TITLE = 'SET_COURSE_TITLE'
export const SET_OBJECTIVES = 'SET_OBJECTIVES'
export const SET_DESCRIPTION = 'SET_DESCRIPTION'
export const SET_PREREQUISITE = 'SET_PREREQUISITE'
export const SET_COURSE_TYPE = 'SET_COURSE_TYPE'
export const SET_DIFFICULTY_LEVEL = 'SET_DIFFICULTY_LEVEL'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_COVER_IMAGE = 'SET_COVER_IMAGE'
export const SET_COURSE_LANGUAGE = 'SET_COURSE_LANGUAGE'
export const SET_MODULES = 'SET_MODULES'
export const SET_INTRODUCTORY_LESSONS = 'SET_INTRODUCTORY_LESSONS'
export const SET_COURSE_PRICE = 'SET_COURSE_PRICE'
export const CLEAR_COURSE_FORM = 'CLEAR_FORM_FILED'
export const REMOVE_FROM_PREREQUISITE = 'REMOVE_FROM_PREREQUISITE'
export const REMOVE_FROM_OBJECTIVES = 'REMOVE_FROM_OBJECTIVES'
export const REMOVE_COVERIMG = 'REMOVE_COVERIMG'