import DashboardIcon from '../assets/icons/dashboard_icon.svg'
// import TaskIcon from '../assets/icons/task_icon.svg'
import MyCoursesIcon from '../assets/icons/my_course_icon.svg'
// import StatisticsIcon from '../assets/icons/stats_icon.svg'
// import ForumIcon from '../assets/icons/forum_icon.svg'
import SettingsIcon from '../assets/icons/settings_icon.svg'
import BillingIcon from '../assets/icons/billing_icon.svg'

export const instructorDashboardRoutes = [
    {
        match: '/tutor',
        path: '/tutor',
        title: 'Dashboard',
        icon: DashboardIcon,
    },
    {
        match: '/tutor/courses',
        path: '/tutor/courses',
        title: 'Manage Courses',
        icon: MyCoursesIcon,
    },
    {
        match: '/tutor/revenue',
        path: '/tutor/revenue',
        title: 'Revenue/Payment',
        icon: BillingIcon,
    },
    // {
    //     match: '/tutor/tasks',
    //     path: '/tutor/tasks',
    //     title: 'Set Tasks',
    //     icon: TaskIcon,
    // },
    // {
    //     match: '/tutor/stats',
    //     path: '/tutor/stats',
    //     title: 'Analytics',
    //     icon: StatisticsIcon,
    // },
    // {
    //     match: '/tutor/forum',
    //     path: '/tutor/forum',
    //     title: 'Forum',
    //     icon: ForumIcon,
    // },
    {
        match: '/tutor/settings/:any',
        path: '/tutor/settings/profile',
        title: 'Settings',
        icon: SettingsIcon,
    },
]
