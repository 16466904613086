import {
    QUIZ_MODE,
    MODULE_ID,
    LESSON_CLICKED,
    QUIZ_ACTIVE,
    START_QUIZ,
    USER_ANSWER,
    SELECTED_ANSWER,
    QUIZ_COMPLETED,
    QUIZ_SCORE,
    CLEAR_ALL,
    COURSE_ID,
    QUIZ_TYPE
} from '../../actions/interface/types.js'

const INIT_STATE = {
    quizMode: false,
    moduleId: '',
    courseId: '',
    quizType: '',
    clicked: false,
    quizActive: false,
    commenceQuiz: false,
    currentOption: '',
    selectedOptions: [],
    quizCompleted: false,
    quizScore: 0
}

export const interfaceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case QUIZ_MODE:
            return {
                ...state,
                quizMode: action.payload
            }
        case MODULE_ID:
            return {
                ...state,
                moduleId: action.payload
            }
        case COURSE_ID:
            return {
                ...state,
                courseId: action.payload
            }
        case LESSON_CLICKED:
            return {
                ...state,
                clicked: action.payload
            }
        case QUIZ_ACTIVE:
            return {
                ...state,
                quizActive: action.payload
            }
        case START_QUIZ:
            return {
                ...state,
                commenceQuiz: action.payload
            }
        case USER_ANSWER:
            return {
                ...state,
                selectedOptions: action.payload
            }
        case SELECTED_ANSWER:
            return {
                ...state,
                currentOption: action.payload
            }
        case QUIZ_TYPE:
            return {
                ...state,
                quizType: action.payload
            }
        case QUIZ_COMPLETED:
            return {
                ...state,
                quizCompleted: action.payload
            }
        case QUIZ_SCORE:
            return {
                ...state,
                quizScore: action.payload
            }
        case CLEAR_ALL:
            return {
                quizMode: false,
                moduleId: '',
                courseId: '',
                quizType: '',
                clicked: false,
                quizActive: false,
                commenceQuiz: false,
                selectedOptions: [],
                quizCompleted: false,
                quizScore: null
            }
        default:
            return state;
    }
}