import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Uploadlist, byteToMb } from '../CreateLesson/createLesson'
import { openNotification } from '../../../../utils/helpers'
import { useForm } from '../../../../hooks'
import CancelIcon from '../../../../assets/icons/cancel_item.svg'

export function CourseIntroLessons() {
    const [showMediaOutline, setShowMediaOutline] = useState(false)
    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')
    const handleDragEnd = (result) => {
        const { destination, source } = result;
        // Below check for destination, making it impossible to drop item outside a droppable container
        if (!destination) {
            return
        }
        if (destination.droppableId === 'main_box') {
            const rightColumnItem = courseForm.introLessons
            const start = source.index + 1
            const end = destination.index + 1
            if (source.droppableId === 'main_box') {
                if (start === end) {
                    return
                }
                const moving = rightColumnItem[start]
                delete rightColumnItem[start]
                if (start < end) {
                    for (let i = start; i < end; i++) {
                        rightColumnItem[i + 1]['key'] = i
                        rightColumnItem[i] = rightColumnItem[i + 1]
                    }
                }
                if (start > end) {
                    for (let i = start; i > end; i--) {
                        rightColumnItem[i - 1]['key'] = i
                        rightColumnItem[i] = rightColumnItem[i - 1]
                    }
                }
                moving['key'] = end
                rightColumnItem[end] = moving
                setIntroLessons(rightColumnItem)
            }
            // Store the state in localstorage as well, the newOption i mean
        }
    }

    const {
        courseForm,
        setIntroLessons
    } = useForm()

    function handleDel(e) {
        const itemId = parseInt(e.target.parentElement.parentElement.dataset.rbdDragHandleDraggableId, 10)
        const rightcolumn = courseForm.introLessons
        const len = Object.keys(rightcolumn).length
        // let obj
        for (let i = 1; i <= len; i++) {
            if (rightcolumn[i]?.key === itemId) {
                // obj = rightcolumn[i]
                delete rightcolumn[i]
                for (let j = i; j <= len; j++) {
                    rightcolumn[j] = rightcolumn[j + 1]
                }
            }
        }
        delete rightcolumn[len]
        setIntroLessons(rightcolumn)
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="intro_lesson_form_cont" id="intro_lesson_form_cont">
                <div>
                    <div className="form__item__cont">
                        <label>Introductory Lesson Title</label>
                        <Form.Item>
                            <Input
                                id="IntroCourseName"
                                name="course name"
                                placeholder="Name of Course..."
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Introductory Lesson File</label>
                        <Form.Item>
                            <div
                                style={{
                                    outline: showMediaOutline
                                        ? '2px solid red'
                                        : '',
                                    cursor: 'pointer',
                                }}
                                className="file__upload"
                            >
                                <h6>Drop file here</h6>
                                <br />
                                <p>or</p>
                                <br />
                                <p
                                    style={{
                                        // paddingInline: '2em',
                                        border: '2px solid #3a86ff',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        borderRadius: '15px',
                                        height: '35px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Select file
                                    <input
                                        type="file"
                                        name="file"
                                        accept="video/mp4, video/webm, video/ogg"
                                        id="file"
                                        title=""
                                        style={{
                                            width: '200px',
                                            position: 'relative',
                                            top: '-30px',
                                            opacity: '0',
                                            cursor: 'pointer',
                                        }}
                                        onChange={(e) => {
                                            const fileSz = byteToMb(
                                                e.target.files[0].size
                                            )
                                            if (fileSz <= 100) {
                                                setFile({
                                                    file: e.target.files[0],
                                                })
                                            } else {
                                                setShowMediaOutline(true)
                                                openNotification({
                                                    title: 'Image file size',
                                                    type: 'error',
                                                    message:
                                                        'Image size too large ( <5mb )',
                                                })
                                                setTimeout(() => {
                                                    setShowMediaOutline(false)
                                                }, 3000)
                                            }
                                        }}
                                    />
                                </p>
                            </div>
                        </Form.Item>
                        {Object.values(file).map((img, idx) => {
                            return (
                                <Uploadlist
                                    key={idx}
                                    name={img.name}
                                    id="setFile"
                                    onClick={() => setFile({})}
                                />
                            )
                        })}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        style={{
                            fontSize: '16px',
                            fontWeight: '800',
                            borderRadius: '8px',
                            width: '180px',
                            paddingBlock: '.6em',
                            height: 'auto',
                        }}
                        disabled={
                            Object.keys(courseForm.introLessons).length === 4
                        }
                        id="setIntroLesson"
                        type="primary"
                        onClick={() => {
                            if (title === '') {
                                openNotification({
                                    title: 'Introductory Lesson Title',
                                    type: 'error',
                                    message: 'Introductory Lesson Title Field Is Required',
                                })
                            } else if (file === '') {
                                openNotification({
                                    title: 'Introductory Lesson File',
                                    type: 'error',
                                    message: 'Introductory Lesson File Field Is Required',
                                })
                            } else {
                                if (
                                    Object.keys(courseForm.introLessons)
                                        .length < 4
                                ) {
                                    const length =
                                        Object.keys(courseForm.introLessons)
                                            .length + 1
                                    const prev = courseForm.introLessons
                                    const newObj = {
                                        key: length,
                                        title: title[0].toUpperCase() + title.slice(1),
                                        file: file.file,
                                    }
                                    prev[length] = newObj
                                    setIntroLessons(prev)
                                    setTitle('')
                                    setFile('')
                                }
                            }
                        }}
                    >
                        Select
                    </Button>
                </div>
                <div className="box_container" style={{ marginTop: '2em' }}>
                    <Droppable droppableId="main_box" type="Intro_lessons">
                        {(provided, snapshot) => (
                            <div
                                className={
                                    snapshot.isDraggingOver
                                        ? 'box right_box dragOver'
                                        : 'box right_box'
                                }
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ width: '80%', marginLeft: '9%' }}
                            >
                                {Object.keys(courseForm.introLessons).length <
                                    1 && (
                                    <div
                                        className="empty_box"
                                        style={{
                                            width: '90',
                                            margin: '1em auto',
                                        }}
                                    >
                                        <h4>Lesson has not been Selected</h4>
                                        <h4>Start by Selecting a New Lesson</h4>
                                    </div>
                                )}
                                {courseForm.introLessons &&
                                    Object.keys(courseForm.introLessons).map(
                                        (order, idx) => (
                                            <Draggable
                                                draggableId={courseForm?.introLessons?.[
                                                    order
                                                ]?.key.toString()}
                                                index={idx}
                                                key={
                                                    courseForm?.introLessons?.[
                                                        order
                                                    ]?.key
                                                }
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        className={
                                                            snapshot.isDragging
                                                                ? 'box_item dragging'
                                                                : 'box_item'
                                                        }
                                                    >
                                                        {!snapshot.isDragging && (
                                                            <div className="order">
                                                                {order}.
                                                            </div>
                                                        )}{' '}
                                                        <div
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                snapshot.isDragging
                                                                    ? 'item_name dragging'
                                                                    : 'item_name'
                                                            }
                                                        >
                                                            <span>
                                                                {
                                                                    courseForm?.introLessons?.[
                                                                        order
                                                                    ]?.title
                                                                }
                                                            </span>{' '}
                                                            {!snapshot.isDragging && (
                                                                <span
                                                                    className="del_item"
                                                                    onClick={
                                                                        handleDel
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            CancelIcon
                                                                        }
                                                                        alt="del"
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    )
}
