import avatar from '../assets/avatar.png'

const Announcement = () => {
    return (
        <div className="announcements-container">
            <div className="announcements-card">
                <div className="announcement-card-header ">
                    <img
                        src={avatar}
                        className="announcements-card-avatar"
                        alt=""
                    />
                    <p className="announcements-instructor-name">
                        John Smith | Instructor
                    </p>
                    <p className="announcements-date">DD MM YY</p>
                </div>
                <p className="announcement-body">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Magnam mollitia eaque quisquam, debitis iure, aut sunt fuga
                    accusantium corrupti officia numquam quas voluptatum!
                    Molestiae perferendis mollitia ipsam dolor quidem numquam
                    nihil officiis, ea aliquam facere debitis, quia totam qui
                    earum?
                </p>
            </div>
        </div>
    )
}

export default Announcement
