import React, { useState } from 'react'

import teacher from './assets/teacherCircular.png'
import star from './assets/starIcon.png'
import medal from './assets/badgeIcon.png'
import people from './assets/peopleIcon.png'
import play from './assets/playIcon.png'

import './teacherInfo.scss'

const ReadMore = ({ children }) => {
    const text = children
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 400) : text}
            <br />
            <span onClick={toggleReadMore} className="readHideTeach">
                {isReadMore ? 'Read More' : ' Show Less'}
            </span>
        </p>
    )
}

const TeacherInfo = () => {
    return (
        <section id="teacherInfo">
            <div id="content">
                <h2 id="teacherText">Teacher Information</h2>

                <img src={teacher} alt="Teacher graphic" id="teacherGraphic2" />

                <div id="bio">
                    <h3 id="teacherName">John Doe</h3>
                    <p id="jobTitle">Senior Front-end developer at Google</p>
                    <a href="follow" id="follow">
                        Follow
                    </a>
                    <a href="viewCourses" id="viewCourses">
                        View my courses
                    </a>
                </div>

                <div id="ratings">
                    <img
                        src={teacher}
                        alt="Teacher graphic"
                        id="teacherGraphic1"
                    />
                    <div id="rating1">
                        <img src={star} alt="Star icon" className="icon" />
                        <p className="info">4.5 star rating</p>
                    </div>
                    <div id="rating2">
                        <img src={medal} alt="Medal icon" className="icon" />
                        <p className="info">253 reviews</p>
                    </div>
                    <div id="rating3">
                        <img src={people} alt="People icon" className="icon" />
                        <p className="info">13,400 Students </p>
                    </div>
                    <div id="rating4">
                        <img
                            src={play}
                            alt="Play button icon"
                            className="icon"
                        />
                        <p className="info">12 Courses</p>
                    </div>
                </div>

                <ReadMore>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum habitasse vitae amet cras integer morbi diam.
                    Tortor tincidunt velit pellentesque sit. Ultrices pharetra
                    facilisis risus elementum pharetra quam. Eget vestibulum
                    integer quis a. Proin nunc condimentum ac nunc lectus dui
                    mus sit nunc. Ultricies urna, ultricies quis urna molestie
                    amet. Quis turpis euismod vulputate tortor nulla commodo.
                    Erisque enim ligula venenatis dolor. Maecenas nisl est,
                    ultrices nec congue eget, auctor vitae massa. Fusce luctus
                    vestibulum augue ut aliquet. Nunc sagittis dictum nisi, sed
                    ullamcorper ipsum dignissim ac. In at libero sed nunc
                    venenatis imperdiet sed ornare turpis. Donec vitae dui eget
                    tellus gravida venenatis. Integer fringilla congue eros non
                    fermentum. Sed dapibus pulvinar nibh tempor porta.
                </ReadMore>
            </div>
        </section>
    )
}

export default TeacherInfo
