import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import {
    ReviewCourseById,
    findAllTopics,
    PublishCourse,
} from '../../../../services'
import './style.scss'
import Difficulty_icon_advanced from '../../../../assets/icons/difficulty_advanced.svg'
import Difficulty_icon_intermediate from '../../../../assets/icons/difficulty_intermediate.svg'
import Difficulty_icon_beginner from '../../../../assets/icons/difficulty_beginner.svg'
// import Module_image from '../../../assets/icons/ModuleImage.svg'
// import Video_content from '../../../assets/icons/Video Content.svg'
import { Button } from '../../../../components'

export const ReviewCourse = () => {
    const [course, setCourse] = useState({})
    const [allTopics, setAllTopics] = useState([])
    const [topics, setTopics] = useState([])
    const [courseCatagories, setCourseCatagories] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const courseIdAddress = window.location.pathname
    const courseId = courseIdAddress.split('/').pop()

    useEffect(() => {
        const getCourseDetails = async () => {
            try {
                const response = await ReviewCourseById(courseId)
                setCourse(response.data)
                setTopics(
                    JSON.parse(response.data.courseModel.courseInfor.topicIds)
                )
            } catch (err) {
                console.error(err)
            }
            // setQuestionLoading(false)
        }
        getCourseDetails()
    }, [courseId])

    useEffect(() => {
        const getTopics = async () => {
            try {
                const response = await findAllTopics()
                setAllTopics(response.data)
            } catch (err) {
                console.error(err)
            }
            // setQuestionLoading(false)
        }
        getTopics()
    }, [])

    useEffect(() => {
        const generateTopicsArray = () => {
            if (allTopics.length !== 0 && topics.length !== 0) {
                const mappedTopics = topics.map((topicId) => {
                    const topicNames = allTopics.find(
                        (topic) => topic.id === topicId
                    )
                    return topicNames?.name
                })
                setCourseCatagories(mappedTopics)
            }
        }
        generateTopicsArray()
    }, [allTopics, topics])

    const getDifficulty = (value) => {
        if (value === 1) {
            return <img src={Difficulty_icon_beginner} alt="Beginner" />
        } else if (value === 2) {
            return <img src={Difficulty_icon_intermediate} alt="Intermediate" />
        } else if (value === 3) {
            return <img src={Difficulty_icon_advanced} alt="Advanced" />
        }
    }

    const handlePublish = (id) => {
        publishCourse(parseInt(id, 10))
        setIsModalVisible(false)
    }

    const publishCourse = async (id) => {
        try {
            const response = await PublishCourse({
                courseId: id,
            })
            console.log(response.data)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <div id="ReviewPage">
            <div className="header">
                <h1>Review Submitted Course</h1>
            </div>
            <h2 className="courseName">
                {course?.courseModel?.courseInfor.name}
                <span>{course?.courseModel?.courseInfor.instructorName}</span>
            </h2>
            <div className="courseDetails">
                <div className="info summary">
                    <h3>Course Summary</h3>
                    <p>{course?.courseModel?.courseInfor.description}</p>
                </div>
                <div className="info">
                    <h3>Difficulty</h3>
                    {getDifficulty(
                        course?.courseModel?.courseInfor.courseLevel
                    )}
                </div>
                <div className="info">
                    <h3>Course Categories</h3>
                    <div className="categories">
                        {courseCatagories.map((val) => {
                            return <button> {val} </button>
                        })}
                    </div>
                </div>
                <div className="info">
                    <h3>Prerequisites</h3>
                    <ul className="prerequisites">
                        <li>
                            {course?.courseModel?.courseInfor.prerequisites[1]}
                        </li>
                    </ul>
                </div>
                <div className="info">
                    <h3>Language</h3>
                    <ul>
                        <li>
                            <p>{course?.courseModel?.courseInfor.language}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <h2 className="modulesTitle">Modules and Lessons</h2>

            <div>
                {course?.courseContent?.map((val) => {
                    return (
                        <div className="moduleAndLessonContainer">
                            <div className="moduleName">
                                <h3>{val.moduleName}</h3>
                                <p>{val.summary}</p>
                            </div>
                            {val.lessons.map((val) => {
                                return (
                                    <div className="lessonContainer">
                                        <div className="lesson">
                                            <h3 className="lessonName">
                                                {val.name}
                                            </h3>
                                            <p>{val.summary}</p>
                                        </div>
                                        <div className="lesson">
                                            <h4>Lesson Resources</h4>
                                            {val.resource.map((val) => {
                                                return <p>{val.fileName}</p>
                                            })}
                                        </div>
                                        <div className="lesson content">
                                            <div>
                                                <h4>Lesson content</h4>
                                                {val.content
                                                    .split('?')[0]
                                                    .split('/')
                                                    .pop()}
                                            </div>
                                            <div>
                                                <h4>Lesson Length</h4>
                                                <p>
                                                    {val.totalLength +
                                                        ' minutes'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="buttons">
                <Link to={'/tutor/review-course/' + courseId}>
                    <Button className="editButton">Edit Course</Button>
                </Link>
                <Button
                    className="submitButton"
                    onClick={() => setIsModalVisible(true)}
                >
                    Submit for review
                </Button>
            </div>
            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[null]}
                closable={false}
            >
                <div className="lessonCardModalText">
                    <h3>
                        {' '}
                        Are you sure you want to Submit this course for
                        approval?
                    </h3>
                    <br />
                    <p> You will not be able to Edit it after this point </p>
                    <br />
                </div>
                <div className="lessonCardModalButtons">
                    <div className="lessonCardModalButton">
                        <Button
                            primary
                            id="finishCancelBtn"
                            onClick={() => handleCancel()}
                        >
                            <p>Cancel</p>
                        </Button>
                    </div>
                    <div className="modalButton">
                        <Button
                            primary
                            className="goBackBtn"
                            onClick={() => handlePublish(courseId)}
                        >
                            <p>Confirm</p>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
