import React from 'react'
import './Feedback.scss'

const Feedback = ({ feedbackRef, inputValue, setInputValue, listOfComments, setListOfComments }) => {

    // Handler for adding a new comment
    const handleAddComment = () => {
        if (!inputValue.trim()) return // Ignore empty inputs
        setListOfComments((prevComments) => [...prevComments, inputValue])
        setInputValue('')
    }

    return (
        <div ref={feedbackRef} id="feedback--section">
            <p className="head-text">Feedback/Comment</p>
            <p className="faint-text">
                Please inform the instructor of the sections requiring further
                attention.
            </p>
            <div className="block-1">
                <input
                    type="text"
                    name="input-commment"
                    className="input-comment"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <button className="add-comment" onClick={handleAddComment}>
                    Add next comment
                </button>
            </div>
            {/* Optionally display the comments */}
            <div style={{ marginLeft: '20px', marginBottom: '20px', }}>
                {listOfComments.length > 0 && (
                    <ul>
                        {listOfComments.map((comment, index) => (
                            <li key={index}>{comment}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default Feedback
