import React, { useState } from 'react'
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
// import Form from ant design
import { Form } from 'antd'
import { Input, Button, AppLayout } from '../../../components'
import { forgetPasswordCallback } from '../../../services'
import { openNotification } from '../../../utils'
import '../shared/FormStyling.scss'

export const ResetPassword = () => {
    const [loading, setLoading] = useState(false)

    const location = useLocation()
    const history = useHistory()
    const params = queryString.parse(location.search)

    const { email, token } = params
    const [form] = Form.useForm()

    const handlePasswordReset = async (values) => {
        setLoading(true)
        try {
            const response = await forgetPasswordCallback({
                email,
                token,
                ...values,
            })
            openNotification({
                type: 'success',
                title: 'Forgot Password',
                message: response.message,
            })
            history.push('/account/login')
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Forgot Password',
                message: error.message,
            })
        }
        setLoading(false)
    }

    if (!email || !token) {
        return <Redirect to="/account/forgot-password" />
    }

    return (
        <AppLayout>
            <section id="change-password">
                <div>
                    <p className="mb-4 text-center">Hi, {email}</p>
                    <h3 className="text-center py-3">Reset Your Password</h3>
                    <Form
                        name="form"
                        form={form}
                        onFinish={handlePasswordReset}
                        autoComplete="on"
                    >
                        <Input.Password
                            name="password"
                            validationRules={[
                                {
                                    required: true,
                                    message: 'Please enter your new password',
                                },
                                {
                                    pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{9,}$/,
                                    message:
                                        'Password should contain at least 8 characters including 1-uppercase, 1-lowercase, 1-alphanumeric and 1-number',
                                },
                            ]}
                            placeholder="New Password"
                            disabled={loading}
                        />

                        <Input.Password
                            name="confirmPassword"
                            dependencies={['password']}
                            validationRules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered do not match!'
                                            )
                                        )
                                    },
                                }),
                            ]}
                            placeholder="Confirm Password"
                            disabled={loading}
                        />

                        <Button
                            loading={loading}
                            type="submit"
                            disabled={loading}
                        >
                            Reset Password
                        </Button>

                        <p className="py-3 text-center text-small">
                            Back to{' '}
                            <Link
                                to="/account/login"
                                style={{ color: '#3a86ff' }}
                            >
                                Login
                            </Link>
                        </p>
                    </Form>
                </div>
            </section>
        </AppLayout>
    )
}
