import React, { useRef } from 'react'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { CertTemplate } from './certTemplate'

export const CourseCertificate = () => {
    const pdfRef = useRef()

    const downloadPDF = () => {
        const input = pdfRef.current
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new JsPDF('l', 'mm', 'a4', true)
            const pdfWidth = 297 // A4 width in mm
            const pdfHeight = 210; // A4 height in mm
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgX = 0;
            const imgY = (pdfHeight - imgHeight) / 2;
            pdf.addImage(imgData, 'JPEG', imgX, imgY, imgWidth, imgHeight);
            pdf.save('certificate.pdf');
            // pdf.addImage(imgData, 'PNG', 1, 17, 300, 170, 'FAST')
            // pdf.save('certificate.pdf')
        })
    }

    return (
        <div
            className="course--certificate--container"
            style={{
                width: '100%',
                // height: "100vh",
                padding: '70px 40px 70px 40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '100%',
                    // marginBottom: '150px',
                }}
            >
                <CertTemplate pdfRef={pdfRef} />
            </div>
            <div>
                <button
                    style={{
                        background: '#3A86FF',
                        marginTop: '30px',
                        padding: '8px 13px',
                        borderRadius: '8px',
                        color: 'white',
                    }}
                    onClick={downloadPDF}
                >
                    Download Certificate
                </button>
            </div>
        </div>
    )
}
// export default CourseCertificate
