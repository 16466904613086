import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import Searchbar from '../../../../components/Searchbar/searchbar.jsx'
// import {
//     // softSearchCourses,
//     findCoursesByName
// } from '../../../../services'
// import { Link } from 'react-router-dom'

import './hero.scss'
import 'swiper/swiper.scss'
import hero1 from './assets/hero1.jpg'
import hero2 from './assets/hero2.jpg'
import hero3 from './assets/hero3.jpg'
import hero4 from './assets/hero4.jpg'

const Hero = () => {
    return (
        <section id="hero">
            <div className="search-container">
                <Searchbar type="course" />
            </div>
            <Swiper
                spaceBetween={0}
                modules={[Autoplay]}
                autoplay={{ delay: 10000, disableOnInteraction: false }}
                slidesPerView={1}
                centeredSlides={true}
                // navigation
                navigation={{
                    nextEl: null,
                    prevEl: null,
                }}
                loop={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <div className="img-container">
                        <div className="img-card">
                            <p>
                                Start learning today at Zustech and learn
                                leading skills that will enhance your value and
                                opportunities in the global market
                                place.
                            </p>
                        </div>
                        <img className="hero1-img" src={hero1} alt="hero1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <div className="img-card">
                            <p>
                                Start your free trial today
                                and launch your career at Zustech
                            </p>
                        </div>
                        <img className="hero1-img" src={hero2} alt="hero1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <div className="img-card">
                            <p>
                                Join our team of instructors around the globe.
                                We provide the best resources needed for
                                effective teaching and learning to support our
                                learners' success.
                            </p>
                        </div>
                        <img className="hero1-img" src={hero3} alt="hero1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <div className="img-card">
                            <p>
                                Purchase a subscription to proceed with your course and unlock
                                access to all our informative and expertly guided
                                courses.
                            </p>
                        </div>
                        <img className="hero1-img" src={hero4} alt="hero1" />
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}
export default Hero

// SEARCH IMPLEMENTATION FROM BACKEND -- SOFTSEARCH

// const handleChange = async (event) => {
//     const { value } = event.target
//     // setInput(value)
//     if (value.length !== 0) {
//         try {
//             // fetch data
//             const response = await softSearchCourses(value)
//             setSearchResult(response?.data)
//             console.log(searchResult)
//         } catch (error) {
//             console.log(error)
//         }
//     } else {
//         setSearchResult([])
//     }
// }

// const debouce = (func) => {
//     let timeout

//     return function (...args) {
//         const context = this
//         if (timeout) clearTimeout(timeout)
//         timeout = setTimeout(() => {
//             timeout = null
//             func.apply(context, args)
//         }, 500)
//     }
// }

// const optimizedFunc = useCallback(debouce(handleChange), []) // eslint-disable-line react-hooks/exhaustive-deps

// <div className="search-container">
//     <input
//         onClick={optimizedFunc}
//         type="text"
//         className="search-bar"
//         placeholder="Search Courses... "
//     />
//     {searchResult.length !== 0 && (
//         <div className="search-result">
//             {searchResult.map((value) => (
//                 <Link
//                     key={value.name}
//                     to={`course-content/${value?.id}`}
//                 >
//                     <div className="search-course-div">
//                         <h4>{value.name}</h4>
//                     </div>
//                 </Link>
//             ))}
//         </div>
//     )}
// </div>

// SEARCH FILTER FROM LOADED COURSE ON FRONTEND

// <div className="search-container">
//     <input
//         onClick={optimizedFunc}
//         onChange={(e) => setSearchInput(e.target.value)}
//         type="text"
//         className="search-bar"
//         placeholder="Search Courses... "
//     />
//     <div className="search-result">
//         {searchInput.length !== 0 &&
//             courseData.filter(
//                 (item) =>
//                     item.name.toLowerCase().includes(searchInput)
//             )
//                 .map((value) => (
//                     <Link
//                         key={value.name}
//                         // to={`course-content/${value?.id}`}
//                         to={`courses/course/${value?.id}`}
//                     >
//                         <div
//                             className="search-course-div"
//                             key={value.id}
//                         >
//                             <h4>{value.name}</h4>
//                         </div>
//                     </Link>
//                 ))}
//     </div>
// </div>