import React from 'react'
import { Displaycontent } from './Displaycontent'

export function Termspage() {
    const content = [
        {
            key: 1,
            type: 'obj',
            title: 'User Agreement',
            content: [
                {
                    key: 1.1,
                    subtitle: null,
                    subcontent:
                        'As an instructor, you are responsible for all content that you post, including lectures, quizzes, coding exercises, practice tests, assignments, resources, answers, course landing page content, labs, assessments, and announcements ("Submitted Content"). ',
                },
                {
                    key: 1.2,
                    subtitle: 'You represent and warrant that:',
                    subcontent: [
                        'you will provide and maintain accurate account information;',
                        'you own or have the necessary licenses, rights, consents, permissions, and authority to authorize Zustech to use your Submitted Content as specified in these Terms and the Terms of Use;',
                        "your Submitted Content will not infringe or misappropriate any third party's intellectual property rights;",
                        'you have the required qualifications, credentials, and expertise (including education, training, knowledge, and skill sets) to teach and offer the services that you offer through your Submitted Content and use of the Services; and ',
                        'you will ensure a quality of service that corresponds with the standards of your industry and instruction services in general;',
                        '',
                    ],
                },
                {
                    key: 1.3,
                    subtitle: 'You warrant that you will not:',
                    subcontent: [
                        'post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, infringing, defamatory or libellous content or information;',
                        'post or transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation (commercial or otherwise) through the Services or to any user;',
                        'use the Services for business other than providing tutoring, teaching, and instructional services to students;',
                        'engage in any activity that would require us to obtain licenses from or pay royalties to any third party, including the need to pay royalties for the public performance of a musical work or sound recording;',
                        'frame or embed the Services (such as to embed a free version of a course) or otherwise circumvent the Services;',
                        "impersonate another person or gain unauthorized access to another person's account;",
                        'interfere with or otherwise prevent other instructors from providing their services or content; or ',
                        'abuse Zustech resources, including support services.',
                    ],
                },
            ],
        },
        {
            key: 2,
            type: null,
            title: 'Privacy Policy',
            content: [
                'You need an account for most activities on our platform, including purchasing and accessing content or submitting content for publication. When creating and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You are fully responsible for your account and everything that happens on your account, including any harm or damage (to us or anyone else) caused by someone using your account without your permission. ',
                "This means you need to be careful with your password. You may not transfer your account to another person or use another person's account. If you contact us to request access to your account, we will not grant you access unless you can provide us with the information we need to prove that you are the owner of that account. You cannot share your account login information with anyone else. You are responsible for what happens to your account, and Zustech will not intervene in disputes between students or instructors who have shared account login information. You must notify us immediately upon becoming aware that someone else may be using your account without your permission (or if you suspect another security breach) by contacting our support team. We may ask you to provide certain information to confirm that you are the owner of your account.",
                "Students and instructors must be at least 18 years old to create an account on Zustech and use the Services. If you are under 18 but over the age required to consent to use online services where you live (e.g.13 in the US or 16 in Ireland), you cannot create an account but we encourage you to invite your parents or guardian to open an account and help you access to content that's right for you. If you are not old enough to consent to using the Online Services, you may not create a Zustech account.",
                'If we discover that you have created an account in violation of these rules, we will terminate your account. Per our Instructor Terms of Service, you may be required to verify your identity before being allowed to submit content for publication on Zustech. You can cancel your account at any time by following the steps here.',
            ],
        },
        {
            key: 3,
            type: null,
            title: 'Content Usage & Ownership ',
            content: [
                'By posting content on Zustech, you retain ownership but grant Zustech the right to reuse and share it. This includes comments, questions, reviews, and suggestions for improvements. You authorise Zustech to use, distribute, modify, and promote your content on any platform and media. You also grant Zustech the right to sublicense this content to others.',
                "By submitting content, you give Zustech a worldwide, non-exclusive, royalty-free license to use, adapt, publish, display, and distribute it. This includes making it available to partners and using it for marketing purposes. You waive any privacy or publicity rights related to this use. You confirm that you have the necessary rights to authorize Zustech's use of your content, and you agree to these terms without compensation.",
            ],
        },
        {
            key: 4,
            type: null,
            title: 'Payments and Billing',
            content: [
                'You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit card or process other means of payment (such as direct debit, or mobile wallet) for those fees. Zustech works with payment service providers to offer you the most convenient payment methods in your country and to keep your payment information secure. We may update your payment methods using information provided by our payment service providers.',
                'When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the content you are enrolling in, you agree to pay us the corresponding fees within 30 days of notification from us. We reserve the right to disable access to any content for which we have not received adequate payment.',
            ],
        },
        {
            key: 5,
            type: null,
            title: 'Subscription',
            content: [
                'The subscription fee will be listed at the time of your purchase. We may also be required to add taxes to your subscription fee as described in the “Payments, Credits, and Refunds” section above. Payments are non-refundable and no refunds or credits for partially used periods unless otherwise required by applicable law. Depending on where you are located, you may qualify for a refund. See our Refund Policy for Subscription Plans for additional information.',
                'To subscribe to a Subscription Plan, you must provide a payment method. By subscribing to a Subscription Plan and providing your billing information during checkout, you grant us and our payment service providers the right to process payment for the then-applicable fees via the payment method we have on record for you. At the end of each subscription term, we will automatically renew your subscription for the same length of term and process your payment method for payment of the then-applicable fees.',
                'If we update your payment method using the information provided by our payment service providers (as described in the “Payments, Credits, and Refunds” section above), you authorize us to continue to charge the then-applicable fees to your updated payment method.',
                'If we are unable to process payment through the payment method we have on file for you, or if you file a chargeback disputing charges made to your payment method and the chargeback is granted, we may suspend or terminate your subscription.',
                'We reserve the right to change our Subscription Plans or adjust pricing for our Services at our sole discretion. Any price changes or changes to your subscription will take effect following notice to you, except as otherwise required by applicable law.',
            ],
        },
        {
            key: 6,
            type: null,
            title: 'Refunds Policy ',
            content: [
                'If the content you purchased is not what you were expecting, you can request, within 30 days of your purchase of the content, that Zustech applies a refund to your account. This refund option does not apply to Subscription Plan purchases, which are covered in Section 8.4 (Payments and Billing) below. We reserve the right to apply your refund as a refund credit or a refund to your original payment method, at our discretion, depending on the capabilities of our payment service providers, the platform from which you purchased your content (website, mobile or TV app), and other factors.',
                'No refund is due to you if you request it after the 30-day guarantee time limit has passed. However, if the content you previously purchased is disabled for legal or policy reasons, you are entitled to a refund beyond this 30-day limit. Zustech also reserves the right to refund students beyond the 30-day limit in cases of suspected or confirmed account fraud.',
                'To request a refund, follow the steps here. As detailed in the Instructor Terms, instructors agree that students have the right to receive these refunds.',
                'If we decide to issue refund credits to your account, they will be automatically applied towards your next content purchase on our website, but can’t be used for purchases in our mobile or TV applications. Refund credits may expire if not used within the specified period and have no cash value, in each case unless otherwise required by applicable law.',
                'At our discretion, if we believe you are abusing our refund policy, such as if you’ve consumed a significant portion of the content that you want to refund or if you’ve previously refunded the content, we reserve the right to deny your refund, restrict you from other future refunds, ban your account, and/or restrict all future use of the Services. If we ban your account or disable your access to the content due to your violation of these Terms, you will not be eligible to receive a refund. Additional information on our refund policy is available here.',
            ],
        },
        {
            key: 7,
            type: null,
            title: 'User Conduct and Behaviour',
            content: [
                'You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behaviour on our platform must comply with applicable local or national laws or regulations of your country. You are solely responsible for the knowledge of and compliance with such laws and regulations that apply to you.',
                'If you are a student, the Services enable you to ask questions to the instructors of courses or other content you are enrolled in and to post reviews of content. For certain content, the instructor may invite you to submit content as “homework” or tests. Don’t post or submit anything that is not yours.',
                'If you are an instructor, you can submit content for publication on the platform and you can also communicate with the students who have enrolled in your courses or other content. In both cases, you must abide by the law and respect the rights of others: you cannot post any course, question, answer, review or other content that violates applicable local or national laws or regulations of your country. You are solely responsible for any courses, content, and actions you post or take via the platform and Services and their consequences. Make sure you understand all the copyright restrictions outlined in the Instructor Terms before you submit any content for publication on Zustech.',
                'If we are put on notice that your course or content violates the law or the rights of others (for example, if it is established that it violates the intellectual property or image rights of others, or is about an illegal activity), if we discover that your content or behaviour violates our Terms & Conditions, or if we believe your content or behaviour is unlawful, inappropriate, or objectionable (for example if you impersonate someone else), we may remove your content from our platform.',
                'We may restrict or terminate your permission to use our platform and Services or ban your account at any time, with or without notice, for any or no reason, including for any violation of these Terms, if you fail to pay any fees when due, for fraudulent chargeback requests, upon the request of law enforcement or government agencies, for extended periods of inactivity, for unexpected technical issues or problems, if we suspect that you engage in fraudulent or illegal activities, or for any other reason in our sole discretion. Upon any such termination, we may delete your account and content, and we may prevent you from further access to the platforms and use of our Services.',
                'Your content may still be available on the platforms even if your account is terminated or suspended. You agree that we will have no liability to you or any third party for termination of your account, removal of your content, or blocking of your access to our platforms and services.',
                'If a user has published content that infringes your copyright or trademark rights, please let us know. Our Instructor Terms require our instructors to follow the law and respect the intellectual property rights of others.',
            ],
        },
        {
            key: 8,
            type: 'obj',
            title: 'Zustech Right ',
            content: [
                {
                    key: 8.1,
                    subtitle: null,
                    subcontent:
                        'All rights, title, and interest in and to the Zustech platform and Services, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services (but excluding content provided by instructors and students) are and will remain the exclusive property of Zustech and its licensors. Our platforms and services are protected by copyright, trademark, and other laws in both the United States and foreign countries. Nothing gives you a right to use the Zustech name or any of the Zustech trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding Zustech or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.',
                },
                {
                    key: 8.2,
                    subtitle:
                        'You may not do any of the following while accessing or using the Zustech platform and Services: ',
                    subcontent: [
                        'access, tamper with, or use non-public areas of the platform (including content storage), Zustech computer systems, or the technical delivery systems of Zustech service providers.',
                        'disable, interfere with, or try to circumvent any of the features of the platforms related to security or probe, scan, or test the vulnerability of any of our systems.',
                        'copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the Zustech platform or Services.',
                        'access or search or attempt to access or search our platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website, mobile apps, or API (and only according to those API terms and conditions). You may not scrape, spider, use a robot, or use other automated means of any kind to access the Services.',
                        'in any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as Zustech); or interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the platforms or services, or in any other manner interfering with or creating an undue burden on the Services. ',
                    ],
                },
            ],
        },
        {
            key: 9,
            type: null,
            title: 'Dispute Resolution',
            content: [
                'Zustech is committed to using its best efforts to resolve disputes with its users, without the need for a formal legal claim to be filed. If an issue arises between us, you and Zustech agree to first work diligently and in good faith to reach a resolution that’s fair and equitable to both sides using the mandatory informal dispute resolution process described below. On occasion, a third party may be necessary to help resolve our dispute. This Dispute Resolution Agreement limits how these disputes can be resolved.',
                'YOU AND ZUSTECH AGREE THAT ANY AND ALL DISPUTES, CLAIMS, OR CONTROVERSIES ARISING OUT OF OR RELATING TO THESE TERMS OR THE APPLICABILITY, BREACH, TERMINATION, VALIDITY, ENFORCEMENT, OR INTERPRETATION OF THEM, OR TO THE USE OF THE SERVICES OR COMMUNICATIONS WITH ZUSTECH (COLLECTIVELY, “DISPUTES”) THAT ARE NOT RESOLVED INFORMALLY MUST BE ADDRESSED SOLELY IN SMALL CLAIMS COURT OR BY BINDING INDIVIDUAL ARBITRATION AND AGREE TO WAIVE THE RIGHT TO A JURY TRIAL AND TO FILE A CASE IN ANY OTHER COURT.',
                'YOU AND ZUSTECH FURTHER AGREE TO BRING CLAIMS AGAINST EACH OTHER IN AN INDIVIDUAL CAPACITY ONLY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE PROCEEDING WHETHER IN COURT OR ARBITRATION.',
                "You and Zustech agree that this Dispute Resolution Agreement applies to each of us as well as all of our respective agents, attorneys, contractors, subcontractors, service providers, employees, and all others acting for, or on behalf of, you and Zustech. This Dispute Resolution Agreement is binding on your and Zustech's respective heirs, successors, and assigns, and is governed by the Federal Arbitration Act.",
            ],
        },
        {
            key: 10,
            type: null,
            title: 'Disclaimers',
            content: [
                'Our platform may be down, either for planned maintenance or because something goes down with the site. It may happen that one of our instructors is making misleading statements in their content. It may also happen that we encounter security issues.',
                'These are just examples. You accept that you will not have any recourse against us in any of these types of cases where things don’t work out right. In legal, more complete language, the Services and their content are provided on an “as is” and “as available” basis.',
                'We (and our affiliates, suppliers, partners, and agents) make no representations or warranties about the suitability, reliability, availability, timeliness, security, lack of errors, or accuracy of the Services or their content, and expressly disclaim any warranties or conditions (express or implied), including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. We (and our affiliates, suppliers, partners, and agents) make no warranty that you will obtain specific results from the use of the Services. Your use of the Services (including any content) is entirely at your own risk. Some jurisdictions don’t allow the exclusion of implied warranties, so some of the above exclusions may not apply to you.',
                'We may decide to cease making available certain features of the Services at any time and for any reason. Under no circumstances will Zustech or its affiliates, suppliers, partners or agents be held liable for any damages due to such interruptions or lack of availability of such features.',
                'We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, or sabotage; natural disaster; electrical, internet, or telecommunication outage; or government restrictions.',
            ],
        },
        {
            key: 11,
            type: null,
            title: 'Updates & Modifications ',
            content: [
                'From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and Zustech reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means, such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise.',
                'Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.',
            ],
        },
        {
            key: 12,
            type: null,
            title: 'How to Contact Us ',
            content: [
                'The best way to get in touch with us is to contact our Support Team. We’d love to hear your questions, concerns, and feedback about our Services.',
                'Thanks for teaching and learning with us!',
            ],
        },
    ]
    return (
        <section className="terms_cont">
            <h1>Terms and Conditions</h1>
            <section className="content">
                <div className="data_modify">
                    <p className="date">Last Updated: [12th April, 2024]</p>
                    <p className="text">
                        Please read these terms and conditions carefully. These
                        terms and conditions are a legally binding agreement
                        between you, and Zustech Learning Management System.
                        This document governs the terms and conditions of use
                        for our learning management system website. By using our
                        website, you agree to comply with these terms and
                        conditions.
                    </p>
                </div>
                <div className="readable">
                    <Displaycontent content={content} type="irregular" />
                </div>
            </section>
        </section>
    )
}
