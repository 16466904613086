import { useEffect, useReducer } from 'react';
import { CartContext } from './cartContext';
import { CartReducer } from './cartReducer';
import { SHOW_HIDE_CART, ADD_TO_CART, REMOVE_ITEM, CLEAR_CART, GET_TOTALS, UPDATE_GRAND_TOTAL } from '../types';
import { openNotification } from '../../utils/helpers';

export const CartState = ({ children }) => {
    const getCartItems = localStorage.getItem('cartItems')
    const parsedCartItems = JSON.parse(localStorage.getItem('cartItems'))
    const getPremSuccessitems = localStorage.getItem('premSuccessItems')
    const parsedPremSuccessitems = JSON.parse(
        localStorage.getItem('premSuccessItems')
    )

    const initialState = {
        showCart: false,
        cartItems: getCartItems ? parsedCartItems : [],
        premSuccessItems: getPremSuccessitems ? parsedPremSuccessitems : [],
        total: 0,
        grandTotal: 0,
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)

    // initializes and update the cart from local storage as the cart state changes.
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
        localStorage.setItem(
            'premSuccessItems',
            JSON.stringify(state.premSuccessItems)
        ) // Storing some course details needed in the premium success page
        localStorage.setItem('premSuccessExpiration', Date.now() + 1800000) // 1800000 is the number of milliseconds in 30 minutes
    }, [state.cartItems, state.premSuccessItems])

    // Set the premSuccessItems in local storage with an expiration time of 30 minutes
    useEffect(() => {
        const storedPremSuccessExpiration = localStorage.getItem(
            'premSuccessExpiration'
        )
        // Check if the premSuccessItems have expired
        if (
            storedPremSuccessExpiration &&
            storedPremSuccessExpiration < Date.now()
        ) {
            // Clear the expired premSuccessItems from local storage
            localStorage.removeItem('premSuccessItems')
            localStorage.removeItem('premSuccessExpiration')
            // dispatch({ type: CLEAR_CART, payload: { cartType: 'premSuccess' } })
        }
    }, [state.premSuccessItems])

    // Listen for changes to the `clearCart` function and clear the cart from local storage
    useEffect(() => {
        const clearCartHandler = () => {
            localStorage.removeItem('cartItems')
            localStorage.removeItem('premSuccessItems')
        }
        window.addEventListener('clearCart', clearCartHandler)
        return () => {
            window.removeEventListener('clearCart', clearCartHandler)
        }
    }, [])

    const addToCart = (item) => {
        // Checks if the item is already in the cart, else it adds it cart.
        if (state.cartItems.find((cartItem) => cartItem.id === item.id)) {
            openNotification({
                type: 'warning',
                title: 'Unable to Add',
                message: 'Item already exists in the cart',
            })
        } else {
            dispatch({ type: ADD_TO_CART, payload: item })
            openNotification({
                type: 'info',
                title: 'Added to Cart',
                message: 'Item added successfully to cart',
            })
        }
    }

    const showHideCart = () => {
        dispatch({ type: SHOW_HIDE_CART })
    }

    const removeItem = (id) => {
        dispatch({ type: REMOVE_ITEM, payload: id })
    }

    const clearCart = () => {
        dispatch({ type: CLEAR_CART })
        // Trigger a custom event to clear the cart from local storage
        window.dispatchEvent(new Event('clearCart'))
    }

    useEffect(() => {
        dispatch({ type: GET_TOTALS })
    }, [state.cartItems])

    const updateGrandTotal = (discountedAmount) => {
        dispatch({ type: UPDATE_GRAND_TOTAL, payload: discountedAmount })
    }

    return (
        <CartContext.Provider
            value={{
                showCart: state.showCart,
                cartItems: state.cartItems,
                cart: state,
                addToCart,
                showHideCart,
                removeItem,
                clearCart,
                updateGrandTotal,
                premSuccessItems: state.premSuccessItems,
            }}
        >
            {children}
        </CartContext.Provider>
    )
}