// React Hooks
// import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
// import swiper react components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import the Swiper styles
import 'swiper/swiper.scss';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css'
// Component Styles
import './HeroCourses.scss'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const HeroCourses = ({ course }) => {
    const history = useHistory()
    return (
        <section id="heroCourses">
            <div className="slider-hero">
                <Swiper
                    pagination={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    centeredSlides={true}
                    navigation
                    loop={true}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                >
                    {course && course.map((cours) => (
                        <SwiperSlide>
                            <div className="imageCont">
                                <img src={cours.coursePicture} alt={cours.name} />
                            </div>
                            <div className="content">
                                <div className="headinghero">
                                    <h1 className="--header">
                                        {cours.name}
                                    </h1>
                                </div>
                                <p className="text--about">
                                    {cours.description}
                                </p>
                                <button
                                    className="watch"
                                    onClick={() => {
                                        history.push(`/courses/course/${cours.id}`)
                                    }}
                                >Watch now
                                </button>
                                {/* <form action="">
                                    <input/>
                                </form> */}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}
export default HeroCourses;
