export const SET_QUIZ_TITLE = 'SET_QUIZ_TITLE'
export const SET_QUIZ_QUESTION = 'SET_QUIZ_QUESTION'
export const SET_QUESTION_TYPE = 'SET_QUESTION_TYPE'
export const SET_OPTIONA = 'SET_OPTION_A'
export const SET_OPTIONB = 'SET_OPTION_B'
export const SET_OPTIONC = 'SET_OPTION_C'
export const SET_OPTIOND = 'SET_OPTION_D'
export const SET_QUIZ_OPTIONS = 'SET_QUIZ_OPTIONS'
export const SET_ANSWER = 'SET_ANSWER'
export const SET_QUIZ_MODULES = 'SET_QUIZ_MODULES'
export const CLEAR_QUIZ_FORM = 'CLEAR_QUIZ_FORM'