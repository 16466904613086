import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { instructorReducer } from './instrucProd'
import { lessonReducer } from './lessonForm'
import { moduleFormReducer } from './moduleForm'
import { courseFormReducer } from './courseForm'
import { quizFormReducer } from './quizForm'
import { interfaceReducer } from './interface'
import { locationReducer } from './userLocation'
// import { courseOverviewReducer } from './courseOverview'

const rootReducer = combineReducers({
    auth: authReducer,
    instruct: instructorReducer,
    lessonForm: lessonReducer,
    moduleForm: moduleFormReducer,
    courseForm: courseFormReducer,
    quizForm: quizFormReducer,
    interFace: interfaceReducer,
    userLocation: locationReducer
})

export default rootReducer
