export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const LOGIN_ADMIN_FAIL = 'LOGIN_USER_FAIL'
export const LOGIN_ADMIN_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const REDIRECT = 'REDIRECT'
export const ACTIVE_SSO = 'ACTIVE_SSO'
export const CLOSE_SSO_MODAL = 'CLOSE_SSO_MODAL'
export const SET_USER_ACTIVE_SUBSCRIPTION = 'SET_USER_ACTIVE_SUBSCRIPTION'
export const SET_USER_COURSE_SUBSCRIPTIONS = 'SET_USER_COURSE_SUBSCRIPTIONS'
export const GET_USER_ACTIVE_SUBSCRIPTION = 'GET_USER_ACTIVE_SUBSCRIPTION'
export const GET_USER_COURSE_SUBSCRIPTIONS = 'GET_USER_COURSE_SUBSCRIPTIONS'
