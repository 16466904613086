import React, { useEffect, useState } from 'react'
import {
    // useHistory,
    Link
} from 'react-router-dom'
import { getDownloadCourseCertificate } from '../../../../../../services'
import './style.scss'
import { useAuth } from '../../../../../../hooks'
// import { openNotification } from '../../../../../../utils'

export const DownloadCertButton = ({ courseId }) => {
    const {
        state: { data: user },
    } = useAuth()
    // const history = useHistory()
    const [certUrl, setCertUrl] = useState('')

    useEffect(() => {
        const GetCertificate = async () => {
            try {
                const res = await getDownloadCourseCertificate(
                    courseId,
                    user.id
                )
                setCertUrl(res?.data?.certUrl)
                // setCertName(res?.data?.name)
                console.log(res?.data)
                console.log(certUrl)
            } catch (err) {
                console.log(err.message)
            }
        }
        GetCertificate()
        // eslint-disable-next-line
    }, [])

    return (
        <Link to={`/me/download-certificate/${courseId}`}>
            <div
                className="download-cert-btn"
                // href={`${certUrl}`}
            >
                View Certificate
            </div>
        </Link>
    )
}

// <a
//     className="download-cert-btn"
//     // onClick={handleRedirect}
//     href={`${certUrl}`}
// >
//     Download Certificate
// </a>

// openNotification({
//     title: 'Download Certificate',
//     message: 'Certificate not available for download.',
//     type: 'error',
// })

// const ExternalLink = ({ url, children }) => {
//     const handleClick = (event) => {
//         event.preventDefault()
//         window.open(url, '_blank')
//     }

//     return (
//         <a
//             href={url}
//             onClick={handleClick}
//             target="_blank"
//             rel="noopener noreferrer"
//         >
//             {children}
//         </a>
//     )
// }