// import { useState, useEffect } from 'react'
// import axios from 'axios'
import { notification } from 'antd'
// import currencySymbolMap from 'currency-symbol-map'

// AUTHENTICATION TOKEN
// prettier-ignore
export const setAuthToken = (rememberMe, token) =>
    (rememberMe
        ? localStorage.setItem('zustech:user:token', token)
        : sessionStorage.setItem('zustech:user:token', token))

export const getAuthToken = () => {
    return (
        localStorage.getItem('zustech:user:token') ||
        sessionStorage.getItem('zustech:user:token')
    )
}

export const removeAuthToken = () => {
    localStorage.removeItem('zustech:user:token')
    sessionStorage.removeItem('zustech:user:token')
}
// REDIRECT LOCATION HISTORY
export const setLocationHistory = (location) =>
    sessionStorage.setItem(
        'zustech:user:redirect:location',
        JSON.stringify(location)
    )

export const getLocationHistory = () => {
    return JSON.parse(sessionStorage.getItem('zustech:user:redirect:location'))
}

export const removeLocationHistory = () =>
    sessionStorage.removeItem('zustech:user:redirect:location')

// DEVICE ID
export const setDeviceId = () => {
    const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0]
    const hex = uint32.toString(16)
    localStorage.setItem('zustech:deviceId', hex)
}

export const getDeviceId = () => {
    const deviceId = navigator.userAgent
    // return localStorage.getItem('zustech:deviceId')
    return deviceId
}

export const clearStorage = () => {
    removeLocationHistory()
    removeAuthToken()
}

// NOTIFICATION
export const openNotification = ({ title, message, type }, time = 3.0) =>
    notification[type]({
        key: 'notification',
        message: title,
        description: message,
        placement: 'topRight',
        duration: time,
    })