import { useState } from 'react'
import './whatYouWillLearn.scss'

const WhatYouWillLearn = () => {
    const [show, toggleShow] = useState(false)

    return (
        <section id="whatYouWillLearn-section">
            <div id="content">
                <h1>What you will learn</h1>
                <div className="features-list">
                    <div id="column1">
                        <ul>
                            <li>
                                Have a fundamental understanding of the Python
                                programming language.
                            </li>
                            <li>
                                Have the skills and understanding of Python to
                                confidently apply for Python programming jobs
                            </li>
                            <li>
                                Acquire the pre-requisite Python skills to move
                                into specific branches - Machine Learning, Data
                                Science, etc...
                            </li>
                            <li>
                                Add the Python Object-Oriented Programming (OOP)
                                skills to your résumé.
                            </li>
                            {!show && (
                                <span
                                    id="readMore"
                                    onClick={() => toggleShow(!show)}
                                >
                                    Read More
                                </span>
                            )}
                        </ul>
                    </div>
                    {show && (
                        <div id="column2Toggle">
                            <ul>
                                <li>
                                    Have a fundamental understanding of the
                                    Python programming language.
                                </li>
                                <li>
                                    Have the skills and understanding of Python
                                    to confidently apply for Python programming
                                    jobs
                                </li>
                                <li>
                                    Acquire the pre-requisite Python skills to
                                    move into specific branches - Machine
                                    Learning, Data Science, etc...
                                </li>
                            </ul>
                        </div>
                    )}
                    <div id="column2">
                        <ul>
                            <li>
                                Have a fundamental understanding of the Python
                                programming language.
                            </li>
                            <li>
                                Have the skills and understanding of Python to
                                confidently apply for Python programming jobs
                            </li>
                            <li>
                                Acquire the pre-requisite Python skills to move
                                into specific branches - Machine Learning, Data
                                Science, etc...
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatYouWillLearn
