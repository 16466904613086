import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../../hooks'
import './style.scss'
import Main1 from '../../../../assets/icons/Hero_1.svg'
import Main2 from '../../../../assets/icons/Hero_2.svg'
import Happier from '../../../../assets/icons/happy_twin.svg'
import Celebrate from '../../../../assets/icons/celebration.svg'

export function Successpage() {
    const {
        state: { data: user }
    } = useAuth()
    const history = useHistory()
    // Set the state for both variables to chnage
    const [imgScr, setImgsrc] = useState(Happier)
    const [width, setWidth] = useState(window.innerWidth)
    const [courseName, setCoureName] = useState('')
    // Get name of created course from local storage before deleting
    const today = new Date()
    const day = String(today.getDate()).padStart(2, 0)
    const month = String(today.getMonth() + 1).padStart(2, 0)
    const year = today.getFullYear()
    useEffect(() => {
        const store = JSON.parse(localStorage.getItem('Preview'))
        if (store !== null) {
            setCoureName(store.name)
        }
    }, [])
    // Listening for changes and updating the neccessary state
    useEffect(() => {
        const resizeHandler = () => setWidth(window.innerWidth)
        window.addEventListener('resize', resizeHandler)
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])
    // Action to be performed at specific state
    useEffect(() => {
        let intervalId;
        if (width <= 900) {
            setImgsrc(Celebrate)
            intervalId = setInterval(() => {
                history.push('/tutor')
                localStorage.removeItem('Preview')
                localStorage.removeItem('Course Modules')
                localStorage.removeItem('Course_Related_data')
                localStorage.removeItem('Intro_lessons')
            }, 8000)
        } else {
            setImgsrc(Happier)
        }

        return () => {
            clearInterval(intervalId)
        }
    }, [width, history])

    return (
        <div className="msg__cont">
            <div className="top__cont">
                <div className="left__sec">
                    <div className="img__main-cont">
                        <div className="sub__cont">
                            <img src={Main1} alt="" />
                            <img id="mov__up" src={Main2} alt="" />
                        </div>
                        <div className="alone">
                            <img src={imgScr} alt="" />
                        </div>
                    </div>
                    <h3>{day}/{month}/{year}</h3>
                </div>
                <div className="right__sec">
                    <h3>You have successfully created your course</h3>
                    <div className="text">
                        <p className="first__child">Hello, <span className="name">{user.firstName}</span> You created the course,<br /> <b>{courseName}</b>.</p>
                        <p className="second__child">Our team will review the course to see if it satisfies all necessary requirements, including those related to our platform standards, terms & conditions, and other guidelines.</p>
                        <p>We appreciate you selecting Zustech. Enjoy the journey.</p>
                    </div>
                    <p className="more__info">Please visit our support page if you are experiencing any issues or have any questions!</p>
                    <p>Thanks again,<br />Zustech Team!</p>
                </div>
            </div>
            { !(width <= 900) &&
                <div className="bott__cont">
                    <button onClick={() => {
                        localStorage.removeItem('Preview')
                        localStorage.removeItem('Course Modules')
                        localStorage.removeItem('Course_Related_data')
                        localStorage.removeItem('Intro_lessons')
                        history.push('/tutor/')
                    }}
                    >Continue
                    </button>
                </div>}
        </div>
    )
}