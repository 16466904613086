export const QUIZ_MODE = 'QUIZ_MODE'
export const MODULE_ID = 'MODULE_ID'
export const LESSON_CLICKED = 'LESSON_CLICKED'
export const QUIZ_ACTIVE = 'QUIZ_ACTIVE'
export const START_QUIZ = 'START_QUIZ'
export const SELECTED_ANSWER = 'SELECTED_ANSWER'
export const USER_ANSWER = 'USER_ANSWER'
export const QUIZ_COMPLETED = 'QUIZ_COMPLETED'
export const QUIZ_SCORE = 'QUIZ_SCORE'
export const CLEAR_ALL = 'CLEAR_ALL'
export const COURSE_ID = 'COURSE_ID'
export const QUIZ_TYPE = 'QUIZ_TYPE'