import './description.scss';

import React, { useState } from 'react';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 400) : text}
            <br />
            <span onClick={toggleReadMore} className="readHide">
                {isReadMore ? 'Read More' : ' Show Less'}
            </span>
        </p>
    );
};

const Description = () => {
    return (
        <section id="description">
            <div id="content">
                <h2>Description</h2>
                <ReadMore>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum habitasse vitae amet cras integer morbi diam. Tortor tincidunt velit pellentesque sit.
                    Ultrices pharetra facilisis risus elementum pharetra quam. Eget vestibulum integer quis a. Proin nunc condimentum ac nunc lectus dui mus sit nunc. Ultricies
                    urna, ultricies quis urna molestie amet. Quis turpis euismod vulputate tortor nulla commodo.
                    Erisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet.
                    Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget
                    tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.
                </ReadMore>
                <div id="Requirements">
                    <h2>Requirements</h2>
                    <ul>
                        <li>laptop with internet</li>
                        <li>visual studios software</li>
                    </ul>
                </div>

            </div>
        </section>
    );
}

export default Description;