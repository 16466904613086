import stars from '../assets/stars.png'
import ratingBars from '../assets/ratingBars.png'
import avatar from '../assets/avatar.png'
import likeButton from '../assets/likeButton.svg'
import dislikeButton from '../assets/dislikeButton.svg'
import './styles.scss'

const Reviews = () => {
    return (
        <div className="studentReviews">
            <h4>Student Reviews</h4>
            <div className="ratingOverview">
                <div>
                    <p>4.2</p>
                    <img src={stars} alt="" />
                </div>
                <img src={ratingBars} alt="" />
            </div>
            <div className="ratingSort">
                <b>756 Reviews</b>
                <select>
                    <option value="">All Ratings</option>
                </select>
            </div>
            <ul className="reviewContainer">
                <li>
                    <div className="profileImageContainer">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="reviewerContent">
                        <p className="reviewerName">Josh Smith</p>
                        <p className="reviewerReview">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum habitasse vitae amet cras integer
                            morbi diam. Tortor tincidunt velit pellentesque sit.
                            Ultrices pharetra facilisis risus elementum pharetra
                            quam.
                        </p>
                        <div className="reviewContentButtons">
                            <div className="reviewRating">
                                <p>Was this review helpful?</p>
                                <div>
                                    <img src={likeButton} alt="Like" />
                                    <p>5</p>
                                </div>
                                <div>
                                    <img src={dislikeButton} alt="Dislike" />
                                    <p>5</p>
                                </div>
                            </div>
                            <div className="reviewReport">
                                <p>Report</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="profileImageContainer">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="reviewerContent">
                        <p className="reviewerName">Josh Smith</p>
                        <p className="reviewerReview">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum habitasse vitae amet cras integer
                            morbi diam. Tortor tincidunt velit pellentesque sit.
                            Ultrices pharetra facilisis risus elementum pharetra
                            quam.
                        </p>
                        <div className="reviewContentButtons">
                            <div className="reviewRating">
                                <p>Was this review helpful?</p>
                                <div>
                                    <img src={likeButton} alt="Like" />
                                    <p>5</p>
                                </div>
                                <div>
                                    <img src={dislikeButton} alt="Dislike" />
                                    <p>5</p>
                                </div>
                            </div>
                            <div className="reviewReport">
                                <p>Report</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Reviews
