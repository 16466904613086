import React from 'react'
import { Popover } from 'antd'
import { BsInfoCircle } from 'react-icons/bs'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CountryData from './country_dial.json'

const Step1Form = ({ formValues, setFormValues, handleNext, handleChange }) => {

    // console.log(formValues)

    // contactNumber: '',
    // country: '',
    // instructorBio: '',
    // jobTitle: '',
    // proficiency: '',

    const popBioContent = (
        <div style={{ width: '400px', height: '250px', padding: '10px' }}>
            <p style={{ fontSize: '.6rem' }}>
                Instructor’s bio template (I'm Sarah, and I'm thrilled to be
                your Python programming course instructor. With a passion
                for coding and a love for teaching, I'm excited to guide you
                through the world of Python and help you become a confident
                programmer. I hold a Degree in Computer Science from Oxford
                University, and I've been working in the tech industry for 6
                years. Throughout my career, I've had the opportunity to
                develop cutting-edge software solutions using Python in
                various domains. Over the past 3 years, I've been actively
                involved in teaching Python to students of all levels. From
                beginners taking their first steps in programming to
                experienced developers honing their skills, I've had the
                pleasure of guiding countless learners on their coding
                journeys. My teaching style is focused on hands-on,
                project-based learning. I believe that the best way to grasp
                Python's concepts is through practical application.
                Together, we'll dive into coding exercises, work on exciting
                projects, and explore real-world examples to solidify your
                understanding. I'm looking forward to embarking on this
                journey with you and helping you unlock the incredible
                potential of this powerful programming language!. See you in
                class, Sarah Richard.)
            </p>
        </div>
    )
    // MAIN COMPONENT RENDER
    return (
        <div className="step1--container">
            <div className="header--text">
                We'd like to start by asking you a few questions about becoming
                an Instructor.
            </div>
            <div className="step1--form">
                {/* Phone Number Field */}
                <div className="field-container">
                    <p className="label--field">Contact</p>
                    <PhoneInput
                        country="us"
                        // country={`${userCountry.toLowerCase()}`}
                        value={formValues.contactNumber}
                        placeholder="+234"
                        enableSearch
                        specialLabel="Phone Number"
                        inputProps={{
                            name: 'contactNumber',
                            id: 'contactNumber',
                            required: true,
                            autoFocus: true,
                        }}
                        // containerStyle={{
                        //     width: '60%',
                        // }}
                        inputStyle={{
                            width: '100%',
                            marginRight: '0px',
                        }}
                        onChange={(value) =>
                            setFormValues({
                                ...formValues,
                                contactNumber: value,
                            })}
                    />
                </div>

                {/* Country Field */}
                <div className="field-container">
                    <label htmlFor="country" className="label--field">
                        Country
                    </label>
                    <select
                        id="country"
                        name="country"
                        type="text"
                        className="country--input"
                        value={formValues.country}
                        onChange={handleChange}
                    >
                        {CountryData.map((country, index) => {
                            return (
                                <option key={index} value={country.name}>
                                    {country.name}
                                </option>
                            )
                        })}
                    </select>
                </div>

                {/* Instructor Bio Field */}
                <div className="field-container">
                    <label htmlFor="instructorBio" className="label--field">
                        Instructor Bio
                        <Popover
                            content={popBioContent}
                            title="instructor bio sample"
                        >
                            <span className="label-icon">
                                <BsInfoCircle className="bio-icon" />
                            </span>
                        </Popover>
                    </label>
                    <textarea
                        id="instructorBio"
                        name="instructorBio"
                        type="text"
                        placeholder="Instructor Bio"
                        value={formValues.instructorBio}
                        onChange={handleChange}
                    />
                </div>

                {/* Job Title Field */}
                <div className="field-container">
                    <label htmlFor="jobTitle" className="label--field">
                        Job Title
                    </label>
                    <input
                        id="jobTitle"
                        name="jobTitle"
                        type="text"
                        placeholder="Job Title"
                        value={formValues.jobTitle}
                        onChange={handleChange}
                    />
                </div>

                {/* Proficiency Field */}
                <div className="field-container">
                    <label htmlFor="proficiency" className="label--field">
                        Proficiency in creating online courses
                    </label>
                    <select
                        id="proficiency"
                        name="proficiency"
                        type="text"
                        placeholder="Proficiency"
                        value={formValues.proficiency}
                        onChange={handleChange}
                    >
                        <option value="">-- Select proficiency --</option>
                        <option value="First time">First time</option>
                        <option value="Very poor">Very poor</option>
                        <option value="Average">Average</option>
                        <option value="Expert">Expert</option>
                    </select>
                </div>

                {/* Continue Button */}
                <button
                    className="continue--btn"
                    id="continue"
                    type="button"
                    onClick={handleNext}
                >
                    Continue
                </button>
            </div>
        </div>
    )
}
export default Step1Form