import { useState, useEffect } from 'react'
import {
    getCourseCategories,
    getCourseByTypeId,
    getCourseByCategoryId,
} from '../../../../services'
import { RequestLoader } from '../../../../components/Loading'
import CourseCard from '../CourseCard/CourseCard'
import './style.scss'

const CoursesContent = ({ courseType }) => {
    const [courses, setCourses] = useState([])
    const [courseCategories, setCourseCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [courseTitle, setCourseTitle] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const getCourses = async () => {
        setIsLoading(true)
        try {
            let course
            if (courseType === 'One-time') {
                setCourseTitle('One-time Purchase')
                const res = await getCourseByTypeId(1, 20, 2)
                if (res.message === 'Success') {
                    course = res?.data
                }
            } else if (courseType === 'Single') {
                setCourseTitle('Single Subscription')
                const res = await getCourseByTypeId(1, 20, 3)
                if (res.message === 'Success') {
                    course = res?.data
                }
            }
            setCourses(course)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
            setIsLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        const getCourseCategory = async () => {
            try {
                const response = await getCourseCategories()
                if (response.message === 'Success') {
                    setCourseCategories(response.data)
                }
            } catch (error) {
                console.error(error)
            }
        }
        // getCourses()
        getCourseCategory()
        return () => {
            setCourses([])
            setCourseCategories([])
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const getCourseInCategory = async (criteria) => {
            setIsLoading(true)
            try {
                const response = await getCourseByCategoryId(criteria.id)
                if (response.message === 'Success') {
                    switch (courseType) {
                        case 'Single':
                            setCourses(
                                response.data.filter(
                                    (course) => course.courseType === 3
                                )
                            )
                            break
                        case 'One-time':
                            setCourses(
                                response.data.filter(
                                    (course) => course.courseType === 2
                                )
                            )
                            break
                        default:
                            setCourses(response.data)
                            break
                    }
                    setIsLoading(false)
                } else {
                    setCourses([])
                    throw new Error(response.message)
                }
            } catch (error) {
                setIsLoading(false)
                console.error(error.message)
            }
        }
        if (selectedCategory) {
            for (let i = 0; i < courseCategories.length; i++) {
                if (selectedCategory === courseCategories[i].name) {
                    getCourseInCategory(courseCategories[i])
                }
            }
        } else {
            getCourses()
        }

        return () => {
            setCourses([])
        }
        // eslint-disable-next-line
    }, [selectedCategory])

    // const changeDisplayedCourse = async (criteria) => {
    //     const passedCourse = []
    //     for (let i = 0; i < courses.length; i++) {
    //         const courseTopicIds = new Array(courses[i].topicIds)
    //         if (courseTopicIds.includes(criteria.id)) {
    //             passedCourse.push(courses[i])
    //         }
    //     }
    //     setCourses(passedCourse)
    // }

    return (
        <div className="main-course-container">
            <div className="course-heading">
                <h4 className="courseType">{courseTitle} Course</h4>
                <h4 className="category_header">
                    Filter by course categories:
                </h4>
                <ul className="lists">
                    {courseCategories.map((category) => {
                        return (
                            <button
                                key={category.id}
                                onClick={() => {
                                    if (category.name === selectedCategory) {
                                        setSelectedCategory('')
                                        getCourses()
                                    } else {
                                        // changeDisplayedCourse(category)
                                        setSelectedCategory(category.name)
                                    }
                                }}
                                className={
                                    category.name === selectedCategory
                                        ? 'list-items active'
                                        : 'list-items'
                                }
                            >
                                {category.name}
                            </button>
                        )
                    })}
                </ul>
            </div>
            {isLoading && (
                <div className="loader_cont">
                    <RequestLoader />
                </div>
            )}
            {!isLoading && !error && (
                <div className="cards-section top-card">
                    <div
                        style={{ paddingTop: '2em' }}
                        className="top-picks-container"
                    >
                        {courses.length >= 1 &&
                            courses.map((course) => (
                                <CourseCard key={course.id} course={course} />
                            ))}
                        {(selectedCategory !== '' && courses.length < 1) && (
                            <div className="noCourse">
                                Course with category {selectedCategory} is not
                                Available
                            </div>
                        )}
                        {(courses.length < 1 &&
                            selectedCategory === '' &&
                            !isLoading) && (
                            <div className="noCourse">
                                {courseTitle} courses are not available at
                                the moment.
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isLoading && error && (
                <div className="card-section top-card">
                    <div className="top-picks-container">
                        <div className="noCourse">
                            An Error occured while fetching {courseTitle} course
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CoursesContent
