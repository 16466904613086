import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Menu, Dropdown } from 'antd'
import { openNotification } from '../../../utils'
import { findInstructorApplications } from '../../../services'
import './Applications.scss'
import arrowDown from '../assets/polygon.png'

export const Applications = () => {
    const [applications, setApplications] = useState([])
    const [filteredApplications, setFilteredApplications] = useState([])
    const [rerenderTrigger, setRerenderTrigger] = useState(false)
    const getApplications = async () => {
        // setLoading(true)
        try {
            const response = await findInstructorApplications()
            setApplications(response.data)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        // setLoading(false)
    }

    useEffect(() => {
        getApplications()
    }, [])

    const filterInstructors = (e) => {
        setRerenderTrigger((prevState) => !prevState)
        if (e.domEvent.target.innerText === 'User Name A ▲ Z') {
            setApplications(
                applications.sort((a, b) => {
                    return a.lastName.localeCompare(b.lastName)
                })
            )
        } else if (e.domEvent.target.innerText === 'User Name A ▼ Z') {
            setApplications(
                applications.sort((a, b) => {
                    return b.lastName.localeCompare(a.lastName)
                })
            )
        } else if (e.domEvent.target.innerText === 'Newest Submissions') {
            setApplications(
                applications.sort((a, b) => {
                    return b.modifiedDate.localeCompare(a.modifiedDate)
                })
            )
        } else if (e.domEvent.target.innerText === 'Oldest Submissions') {
            setApplications(
                applications.sort((a, b) => {
                    return a.modifiedDate.localeCompare(b.modifiedDate)
                })
            )
        } else {
            if (e.domEvent.target.innerText === 'Rejected') {
                setApplications(
                    applications.filter(
                        (application) =>
                            application.instructorApplicationStatus === 3
                    )
                )
            } else if (e.domEvent.target.innerText === 'Accepted') {
                setApplications(
                    applications.filter(
                        (application) =>
                            application.instructorApplicationStatus === 1
                    )
                )
            } else if (e.domEvent.target.innerText === 'Pending') {
                setApplications(
                    applications.filter(
                        (application) =>
                            application.instructorApplicationStatus === 2
                    )
                )
            } else if (e.domEvent.target.innerText === 'All') {
                getApplications()
            }
        }
    }
    useEffect(() => {
        if (applications !== filteredApplications) {
            setFilteredApplications(applications)
        }
    }, [filteredApplications, rerenderTrigger, applications])

    const applicationStatus = (val) => {
        if (val === 1) {
            return (
                <div className="approved">
                    <p>Approved</p>
                </div>
            )
        } else if (val === 2) {
            return (
                <div className="processing">
                    <p>Processing</p>
                </div>
            )
        } else if (val === 3) {
            return (
                <div className="rejected">
                    <p>Rejected</p>
                </div>
            )
        } else if (val === 4) {
            return (
                <div className="Suspended">
                    <p>Suspended</p>
                </div>
            )
        }
    }

    const profilePic = (val) => {
        if (val?.profilePicture) {
            return (
                <Avatar size="large" id="profilePic" src={val.profilePicture} />
            )
        }
    }
    const menuUsers = (
        <Menu>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">User Name A ▲ Z</p>
            </Menu.Item>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">User Name A ▼ Z</p>
            </Menu.Item>
        </Menu>
    )
    const menuActivity = (
        <Menu>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">Newest Submissions</p>
            </Menu.Item>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">Oldest Submissions</p>
            </Menu.Item>
        </Menu>
    )
    const menuApplication = (
        <Menu>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">Rejected</p>
            </Menu.Item>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">Accepted</p>
            </Menu.Item>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">Pending</p>
            </Menu.Item>
            <Menu.Item onClick={filterInstructors}>
                <p className="dropdown-text">All</p>
            </Menu.Item>
        </Menu>
    )
    const calculateDays = (date) => {
        const date1 = new Date()
        const date2 = new Date(`${date}`)

        const calculateDifferenceInTime = date1.getTime() - date2.getTime()
        const calculateDifferenceInDays =
            calculateDifferenceInTime / (1000 * 3600 * 24)
        return `${Math.floor(calculateDifferenceInDays)} Days ago`
    }

    return (
        <div className="instructorApplications">
            <div className="header">Applications</div>
            <h5 className="overview">Overview</h5>
            <div className="tableContainer">
                <table className="historyTable">
                    <thead>
                        <tr>
                            <Dropdown className="dropdown" overlay={menuUsers}>
                                <th className="left">
                                    Users <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>
                            <Dropdown
                                className="dropdown"
                                overlay={menuApplication}
                            >
                                <th className="center">
                                    Application Status{' '}
                                    <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>
                            <Dropdown
                                className="dropdown"
                                overlay={menuActivity}
                            >
                                <th className="right">
                                    Last Activity <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>

                            {/* <th>Amount:</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredApplications?.map((val) => {
                            return (
                                <tr key={val.id}>
                                    {/* users */}
                                    <td>
                                        <Link
                                            to={
                                                '/admin/applications-review/' +
                                                val.id
                                            }
                                        >
                                            {' '}
                                            <div className="instructor">
                                                {profilePic(val)}
                                                <p>
                                                    {val.firstName +
                                                        ' ' +
                                                        val.lastName}
                                                </p>
                                            </div>
                                        </Link>
                                    </td>
                                    {/* Application Status */}
                                    <td>
                                        <Link
                                            to={
                                                '/admin/applications-review/' +
                                                val.id
                                            }
                                        >
                                            {applicationStatus(
                                                val.instructorApplicationStatus
                                            )}
                                        </Link>
                                    </td>
                                    {/* activity */}
                                    <td>
                                        <Link
                                            to={
                                                '/admin/applications-review/' +
                                                val.id
                                            }
                                        >
                                            <div className="date">
                                                <p>
                                                    {calculateDays(
                                                        val.modifiedDate.slice(
                                                            0,
                                                            10
                                                        )
                                                    )}
                                                </p>
                                            </div>
                                        </Link>
                                    </td>
                                    {/* ammount */}
                                    {/* <td>{'£' + plan?.amount}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
