import React, { useEffect, useState, useMemo, memo } from 'react'
import { Rate, Progress } from 'antd';
import { isEqual } from 'lodash'
import { ReviewForm } from './reviewForm/reviewForm'
import { ReviewComment } from './reviewForm/reviewComment'

const Review = ({ userProgress, reviewData, refetch }) => {
    const [canReview, setCanReview] = useState()
    const memoedArry = useMemo(() => (reviewData.Reviews), [reviewData.Reviews])
    const [rateLevel, setRateLevel] = useState({
        '5': 0,
        '4': 0,
        '3': 0,
        '2': 0,
        '1': 0
    })
    useEffect(() => {
        const record = {
            '5': 0,
            '4': 0,
            '3': 0,
            '2': 0,
            '1': 0
        }
        // Granting access to giving a review
        if (userProgress >= 25) {
            setCanReview(true)
        } else {
            setCanReview(false)
        }
        // Detting Amount of Rating so as to cal level
        for (let i = 0; i < reviewData.Review_len; i++) {
            switch (memoedArry[i].rating) {
                case 5:
                    record['5'] += 1
                    break;
                case 4:
                    record['4'] += 1
                    break;
                case 3:
                    record['3'] += 1
                    break;
                case 2:
                    record['2'] += 1
                    break;
                case 1:
                    record['1'] += 1
                    break;
                default:
                    return;
            }
        }
        setRateLevel(record)
    }, [reviewData.Review_len, userProgress, memoedArry])

    const getPercent = (num, total) => {
        if (num === 0) {
            return 0
        } else {
            const res = ((num / total) * 100)
            if (res < 100) {
                return (res.toPrecision(2))
            } else {
                return (res)
            }
        }
    }

    return (
        <div className="review_section">
            <section className="review_summary">
                <div className="star_rating">
                    {/* Average rating */}
                    <h1>{reviewData.Average_rate}</h1>
                    <div className="rate">
                        <Rate
                            disabled
                            allowHalf
                            value={parseFloat(reviewData.Average_rate)}
                            // value={4.5}
                            style={{
                                'fontSize': '1.3em',
                            }}
                        />
                    </div>
                    <h4>{reviewData.Review_len} reviews</h4>
                </div>
                <div className="progress_cont">
                    {rateLevel && Object.keys(rateLevel).sort((a, b) => b - a).map((i) => (
                        <div key={i} className="progress_item"><span>{i}</span><Progress percent={getPercent(rateLevel[i], reviewData.Review_len)} status="active" /></div>
                    ))}
                </div>
            </section>
            <section className="review_form">
                <ReviewForm canReview={canReview} courseId={reviewData.courseId} refetch={refetch} />
            </section>
            <section className="review_comment">
                {memoedArry.length !== 0 && memoedArry.filter((a) => a.comment !== '').map((item, idx) => (<ReviewComment key={idx} commentObj={item} />))}
                {reviewData?.Reviews.filter((a) => a.comment !== '').length < 1 && (
                    <div className="no_review">
                        <h4>No Review Comment Yet</h4>
                        <h5>Kindly Rate and share your thought about this course</h5>
                    </div>
                )}
            </section>
        </div>
    )
}

export default memo(Review, isEqual)