import React, { useState } from 'react'
import { Collapse } from 'antd'
// import ArrowDown from '../../../../assets/icons/module_arrow_down.svg'
// import Lock from '../../../../assets/icons/Lock.svg'
// import Uncheck from '../../../../assets/icons/uncheck_circle.svg'
// import Check from '../../../../assets/icons/check_circle.svg'

export const ModuleHeader = ({ itm }) => {
    return (
        <div className="module_item">
            <p title={itm.name}>{itm.name}</p>
            <div>
                <span>{itm.Lessons ? Object.keys(itm.Lessons).length : 0} Lessons</span>
            </div>
        </div>)
}

const Module = ({ mod, updateUrl }) => {
    const { Panel } = Collapse;
    const video = document.createElement('video')
    const [duration, setDuration] = useState('0')
    return (
        <div>
            <Collapse
                defaultActiveKey={['0']}
                expandIconPosition="end"
                bordered="false"
            >
                {mod && Object.entries(mod).map(([idx, itm]) => (
                    <Panel header={<ModuleHeader itm={itm} />} key={idx} showArrow="true">
                        {itm.Lessons && Object.values(itm.Lessons).map((les) => {
                            video.src = les.content
                            video.addEventListener('loadedmetadata', () => {
                                const num = (video.duration / 60) < 10 ? `0${(video.duration / 60).toFixed()}` : `${(video.duration / 60).toFixed()}`
                                const num2 = (video.duration % 60) < 10 ? `0${(video.duration % 60).toFixed()}` : `${(video.duration % 60).toFixed()}`
                                setDuration(`${num}:${num2}`)
                            })
                            return (
                                <div
                                    className="lesson_item"
                                    key={les.id}
                                    onClick={() => {
                                        updateUrl(les.content)
                                    }}
                                >
                                    <p>{les.name}</p>
                                    <div>
                                        <p>{duration}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </Panel>
                ))}
            </Collapse>
        </div>
    )
}

export default Module;