import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useLocation } from '../../hooks'
import { useGeoLocation } from '../../utils'
import { DashboardLayout } from '../../components'
import { userDashboardRoutes } from '../../constants'
import { Dashboard } from './Dashboard'
import { NotFound } from './NotFound'
// import { BecomeAnInstructor } from './BecomeAnInstructor'
// import { ApplicationStatus } from './ApplicationStatus'
import { Courses } from './Courses'
import { Settings } from './Settings'
import Pricing from '../landing/pricing/Pricing/index'
import { Payment } from '../landing/pricing/Payment/index'
import { CourseDetails } from './CourseDetails'
import { CourseVideoResource } from './CourseDetails/oldDesign/shared/CourseVideo'
import { PremiumCourses } from './premiumCourses'
import { InstitutionalCourse } from './InstitutionalCourse'
import { SingleCoursePricing } from './InstitutionalCourse/Pricing'
import { CartItems } from '../../components/PremiumCourse/cartItems'
import { PremiumCourseDetails } from './premiumCourses/premiumDetails'
import { SuccessPage } from './premiumCourses/SuccessPage'
import { MainEnrolledPage } from './EnrolledCourse/MainEnrolledPage'
import { SuccessPage as Subscription_success  } from './Subscrip_success/index'
import InstructorOnboarding from './InstructorOnboarding/index'
import OnboardSuccessPage from './InstructorOnboarding/OnboardSuccessPage'
import { CourseCertificate } from './CourseCertificate'

const routes = [
    {
        path: '/me',
        name: 'Home',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/me/courses',
        name: 'courses',
        exact: true,
        component: Courses,
    },
    {
        path: '/me/payment/:id',
        name: 'Payment',
        exact: false,
        component: Payment,
    },
    {
        path: '/me/courses/course-content',
        name: 'CoursesVideo',
        exact: false,
        component: CourseVideoResource,
    },
    {
        path: '/me/instructor-onboarding-application',
        name: 'Instructor Onboarding',
        exact: true,
        component: InstructorOnboarding,
    },
    {
        path: '/me/instructor-onboard-success',
        name: 'Instructor Onboarding Success Page',
        exact: true,
        component: OnboardSuccessPage,
    },
    {
        path: '/me/courses/course/:id',
        name: 'Course Details',
        exact: false,
        component: CourseDetails,
    },
    {
        path: '/me/learning',
        name: 'Enrolled Course',
        exact: false,
        component: MainEnrolledPage,
    },
    {
        path: '/me/download-certificate/:id',
        name: 'Download Certificate',
        exact: false,
        component: CourseCertificate,
    },
    {
        path: '/me/premium',
        name: 'Premium Course',
        exact: true,
        component: PremiumCourses,
    },
    {
        path: '/me/premium/overview',
        name: 'Premium overview',
        exact: false,
        component: PremiumCourseDetails,
    },
    {
        path: '/me/premium/cart',
        name: 'Premium Course',
        exact: false,
        component: CartItems,
    },
    {
        path: '/me/premium/success',
        name: 'Cart Success',
        exact: true,
        component: SuccessPage,
    },
    {
        path: '/me/subscription',
        name: 'Subscription',
        exact: true,
        component: Pricing,
    },
    {
        path: '/me/subscription/success',
        name: 'Successful Subscription',
        exact: true,
        component: Subscription_success,
    },
    {
        path: '/me/single-course',
        name: 'Single Course',
        exact: true,
        component: InstitutionalCourse,
    },
    {
        path: '/me/single-subscription/:id',
        name: 'Institutional/Single Course Subscription',
        exact: true,
        component: SingleCoursePricing,
    },
    {
        path: '/me/settings',
        name: 'Settings',
        // exact: true,
        component: Settings,
    },
    {
        path: '',
        name: 'Not Found',
        exact: true,
        component: NotFound,
    },
]

export const UserDashboard = () => {
    const { location } = useGeoLocation()
    const { addUserLocation } = useLocation()

    // Use effect to set user location when the page is loaded for the first time.
    useEffect(() => {
        const setUsersLocation = () => {
            const storedCountrycode = JSON.parse(
                localStorage.getItem('zustech:user-location')
            )
            if (location !== null) {
                if (
                    storedCountrycode === null ||
                    storedCountrycode.city !== location.city
                ) {
                    localStorage.setItem(
                        'zustech:user-location',
                        JSON.stringify(location)
                    )
                    addUserLocation(location)
                } else {
                    addUserLocation(storedCountrycode)
                }
            }
        }

        setUsersLocation()

        return () => {
            localStorage.removeItem('zustech:user-location')
            addUserLocation(null)
        }

        // eslint-disable-next-line
    }, [location])
    return (
        <DashboardLayout routeList={userDashboardRoutes}>
            <Switch>
                {routes.map((route, idx) => {
                    return <Route key={idx} {...route} />
                })}
            </Switch>
        </DashboardLayout>
    )
}