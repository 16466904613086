import React from 'react'
import { useLocation } from '../../../../hooks'
import Euro from '../../../../assets/icons/euro.svg'
import Students from '../../../../assets/icons/stdEnroll.svg'
import Star from '../../../../assets/icons/Stars.svg'
// Style sheet is in parent folder ../courseCard.scss

export function RetiredCourse({ thumbnail, CTA }) {
    const {
        state: { currencySymbol },
    } = useLocation()
    return (
        <div className="course__card">
            <div className="course__thumbnail">
                <img src={thumbnail} alt="thumbnail" />
            </div>
            <div className="course__details">
                <h3>AI Technology Masterclass</h3>
                <div>
                    <p>Published 01/02/23</p>
                    <button>Retired</button>
                </div>
            </div>
            <div className="course__numericsI">
                <div className="top">
                    <h3 className="icon_et_info">
                        <span>
                            <img src={Students} alt="icon" />
                        </span>
                        <span>1,356</span>
                    </h3>
                    <p>Students enrolled</p>
                </div>
                <div className="bottom">
                    <h3 className="icon_et_info">
                        <span>
                            <img src={Star} alt="icon" />
                        </span>
                        <span>4.3</span>
                    </h3>
                    <p>Star rating</p>
                </div>
            </div>
            <div className="course__numericsII">
                <div className="top">
                    <h3 className="icon_et_info">
                        <span>
                            <img src={Euro} alt="icon" />
                        </span>
                        <span>{currencySymbol}254</span>
                    </h3>
                    <p>Earnings this mounth</p>
                </div>
                <div className="bottom">
                    <h3 className="icon_et_info">
                        <span>
                            <img src={Euro} alt="icon" />
                        </span>
                        <span>{currencySymbol}1305</span>
                    </h3>
                    <p>Total earnings</p>
                </div>
            </div>
            <div className="course__status">
                <CTA
                    type="primary"
                    danger
                    style={{
                        borderRadius: '6px',
                        cursor: 'default',
                    }}
                >
                    {' '}
                    Closed
                </CTA>
            </div>
        </div>
    )
}
