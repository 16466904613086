import axios from 'axios'
// TEMPORARY
export const imageUpload = async (formData) => {
    try {
        const url = `https://api.cloudinary.com/v1_1/${
            process.env.REACT_APP_CLOUDINARY_ACCOUNT || 'demo'
        }/image/upload`

        const res = await axios.post(url, formData)
        return res?.data
    } catch (err) {
        const error = err.response?.data || err.message
        throw new Error(error.message)
    }
}
