import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { softSearchCourses } from '../../services'

import './searchbar.scss'

const useLocalDebounce = (value, delay = 1000) => {
    const [debounceValue, setDebounceValue] = useState(value)
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebounceValue(value)
        }, delay)

        return () => clearTimeout(timeoutId)
    }, [value, delay])

    return debounceValue
}

const SearchBar = ({ type }) => {
    const [input, setInput] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [searchResult, setSearchResult] = useState([])
    // The focus of the debounce implemented here is on the value provided, the hook useLocalDebounce returns the last
    // value provided to it after a certain amount to millisec
    const mainValue = useLocalDebounce(input)
    // const mainValue = ''

    const fetchRequest = async (value, check) => {
        try {
            let response
            // fetch data
            switch (check) {
                case 'course':
                    response = await softSearchCourses(value)
                    if (response.status) {
                        return (response?.data)
                    }
                    break;
                default:
                    return []
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const pattern = /[a-zA-Z]+/
        if (pattern.test(mainValue) && mainValue.length >= 1) {
            const response = fetchRequest(mainValue, type)
            response.then(data => {
                setIsFetching(false)
                setSearchResult(data)
            })
        } else {
            setIsSearch(false)
            setSearchResult([])
            setIsFetching(false)
        }

    }, [mainValue, type])

    return (
        <div id="search">
            <div className="search-input">
                <input
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value)
                        setSearchResult([])
                        setIsSearch(true)
                        setIsFetching(true)
                    }}
                    type="text"
                    placeholder={`Search for ${type}`}
                />
                {searchResult.length !== 0 && (
                    <div className="search-result">
                        {searchResult.map((value) => (
                            <Link
                                key={value.name}
                                to={`courses/course/${value?.id}`}
                            >
                                <div className="search-course-div">
                                    <h4>{value.name}</h4>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
                {(isSearch && searchResult.length === 0) && (
                    <div className="search-result">
                        <div className="search-course-div">
                            <h4>{type.replace(/^\w/, c => c.toUpperCase())} with name {input} is not available</h4>
                        </div>
                    </div>
                )}
                {(isFetching) && (
                    <div className="search-result">
                        <div className="search-course-div">
                            <h4>Fetching...</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBar
