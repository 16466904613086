import { request as RQ } from '../utils'

export const createInstructor = async (values) => {
    try {
        const url = `/Instructor/CreateInstructor`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const submitInstructorQuestionaire = async (values) => {
    try {
        const url = `/Instructor/instructorQuestionier`
        const request = await RQ
        const response = await request.post(url, values)

        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findInstructorByUserId = async (UserId, signal) => {
    try {
        const url = `/Instructor/FindInstructorByUserId?UserId=${UserId}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findCourseByInstructorId = async (InstructorId, publishCourseStatus, Page, PageSize, signal) => {
    try {
        const url = `/Course/FindCourseByInstructorId?InstructorId=${InstructorId}&publishCourseStatus=${publishCourseStatus}&Page=${Page}&PageSize=${PageSize}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindLessonsByInstructorId = async (Id, signal) => {
    try {
        const url = `/Lesson/FindLessonsByInstructorId?Id=${Id}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        const neededLessons = response.data.data.filter((lesson) => lesson.isIntroLesson === false)
        const mimickedResponse = { 'responseCode': '00', 'status': true, 'data': neededLessons, 'message': 'Success' }
        return mimickedResponse
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// Create Course:

// 1) CreateCourse with instructorId:

// 2) Course objective:

// 3) Create Module  get module id:

// 4) Upload Resources then get the resourceId from response:

// 5) Then add resource to the module using module and resource id:

export const findAllLanguages = async (abort) => {
    try {
        const url = `/Language/FindAll`
        const request = await RQ
        const response = await request.get(url, { cancelToken: abort })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const findAllTopics = async () => {
    try {
        const url = `/Topic/FindAll`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findAllCourse = async (pageSize) => {
    try {
        const url = `/Course/FindAllCourse?Page=1&PageSize=${pageSize}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createCourse = async (values) => {
    try {
        const url = `/Course/CreateCourse`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createCourseObjective = async (values) => {
    try {
        const url = `/CourseObjective/Create`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findAllModules = async (InstructorId) => {
    try {
        const url = `/Module/FindModulesByInstructorId?IntructorId=${InstructorId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getCourseByTypeId = async (page, pageSize, typeId) => {
    try {
        const url = `/Course/FindCourseByCourseCategory?courseType=${typeId}&page=${page}&pageSize=${pageSize}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }

        const error = err?.response?.data?.message || err?.message

        throw new Error(error)
    }
}

export const createModule = async (values) => {
    try {
        const url = `/Module/CreateModule`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const deleteModule = async (id) => {
    try {
        const url = `/Module/DeleteModuleById?id=${id}`
        const request = await RQ
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const addResourceToLesson = async (lessonId, formFile) => {
    try {
        const url = `/Lesson/AddResourceToLesson`
        const request = await RQ
        const response = await request.post(url, formFile, {
            params: {
                lessonId,
            },
        })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const addLessonToModule = async (values) => {
    try {
        const url = `/Lesson/AddLessonToModule`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createLesson = async (
    name,
    summary,
    totalLength,
    lessonContent,
    isIntroLesson
) => {
    try {
        const url = `/Lesson/CreateLesson`
        const request = await RQ
        const response = await request.post(url, lessonContent, {
            params: {
                name,
                summary,
                totalLength,
                isIntroLesson
            }
        })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindLessonsById = async (id) => {
    try {
        const url = `/Lesson/FindLessonsById?id=${id}`
        const request = await RQ
        const response = await request.post(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// export const findLessonByInstructorId = async () => {
//     try {
//         const url = `/Lesson/FindLessonsByInstructorId`
//          const request = await RQ
//         const response = await request.get(url)
//         return response?.data
//     } catch (err) {
//         if (err?.response?.data?.data?.message) {
//             throw new Error(err.response.data.data.message.join('\n\n'))
//         }
//         const error = err?.response?.data?.message || err?.message
//         throw new Error(error)
//     }
// }

export const findModuleByInstructorId = async (InstructorId, pageSZ, page) => {
    try {
        const url = `/Module/FindModulesByInstructorId?InstructorId=${InstructorId}&PageSize=${pageSZ}&Page=${page}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const editLesson = async (
    name,
    newName,
    summary,
    totalLength,
    lessonContent
) => {
    try {
        const url = `/Lesson/EditLesson`
        const request = await RQ
        const response = await request.post(url, lessonContent, {
            params: {
                name,
                newName,
                summary,
                totalLength,
            },
        })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const DeleteLessonById = async (id) => {
    try {
        const url = `/Lesson/DeleteLessonById?id=${id}`
        const request = await RQ
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findLessonByModuleId = async (id, signal) => {
    try {
        const url = `/Lesson/GetAllLessonsByModuleId?ModuleId=${id}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findModuleByCourseId = async (id) => {
    try {
        const url = `/Module/FindModuleByCourseId?CourseId=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const addModuleToCourse = async (values) => {
    try {
        const url = `/Module/AddModuleToCourse`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findInstructorApplications = async () => {
    try {
        const url = `/Instructor/FindAllPendingInstructors`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindInstructorById = async (id) => {
    try {
        const url = `/Instructor/FindInstructorById?Id=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const deleteCourse = async (id) => {
    try {
        const url = `/Course/DeleteCourse?id=${id}`
        const request = await RQ
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const acceptInstructorApplication = async (instructorId) => {
    try {
        const url = `/Instructor/AcceptInstructorApplication`
        const request = await RQ
        const response = await request.put(url, instructorId)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const denyInstructorApplication = async (instructorId) => {
    try {
        const url = `/Instructor/DenyInstructorApplication`
        const request = await RQ
        const response = await request.post(url, instructorId)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findCourseById = async (id, signal) => {
    try {
        const url = `/Course/FindCourseById?CourseId=${id}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findAllCourses = async (CourseApproval, page, size) => {
    try {
        const url = `/Course/FindAllCourse?CourseApproval=${CourseApproval}&Page=${page}&PageSize=${size}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getPremiumCourses = async () => {
    try {
        const url = `/Course/getPremiumCourses`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const findCoursesByName = async (name, page, size) => {
    try {
        const url = `/Course/FindCourseByCourseName?Name=${name}&Page=${page}&PageSize=${size}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const editCourse = async (values) => {
    try {
        const url = `/Course/EditCourse`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const removeModuleFromCourse = async (moduleId, courseId) => {
    try {
        const url = `/Module/RemoveModuleFromCourse?moduleId=${moduleId}&courseId=${courseId}`
        const request = await RQ
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const updateModule = async (values) => {
    try {
        const url = `/Module/UpdateModule`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const removeLessonFromModule = async (moduleId, lessonId) => {
    try {
        const url = `/Lesson/RemoveLessonFromModule?moduleId=${moduleId}&lessonId=${lessonId}`
        const request = await RQ
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindCourseById = async (Id) => {
    try {
        const url = `/Course/FindCourseById?Id=${Id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindPlanById = async (Id) => {
    try {
        const url = `/Plan/FindPlanById?Id=${Id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const ReviewCourseById = async (Id) => {
    try {
        const url = `/Course/ReviewCourseToPublish?CourseId=${Id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const PublishCourse = async (values) => {
    try {
        const url = `/Course/PublishCourse`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findModuleById = async (id, signal) => {
    try {
        const url = `/Module/FindModuleById?id=${id}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getResourceByLessonId = async (id) => {
    try {
        const url = `/Resource/GetResourcesByLessonId?Id=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// GETTING INSTRUCTOR BY FIRSTNAME
export const findInstructorByFirstName = async (name, signal) => {
    try {
        const url = `/Instructor/FindInstructorByFirstName?FirstName=${name}`
        const request = await RQ
        const response = await request.get(url, { cancelToken: signal })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const CreateNewLesson = async (values) => {
    try {
        const url = `/Lesson/CreateLesson`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getCourseCategory = async (abort) => {
    try {
        const url = `/CourseCategory/findCourseCategories`
        const request = await RQ
        const response = await request.get(url, { cancelToken: abort })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const uploadResources = async (comment, transcript, values) => {
    try {
        const url = `/Resource/Upload?Comment=${comment}&Transcript=${transcript}`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getInstructorTierPrice = async () => {
    try {
        const url = `/Price/getAll`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createQuizQuestion = async (formdata) => {
    try {
        const url = `/Question/createQuestion`
        const request = await RQ
        const response = await request.post(url, formdata)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getQuestionById = async (id) => {
    try {
        const url = `/Question/findQuestionById?QuestionId=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createModuleTest = async (values) => {
    try {
        const url = `/Test/CreateTest`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createCourseTest = async (values) => {
    try {
        const url = `/Test/CreateCourseTest`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const addQuestionToTest = async (values) => {
    try {
        const url = `/Question/addQuestionToTest`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findQuizByInstructorId = async (InstructorId) => {
    try {
        const url = `/Test/FindByInstructorId?userId=${InstructorId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findQuizByModuleId = async (InstructorId) => {
    try {
        const url = `/Test/FindByModuleId?moduleid=${InstructorId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findQuizById = async (quizId) => {
    try {
        const url = `/Test/FindByTestId?testId=${quizId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findQuizByCourseId = async (courseId) => {
    try {
        const url = `/Test/FindByCourseId?CourseId=${courseId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const startQuiz = async (values) => {
    try {
        const url = `/Test/TakeTest`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const endQuiz = async (values) => {
    try {
        const url = `/Test/FinishTest`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getCourseByModuleId = async (moduleId) => {
    try {
        const url = `/Course/FindCourseByModuleId?moduleid=${moduleId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getCourseRevenueByInstructorId = async (courseType, instructorId) => {
    try {
        const url = `/InstructorDashboard/coursesRevenue?courseType=${courseType}&instructorId=${instructorId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getInstructorTransactionReport = async (values) => {
    try {
        const url = `/InstructorDashboard/transactionReport?`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// /api/InstructorDashboard/MonthlyRevenue

export const getInstructorMonthlyRevenue = async (instructorId) => {
    try {
        const url = `/InstructorDashboard/MonthlyRevenue?instructorId=${instructorId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}