import React from 'react'
import { Table } from 'antd'
import '../style.scss'

export default function InvoiceContent({ invoiceRef }) {
    const invoiceTableColumns = [
        {
            title: 'Transaction description',
            dataIndex: 'transDescrip',
            key: 'trans_descrip',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 200
        },
        {
            title: 'No of students',
            dataIndex: 'NOS',
            key: 'nos',
            width: 200
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amt',
            width: 200
        },
    ]

    const inoviceData = [
        {
            key: 1,
            transDescrip: 'UI/UX Design masterclass',
            price: '$30',
            NOS: '100',
            amount: '$3000'
        },
        {
            key: 2,
            transDescrip: 'Python for beginners',
            price: '',
            NOS: '1230',
            amount: '$1380'
        },
        {
            key: 3,
            transDescrip: 'Javascript advanced',
            price: '',
            NOS: '1880',
            amount: '$2880'
        },
        {
            key: 4,
            transDescrip: '',
            price: '',
            NOS: 'Subtotal',
            amount: '$7260.00'
        },
        {
            key: 5,
            transDescrip: '',
            price: '',
            NOS: 'Grand Total',
            amount: '$7260.00'
        }
    ]

    return (
        <div ref={invoiceRef} className="invoice_body">
            <div className="info_cont">
                <div className="userDetail">
                    <p>Invoice To</p>
                    <h2>John Doe</h2>
                </div>
                <div className="invoice_cred">
                    <p>
                        <span className="key">
                            <span>Invoice ID</span>
                            <span>:</span>
                        </span>
                        <span className="value">346JK56</span>
                    </p>
                    <p>
                        <span className="key">
                            <span>Date</span>
                            <span>:</span>
                        </span>
                        <span className="value">12 July, 2023</span>
                    </p>
                </div>
            </div>
            <h5>Instructor Monthly Payment for September</h5>
            <Table
                dataSource={inoviceData}
                columns={invoiceTableColumns}
                size="small"
                pagination={{
                    position: ['none', 'none'],
                }}
                rowClassName="invoice_table_row"
            />
            <p>Invoice is valid without signature and seals</p>
        </div>
    )
}
