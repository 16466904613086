import React, { } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top';
import { Root } from './store'
import { AppRouter } from './routes'

function App() {
    // useEffect(() => {
    //     function ctrlShiftKey(e, keyCode) {
    //         return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    //     }

    //     if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    //         // production build code
    //         // Disable right-click
    //         document.addEventListener('contextmenu', (e) => e.preventDefault());

    //         document.onkeydown = (e) => {
    //         // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    //             if (e.keyCode === 123 || ctrlShiftKey(e, 'I') || ctrlShiftKey(e, 'J') || ctrlShiftKey(e, 'C') || (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    //             ) return false;
    //         };
    //     }
    // }, [])

    return (
        <Root>
            <BrowserRouter>
                <ScrollToTop>
                    <AppRouter />
                </ScrollToTop>
            </BrowserRouter>
        </Root>
    )
}

export default App