import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Modal } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { openNotification } from '../../../../utils/helpers'
import CancelIcon from '../../../../assets/icons/cancel_item.svg'
import DropIcon from '../../../../assets/icons/drop_icon.svg'
import DoneLoading from '../../../../assets/icons/Done_loading.svg'
import {
    createModule,
    addLessonToModule
} from '../../../../services'
import { useForm, useInstructorData } from '../../../../hooks'
import { RequestLoader } from '../../../../components/Loading';
// import { getAllLessons } from '@store/actions/instrucProd'
// STYLES ARE IN /instructorDashboard/CreateEditCourses/styles.scss
// FindLessonsByInstructorId
// FindLessonsByInstructorId

export function CreateModule() {
    const {
        state: { instructorID, lessons },
        getAllLessons
    } = useInstructorData()

    const {
        moduleForm,
        setModuleSummary,
        setModuleLessons,
        setModuleTitle,
        clearModuleField
    } = useForm()

    const loc = document.location.pathname
    const { TextArea } = Input;
    const [openModal, setOpenModal] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [availableLessons, setAvailableLessons] = useState([])
    const [option, setOption] = useState([])
    const [ready, setReady] = useState(false)
    const bool = loc === '/tutor/create-module'
    // const resetInpValue = () => {
    //     setModuleSummary('')
    //     setModuleTitle('')
    //     setModuleLessons([])
    // }

    // Base on the fact that a module can be re-used in other course by the instructor
    // Use a default courseId of 20, to create module
    // the created module has an id, attach the lessons id to that moduleId (Lessons can also be re-used in different module)

    useEffect(() => {
        // const source = axios.CancelToken.source()
        // const getNeccesary = async () => {
        //     try {
        //         const res = await FindLessonsByInstructorId(instructorID, source.token)
        //         // setAvailableLessons(res.data)
        //     } catch (error) {
        //         console.error(error.message)
        //     }
        // }
        // getNeccesary()
        // This shouldn't cause infinit loop, only updating lessons if it's empty..
        if (lessons.length === 0) {
            // this runs when module creation form is refreshed
            getAllLessons(instructorID)
        } else {
            setAvailableLessons(lessons)
            setReady(true)
        }
        return () => {
            setAvailableLessons([])
            setReady(false)
        }
        // eslint-disable-next-line
    }, [instructorID, lessons])

    function validateForm() {
        if (moduleForm.title === '') {
            openNotification({
                title: 'Module Title',
                type: 'error',
                message: 'Module title field can\'t be blank'
            })
            return false
        } else if (moduleForm.summary === '') {
            openNotification({
                title: 'Module Summary',
                type: 'error',
                message: 'Module summary is neccessary'
            })
            return false
        } else if (moduleForm.lessons.length < 1) {
            openNotification({
                title: 'Module Lessons',
                type: 'error',
                message: 'Kindly provide lesson(s) for this Module'
            })
            return false
        }
        return true
    }

    const getModuleId = async () => {
        const data = {
            'name': moduleForm.title,
            'courseId': 20, // Using a constant for all course
            'summary': moduleForm.summary,
            'resourceStatus': 2  // required (no idea bout what it does)
        }
        try {
            const response = await createModule(data)
            return response.data.id
        } catch (error) {
            return error.message
        }
    }

    // Id of the selected lesson is needed so as to attach it to the newly created module
    const handleSubmit = async () => {
        setIsloading(true)
        const newArry = {}
        for (let i = 1; i <= Object.keys(moduleForm.lessons).length; i++) {
            for (let z = 0; z < availableLessons.length; z++) {
                if (moduleForm.lessons[i].key === availableLessons[z].id) {
                    newArry[i] = availableLessons[z]
                    break
                }
            }
        }
        const moduleId = await getModuleId()
        const attachResource = (index, lessId) => {
            const data = {
                'instructorId': instructorID,
                'moduleId': moduleId,
                'lessonId': lessId,
                'index': index
            }

            return data
        }
        const promisArry = []
        for (let i = 1; i <= Object.keys(newArry).length; i++) {
            try {
                const prom1 = addLessonToModule(attachResource(i, newArry[i].id))
                promisArry.push(prom1)
            } catch (error) {
                console.error(error)
            }
        }
        Promise.all(promisArry)
            .then((result) => {
                openNotification({
                    title: 'Module creation',
                    type: 'success',
                    message: result[0].message,
                })
                clearModuleField()
                setIsloading(false)
            })
            .catch(error => {
                openNotification({
                    title: 'Module creation',
                    type: 'error',
                    message: error.message
                })
                setIsloading(false)
                setOpenModal(false)
            })
    }

    function handleDel(e) {
        const itemId = parseInt(e.target.parentElement.parentElement.dataset.rbdDragHandleDraggableId, 10)
        const rightcolumn = moduleForm.lessons
        const oldOption = option
        const len = Object.keys(rightcolumn).length
        let obj
        for (let i = 1; i <= len; i++) {
            if (rightcolumn[i]?.key === itemId) {
                obj = rightcolumn[i]
                delete rightcolumn[i]
                oldOption.push(obj)
                for (let j = i; j <= len; j++) {
                    rightcolumn[j] = rightcolumn[j + 1]
                }
            }
        }
        delete rightcolumn[len]
        setOption(oldOption)
        setModuleLessons(rightcolumn)
    }

    useEffect(() => {
        const options = []
        for (let i = 0; i < availableLessons.length; i++) {
            options.push({ 'lable': availableLessons[i].name, 'key': availableLessons[i].id, 'value': availableLessons[i].name  })
        }
        setOption(options)
    }, [availableLessons])

    useEffect(() => {
        if (Object.keys(moduleForm.lessons).length !== 0) {
            const arry = option
            for (let j = 0; j < arry.length; j++) {
                for (let i = 1; i <= Object.keys(moduleForm.lessons).length; ++i) {
                    if (arry[j].key === moduleForm.lessons[i].key) {
                        arry.splice(j, 1)
                    }
                }
                setOption(arry)
            }
        }
        // eslint-disable-next-line
    }, [ready, option])

    const handleDragEnd = (result) => {
        const { destination, source } = result;
        // Below check for destination, making it impossible to drop item outside a droppable container
        if (!destination) {
            return
        }
        // Below makes it impossible to reorder the left column
        if (destination.droppableId === 'left_box' && source.droppableId === 'left_box') {
            return
        }
        // creating a new DS for the right column
        if (destination.droppableId === 'right_box') {
            const rightColumnItem = moduleForm.lessons
            const oldOrder = option
            const start = source.index + 1
            const end = destination.index + 1
            if (source.droppableId === 'right_box') {
                if (start === end) {
                    return
                }
                const moving = rightColumnItem[start]
                delete rightColumnItem[start]
                if (start < end) {
                    for (let i = start; i < end; i++) {
                        rightColumnItem[i] = rightColumnItem[i + 1]
                    }
                }
                if (start > end) {
                    for (let i = start; i > end; i--) {
                        rightColumnItem[i] = rightColumnItem[i - 1]
                    }
                }
                rightColumnItem[end] = moving
                setModuleLessons(rightColumnItem)
            } else {
                const obj = option[source.index]
                let len = Object.keys(rightColumnItem).length
                rightColumnItem[++len] = obj
                oldOrder.splice(source.index, 1)
                // oldOrder.splice(destination.index, 0, obj)
                setOption(oldOrder)
                setModuleLessons(rightColumnItem)
            }
            // Store the state in localstorage as well, the newOption i mean
        } else {
            const rightColumnItem = moduleForm.lessons
            const start = source.index + 1
            const len = Object.keys(rightColumnItem).length
            const moving = rightColumnItem[start]
            const oldOrder = option
            // remove item from the rightcolumn
            delete rightColumnItem[start]
            if (start !== len) {
                // adjust the keys of the rightcolumn
                for (let i = start; i < len; i++) {
                    rightColumnItem[i] = rightColumnItem[i + 1]
                }
                // This removes the duplicate of the last Item
                delete rightColumnItem[len]
            }
            setModuleLessons(rightColumnItem)
            // add the removed item to the left column
            oldOrder.splice(destination.index, 0, moving)
            setOption(oldOrder)
        }
    }

    return (
        <div id="CreateModule" className="form__cont">
            {loc === '/tutor/create-module' ? <h2>Create Module</h2> : '' }
            <DragDropContext onDragEnd={handleDragEnd}>
                <Form>
                    <div className="form__item__cont">
                        <label>Module Title</label>
                        <Form.Item>
                            <Input
                                id="ModuleName"
                                name="module name"
                                value={moduleForm.title}
                                placeholder="Name of Module..."
                                onChange={(e) => {
                                    setModuleTitle(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Module Summary</label>
                        <Form.Item>
                            <TextArea
                                className="textbox"
                                id="ModuleSummary"
                                name="module Summary"
                                maxLength={300}
                                value={moduleForm.summary}
                                placeholder="Brief summary of module"
                                onChange={(e) => {
                                    setModuleSummary(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </div>
                    {/* <div className="form__item__cont">
                        <label>Add lessons</label>
                        <Form.Item>
                            <Select
                                mode="multiple"
                                placeholder="Add lessons..."
                                options={option}
                                value={moduleForm.lessons}
                                onChange={(value) => {
                                    setModuleLessons(value)
                                }}
                            />
                        </Form.Item>
                    </div> */}
                    <div className="form__item__cont">
                        <label>Add lessons</label>
                        <p>(Drag and Drop Lessons in the order you want them to be presented)</p>
                        <div className="box_container">
                            <Droppable droppableId="left_box" type="Lessons">
                                {(provided, snapshot) => (
                                    <div
                                        className={snapshot.isDraggingOver ? 'box left_box dragOver' : 'box left_box'}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {(option.length < 1) &&
                                            (
                                                <div className="empty_box">
                                                    <h4>Instructor has no lesson</h4>
                                                    <h4>Start by Creating a New Lesson</h4>
                                                </div>
                                            )}
                                        {option && option.map((opt, idx) => (
                                            <Draggable
                                                draggableId={opt.key.toString()}
                                                index={idx}
                                                key={opt.key}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={snapshot.isDragging ? 'fetched_item dragging' : 'fetched_item'}
                                                    >{opt.value}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="right_box" type="Lessons">
                                {(provided, snapshot) => (
                                    <div
                                        className={snapshot.isDraggingOver ? 'box right_box dragOver' : 'box right_box'}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {(Object.keys(moduleForm.lessons).length < 1) &&
                                            (
                                                <div className="empty_box">
                                                    <h4>Drag and drop lessons into module</h4>
                                                    <div className="img_cont">
                                                        <img src={DropIcon} alt="drop icon" />
                                                    </div>
                                                </div>
                                            )}
                                        {moduleForm.lessons && Object.keys(moduleForm.lessons).map((order, idx) => (
                                            <Draggable
                                                draggableId={moduleForm?.lessons?.[order]?.key.toString()}
                                                index={idx}
                                                key={moduleForm?.lessons?.[order]?.key}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        className={snapshot.isDragging ? 'box_item dragging' : 'box_item'}
                                                    >{!snapshot.isDragging && <div className="order">{order}.</div>} <div {...provided.dragHandleProps} className={snapshot.isDragging ? 'item_name dragging' : 'item_name'}><span>{moduleForm?.lessons?.[order]?.value}</span> {!snapshot.isDragging && <span className="del_item" onClick={handleDel}><img src={CancelIcon} alt="del" /></span>}</div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <div className="btn__cont">
                        <Button id="ClearModuleField" onClick={clearModuleField} type="primary">Clear all</Button>   {/* an onclick event that reset all field */}
                        <Button
                            onClick={() => {
                                // Wanna check for validation here
                                const cont = validateForm()
                                if (cont) {
                                    handleSubmit()
                                    setOpenModal(true)
                                }
                            }}
                            id="SaveAndExit"
                            type="primary"
                        >{loc === '/tutor/create-module' ? 'Save and exit' : 'Save' }
                        </Button> {/* modal for confirmation msg before submitting */}
                    </div>
                    <Modal
                        id="ModuleModal"
                        centered
                        footer={null}
                        open={openModal}
                        // open={true}
                        style={{
                            textAlign: 'center',
                        }}
                        onCancel={() => {
                            setOpenModal(false)
                        }}
                    >
                        <div>
                            <h4 style={{ color: '#3a86ff' }}>{isLoading ? 'Creating Module' : 'Module Created Successfully'}</h4>
                            <p style={{ marginBlock: '1em' }}>
                                Every Created modules are accessible in the <b>add modules field</b> of the <b>create course section</b>
                            </p>
                            {/* {!isLoading &&
                            <p style={{ marginBlock: '1em' }}>
                                <img src={Infoicon} alt="info icon" /> <span>After adding all reasons and resources, click on <b>Save and Close</b>.</span>
                            </p>} */}
                            <div style={{ marginBlock: '2em' }}>
                                {isLoading && <RequestLoader />}
                                {!isLoading &&
                                    <img src={DoneLoading} alt="done_loading" />}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    /** button to submit the form */
                                    key={Math.random() * 100}
                                    size="middle"
                                    disabled={isLoading && true}
                                    id="SubmitModule"
                                    type="primary"
                                    style={{
                                        borderRadius: '10px',
                                        paddingInline: '2em',
                                        // marginRight: '200px',
                                        height: '40px'
                                    }}
                                    onClick={() => {
                                        setAvailableLessons(lessons)
                                        setOpenModal(false)
                                        // location.reload()
                                    }}
                                >{isLoading ? 'Cancel' : 'Continue creating module'}
                                </Button>
                                <Link id="ModuleToCreateCourse" to={bool ? '/tutor/create-course' : '#create_course'}>
                                    <Button
                                        /** button to submit the form */
                                        key={Math.random() * 100}
                                        size="middle"
                                        disabled={isLoading && true}
                                        type="primary"
                                        style={{
                                            borderRadius: '10px',
                                            paddingInline: '2em',
                                            // marginLeft: '200px',
                                            height: '40px'
                                        }}
                                        onClick={() => {
                                            if (!bool) {
                                                const course = document.getElementById('course_section')
                                                course.scrollIntoView({ behavior: 'smooth' })
                                            }
                                            setOpenModal(false)
                                        }}
                                    >{isLoading ? 'Continue creating module' : 'Goto Create Course Form'}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Modal>
                </Form>
            </DragDropContext>
        </div>
    )
}
