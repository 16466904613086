import { Testimonial } from '../shared'
import Hero from './Hero'
import WhyZus from './WhyZustech'
import Cta from './Cta'
import Careers from './Careers'
import CourseCategories from './CourseCategories'
import StartCareer from './StartCareer/StartCareer'

import './style.scss'

export const HomePage = () => {
    return (
        <>
            <Hero />
            <CourseCategories />
            <StartCareer />
            <WhyZus />
            <Testimonial />
            <Careers />
            <Cta />
        </>
    )
}
