import {
    GET_INSTRUCTOR_ID,
    FETCH_DATA,
    FETCH_FAILED,
    REQUEST_PASSED,
    GET_INSTRUCTOR_LESSON,
    GET_INSTRUCTOR_MODULE,
    GET_INSTRUCTOR_COURSE,
    GET_COURSE_CATEGORIES,
    GET_LANGUAGES,
    GET_INSTRUCTOR_APPLICATION_STATUS,
    SET_FILTERD_COURSE,
    SET_FILTERD
} from './types'
import {
    findModuleByInstructorId,
    findCourseByInstructorId,
    FindLessonsByInstructorId,
    getCourseCategory,
    findAllLanguages,
    findInstructorByUserId,
} from '../../../services'

export const getAllCourses = (value, signal) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await findCourseByInstructorId(value, 0, 1, 100, signal)
        dispatch({ type: GET_INSTRUCTOR_COURSE, payload: res.data })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const getInstructorID = (value, signal) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await findInstructorByUserId(value, signal)
        dispatch({ type: GET_INSTRUCTOR_ID, payload: res.data.id })
        dispatch({ type: GET_INSTRUCTOR_APPLICATION_STATUS, payload: res.data.instructorApplicationStatus })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const getAllModules = (value) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await findModuleByInstructorId(value, 100, 1)
        dispatch({ type: GET_INSTRUCTOR_MODULE, payload: res.data })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const getAllLessons = (value, signal) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await FindLessonsByInstructorId(value, signal)
        dispatch({ type: GET_INSTRUCTOR_LESSON, payload: res.data })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const getAllLanguages = (signal) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await findAllLanguages(signal)
        dispatch({ type: GET_LANGUAGES, payload: res.data })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const getAllCourseCategory = (signal) => async (dispatch) => {
    dispatch({ type: FETCH_DATA })
    try {
        const res = await getCourseCategory(signal)
        dispatch({ type: GET_COURSE_CATEGORIES, payload: res.data })
        dispatch({ type: REQUEST_PASSED })
    } catch (err) {
        dispatch({ type: FETCH_FAILED, payload: err.message })
    }
}

export const setFilteredCourse = (courses) => (dispatch) => {
    dispatch({ type: SET_FILTERD_COURSE, payload: courses })
}

export const setFiltered = (bool) => (dispatch) => {
    dispatch({ type: SET_FILTERD, payload: bool })
}