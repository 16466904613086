import { Layout } from 'antd'
import './SideBar.scss'

export const SideBar = (props) => {
    const { menu, showSideBar, isCollapsed, collapseToggle } = props

    return (
        <Layout.Sider
            id="Dashboard_sidebar"
            className={`${showSideBar ? 'Dashboard_sidebar' : 'close-sidebar'}`}
            breakpoint="sm"
            theme="light"
            width={250}
            // collapsible
            collapsed={isCollapsed}
            trigger={null}
        >
            <button className="Dashboard_trigger" onClick={collapseToggle}>
                {isCollapsed ? (
                    <svg
                        width="7"
                        height="15"
                        viewBox="0 0 7 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1L5 7.36735L1 14"
                            stroke="#828282"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="7"
                        height="15"
                        viewBox="0 0 7 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 14L2 7.63265L6 1"
                            stroke="#828282"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                )}
            </button>
            {menu}
        </Layout.Sider>
    )
}
