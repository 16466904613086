import React, { useEffect, useState, useRef } from 'react'
import { Table, DatePicker, Select, Modal } from 'antd';
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from 'recharts'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import InvoiceContent from './invoiceComponent/invoiceContent';
import Barchart from './chartComponent/barchart'
import Blankpage from './blankPage/Blankpage';
import { RequestLoader } from '../../../components/Loading'
import {
    getCourseRevenueByInstructorId,
    findWalletByUserId,
    getInstructorTransactionReport,
    getInstructorMonthlyRevenue
} from '../../../services'
import {
    useAuth,
    useInstructorData
} from '../../../hooks'
// import JsIcon from '../../../assets/icons/jsIcon.svg'
// import PythonIcon from '../../../assets/icons/pythonIcon.svg'
// import PhotoshopIcon from '../../../assets/icons/photoshop.svg'
import UxIcon from '../../../assets/icons/uiuxIcon.svg'
import BackIcon from '../../../assets/icons/Back_button.svg'
import Calendar from '../../../assets/icons/ion_calendar.svg'
import BackArrow from '../../../assets/icons/back_arrow.svg'
import DownloadIcon from '../../../assets/icons/download_icon.svg'
import Zustech from '../../../assets/icons/company_logo.svg'
import './style.scss'

const { RangePicker } = DatePicker;

export default function PaymentDashboard() {
    const [showFullTable, setShowFullTable] = useState(false)
    const [isLoading, setIsLoading] = useState()
    const [showInvoice, setShowInvoice] = useState(false)
    const [fixedRange, setFixedRange] = useState('All')
    const [chartRange, setChartRange] = useState('Year')
    const [datePickerParam, setDatePickerParam] = useState('week')
    const [currentView, setCurrrentView] = useState('Overview')
    const [downloadModal, setDownloadModal] = useState(false)
    const [instructorTransactionHistory, setInstructorTransactionHistory] = useState([])
    const [viewData, setViewData] = useState(null)
    const [courseRevenue, setCourseRevenue] = useState([])
    const [instructorBaln, setInstructorBaln] = useState(0)
    const [dateRangeValue, setDateRangeValue] = useState(null)
    const [invalidInput, setInvalidInput] = useState(false)

    const {
        state: { data }
    } = useAuth()
    const {
        state: { courses, instructorID },
        getAllCourses,
    } = useInstructorData()

    useEffect(() => {
        const getCoursesRevenue = async (instructorId) => {
            const allCourseWithRevenue = []
            try {
                const typeOneCourse = await getCourseRevenueByInstructorId(1, instructorId)
                if (typeOneCourse.status === true) {
                    typeOneCourse.data.forEach(courseInfo => {
                        const updatedCourseInfo = { ...courseInfo, 'courseType': 1 }
                        allCourseWithRevenue.push(updatedCourseInfo)
                    })
                }
            } catch (err) {
                console.error('Error occured while fetching', err.message)
            }
            try {
                const typeTwoCourse = await getCourseRevenueByInstructorId(2, instructorId)
                if (typeTwoCourse.status === true) {
                    typeTwoCourse.data.forEach(courseInfo => {
                        const updatedCourseInfo = { ...courseInfo, 'courseType': 2 }
                        allCourseWithRevenue.push(updatedCourseInfo)
                    })
                }
            } catch (err) {
                console.error('Error occured while fetching', err.message)
            }
            try {
                const typeThreeCourse = await getCourseRevenueByInstructorId(3, instructorId)
                if (typeThreeCourse.status === true) {
                    typeThreeCourse.data.forEach(courseInfo => {
                        const updatedCourseInfo = { ...courseInfo, 'courseType': 3 }
                        allCourseWithRevenue.push(updatedCourseInfo)
                    })
                }
            } catch (err) {
                console.error('Error occured while fetching', err.message)
            }
            return allCourseWithRevenue
        }

        const course = getCoursesRevenue(instructorID)
        course.then(data => {
            setCourseRevenue(data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    useEffect(() => {
        const fetchInstructorCourse = async () => {
            await getAllCourses(instructorID)
        }

        if (courses.length < 1) {
            fetchInstructorCourse()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    useEffect(() => {
        setIsLoading(true)
        function setInitiaData(courses, courseType, coursRev) {
            const output = {
                'topSection': [],
                'courses': [],
                'pieChartData': [],
                'chartKey': []
            }
            const getNumOfStudent = (x) => {
                let total = 0
                x.forEach(cors => {
                    total += cors.students
                })

                return total
            }
            const getTotalRevenue = (x) => {
                let total = 0
                x.forEach(cors => {
                    total += cors.amount
                })

                return total
            }
            const setPieData = (arr) => {
                const basevalue = getTotalRevenue(arr)
                arr.forEach((cour, idx) => {
                    const pieValue = {
                        'name': cour.courseName,
                        'value': Math.floor((cour.amount / basevalue) * 360),
                        'label': `${((cour.amount / basevalue) * 100)}%`,
                        'fill': `symbol_${idx + 1}`,
                    }
                    const chartKey = {
                        'class': `symbol_${idx + 1}`,
                        'value': cour.courseName,
                    }

                    output.pieChartData.push(pieValue)
                    output.chartKey.push(chartKey)
                })
            }
            const totalRevenue = getTotalRevenue(coursRev)
            output.topSection.push({ 'name': 'Total Earning', 'value': `$${totalRevenue}` })
            if (courses.length >= 1) {
                const numStud = getNumOfStudent(courses)
                switch (courseType) {
                    case 'Overview':
                        output.topSection.push({ 'name': 'My Balance', 'value': `$${instructorBaln}` })
                        output.topSection.push({ 'name': 'Total no. of enrolled students', 'value': numStud })
                        break;
                    case 'Multi-course subscriptions':
                        console.log('here 2')
                        output.topSection.push({ 'name': 'No of students to have completed the course', 'value': numStud })
                        break;
                    case 'Single-course subscriptions':
                        output.topSection.push({ 'name': 'Total no. of Subscribed students', 'value': numStud })
                        break;
                    case 'One-time purchase Course':
                        output.topSection.push({ 'name': 'Total no. of Purchased Courses', 'value': numStud })
                        break;
                    default:
                        break;
                }
            }
            coursRev.forEach((course, idx) => {
                const newFormat = {
                    'key': idx + 1,
                    'courseIcon': <img src={UxIcon} alt="" />,
                    'courseName': course.courseName,
                    'courseAmount': `$${course.amount}`
                }

                output.courses.push(newFormat)
            })
            setPieData(coursRev)

            return output
        }

        const getTransactionHistory = async () => {
            try {
                const requestData = {
                    'transactionRef': '',
                    'vendor': 1,
                    'transactionStatus': 1,
                    'fromDate': '2023-01-01',
                    'toDate': '2024-02-28'
                }
                const history = await getInstructorTransactionReport(requestData)
                if (history.status) {
                    return history.data
                } else {
                    return false
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        const getInstructorPaymentMonthly = async (instructorId) => {
            try {
                const { data } = await getInstructorMonthlyRevenue(instructorId)
                const months = {
                    1: { long: 'January', short: 'Jan' },
                    2: { long: 'February', short: 'Feb' },
                    3: { long: 'March', short: 'Mar' },
                    4: { long: 'April', short: 'Apr' },
                    5: { long: 'May', short: 'May' },
                    6: { long: 'June', short: 'Jun' },
                    7: { long: 'July', short: 'Jul' },
                    8: { long: 'August', short: 'Aug' },
                    9: { long: 'September', short: 'Sep' },
                    10: { long: 'October', short: 'Oct' },
                    11: { long: 'November', short: 'Nov' },
                    12: { long: 'December', short: 'Dec' }
                };
                if (data.length >= 1) {
                    const newData = data.map(value => ({ ...months[value.month], amount: value.amount }))
                    return newData
                } else {
                    return []
                }
            } catch (err) {
                console.error(err.message)
                return false
            }
        }

        if (currentView === 'Overview' && courseRevenue.length >= 1) {
            const data = setInitiaData(courses, currentView, courseRevenue)
            const transactionHistory = getTransactionHistory()
            transactionHistory.then((transact) => {
                if (typeof transact !== 'boolean') {
                    const tableFormat = []
                    const instructorPay = transact.filter(transction => transction.transactionType === 3)
                    instructorPay.forEach((transact, idx) => {
                        const obj = {
                            'key': idx + 1,
                            'title': transact.description,
                            'date': '',
                            'amount': `$${transact.amount}`,
                            'status': transact.status
                        }
                        tableFormat.push(obj)
                    })
                    setInstructorTransactionHistory(tableFormat)
                }
                setViewData(data)
                setIsLoading(false)
            })
        } else {
            setIsLoading(true)
            const specificCourseRevenue = []
            if (currentView === 'Multi-course subscriptions') {
                courseRevenue.forEach((revenue) => {
                    if (revenue.courseType === 1) {
                        specificCourseRevenue.push(revenue)
                    }
                })
            } else if (currentView === 'Single-course subscriptions') {
                courseRevenue.forEach((revenue) => {
                    if (revenue.courseType === 3) {
                        specificCourseRevenue.push(revenue)
                    }
                })
            } else if (currentView === 'One-time purchase Course') {
                courseRevenue.forEach((revenue) => {
                    if (revenue.courseType === 2) {
                        specificCourseRevenue.push(revenue)
                    }
                })
            }

            if (specificCourseRevenue.length >= 1) {
                const data = setInitiaData(courses, currentView, specificCourseRevenue)
                const monthPayment = getInstructorPaymentMonthly(instructorID)
                monthPayment.then(res => {
                    if (res.length >= 1) {
                        data['monthlyPay'] = res
                        setViewData(data)
                    }
                })
            } else {
                setViewData(null)
            }
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseRevenue, currentView])

    useEffect(() => {
        const getInstructorBaln = async (userId) => {
            const usersBaln = await findWalletByUserId(userId)
            setInstructorBaln(usersBaln.data.ledgerBalance)
        }

        getInstructorBaln(data.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id])

    const pdfRef = useRef()

    // const initialData = {
    //     'topSection': [{ 'name': 'Total Earning', 'value': '$120,000' }, { 'name': 'My Balance', 'value': '$64,000' }, { 'name': 'Total no. of enrolled students', 'value': '20,000' }],
    //     'courses': [
    //         {
    //             key: 1,
    //             courseIcon: <img src={UxIcon} alt="" />,
    //             courseName: 'UI/UX Design masterclass',
    //             courseAmount: '$210,000'
    //         },
    //         {
    //             key: 2,
    //             courseIcon: <img src={PythonIcon} alt="" />,
    //             courseName: 'Python for beginners',
    //             courseAmount: '$60,000'
    //         },
    //         {
    //             key: 3,
    //             courseIcon: <img src={JsIcon} alt="" />,
    //             courseName: 'Javascript advanced',
    //             courseAmount: '$30,000'
    //         },
    //     ],
    //     'pieChartData': [
    //         {
    //             name: 'Javascript',
    //             value: Math.floor(0.1 * 360),
    //             label: '10%'
    //         },
    //         {
    //             name: 'Python',
    //             value: Math.floor(0.2 * 360),
    //             label: '20%'
    //         },
    //         {
    //             name: 'Ui/Ux',
    //             value: Math.floor(0.7 * 360),
    //             label: '70%'
    //         },
    //     ],
    //     'chartKey': [
    //         {
    //             class: 'symbol_1',
    //             value: 'UI/UX Design masterclass'
    //         },
    //         {
    //             class: 'symbol_2',
    //             value: 'Python for beginners'
    //         },
    //         {
    //             class: 'symbol_3',
    //             value: 'Javascript advanced'
    //         }
    //     ]
    // }

    const downloadPDF = () => {
        const input = pdfRef.current
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new JsPDF('l', 'mm', 'a4', true)
            const pdfWidth = 297 // A4 width in mm
            const pdfHeight = 210; // A4 height in mm
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgX = 0;
            const imgY = (pdfHeight - imgHeight) / 2;
            pdf.addImage(imgData, 'JPEG', imgX, imgY, imgWidth, imgHeight);
            pdf.save('invoice.pdf');
            // pdf.addImage(imgData, 'PNG', 1, 17, 300, 170, 'FAST')
            // pdf.save('certificate.pdf')
        })
    }

    // function changeData(value) {
    //     setCurrrentView(value)
    //     switch (value) {
    //         case 'One-time purchase Course':
    //             setViewData({
    //                 'topSection': [{ 'name': 'Total Course Earnings', 'value': '$42,000' }, { 'name': 'Total no. of Purchased Courses', 'value': '2,000' }],
    //                 'courses': [{
    //                     key: 1,
    //                     courseIcon: <img src={JsIcon} alt="" />,
    //                     courseName: 'Javascript advanced',
    //                     courseAmount: '$42,000'
    //                 }],
    //                 'pieChartData': [{
    //                     name: 'Javascript',
    //                     value: Math.floor(1 * 360),
    //                     label: '100%'
    //                 }],
    //                 'chartKey': [{
    //                     class: 'symbol_3',
    //                     value: 'Javascript advanced'
    //                 }],
    //                 'chartData': [
    //                     {
    //                         'month': 'Jan',
    //                         'amount': 234,
    //                         'full': 'January'
    //                     },
    //                     {
    //                         'month': 'Feb',
    //                         'amount': 1052,
    //                         'full': 'Febuary'
    //                     },
    //                     {
    //                         'month': 'Mar',
    //                         'amount': 589,
    //                         'full': 'March'
    //                     },
    //                     {
    //                         'month': 'Apr',
    //                         'amount': 2000,
    //                         'full': 'April'
    //                     },
    //                     {
    //                         'month': 'May',
    //                         'amount': 301,
    //                         'full': 'May'
    //                     },
    //                     {
    //                         'month': 'Jun',
    //                         'amount': 695,
    //                         'full': 'June'
    //                     },
    //                     {
    //                         'month': 'Jul',
    //                         'amount': 876,
    //                         'full': 'July'
    //                     },
    //                     {
    //                         'month': 'Aug',
    //                         'amount': 1560,
    //                         'full': 'August'
    //                     },
    //                     {
    //                         'month': 'Sept',
    //                         'amount': 1264,
    //                         'full': 'September'
    //                     },
    //                     {
    //                         'month': 'Oct',
    //                         'amount': 845,
    //                         'full': 'October'
    //                     },
    //                     {
    //                         'month': 'Nov',
    //                         'amount': 950,
    //                         'full': 'November'
    //                     },
    //                     {
    //                         'month': 'Dec',
    //                         'amount': 460,
    //                         'full': 'December'
    //                     },
    //                 ]
    //             })
    //             break
    //         case 'Multi-course subscriptions':
    //             setViewData({
    //                 'topSection': [{ 'name': 'Total Course Earnings', 'value': '$14,035' }, { 'name': 'No of students to have completed the course', 'value': '7,500' }],
    //                 'courses': [{
    //                     key: 1,
    //                     courseIcon: <img src={PythonIcon} alt="" />,
    //                     courseName: 'Python for beginners',
    //                     courseAmount: '$14,035'
    //                 }],
    //                 'pieChartData': [{
    //                     name: 'Python',
    //                     value: Math.floor(1 * 360),
    //                     label: '100%'
    //                 }],
    //                 'chartKey': [{
    //                     class: 'symbol_2',
    //                     value: 'Python for beginners'
    //                 }],
    //                 'chartData': [
    //                     {
    //                         'month': 'Jan',
    //                         'amount': 1020,
    //                         'full': 'January'
    //                     },
    //                     {
    //                         'month': 'Feb',
    //                         'amount': 1680,
    //                         'full': 'Febuary'
    //                     },
    //                     {
    //                         'month': 'Mar',
    //                         'amount': 900,
    //                         'full': 'March'
    //                     },
    //                     {
    //                         'month': 'Apr',
    //                         'amount': 845,
    //                         'full': 'April'
    //                     },
    //                     {
    //                         'month': 'May',
    //                         'amount': 678,
    //                         'full': 'May'
    //                     },
    //                     {
    //                         'month': 'Jun',
    //                         'amount': 2890,
    //                         'full': 'June'
    //                     },
    //                     {
    //                         'month': 'Jul',
    //                         'amount': 648,
    //                         'full': 'July'
    //                     },
    //                     {
    //                         'month': 'Aug',
    //                         'amount': 2180,
    //                         'full': 'August'
    //                     },
    //                     {
    //                         'month': 'Sept',
    //                         'amount': 300,
    //                         'full': 'September'
    //                     },
    //                     {
    //                         'month': 'Oct',
    //                         'amount': 809,
    //                         'full': 'October'
    //                     },
    //                     {
    //                         'month': 'Nov',
    //                         'amount': 489,
    //                         'full': 'November'
    //                     },
    //                     {
    //                         'month': 'Dec',
    //                         'amount': 700,
    //                         'full': 'December'
    //                     },
    //                 ]
    //             })
    //             break
    //         case 'Single-course subscriptions':
    //             setViewData({
    //                 'topSection': [{ 'name': 'Total Course Earnings', 'value': '$48,000' }, { 'name': 'Total no. of Subscribed students', 'value': '8,000' }],
    //                 'courses': [
    //                     {
    //                         key: 1,
    //                         courseIcon: <img src={UxIcon} alt="" />,
    //                         courseName: 'UI/UX Design masterclass',
    //                         courseAmount: '$40,002'
    //                     },
    //                     {
    //                         key: 2,
    //                         courseIcon: <img src={PhotoshopIcon} alt="" />,
    //                         courseName: 'Photoshop Beginners masterclass',
    //                         courseAmount: '$7,998'
    //                     }
    //                 ],
    //                 'pieChartData': [
    //                     {
    //                         name: 'Ui/Ux',
    //                         value: Math.floor(0.7 * 360),
    //                         label: '70%'
    //                     },
    //                     {
    //                         name: 'Photoshop',
    //                         value: Math.floor(0.3 * 360),
    //                         label: '30%'
    //                     },
    //                 ],
    //                 'chartKey': [
    //                     {
    //                         class: 'symbol_1',
    //                         value: 'UI/UX Design masterclass'
    //                     },
    //                     {
    //                         class: 'symbol_4',
    //                         value: 'Photoshop Beginners masterclass'
    //                     }
    //                 ],
    //                 'chartData': [
    //                     {
    //                         'month': 'Jan',
    //                         'amount': 999,
    //                         'full': 'January'
    //                     },
    //                     {
    //                         'month': 'Feb',
    //                         'amount': 1220,
    //                         'full': 'Febuary'
    //                     },
    //                     {
    //                         'month': 'Mar',
    //                         'amount': 825,
    //                         'full': 'March'
    //                     },
    //                     {
    //                         'month': 'Apr',
    //                         'amount': 900,
    //                         'full': 'April'
    //                     },
    //                     {
    //                         'month': 'May',
    //                         'amount': 670,
    //                         'full': 'May'
    //                     },
    //                     {
    //                         'month': 'Jun',
    //                         'amount': 2200,
    //                         'full': 'June'
    //                     },
    //                     {
    //                         'month': 'Jul',
    //                         'amount': 600,
    //                         'full': 'July'
    //                     },
    //                     {
    //                         'month': 'Aug',
    //                         'amount': 1200,
    //                         'full': 'August'
    //                     },
    //                     {
    //                         'month': 'Sept',
    //                         'amount': 720,
    //                         'full': 'September'
    //                     },
    //                     {
    //                         'month': 'Oct',
    //                         'amount': 260,
    //                         'full': 'October'
    //                     },
    //                     {
    //                         'month': 'Nov',
    //                         'amount': 850,
    //                         'full': 'November'
    //                     },
    //                     {
    //                         'month': 'Dec',
    //                         'amount': 500,
    //                         'full': 'December'
    //                     },
    //                 ]
    //             })
    //             break
    //         default:
    //             setViewData(initialData)
    //     }
    // }

    const courseDropDownItem = [
        {
            lable: <h2>Overview</h2>,
            value: 'Overview'
        },
        {
            lable: <h2>Multi-course subscriptions</h2>,
            value: 'Multi-course subscriptions'
        },
        {
            lable: <h2>Single-course subscriptions</h2>,
            value: 'Single-course subscriptions'
        },
        {
            lable: <h2>One-time purchase Course</h2>,
            value: 'One-time purchase Course'
        },
    ]

    const tableOneColumns = [
        {
            title: '',
            dataIndex: 'courseIcon',
            key: 'crsIcon',
            width: 100
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'crs',
        },
        {
            title: 'Amount',
            dataIndex: 'courseAmount',
            key: 'amt',
            width: 300
        },
    ];

    const tableTwoColumns = [
        {
            title: 'Title/Type',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date and time',
            dataIndex: 'date',
            key: 'DNT',
            width: 200,
            sorter: (a, b) => a.title.length - b.title.length,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amt',
            width: 200,
            sorter: (a, b) => a.title.length - b.title.length,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            sorter: (a, b) => a.title.length - b.title.length,
            sortDirections: ['descend', 'ascend'],
            render: (_, status) => {
                const eleProp = {}
                // console.log(status)

                switch (status.status) {
                    case 3:
                        eleProp['color'] = 'rgba(196, 3, 3, 1)'
                        eleProp['bgColor'] = 'rgba(196, 3, 3, 0.1)'
                        eleProp['text'] = 'Failed'
                        break;
                    case 2:
                        eleProp['color'] = '#02AD13'
                        eleProp['bgColor'] = 'rgba(2, 173, 19, 0.1)'
                        eleProp['text'] = 'Processing'
                        break;
                    case 1:
                        eleProp['color'] = 'rgba(58, 134, 255, 1)'
                        eleProp['bgColor'] = 'rgba(58, 134, 255, 0.1)'
                        eleProp['text'] = 'Paid'
                        break;
                    case 4:
                        eleProp['color'] = '#FFBF00'
                        eleProp['bgColor'] = 'rgba(234, 187, 0, 0.1)'
                        eleProp['text'] = 'Due'
                        break;
                    default:
                        eleProp['color'] = '#FFBF00'
                        eleProp['bgColor'] = 'rgba(234, 187, 0, 0.1)'
                        eleProp['text'] = 'Due'
                        break;
                }

                return (
                    <p
                        style={{
                            backgroundColor: `${eleProp.bgColor}`,
                            color: `${eleProp.color}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: '600',
                            width: '70%',
                            margin: '0 auto',
                            height: '30px',
                            borderRadius: '8px'
                        }}
                    >{eleProp.text}
                    </p>
                )
            }
        },
        {
            title: '',
            dataIndex: 'more',
            key: 'moreOptions',
            width: 100,
            render: () => {
                return (<div onClick={() => { setShowInvoice(true) }} style={{ fontWeight: '700', cursor: 'pointer' }}>...</div>)
            }
        },
    ];

    const dropDownMenuItems = [
        {
            key: 1,
            lable: 'All',
            value: 'All'
        },
        {
            key: 2,
            lable: 'Last 7 days',
            value: 'Last 7 days'
        },
        {
            key: 3,
            lable: 'Last 2 weeks',
            value: 'Last 2 weeks'
        },
        {
            key: 4,
            lable: 'Last 1 month',
            value: 'Last 1 month'
        },
        {
            key: 5,
            lable: 'Last 3 months',
            value: 'Last 3 months'
        },
        {
            key: 6,
            lable: 'Last 1 year',
            value: 'Last 1 year'
        }
    ]

    const customRangeOption = [
        {
            key: 1,
            lable: 'Week',
            value: 'week'
        },
        {
            key: 2,
            label: 'Monthly',
            value: 'month'
        },
        {
            key: 3,
            label: 'Quarter',
            value: 'quarter'
        },
        {
            key: 4,
            label: 'Year',
            value: 'year'
        }
    ]

    const pieColors = {
        'symbol_1': '#67C587',
        'symbol_2': '#EAF6ED',
        'symbol_3': '#C9EAD4',
        'symbol_4': '#616864'
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    if (isLoading) {
        return (
            <div id="payment_loader_cont">
                <RequestLoader />
            </div>
        )
    }

    return (
        <main id={showInvoice ? 'invoice_page' : 'revenue_page'}>
            {(!showFullTable && !showInvoice) &&
            <>
                <section className="top_control">
                    <div className="info">
                        <Select
                            defaultValue="Overview"
                            style={{ minWidth: 180, fontSize: '1.7em', fontWeight: 700, color: '#3a86ff' }}
                            bordered={false}
                            options={courseDropDownItem}
                            onChange={(e) => {
                                setCurrrentView(e)
                            }}
                        />
                        <p>Revenue and payment details <span>({fixedRange})</span></p>
                    </div>
                    {viewData &&
                    <div className="main_control">
                        <div className="selector_dropDown">
                            <div className="img_cont">
                                <img src={Calendar} alt="" />
                            </div>
                            <Select
                                id="custom_select"
                                size="middle"
                                value={fixedRange}
                                onChange={(value) => {
                                    setFixedRange(value)
                                }}
                                bordered={false}
                                style={{
                                    width: '86%'
                                }}
                                options={dropDownMenuItems}
                            />
                        </div>
                        <button
                            id="custom_range"
                            onClick={() => {
                                setDownloadModal(true)
                            }}
                        > Download Custom Report
                        </button>
                    </div>}
                </section>
                {viewData &&
                <section className="main_report">
                    <div className="leftSide">
                        <div className="upper_breakDown">
                            {viewData && viewData.topSection.map((item, idx) => {
                                return (
                                    <div key={idx} className="summary_cont">
                                        <h5>{item.name}</h5>
                                        <p>{item.value}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="my_course">
                            <div className="table_hrd">
                                <p>My Course</p>
                            </div>
                            <Table
                                dataSource={viewData?.courses}
                                columns={tableOneColumns}
                                size="small"
                                pagination={{
                                    position: ['none', 'none'],
                                }}
                                rowClassName="table_row"
                            />
                        </div>
                    </div>
                    <div className="course_distribution">
                        <h2>Course distribution</h2>
                        <ResponsiveContainer height="70%" width="100%">
                            <PieChart key={Math.random() * 10}>
                                <Pie
                                    data={viewData?.pieChartData}
                                    dataKey="value"
                                    cx="40%"
                                    cy="50%"
                                    innerRadius={0}
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                    key="Hello"
                                >
                                    {
                                        viewData && viewData.pieChartData.map((entry, index) => (
                                            <>
                                                <Cell key={`cell-${index}`} fill={pieColors[entry.fill]} />
                                            </>
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="chart_key">
                            {viewData && viewData.chartKey.map((item, idx) => {
                                return (
                                    <div key={idx} className="key">
                                        <div className={`symbol ${item.class}`}>&nbsp;</div>
                                        <p>{item.value}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>}
                {!viewData && <Blankpage spec={currentView} />}
                {(!isLoading && currentView === 'Overview' && viewData !== null) &&
                <section className="payment_history">
                    <div className="history_header">
                        <span>Payment History</span>
                        <div>
                            <span>{fixedRange}</span>
                            <button
                                onClick={() => {
                                    setShowFullTable(true)
                                }}
                            >View all
                            </button>
                        </div>
                    </div>
                    <Table
                        dataSource={instructorTransactionHistory}
                        columns={tableTwoColumns}
                        // footer="undefined"
                        pagination={{
                            position: ['none', 'none']
                        }}
                        scroll={{
                            y: 340,
                        }}
                    />
                </section>}
                {(!isLoading && currentView !== 'Overview' && viewData !== null) &&
                    <section
                        className="data_rep"
                    >
                        <div className="data_chart">
                            <div className="chart_header">
                                <p>Revenue Distribution</p>
                                <Select
                                    id="chart_select"
                                    size="middle"
                                    value={chartRange}
                                    onChange={(value) => {
                                        setChartRange(value)
                                    }}
                                    bordered={false}
                                    style={{
                                        width: '12%',
                                        color: '#3a86ff',
                                    }}
                                    options={customRangeOption}
                                />
                            </div>
                            <Barchart data={viewData?.monthlyPay} />
                            {/* <h1>Chart will be here</h1> */}
                        </div>
                        <div className="student_detail">
                            <h2>Earnings per month</h2>
                            {viewData.monthlyPay && viewData.monthlyPay.map((months, idx) => {
                                return (
                                    <div key={idx} className="month_card">
                                        <div className="glassy">{months.long}</div>
                                        <p>{`$${months.amount}`}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </section>}
            </>}
            {(showFullTable && !showInvoice) &&
            <>
                <section className="full_table_sec">
                    <div className="first_layer_cont">
                        <div className="nav_back">
                            <div
                                className="img_cont"
                                onClick={() => {
                                    setShowFullTable(false)
                                }}
                            >
                                <img src={BackIcon} alt="" />
                            </div>
                            <p>Payment History</p>
                        </div>
                        <div className="controls">
                            <div className="filter_cont">
                                <Select
                                    size="middle"
                                    value="Filter"
                                    // onChange={(value) => {
                                    //     setFixedRange(value)
                                    // }}
                                    bordered={false}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={dropDownMenuItems}
                                />
                            </div>
                            <div className="field_cont">
                                <Select
                                    size="middle"
                                    value={fixedRange}
                                    onChange={(value) => {
                                        setFixedRange(value)
                                    }}
                                    bordered={false}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={dropDownMenuItems}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table_cont">
                        <Table
                            dataSource={instructorTransactionHistory}
                            columns={tableTwoColumns}
                            pagination={{
                                pageSize: 5,
                                position: ['none', 'bottomCenter'],
                            }}
                            scroll={{
                                y: 340,
                            }}
                        />
                    </div>
                </section>
            </>}
            {showInvoice &&
            <section id="invoice_cont">
                <div className="upperSection">
                    <div className="invoice_unique_info">
                        <div className="top">
                            <h2>Invoice</h2>
                            <span>#346JK56</span>
                        </div>
                        <div className="lower">
                            <p>Created: 12 July, 2023. 03:03 PM</p>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setShowInvoice(false)
                            setShowFullTable(false)
                        }}
                        className="button_like"
                    >
                        <div className="img_cont">
                            <img src={BackArrow} alt="backIcon" />
                        </div>
                        <p>Back</p>
                    </div>
                </div>
                <section className="invoice_detail">
                    <div className="invoice_header">
                        <div className="company_logo">
                            <div className="img_cont">
                                <img src={Zustech} alt="company_logo" />
                            </div>
                        </div>
                        <div
                            className="downloadDocument"
                            onClick={() => {
                                // eslint-disable-next-line
                                // window.alert('Download Functionality not yet available')
                                downloadPDF()
                            }}
                            // onClick={downloadPDF}
                        >
                            <p>Download</p>
                            <div className="img_cont">
                                <img src={DownloadIcon} alt="download_img" />
                            </div>
                        </div>
                    </div>
                    <InvoiceContent invoiceRef={pdfRef} />
                </section>
            </section>}
            <Modal
                className="DownloadReportModal"
                centered
                footer={null}
                open={downloadModal}
                style={{
                    textAlign: 'center',
                }}
                onCancel={() => {
                    setDownloadModal(false)
                }}
            >
                <h4 style={{ color: '#3a86ff' }}>Select Report Duration</h4>
                <div className="custom_range">
                    <Select
                        size="middle"
                        value={datePickerParam}
                        onChange={(value) => {
                            setDatePickerParam(value)
                        }}
                        style={{
                            width: '100%',
                        }}
                        options={customRangeOption}
                        bordered={false}
                    />
                </div>
                <div className={invalidInput ? 'colorRed selector_dropDown' : 'selector_dropDown'}>
                    <RangePicker
                        picker={datePickerParam}
                        bordered={false}
                        onChange={(dates, dateStrings) => {
                            if (dates) {
                                console.log('From: ', dates[0], ', to: ', dates[1]);
                                // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
                                setDateRangeValue([dateStrings[0], dateStrings[1]])
                            } else {
                                console.log('Clear');
                            }
                        }}
                    />
                </div>
                <section className="btn_cont">
                    <button
                        id="reportSummary"
                        onClick={() => {
                            if (dateRangeValue !== null) {
                                console.log('Download of custom report is not availabe at the mom')
                            } else {
                                setInvalidInput(true)
                                setTimeout(() => {
                                    setInvalidInput(false)
                                }, 3000)
                            }
                        }}
                    > Download Report
                    </button>
                </section>
            </Modal>
        </main>
    )
}