import { Link, useHistory } from 'react-router-dom'

import tick from './assets/tick-icon.png'
import graduate from './assets/graduate.jpg'

import './StartCareer.scss'

const StartCareer = () => {
    const history = useHistory()

    async function handleSubmit(event) {
        event.preventDefault()
        history.push('/pricing')
    }

    return (
        <section id="start-career">
            <div className="top-bar">
                <div className="title-container">
                    <h2 className="start-title">START YOUR CAREER AT</h2>
                    <h2 className="zustech-title">ZUSTECH</h2>
                </div>
            </div>
            <div className="mid-paragraph-container">
                <p className="mid-paragraph">
                    <p className="paragraph">
                        Start learning today at Zustech and learn core skills in:
                        Business Analysis, Project Management, Software Testing,
                        Test Automation and more!
                    </p>
                </p>
            </div>
            <div className="third_part">
                <h2 className="start-title-bottom">Let's get you started...</h2>
            </div>
            <div className="bottom-bar">
                <div className="form-container">
                    <form className="start-form">
                        <input
                            onSubmit={handleSubmit}
                            placeholder="Enter your email"
                            type="text"
                            className="start-input"
                            id="start-input"
                        />
                        <Link to="/pricing">
                            <button type="submit" className="start-submit-btn ">
                                Start Free Trial
                            </button>
                        </Link>
                    </form>
                    <div className="under-email-info">
                        <div className="info-section">
                            <div className="info-tick">
                                <img src={tick} alt="tick" />
                            </div>
                            <p className="info-text">Powered by Zustech</p>
                        </div>
                        <div className="info-section">
                            <div className="info-tick">
                                <img src={tick} alt="tick" />
                            </div>
                            <p className="info-text">30 / 7 day free trial</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="img_cont">
                <img className="graduate-img" src={graduate} alt="graduate" />
            </div>
            {/* <div className="hero-left">
                <form className="cta-form">
                    <input
                        placeholder="Enter your email"
                        type="text"
                        className="cta-input"
                        id="cta-input"
                    />
                    <button type="submit" className="cta-submit-btn btn">
                        Start Free Trial
                    </button>
                </form>
                <div className="feature-points">
                    <ul>
                        <li className="bullet">Powered by Zustech</li>
                    </ul>
                    <ul>
                        <li className="bullet">30 / 7 day free trial</li>
                    </ul>
                </div>
            </div> */}
        </section>
    )
}

export default StartCareer
