import {
    SET_LESSONS,
    SET_MODULE_SUMMARY,
    SET_MODULE_TITLE,
    CLEAR_MODULE_FORM
} from './types'

export const setModuleTitle = (inp) => {
    return {
        type: SET_MODULE_TITLE,
        payload: inp
    }
}
export const setModuleSummary = (inp) => {
    return {
        type: SET_MODULE_SUMMARY,
        payload: inp
    }
}
export const setLesson = (inp) => {
    return {
        type: SET_LESSONS,
        payload: inp
    }
}
export const clearModuleForm = () => {
    return {
        type: CLEAR_MODULE_FORM
    }
}